import { YyyyMmDd } from "@m7-health/shared-utils";
import { createSlice } from "@reduxjs/toolkit";

import { IOpenShiftsSidebarState, TOpenShiftsSidebarFilters } from "../types";

import { initialState } from "./initialState";

import type { PayloadAction } from "@reduxjs/toolkit";

export const openShiftsSidebar = createSlice({
  name: "openShiftsSidebar",
  initialState,
  reducers: {
    resetOpenShiftSidebarState: () => initialState,
    setOpenShiftsSidebarData: (
      state,
      action: PayloadAction<Partial<IOpenShiftsSidebarState["data"]>>,
    ) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
    setSidebarSelectedDate: (state, action: PayloadAction<YyyyMmDd | null>) => {
      state.selectedDate = action.payload;
    },
    setSidebarFilters: (state, action: PayloadAction<Partial<TOpenShiftsSidebarFilters>>) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    setApproveOpenShiftModalOpen: (state, action: PayloadAction<boolean>) => {
      state.modals.approveOpenShiftModal.isOpen = action.payload;
    },
    setRejectOpenShiftModalOpen: (state, action: PayloadAction<boolean>) => {
      state.modals.rejectOpenShiftModal.isOpen = action.payload;
    },
  },
});

export const openShiftsSidebarActions = openShiftsSidebar.actions;

export default openShiftsSidebar.reducer;
