import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { getTzDate, YyyyMmDd } from "@m7-health/shared-utils";
import { entries, isEqual, uniqBy, values } from "lodash";

import { useAppConfigQuery } from "#/features/User/queries";
import {
  IOpenShift,
  IOpenShiftRequest,
  OpenShiftRequest,
  StaffCategory,
  useListOrphanOpenShiftRequestsQuery,
} from "@/api";
import { useCurrentTimezone, useDeepMemo } from "@/common/hooks";
import { useAppSelector } from "@/common/hooks/useRedux";
import { useKeyBy, useMapBy } from "@/common/hooks/utils";

import { ESideBarState } from "../OpenShiftSidebarContent";

import { buildOpenShiftGroupKey } from "./useSingleDayOpenShiftGroups";

/**
 * Custom hook to retrieve and process multi-day open shift data.
 *
 * This hook fetches and filters open shift data based on the current state of the application,
 * including user-selected filters and timezone settings. It returns a structured object containing
 * the filtered open shifts, shift options, unit shift incentive levels, staff details, and schedules.
 *
 * @returns {Object} An object containing:
 * - `sortedDateKeys`: An array of date keys (YYYY-MM-DD) sorted in ascending order.
 * - `openShiftsByDate`: A record of filtered open shifts indexed by date.
 * - `shiftOptions`: Available shift options.
 * - `unitShiftIncentiveLevels`: Incentive levels for shifts, keyed by their ID.
 * - `staffDetails`: Details of the staff members.
 * - `schedulesById`: Schedules indexed by their ID.
 */
export const useGetMultiDayOpenShiftData = (sidebarState: ESideBarState) => {
  const { unitIds, openShiftsByDate, shiftOptions, staffDetails, sidebarFilters, schedules } =
    useAppSelector(
      (state) => ({
        unitIds: state.openShiftsSidebar.data.schedules.map((schedule) => schedule.unitId),
        openShiftsByDate: state.openShiftsSidebar.data.openShiftsByDate,
        shiftOptions: state.openShiftsSidebar.data.shiftTypes,
        staffDetails: state.openShiftsSidebar.data.staffDetails,
        sidebarFilters: state.openShiftsSidebar.filters,
        schedules: state.openShiftsSidebar.data.schedules,
      }),
      isEqual,
    );

  const timezone = useCurrentTimezone();
  const [searchParams] = useSearchParams();
  const selectedDayFromQuery = (searchParams.get("selectedDay") as YyyyMmDd | undefined) || null;
  const isHouseView = sidebarState === ESideBarState.houseView;
  //In house view the current day that we care about is the day selected in the day selector filter
  //Otherwise its the current day
  const startOfRelativeDay = getTzDate(isHouseView ? selectedDayFromQuery : null, timezone).startOf(
    "day",
  );
  const schedulesById = useKeyBy(schedules, "id");
  const scheduleIds = useMapBy(schedules, "id");
  const unitConfigs = useAppConfigQuery().data?.accessibleUnits?.filter((unit) =>
    unitIds.includes(unit.id),
  );
  const shiftIncentiveLevels = useDeepMemo(
    () => unitConfigs?.flatMap((config) => config.shiftIncentiveLevels),
    [unitIds],
  );
  const unitShiftIncentiveLevels = useKeyBy(shiftIncentiveLevels, "id");

  const { data: orphanOpenShiftRequests } = useListOrphanOpenShiftRequestsQuery(
    { scheduleIds: scheduleIds },
    { skip: scheduleIds.length === 0 },
  );

  const filteredOpenShiftsByDate = useMemo(() => {
    const filteredOpenShifts: Record<YyyyMmDd, (IOpenShift & { shiftCount: number })[]> = {};

    const emptyOpenShiftsById: Record<IOpenShift["id"], IOpenShift> = {};
    const emptyOpenShiftsByDateById: Record<YyyyMmDd, Record<IOpenShift["id"], IOpenShift>> = {};

    orphanOpenShiftRequests?.forEach((request) => {
      request.openShifts.forEach((openShift) => {
        (((emptyOpenShiftsByDateById[openShift.date] ||= {})[openShift.id] ||= {
          ...openShift,
        }).openShiftRequests ||= []).push(request);
        emptyOpenShiftsById[openShift.id] = openShift;
      });
    });

    const allOpenShiftsByDate: Record<YyyyMmDd, IOpenShift[]> = {};
    entries(openShiftsByDate).forEach(([date, dateOpenShifts]) => {
      allOpenShiftsByDate[date] = dateOpenShifts.allOpenShifts;
    });
    entries(emptyOpenShiftsByDateById).forEach(([date, dateEmptyOpenShifts]) => {
      const openShifts = values(dateEmptyOpenShifts);
      const combinedOpenShifts = uniqBy(
        [...(allOpenShiftsByDate[date] || []), ...openShifts],
        "id",
      );
      allOpenShiftsByDate[date] = combinedOpenShifts;
    });

    entries(allOpenShiftsByDate).forEach(([date, dateOpenShifts]) => {
      const filteredOpenShiftsForDay = dateOpenShifts.filter((openShift) => {
        if (
          (sidebarFilters?.shiftTypes?.length ?? 0) > 0 &&
          !sidebarFilters?.shiftTypes?.includes(openShift.shiftType)
        ) {
          return false;
        }
        if (
          (sidebarFilters?.staffCategories?.length ?? 0) > 0 &&
          !sidebarFilters?.staffCategories?.includes(openShift.staffCategoryKey)
        ) {
          return false;
        }
        if (
          (sidebarFilters?.unitIds?.length ?? 0) > 0 &&
          !sidebarFilters?.unitIds?.includes(schedulesById[openShift.scheduleId]?.unitId || "")
        ) {
          return false;
        }

        const hasAtLeastOnePendingRequest = openShift.openShiftRequests?.some(
          (request) => request.status === OpenShiftRequest.EStatus.pending,
        );
        if (!hasAtLeastOnePendingRequest) return false;

        return true;
      });

      if (filteredOpenShiftsForDay.length && getTzDate(date, timezone) >= startOfRelativeDay) {
        filteredOpenShifts[date] = groupOpenShifts(filteredOpenShiftsForDay);
      }
    });
    return filteredOpenShifts;
  }, [
    orphanOpenShiftRequests,
    openShiftsByDate,
    sidebarFilters.shiftTypes,
    sidebarFilters.staffCategories,
    startOfRelativeDay,
    timezone,
    schedulesById,
    sidebarFilters.unitIds,
  ]);

  const longestLabelLengths: { shiftType: number; staffCategory: number } = useMemo(() => {
    return values(filteredOpenShiftsByDate)
      .flat()
      .reduce(
        (acc, { scheduleId, shiftType, staffCategoryKey }) => {
          const shift = shiftOptions[scheduleId]?.[shiftType];
          const staffCategory = StaffCategory.EName[staffCategoryKey];
          if (!shift || !staffCategory) return acc;
          return {
            shiftType: Math.max(acc.shiftType, shift.scheduleViewDisplayName.length),
            staffCategory: Math.max(acc.staffCategory, staffCategory.length),
          };
        },
        { shiftType: 0, staffCategory: 0 },
      );
  }, [filteredOpenShiftsByDate, shiftOptions]);

  return useMemo(() => {
    return {
      sortedDateKeys: Object.keys(filteredOpenShiftsByDate).sort() as YyyyMmDd[],
      openShiftsByDate: filteredOpenShiftsByDate,
      shiftOptions,
      unitShiftIncentiveLevels,
      staffDetails,
      schedulesById,
      longestLabelLengths,
    };
  }, [
    filteredOpenShiftsByDate,
    shiftOptions,
    unitShiftIncentiveLevels,
    staffDetails,
    schedulesById,
    longestLabelLengths,
  ]);
};

const groupOpenShifts = (openShifts: IOpenShift[]) => {
  const groupedOpenShifts: Record<
    string,
    IOpenShift & {
      shiftCount: number;
      openShiftRequests: IOpenShiftRequest[];
    }
  > = {};
  openShifts.forEach((openShift) => {
    const openShiftGroupKey = buildOpenShiftGroupKey(openShift);
    const keyGrouping = (groupedOpenShifts[openShiftGroupKey] ||= {
      ...openShift,
      shiftCount: 0,
      openShiftRequests: [],
    });
    keyGrouping.shiftCount += 1;
    keyGrouping.openShiftRequests = uniqBy(
      [...keyGrouping.openShiftRequests, ...(openShift.openShiftRequests || [])],
      "id",
    );
  });
  return values(groupedOpenShifts);
};
