import { sortBy } from "lodash";

import { StaffDetails } from "~/api";
import { axiosInstance } from "~/common/packages/httpClient";

import { ITargetLevelsByDate } from "../../../common/types/schedule";

import { IScheduleRequest, IUpdateTargetLevels, TGetSchedules } from "./types";

export const createScheduleApi = async (requestArgs: IScheduleRequest) => {
  const { body, unitId } = requestArgs;

  const { data } = await axiosInstance.post<{ id: string }>(`/schedules/${unitId || ""}`, body);

  return data;
};

export const updateScheduleDetailsApi = async ({ body }: IScheduleRequest) => {
  const { data } = await axiosInstance.patch<void>(
    `/schedules/${body.id || ""}/update-details`,
    body,
  );

  return data;
};

export const deleteScheduleApi = async (scheduleId: string) => {
  await axiosInstance.delete(`/schedules/${scheduleId}`);
};

export const getFutureSchedulesApi = async (unitId: string | undefined) => {
  if (!unitId) return;

  const { data } = await axiosInstance.get<TGetSchedules>(`/units/${unitId}/schedules-list`);

  const sortedData = sortBy(data.data, ["startDay"]);

  return sortedData;
};

export const getPastSchedulesApi = async (unitId: string | undefined) => {
  if (!unitId) return;

  const { data } = await axiosInstance.get<TGetSchedules>(`/units/${unitId}/schedules-list/past`);

  const sortedData = sortBy(data.data, ["startDay"]);

  return sortedData;
};

export const shareWithSchedulersApi = async (scheduleId: string) => {
  const { data } = await axiosInstance.post<void>(`/schedules/${scheduleId}/share-with-scheduler`);

  return data;
};

export const publishScheduleApi = async ({
  scheduleId,
  publishPRNOnly,
  sendMassSms,
}: {
  scheduleId: string;
  publishPRNOnly: boolean;
  sendMassSms: boolean;
}) => {
  const { data } = await axiosInstance.post<void>(`/schedules/${scheduleId}/publish-schedule`, {
    sendMassSms,
    ...{
      employmentTypes: publishPRNOnly
        ? [StaffDetails.EEmploymentType.perDiem]
        : Object.values(StaffDetails.EEmploymentType),
    },
  });
  return data;
};

export const updateTargetLevelsOfScheduleApi = async (
  requestArgs: IUpdateTargetLevels,
): Promise<ITargetLevelsByDate> => {
  const { data } = await axiosInstance.put<ITargetLevelsByDate>(
    `/schedules/${requestArgs.scheduleId}/update-target-levels`,
    requestArgs.body,
  );

  return data;
};
