import { APIListResponse, ApiResponse } from "../_shared/types";
import { IFacility } from "../facility";
import { IUnit } from "../unit";
import { IUser } from "../user";

export namespace BeAsyncJob {
  export namespace API {
    export const ROOT_PATH = "/general-queue";

    export namespace Dummy {
      export const PATH = ROOT_PATH;
      export interface QueryParams {
        async?: boolean;
      }

      export type Response = APIListResponse<object>;
    }

    export namespace ResetStaffPreferredHours {
      export const PATH = ROOT_PATH + "/reset-staff-preferred-hours";
      export interface QueryParams {
        unitId?: IUnit["id"];
        async?: boolean;
      }

      export type Response = APIListResponse<object>;
    }

    export namespace SendEmail {
      export const PATH = ROOT_PATH + "/send-email";
      export interface QueryParams {
        to: string;
        subject: string;
        text: string;
      }

      export type Response = APIListResponse<object>;
    }

    export namespace UploadPTORequestsCSV {
      export const PATH = ROOT_PATH + "/upload-pto-requests-csv";
      export interface QueryParams {
        async?: boolean;
        file: File;
      }

      export type Response = APIListResponse<object>;
    }

    export namespace UploadStaffingLevelMatrixCSV {
      export const PATH = ROOT_PATH + "/upload-staffing-level-matrix-csv";
      export interface QueryParams {
        async?: boolean;
        file: File;
      }

      export type Response = APIListResponse<object>;
    }

    export namespace CreateBlankSubmissions {
      export const PATH = ROOT_PATH + "/create-blank-submissions";
      export interface QueryParams {
        unitId: IUnit["id"];
      }

      export type Response = APIListResponse<object>;
    }

    export namespace TriggerZendeskUserSync {
      export const PATH = ROOT_PATH + "/upsert-m7-user-in-zendesk";
      export interface QueryParams {
        unitIds?: IUnit["id"][];
        userIds?: IUser["id"][];
        facilityId?: IFacility["id"];
      }

      export type Response = APIListResponse<object>;
    }

    export namespace TriggerOpenShiftV2DataMigration {
      export const PATH = ROOT_PATH + "/migrate-facility-to-open-shift-v2";
      export interface QueryParams {
        facilityId: IFacility["id"];
      }

      export type Response = ApiResponse<"OK">;
    }
  }
}
