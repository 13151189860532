import React, { useMemo } from "react";

import { Box, Grid, Typography } from "@mui/material";

import { IScheduleShiftType, StaffCategory, Unit } from "~/api";
import { ExpandMore } from "~/common/components/ExpandMore/ExpandMore";
import { lightGray, lightPurple, lines } from "~/common/theming/colors";
import { useStaffAttributesByKeyForUnit } from "~/routes/hooks/useStaffAttributesByKeyForUnit";

import { THouseViewState } from "#/features/HouseView/store";
import { useAppFlags } from "@/common/hooks";
import { TimeStringToStandardTime } from "@/common/utils/dates";

import { CELL_DIMENSIONS } from "../constants";
import { TTargetLevelData } from "../hooks/useOrganizeTargetLevelDataForSchedules";

import { ShiftTypeSummaryRows } from "./ShiftTypeSummaryRows";

export const HeaderNameCell = ({
  unit,
  expanded,
  setExpandedUnits,
  shiftTypes,
  targetData,
  stateData: { customTimeRange: selectedCustomTimeRange, gridFilters, selectedStaffCategories },
}: {
  unit: Unit.DTO;
  expanded: boolean;
  setExpandedUnits: (value: React.SetStateAction<Record<string, boolean>>) => void;
  shiftTypes: { [shiftTypeKey: IScheduleShiftType["key"]]: IScheduleShiftType };
  targetData: TTargetLevelData;
  stateData: {
    selectedStaffCategories: THouseViewState["pageFilters"]["selectedStaffCategories"];
    showTargetLevels: THouseViewState["pageFilters"]["showTargetLevels"];
    customTimeRange: THouseViewState["pageFilters"]["customTimeRange"];
    gridFilters: THouseViewState["pageFilters"]["gridFilters"];
    targetLevelCounts: THouseViewState["pageData"]["targetLevelCounts"];
    targetLevels: THouseViewState["pageData"]["targetLevels"];
  };
}) => {
  const unitName = unit.name;
  const unitId = unit.id;
  const { showSixWeekViewShiftTypeSummary } = useAppFlags();
  const staffAttributesByKey = useStaffAttributesByKeyForUnit(unitId);

  const attributesToShow = useMemo(() => {
    const assigned = gridFilters.showAssignedAttributeTargetLevels ? ["assigned"] : [];
    const eligible = gridFilters.showEligibleAttributeTargetLevels ? ["eligible"] : [];
    return [...assigned, ...eligible] as ("assigned" | "eligible")[];
  }, [
    gridFilters.showAssignedAttributeTargetLevels,
    gridFilters.showEligibleAttributeTargetLevels,
  ]);

  const allFacilityTimeRanges = unit?.facility?.configuration.settings?.houseViewTimeRanges || [];
  if (!selectedCustomTimeRange) {
    return null;
  }
  const timeRangesToShow =
    selectedCustomTimeRange.customAbbreviation === "All"
      ? allFacilityTimeRanges
      : [selectedCustomTimeRange];
  const unitStaffCategories = unit.staffCategoryKeys.filter(
    (category) => !selectedStaffCategories.length || selectedStaffCategories.includes(category),
  );

  return (
    <Grid container flexDirection="column">
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        wrap="nowrap"
        sx={{
          minWidth: "300px",
          maxWidth: "300px",
          height: "40px",
          background: expanded ? `${lightPurple}60` : lines, // 80 in hex = 50% opacity
          borderRadius: "10px 10px 0 0",
        }}
        item
      >
        <Grid
          container
          item
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          sx={{
            mt: 1,
          }}
        >
          <Typography
            variant="small"
            fontWeight={600}
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "280px", // Leaving space for the expand arrow
            }}
          >
            <Box
              onClick={() =>
                setExpandedUnits((previous) => ({
                  ...previous,
                  [unitId]: !expanded,
                }))
              }
              display={"inline"}
            >
              <Typography variant="small" fontSize={13} fontWeight={300} flexWrap={"nowrap"}>
                <ExpandMore direction="right" expand={expanded} />
              </Typography>
            </Box>
            {unitName}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        item
        flexDirection="column"
        flexWrap="nowrap"
        sx={{
          borderTop: `1px solid ${lines}`,
        }}
      >
        {(!showSixWeekViewShiftTypeSummary || gridFilters.showTimeTargetLevels) &&
          timeRangesToShow.map((aRange) => (
            <>
              {unitStaffCategories.map((category) => (
                <Grid
                  key={`${aRange.startTime}-${aRange.endTime}-${category}`}
                  sx={{
                    height: CELL_DIMENSIONS.height,
                    background: lightGray,
                    borderTop: `${1}px solid ${lines}`,
                    borderBottom: `0px solid ${lines}`,
                  }}
                  container
                  alignItems="center"
                >
                  <Typography pl={2} fontSize="0.75rem" fontWeight="400">
                    {aRange.customAbbreviation || TimeStringToStandardTime(aRange.startTime)}{" "}
                    {StaffCategory.EName[category]}
                  </Typography>
                </Grid>
              ))}
            </>
          ))}
        {gridFilters.showShiftTargetLevels && showSixWeekViewShiftTypeSummary && targetData && (
          <ShiftTypeSummaryRows.Name
            orderedTargets={targetData.orderedTargetLevelKeys}
            filteredTargets={targetData.filteredTargetLevelsForUnit}
            shiftTypes={shiftTypes}
            timeRangesToShow={timeRangesToShow}
          />
        )}
        {gridFilters.showAggregatedShiftTargetLevels && showSixWeekViewShiftTypeSummary && (
          <ShiftTypeSummaryRows.Name
            orderedTargets={targetData.orderedAggregatedTargetLevelKeys}
            filteredTargets={targetData.filteredAggregatedTargetLevelsForUnit}
            shiftTypes={shiftTypes}
            timeRangesToShow={timeRangesToShow}
            aggregated={true}
          />
        )}
        {!!attributesToShow.length && showSixWeekViewShiftTypeSummary && (
          <>
            <ShiftTypeSummaryRows.AttributeName
              attributesToShow={attributesToShow}
              orderedTargets={targetData.orderedTargetLevelKeys}
              filteredTargets={targetData.filteredTargetLevelsForUnit}
              shiftTypes={shiftTypes}
              timeRangesToShow={timeRangesToShow}
              staffAttributesByKey={staffAttributesByKey}
            />

            <ShiftTypeSummaryRows.AttributeName
              attributesToShow={attributesToShow}
              orderedTargets={targetData.orderedAggregatedTargetLevelKeys}
              filteredTargets={targetData.filteredAggregatedTargetLevelsForUnit}
              shiftTypes={shiftTypes}
              timeRangesToShow={timeRangesToShow}
              staffAttributesByKey={staffAttributesByKey}
              aggregated={true}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};
