import { useMemo } from "react";

import { Dayjs, getTzDayjs, getTzFormattedDate, Timezone, YyyyMmDd } from "@m7-health/shared-utils";
import { get, isEqual, keys, map, set } from "lodash";

import { IUnitBasic } from "~/routes/api/types";

import { HouseViewHooks } from "#/features/HouseView/hooks";
import {
  ISchedule,
  IStaffShift,
  StaffDetails,
  useListSchedulesQuery,
  useListStaffDetailsQuery,
  useListStaffShiftsQuery,
} from "@/api";
import { StaffCalendarHooks } from "@/common/components/StaffCalendar/hooks";
import {
  useAppSelector,
  useCurrentFacility,
  useDeepMemo,
  useFacilityTimezones,
  useKeyBy,
} from "@/common/hooks";
import { getDatesInRange, timeAdd, timeOverlap } from "@/common/utils/dates";

const { useSelectedDay } = HouseViewHooks;
const { useSchedulesShiftTypes } = StaffCalendarHooks;

const emptyStaffDetails: StaffDetails.DTO[] = [];
const emptySchedules: ISchedule[] = [];
const emptyStaffShifts: IStaffShift[] = [];

export const useGetWeekViewData = () => {
  const facilityTimezones = useFacilityTimezones();
  const facility = useCurrentFacility();
  const allFacilityTimeRanges = facility?.configuration.settings?.houseViewTimeRanges;

  const { selectedStaffCategories, selectedCustomTimeRange } = useAppSelector(
    (state) => ({
      selectedStaffCategories: state.houseView.pageFilters.selectedStaffCategories,
      selectedCustomTimeRange: state.houseView.pageFilters.customTimeRange,
    }),
    isEqual,
  );

  const { datePickerValue: selectedDay } = useSelectedDay();

  const rangesByTimezone = useMemo(
    () =>
      facilityTimezones.reduce(
        (allRanges, timezone) => {
          const from = getTzDayjs(selectedDay, timezone);
          const to = getTzDayjs(selectedDay, timezone).addInTz(6, "day");
          const allDays = getDatesInRange(from, to);
          const formattedDays = map(allDays, (day) => getTzFormattedDate(day));
          allRanges[timezone] = {
            from,
            to,
            allDays,
            formattedDays,
          };
          return allRanges;
        },
        {} as Record<
          Timezone,
          { from: Dayjs; to: Dayjs; allDays: Dayjs[]; formattedDays: YyyyMmDd[] }
        >,
      ),
    [facilityTimezones, selectedDay],
  );

  const firstRange = useDeepMemo(
    () => facilityTimezones[0] && rangesByTimezone[facilityTimezones[0]],
    [facilityTimezones, rangesByTimezone],
  );

  const { data: staffShifts = emptyStaffShifts, isLoading: isLoadingStaffShifts } =
    useListStaffShiftsQuery(
      {
        date: firstRange
          ? [
              { value: getTzFormattedDate(firstRange.from), operator: "gte" },
              { value: getTzFormattedDate(firstRange.to), operator: "lte" },
            ]
          : [],
      },
      { skip: !facility || !firstRange },
    );
  const { data: allStaffDetails = emptyStaffDetails, isLoading: isLoadingStaffDetails } =
    useListStaffDetailsQuery({}, { skip: staffShifts.length === 0 });
  const { data: allSchedules = emptySchedules } = useListSchedulesQuery({});
  const staffDetailsByUserId = useKeyBy(allStaffDetails, "userId");
  const shiftsByScheduleId = useKeyBy(staffShifts, "scheduleId");
  const schedules = useMemo(() => {
    return allSchedules.filter((schedule) => keys(shiftsByScheduleId).includes(schedule.id));
  }, [allSchedules, shiftsByScheduleId]);
  const schedulesById = useKeyBy(schedules, "id");
  const shiftTypesByScheduleIdByKey = useSchedulesShiftTypes(map(schedules, "id"));

  const filteredStaffShifts = useMemo(() => {
    return staffShifts.filter((shift) => {
      const staffDetails = staffDetailsByUserId[shift.staffId];
      const shiftType = shiftTypesByScheduleIdByKey[shift.scheduleId]?.[shift.shiftTypeKey];
      if (!staffDetails || !selectedCustomTimeRange || !shiftType) return false;

      const timeRangesToShow =
        selectedCustomTimeRange.customAbbreviation === "All"
          ? allFacilityTimeRanges
          : [selectedCustomTimeRange];

      const shiftStartTime = shift.customStartTime || shiftType.startTime;
      const shiftDuration = shift.customDuration || shiftType.durationSeconds;
      const shiftEndTime = timeAdd(shiftStartTime, shiftDuration);
      const shiftInRange = (timeRangesToShow || []).reduce((inRange, timeRange) => {
        const { startTime, endTime } = timeRange;
        const overlapSeconds = timeOverlap(
          { from: shiftStartTime, to: shiftEndTime },
          { from: startTime, to: endTime },
        );
        return inRange || overlapSeconds > 0;
      }, false);

      if (!shiftInRange) return false;
      if (
        selectedStaffCategories.length &&
        !selectedStaffCategories.includes(staffDetails.staffType.staffCategoryKey)
      ) {
        return false;
      }
      return true;
    });
  }, [
    staffShifts,
    selectedStaffCategories,
    staffDetailsByUserId,
    selectedCustomTimeRange,
    allFacilityTimeRanges,
    shiftTypesByScheduleIdByKey,
  ]);

  const shiftsByDayByUnit = useMemo(
    () =>
      filteredStaffShifts.reduce(
        (acc, staffShift) => {
          const { scheduleId, date } = staffShift;
          const shiftDate = facilityTimezones[0] && getTzFormattedDate(date, facilityTimezones[0]);
          const unitId = schedulesById[scheduleId]?.unitId;
          if (unitId && shiftDate) {
            const prevShiftsForDate = get(acc, [shiftDate, unitId], []);
            set(acc, [shiftDate, unitId], [...prevShiftsForDate, staffShift]);
          }
          return acc;
        },
        {} as Record<YyyyMmDd, Record<IUnitBasic["id"], IStaffShift[]>>,
      ),
    [filteredStaffShifts, schedulesById, facilityTimezones],
  );

  return useMemo(
    () => ({
      firstRange,
      shiftsByDayByUnit,
      staffDetailsByUserId,
      isLoadingData: isLoadingStaffShifts || isLoadingStaffDetails,
    }),
    [
      firstRange,
      shiftsByDayByUnit,
      staffDetailsByUserId,
      isLoadingStaffShifts,
      isLoadingStaffDetails,
    ],
  );
};
