import { useMemo, useState } from "react";

import { filter, sortBy } from "lodash";

import { ExpandLess, MenuBook } from "@mui/icons-material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from "@mui/lab";
import { Box, Stack, Tooltip, Typography } from "@mui/material";

import {
  NoteUpdated,
  StaffShiftCreated,
  StaffShiftDeleted,
  StaffShiftUpdated,
} from "~/api/eventLog/events";

import { EventLog } from "@/api";
import { black, mediumGray } from "@/common/theming/colors";

import { AppLoader } from "../AppLoader/AppLoader";
import CustomModal from "../Modal";

import { IEventLogComponent, componentForEvent } from "./events";
import { EVENT_TITLES, HumanizedTimestamp, IconForEvent } from "./helpers";
import { ModalEvents, TTarget } from "./types";
import { useDataForShift } from "./useDataForShift";

const ShiftEvents = new Set([
  EventLog.EEventType.staffShiftCreated,
  EventLog.EEventType.staffShiftUpdated,
  EventLog.EEventType.staffShiftDeleted,
]);
const EventsToShow = new Set([...ShiftEvents, EventLog.EEventType.noteUpdated]);

type TStaffShiftEventType =
  | StaffShiftCreated.Payloads
  | StaffShiftDeleted.Payloads
  | StaffShiftUpdated.Payloads;
type TStaffShiftEvent = EventLog.DTO<TStaffShiftEventType>;
type TNoteEvent = EventLog.DTO<NoteUpdated.Payloads>;

export const StaffShiftHistoryModal = ({
  onClose,
  modalTitle,
  withBlurBackground = true,
  ...target
}: TTarget & {
  modalTitle: string;
  withBlurBackground?: boolean;
  onClose: () => void;
}) => {
  const { logs, usersById, indexedShiftTypes, unitIdByScheduleId, isLoading } =
    useDataForShift(target);
  const [shiftHistoryIsOpen, setShiftHistoryIsOpen] = useState(false);

  // For now constant, only show draft. We might want to change this in the future
  const currentScheduleType = "draft";

  const logsToShow = useMemo(() => {
    const filteredLogs = filter(logs, (event) => {
      if (!EventsToShow.has(event.eventType)) return false;

      if (ShiftEvents.has(event.eventType)) {
        const scheduleType = (event as TStaffShiftEvent).eventPayload.staffShift.scheduleType;

        return !scheduleType || scheduleType === currentScheduleType;
      }

      // Only keep events that belongs to current shown user
      if (event.eventType === EventLog.EEventType.noteUpdated) {
        if (!target.userId) return false;
        return (event as TNoteEvent).eventPayload.note.userId === target.userId;
      }

      return false;
    }) as (TNoteEvent | TStaffShiftEvent)[];
    const sortedLogs = sortBy(filteredLogs, ({ eventEmittedAt }) => eventEmittedAt).reverse();

    return sortedLogs;
  }, [logs, target.userId, currentScheduleType]);

  const modalContent = isLoading ? (
    <AppLoader open={true} />
  ) : logsToShow.length ? (
    <Timeline sx={leftAlignedTimelineCss}>
      {logsToShow.map((eventLog, index) => {
        return (
          <TimelineItem
            key={eventLog.eventEmittedAt}
            sx={{ p: 0 }}
            className={`event-timeline-item ${eventLog.eventType}`}
          >
            {index === 0 && (
              <ExpandLess
                sx={{
                  position: "absolute",
                  left: "6px",
                  top: "-8px",
                  color: "rgb(189, 189, 189)",
                }}
              />
            )}
            {/* Line + icon */}
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot>
                <IconForEvent eventLog={eventLog} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>

            {/* Actual content of the event (beside the line) */}
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              {/* Title + timestamp */}
              <Stack direction="row" alignItems="center">
                <Typography component="span">
                  {EVENT_TITLES[eventLog.eventType as ModalEvents] || "Unknown event"}
                  {!target.staffShiftId && ShiftEvents.has(eventLog.eventType) && (
                    <>
                      <Tooltip title="Browse shift full history">
                        <MenuBook
                          fontSize="small"
                          sx={{
                            color: mediumGray,
                            "&:hover": { color: black },
                            ml: 1,
                          }}
                          onClick={(event) => {
                            setShiftHistoryIsOpen(true);
                            event.stopPropagation();
                          }}
                        />
                      </Tooltip>
                      {shiftHistoryIsOpen && (
                        <StaffShiftHistoryModal
                          staffShiftId={(eventLog as TStaffShiftEvent).eventPayload.staffShift.id}
                          onClose={() => setShiftHistoryIsOpen(false)}
                          modalTitle="Shift History"
                          withBlurBackground={false}
                        />
                      )}
                    </>
                  )}
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <HumanizedTimestamp date={eventLog.eventEmittedAt} />
              </Stack>

              {/* Description */}
              <Typography display="inline-block">
                {descriptionForEventLog({
                  event: eventLog,
                  usersById,
                  indexedShiftTypes,
                  unitIdByScheduleId,
                })}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  ) : (
    <Typography variant="body1">No history found</Typography>
  );

  return (
    <CustomModal
      sx={{ ".modal-content-container": { overflowY: "auto", maxHeight: "80vh" } }}
      modalHeaderText={modalTitle}
      modalContent={modalContent}
      isOpen={true}
      withBlurBackground={withBlurBackground}
      onClose={onClose}
    />
  );
};

const descriptionForEventLog = (params: IEventLogComponent) => {
  const EventComponent = componentForEvent(params.event);
  if (!EventComponent) return "Unknown event";

  return <EventComponent {...params} />;
};

const leftAlignedTimelineCss = {
  p: 0,
  [`& .${timelineItemClasses.root}:before`]: {
    flex: 0,
    padding: 0,
  },
};
