import { memo, useCallback, useState } from "react";

import { isEqual } from "lodash";

import { FilterAlt } from "@mui/icons-material";
import { Grid, Popover, Typography } from "@mui/material";

import { CustomButton, CustomSwitch } from "@/common/components";
import { useAppDispatch, useAppSelector } from "@/common/hooks";

import { houseViewStore } from "../store";
import { TGridFilters } from "../store/pageFiltersActions";

export const SummaryRowsFilters = memo(function Memoized_SummaryRowsFilter() {
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const openPopover = Boolean(anchorEl);

  const gridFilters = useAppSelector((state) => state.houseView.pageFilters.gridFilters, isEqual);

  const toggleBooleanFilter = useCallback(
    (key: keyof TGridFilters) => {
      dispatch(houseViewStore.state.setGridFilters({ [key]: !gridFilters[key] }));
    },
    [dispatch, gridFilters],
  );

  return (
    <>
      <Grid
        item
        alignItems="center"
        container
        justifyContent="flex-end"
        sx={{ cursor: "pointer" }}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
      >
        <CustomButton
          variant="outlined"
          startIcon={<FilterAlt sx={{ fontSize: "20px" }} />}
          label="Filter"
          style={{
            padding: "0px 6px",
            marginRight: "10px",
            ".MuiButton-startIcon": { marginRight: "0px" },
          }}
        />
      </Grid>
      <Popover
        id={"simple-popover"}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Grid container direction="column" sx={{ py: 3 }}>
          <Grid container direction="column" ml={3} gap={1}>
            <Typography fontWeight={500} children="View / Hide" />
            <CustomSwitch
              checked={!!gridFilters.showTimeTargetLevels}
              label="Summary by Time Period"
              name="showTimeTargetLevels"
              onChange={() => toggleBooleanFilter("showTimeTargetLevels")}
              key={`showTimeTargetLevels toggle`}
            />

            <CustomSwitch
              checked={!!gridFilters.showShiftTargetLevels}
              label="Summary by Shift Type"
              name="showShiftTargetLevels"
              onChange={() => toggleBooleanFilter("showShiftTargetLevels")}
              key={`showShiftTargetLevels toggle`}
            />

            <CustomSwitch
              checked={!!gridFilters.showAggregatedShiftTargetLevels}
              label="Summary by Aggregated Shift Type"
              name="showAggregatedShiftTargetLevels"
              onChange={() => toggleBooleanFilter("showAggregatedShiftTargetLevels")}
              key={`showAggregatedShiftTargetLevels toggle`}
            />

            <CustomSwitch
              checked={!!gridFilters.showAssignedAttributeTargetLevels}
              label="Summary by Assigned Attribute"
              name="showAssignedAttributeTargetLevels"
              onChange={() => toggleBooleanFilter("showAssignedAttributeTargetLevels")}
              key={`showAssignedAttributeTargetLevels toggle`}
            />

            <CustomSwitch
              checked={!!gridFilters.showEligibleAttributeTargetLevels}
              label="Summary by Eligible Attribute"
              name="showEligibleAttributeTargetLevels"
              onChange={() => toggleBooleanFilter("showEligibleAttributeTargetLevels")}
              key={`showEligibleAttributeTargetLevels toggle`}
            />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
});
