import { memo, useCallback, useEffect, useMemo } from "react";

import { EUnitPermissionAreas, getTzDayjs, YyyyMmDd } from "@m7-health/shared-utils";
import { isEqual, map, uniq } from "lodash";

import {
  ArrowBackIosNewOutlined,
  CalendarMonth,
  CalendarToday,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { alpha, Badge, Box, ButtonGroup, SelectChangeEvent, Typography } from "@mui/material";

import { MixpanelProvider } from "~/modules/mixpanel/Provider";
import { Mxp } from "~/modules/mixpanel/types";

import { IAppConfig } from "#/features/User/types";
import { IScheduleShiftType, StaffCategory } from "@/api";
import { CustomButton, CustomSelect, CustomTabs, ShiftV2, UnitPicker } from "@/common/components";
import {
  useAppDispatch,
  useAppFlags,
  useAppSelector,
  useCheckUserPermission,
  useCurrentFacilityId,
  useCurrentRole,
  useCurrentTimezone,
  useToast,
} from "@/common/hooks";
import { localDayJs } from "@/common/packages/dayjs";
import { black, darkGray, mediumGray } from "@/common/theming";
import { TimeStringToStandardTime } from "@/common/utils/dates";
import { isOnMobile } from "@/common/utils/isOnMobile";

import { HouseViewHooks } from "../hooks";
import { houseViewStore } from "../store";
import { DEFAULT_CUSTOM_TIME_RANGE, EViewLengthOptions } from "../store/pageFiltersActions";

import { DateNavigator } from "./DateNavigator";

const { useSelectedDay } = HouseViewHooks;

const viewLengthSelectOptions = [
  { value: EViewLengthOptions.day, label: "Day" },
  { value: EViewLengthOptions.week, label: "Week" },
  { value: EViewLengthOptions.sixWeek, label: "Multi-Week" },
];

const Headers = memo(
  ({
    availableUnits,
    numberOfUnitsWithNoStaffingTargets,
    todayHouseSupervisors,
  }: {
    availableUnits: IAppConfig["accessibleUnits"];
    numberOfUnitsWithNoStaffingTargets: number;
    todayHouseSupervisors?: {
      firstName: string;
      lastName: string;
      shiftType?: IScheduleShiftType;
    }[];
  }) => {
    const { staffingTargetLevelModalQueuesToInputPatientCount, useOneWeekView } = useAppFlags();
    /** HIGH-LEVEL STATE */
    const dispatch = useAppDispatch();
    const toast = useToast();

    /** USER STATE */
    const { userIsKiosk } = useCurrentRole();
    const canManage = useCheckUserPermission("manage", EUnitPermissionAreas.houseView);
    const currentFacilityId = useCurrentFacilityId();
    const currentTimezone = useCurrentTimezone();

    const { datePickerValue: selectedDay, setSelectedDay } = useSelectedDay();
    const {
      selectedCustomTimeRangeState,
      isDayViewSelected,
      selectedStaffCategories,
      selectedShowTargetLevels,
      sidebarActionDirty,
      selectedUnitIds,
      selectedViewLength,
      isSidebarExpanded,
    } = useAppSelector(
      (state) => ({
        selectedCustomTimeRangeState: state.houseView.pageFilters.customTimeRange,
        isDayViewSelected:
          state.houseView.pageFilters.selectedViewLength === EViewLengthOptions.day,
        selectedStaffCategories: state.houseView.pageFilters.selectedStaffCategories,
        selectedShowTargetLevels: state.houseView.pageFilters.showTargetLevels,
        sidebarActionDirty: state.houseView.sidebarCurrentAction.isDirty,
        selectedUnitIds: state.houseView.pageFilters.unitIds,
        selectedViewLength: state.houseView.pageFilters.selectedViewLength,
        isSidebarExpanded: state.houseView.pageFilters.isSidebarExpanded,
      }),
      isEqual,
    );
    const selectedCustomTimeRange = selectedCustomTimeRangeState || DEFAULT_CUSTOM_TIME_RANGE;

    // Get facility configuration
    const unit = availableUnits.find((aUnit) => aUnit.facilityId === currentFacilityId);
    const facility = unit?.facility;
    const facilityTimeRanges = facility?.configuration?.settings?.houseViewTimeRanges;
    // if on mobile, then always use the start time formatted label and never the custom abbreviation
    // else (on desktop), use the custom abbreviation and default to the start time formatted label if needed
    const facilityTimeRangesCustomSelect = useMemo(
      () =>
        facilityTimeRanges?.map((timeRange) => ({
          label: isOnMobile()
            ? TimeStringToStandardTime(timeRange.startTime)
            : timeRange.customAbbreviation || TimeStringToStandardTime(timeRange.startTime),
          value: timeRange.startTime + "-" + timeRange.endTime,
          item: timeRange.startTime + "-" + timeRange.endTime,
        })) || [],
      [facilityTimeRanges],
    );

    const dayInMonth = useMemo(
      () => getTzDayjs(selectedDay, currentTimezone).format("DD"),
      [currentTimezone, selectedDay],
    );

    const filteredStaffCategoryItems = useMemo(() => {
      const availableStaffCategoryItems = uniq(
        availableUnits
          .filter(
            (availableUnit) =>
              !selectedUnitIds.length || selectedUnitIds.includes(availableUnit.id),
          )
          .flatMap((availableUnit) => availableUnit.staffCategoryKeys),
      ).map((staffCategory) => ({
        label: StaffCategory.EName[staffCategory],
        value: staffCategory,
      }));
      return [
        {
          label: "All",
          value: "all",
        },
        ...availableStaffCategoryItems,
      ];
    }, [availableUnits, selectedUnitIds]);

    const onDayChange = useCallback(
      (date: YyyyMmDd) => {
        if (sidebarActionDirty)
          toast.showInfo("You have unsaved changes. Please save or cancel first.");
        else setSelectedDay(date);
      },
      [sidebarActionDirty, toast, setSelectedDay],
    );

    const onStaffCategoryChange = useCallback(
      (event: SelectChangeEvent) => {
        const newCategoryValues = (
          Array.isArray(event.target.value) ? event.target.value : [event.target.value]
        ) as StaffCategory.EKey[];
        const isAllOptionInNew = newCategoryValues.includes("all" as StaffCategory.EKey);
        const isAllOptionSelected = selectedStaffCategories.length === 0;
        const isAllOptionNewlySelected = isAllOptionInNew && !isAllOptionSelected;
        const allCategoriesSelected =
          !isAllOptionInNew && newCategoryValues.length === filteredStaffCategoryItems.length - 1; //subtract 1 for the all option
        dispatch(
          houseViewStore.state.selectStaffCategories(
            isAllOptionNewlySelected || allCategoriesSelected
              ? []
              : newCategoryValues.filter((category) => category !== ("all" as StaffCategory.EKey)),
          ),
        );
      },
      [dispatch, filteredStaffCategoryItems.length, selectedStaffCategories.length],
    );

    const onTimeRangeSelect = useCallback(
      (event: SelectChangeEvent<string>) => {
        const customTimeRangeBasedOnValue = facilityTimeRanges?.find(
          (timeRange) => timeRange.startTime + "-" + timeRange.endTime === event.target.value,
        );
        if (!customTimeRangeBasedOnValue) return;
        dispatch(houseViewStore.state.selectCustomTimeRange(customTimeRangeBasedOnValue));
      },
      [dispatch, facilityTimeRanges],
    );

    const onViewLengthSelect = useCallback(
      (viewLength: EViewLengthOptions) => {
        //If 6 week view is selected then set the staff category to the first available staff category
        //If week view is selected, then set the staff category to all
        if (viewLength !== EViewLengthOptions.day) {
          const firstAvailableStaffCategory = filteredStaffCategoryItems[1]
            ?.value as StaffCategory.EKey;
          dispatch(
            houseViewStore.state.selectStaffCategories(
              firstAvailableStaffCategory && viewLength === EViewLengthOptions.sixWeek
                ? [firstAvailableStaffCategory]
                : [],
            ),
          );
        }
        dispatch(houseViewStore.state.selectViewLength(viewLength));
      },
      [dispatch, filteredStaffCategoryItems],
    );

    const houseViewTabs = useMemo(
      () => [
        {
          label: (
            <>
              <CalendarToday />
              <span className="day-in-month">{dayInMonth}</span>
            </>
          ),
          value: EViewLengthOptions.day,
        },
        { label: <CalendarMonth />, value: EViewLengthOptions.sixWeek },
      ],
      [dayInMonth],
    );

    // Set default staff category if selected staff category is undefined or not in the staffCategoryItems array
    useEffect(() => {
      if (!isDayViewSelected) {
        dispatch(
          houseViewStore.state.selectStaffCategories(
            selectedStaffCategories.filter((category) =>
              Object.keys(StaffCategory.EName).some((categoryKey) => categoryKey === category),
            ) as StaffCategory.EKey[],
          ),
        );
      }
    }, [selectedStaffCategories, dispatch, isDayViewSelected]);

    // If kiosk user, don't show targets
    useEffect(() => {
      if (userIsKiosk || !canManage) dispatch(houseViewStore.state.selectShowTargetLevels(false));
    }, [userIsKiosk, canManage, dispatch]);

    const date = localDayJs(selectedDay);

    return (
      <MixpanelProvider properties={{ [Mxp.Property.layout.section]: "headers" }}>
        <Typography fontSize={20} className="hide show-on-print increase-text-size-on-print">
          {date.format("dddd, MMMM DD, YYYY")}
        </Typography>

        <Box className="house-view-headers hide-on-print" p={"16px"} pl={0}>
          {/* First row */}
          <Box
            display="flex"
            flexDirection="row"
            width={"100%"}
            justifyContent="space-between"
            columnGap={1}
            sx={{ justifyContent: "space-between" }}
          >
            <Box
              className={"house-view-headers-left"}
              display="flex"
              flexDirection="row"
              flexWrap={"wrap"}
              columnGap={1}
              rowGap={2}
            >
              {/* Date picker */}
              <DateNavigator
                selectedDay={selectedDay}
                onDayChange={onDayChange}
                readonly={sidebarActionDirty}
                containerSx={{ minWidth: "225px", whiteSpace: "nowrap" }}
              />

              {/* day <> week slider */}
              {!isOnMobile() && (
                <>
                  {useOneWeekView ? (
                    <CustomSelect
                      className="view-length-selector"
                      label="View length"
                      width="fit-content"
                      style={{ minWidth: "130px" }}
                      items={viewLengthSelectOptions}
                      value={selectedViewLength}
                      onChange={(event) =>
                        onViewLengthSelect(event.target.value as EViewLengthOptions)
                      }
                      hideSelectedOption={false}
                    />
                  ) : (
                    <CustomTabs<EViewLengthOptions>
                      tracking
                      onChange={onViewLengthSelect}
                      pillTabs
                      tabs={houseViewTabs}
                      value={selectedViewLength}
                      width="auto"
                      className="day-week-slider"
                    />
                  )}
                </>
              )}

              {/* Range picker */}
              <CustomSelect<string>
                className="time-range-select"
                label="Time range"
                width={"130px"}
                items={facilityTimeRangesCustomSelect}
                value={selectedCustomTimeRange.startTime + "-" + selectedCustomTimeRange.endTime}
                onChange={onTimeRangeSelect}
              />

              {/* Staff category picker */}
              {!isDayViewSelected && (
                <Box
                  className="staff-category-picker"
                  sx={{ width: "fit-content", minWidth: "120px" }}
                >
                  <CustomSelect
                    label="Staff Categories"
                    items={filteredStaffCategoryItems}
                    value={selectedStaffCategories.length ? selectedStaffCategories : ["all"]}
                    onChange={onStaffCategoryChange}
                    multiple
                  />
                </Box>
              )}

              {/* unit picker */}
              {
                <UnitPicker
                  unitOptions={availableUnits}
                  facilityId={currentFacilityId || undefined}
                  valueIds={selectedUnitIds}
                  onChange={(units) =>
                    dispatch(houseViewStore.state.setUnitsFilter(map(units, "id")))
                  }
                  withQueryParams
                />
              }
            </Box>

            <Box
              className={"house-view-headers-right"}
              display="flex"
              flexDirection="row"
              columnGap={1}
              rowGap={2}
              flexGrow={1}
              flexWrap={isOnMobile() ? "wrap" : "nowrap"}
              justifyContent={"flex-end"}
            >
              {/* Update staff targets button */}
              {!userIsKiosk && canManage && (
                <ButtonGroup className="targets-buttons" sx={{ order: "2" }}>
                  {/* Show/hide targets button */}
                  <CustomButton
                    color="darkPurple"
                    trackingLabel="toggle-show-hide-targets"
                    variant={selectedShowTargetLevels ? "contained" : "outlined"}
                    className="show-hide action"
                    startIcon={selectedShowTargetLevels ? <Visibility /> : <VisibilityOff />}
                    onClick={() =>
                      dispatch(
                        houseViewStore.state.selectShowTargetLevels(!selectedShowTargetLevels),
                      )
                    }
                  />
                  {/* Inputting Patient Count button */}
                  <CustomButton
                    variant="outlined"
                    color="darkPurple"
                    trackingLabel={undefined}
                    className="label"
                    children="Patient Count"
                    disabled={!isDayViewSelected}
                    onClick={() => dispatch(houseViewStore.state.setStaffingLevelModalIsOpen(true))}
                  />
                  {numberOfUnitsWithNoStaffingTargets > 0 &&
                    staffingTargetLevelModalQueuesToInputPatientCount && (
                      <Badge badgeContent={"!"} color="error"></Badge>
                    )}
                </ButtonGroup>
              )}

              {/* House view supervisors for the day */}
              {isDayViewSelected && (
                <Box
                  className="house-supervisors"
                  minWidth={"150px"}
                  padding={1}
                  sx={{
                    height: "fit-content",
                    minHeight: "24px",
                    position: "relative",
                    border: `1px solid ${mediumGray}`,
                    borderRadius: 2,
                    order: "3",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      p: 0.5,
                      position: "absolute",
                      background: "white",
                      color: darkGray,
                      top: "-15px",
                      left: "5px",
                    }}
                  >
                    House Supervisor(s)
                  </Typography>
                  {todayHouseSupervisors?.map(({ firstName, lastName, shiftType }, index) => {
                    if (!shiftType) return null;

                    // Make sure we have a unique key to avoid weird rendering issues
                    const uniqReactKey = [
                      "house-view-headers-house-supervisors-",
                      firstName,
                      lastName,
                      shiftType.key,
                      index.toString(),
                      selectedDay,
                    ].join("-");

                    return (
                      <Box
                        key={uniqReactKey}
                        pt={1}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <ShiftV2
                          shiftType={shiftType}
                          variant="small"
                          miniView={true}
                          sx={shiftCompactSx}
                        />
                        <Box pl={1}>
                          <Typography fontSize="13px">
                            {lastName} {firstName}
                          </Typography>
                        </Box>
                        <Box flexGrow={1} />
                      </Box>
                    );
                  })}
                </Box>
              )}

              {useOneWeekView && !isOnMobile() && isDayViewSelected && (
                <CustomButton
                  className={"house-view-print"}
                  label="Print"
                  onClick={() => {
                    try {
                      // Print for Safari browser
                      document.execCommand("print", false, undefined);
                    } catch {
                      window.print();
                    }
                  }}
                  sx={{ order: "4", height: "40px" }}
                />
              )}

              {!isSidebarExpanded && (
                <Box
                  className={"expand-sidebar-button-container"}
                  sx={{
                    marginRight: "-10px",
                    alignItems: "center",
                    display: "flex",
                    order: isOnMobile() ? "1" : "5",
                  }}
                >
                  <CustomButton
                    className="expand-sidebar-button"
                    iconOnly
                    startIcon={<ArrowBackIosNewOutlined />}
                    trackingLabel="collapse-sidebar"
                    onClick={() =>
                      dispatch(houseViewStore.state.setIsSidebarExpanded(!isSidebarExpanded))
                    }
                    sx={{
                      backgroundColor: alpha(mediumGray, 0.5),
                      padding: 0.7,
                      border: `1px solid ${black}`,
                      "&:hover": {
                        backgroundColor: alpha(mediumGray, 0.7),
                      },
                    }}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </MixpanelProvider>
    );
  },
);

const shiftCompactSx = {
  height: "20px",
  maxWidth: "40px",
  padding: "0 !important",
  width: "20px",
  ".MuiButton-startIcon": {
    marginRight: "-3px",
  },
};

export const __HouseViewHeaders = Headers;
