import { useCallback, useEffect, useMemo, useState } from "react";

import { ECommunicationMethod, ELoginCommunicationType } from "@m7-health/shared-utils";
import { isEqual, map } from "lodash";

import { Email, InfoOutlined, Sms } from "@mui/icons-material";
import { Box, Stack, ToggleButtonGroup, Typography } from "@mui/material";

import { IUser } from "~/api/user/types";

import { useTriggerLoginCommunication } from "@/api";
import {
  AutocompleteStaffSelector,
  CustomModal,
  CustomSwitch,
  ToggleButton,
} from "@/common/components";
import { useExternalUserManagement, useFilterBy, useKeyBy, useMap, useToast } from "@/common/hooks";
import { useAppDispatch, useAppSelector } from "@/common/hooks/useRedux";

import { closeModal, setUsersToSendCommunications } from "../../store";

import { useGetUsers } from "./useGetUsers";

//Modal for sending log in communications to users
//When the current facility has useExternalUserIntegration enabled, it will send a M7 invite email/text
//Otherwise, it will send a reset password email/text
export const SendCommunicationsModal = () => {
  const { showError, showSuccess } = useToast();
  const dispatch = useAppDispatch();
  const isExternalUsers = useExternalUserManagement();

  // Store data
  const storeUserIds = useAppSelector(
    (state) => state.roster.sendCommunicationsModal.userIds,
    isEqual,
  );

  // Queries
  const users = useGetUsers();
  const { mutateAsync: triggerLoginCommunication } = useTriggerLoginCommunication({});

  // State
  const [isLoading, setIsLoading] = useState(false);
  const [userIds, setUserIds] = useState<IUser["id"][]>([]);
  const [communicationMethod, setCommunicationMethod] = useState<ECommunicationMethod>(
    ECommunicationMethod.email,
  );
  const [sendCommunicationsToNotLoggedUsers, setSendCommunicationsToNotLoggedUsers] =
    useState(false);

  // Computed values
  const singleUserMode = storeUserIds?.length === 1;
  const usersById = useKeyBy(users, "id");
  const notLoggedInUsers = useFilterBy(users, (user) => !user.lastLoggedInAt, []);
  const notLoggedInUserIds = useMap(notLoggedInUsers, (user) => user.id);
  const canSendSms = useMemo(() => {
    const usersToSendTo = sendCommunicationsToNotLoggedUsers
      ? notLoggedInUsers
      : map(userIds, (id) => usersById[id]);
    const allUsersHavePhoneNumber = usersToSendTo.every((user) => user && user.phoneNumber);
    if (!allUsersHavePhoneNumber) {
      setCommunicationMethod(ECommunicationMethod.email);
    }
    return allUsersHavePhoneNumber;
  }, [notLoggedInUsers, usersById, userIds, sendCommunicationsToNotLoggedUsers]);

  useEffect(() => {
    setUserIds(storeUserIds ?? []);
  }, [storeUserIds]);

  const closeAction = useCallback(() => {
    dispatch(closeModal("sendCommunicationsModal"));
    dispatch(setUsersToSendCommunications([]));
  }, [dispatch]);

  const sendCommunicationsAction = useCallback(() => {
    void (async () => {
      setIsLoading(true);
      const userIdsToSendTo = sendCommunicationsToNotLoggedUsers
        ? map(notLoggedInUsers, "id")
        : userIds;
      const loginCommunicationType = isExternalUsers
        ? ELoginCommunicationType.m7Invite
        : ELoginCommunicationType.passwordReset;

      try {
        await triggerLoginCommunication({
          userIds: userIdsToSendTo,
          preferredCommunicationMethod: communicationMethod,
          loginCommunicationType,
        });
        showSuccess(`${isExternalUsers ? "M7 invite" : "Reset password"} sent successfully`);
        closeAction();
      } catch (error) {
        showError(`${isExternalUsers ? "M7 invite" : "Reset password"} failed to send`);
      }

      setIsLoading(false);
    })();
  }, [
    sendCommunicationsToNotLoggedUsers,
    notLoggedInUsers,
    userIds,
    communicationMethod,
    showSuccess,
    closeAction,
    showError,
    isExternalUsers,
    triggerLoginCommunication,
  ]);

  const content = useMemo(() => {
    if (singleUserMode) {
      const user = usersById[userIds[0]!];
      return (
        <Typography>
          Trigger {isExternalUsers ? "M7 invite" : "reset password flow"} for{" "}
          <b>
            {user?.firstName || ""} {user?.lastName || ""}
          </b>
          .<br />
          The user will receive an <b>{communicationMethod}</b> with a link to
          {isExternalUsers ? " login to M7" : " reset their password"}.
        </Typography>
      );
    } else {
      const userCount = sendCommunicationsToNotLoggedUsers
        ? notLoggedInUsers.length
        : userIds.length;
      return (
        <Typography>
          Send {isExternalUsers ? "M7 invite" : "reset password"} <b>{communicationMethod}</b> to{" "}
          {userCount} users
        </Typography>
      );
    }
  }, [
    singleUserMode,
    userIds,
    usersById,
    communicationMethod,
    notLoggedInUsers,
    sendCommunicationsToNotLoggedUsers,
    isExternalUsers,
  ]);

  const communicationMethodToggle = (
    <ToggleButtonGroup
      value={communicationMethod}
      exclusive
      onChange={(_, value: ECommunicationMethod) => setCommunicationMethod(value)}
      aria-label="text alignment"
      color={"darkPurple"}
      sx={{ position: "absolute", right: "32px", bottom: "32px" }}
    >
      <ToggleButton value={ECommunicationMethod.email} aria-label="email" trackingLabel="email">
        Email <Email sx={{ pl: 1 }} />
      </ToggleButton>
      <ToggleButton
        disabled={!canSendSms}
        value={ECommunicationMethod.sms}
        aria-label="sms"
        trackingLabel="sms"
      >
        Text <Sms sx={{ pl: 1 }} />
      </ToggleButton>
    </ToggleButtonGroup>
  );

  const userPicker = singleUserMode ? null : (
    <Stack direction="column" width="100%">
      <AutocompleteStaffSelector
        limitTags={15}
        trackingLabel={null}
        label={isExternalUsers ? "Send M7 invite to" : "Send reset password to"}
        staffOptions={users || []}
        selectedStaffIds={sendCommunicationsToNotLoggedUsers ? notLoggedInUserIds : userIds}
        setSelectedStaffIds={setUserIds}
        sx={{ my: 2 }}
        disabled={sendCommunicationsToNotLoggedUsers}
        showNoPhoneNumberStatus
      />
      <CustomSwitch
        trackingLabel={"send-communications-to-all-not-logged-in-users"}
        switchVariant="checkbox"
        label={
          <>
            Send {isExternalUsers ? "M7 invite" : "reset password"} to{" "}
            <b>all {notLoggedInUsers.length} users</b> that have not logged in yet.
          </>
        }
        name="send-communications-to-all-users-that-have-not-logged-in-yet"
        checked={sendCommunicationsToNotLoggedUsers}
        onChange={(checked) => setSendCommunicationsToNotLoggedUsers(checked)}
      />
    </Stack>
  );
  const smsDisabledText = useMemo(() => {
    if (canSendSms) {
      return null;
    }
    return (
      <Stack direction="row" alignItems="center" gap={0.3} sx={{ mt: 1, width: "fit-content" }}>
        <InfoOutlined sx={{ fontSize: "18px" }} />
        <Typography fontSize="12px">
          Unable to text because a selected user has no phone number.
        </Typography>
      </Stack>
    );
  }, [canSendSms]);

  return (
    <CustomModal
      isOpen={true}
      onClose={closeAction}
      modalHeaderText={isExternalUsers ? "Send M7 Invite" : "Reset Password"}
      primaryBtnText="Send"
      onSubmit={sendCommunicationsAction}
      secondaryBtnText="Cancel"
      onSecondaryBtnClick={closeAction}
      isLoading={isLoading}
      primaryDisabled={userIds.length === 0 && !sendCommunicationsToNotLoggedUsers}
      trackingLabel="send-reset-password"
    >
      <Box sx={{ maxWidth: "500px" }}>
        <Typography children={content} />
        {userPicker}
        {smsDisabledText}
        {communicationMethodToggle}
      </Box>
    </CustomModal>
  );
};
