import { useCallback, useMemo, useRef, useState } from "react";

import { YyyyMmDd } from "@m7-health/shared-utils";
import { useMutation } from "@tanstack/react-query";
import { get } from "lodash";

import { updateTargetLevelsOfScheduleApi } from "#/features/SchedulerGrid/api";
import { IUpdateTargetLevels } from "#/features/SchedulerGrid/api/types";
import {
  ITargetLevel,
  IUnitTargetLevel,
  useInvalidateQuery,
  useListOpenShiftsQuery,
  useListOrphanOpenShiftRequestsQuery,
  useListTargetLevelsQuery,
} from "@/api";
import { TToast, TToaster, useStaffCategoryByKey, useToast } from "@/common/hooks";
import { getTargetLevelKey } from "@/common/utils/getTargetLevelKey";

const successToast: {
  count: number;
  toast: TToast | null;
} = {
  count: 0,
  toast: null,
};

/**
 * Returns functions to handle target level changes, at the individual level
 * Meant for use in the OpenShiftSidebarContent component
 */
export const useHandleTargetChange = ({
  date,
  target,
  scheduleId,
}: {
  date: YyyyMmDd;
  target?: ITargetLevel | IUnitTargetLevel;
  scheduleId: string;
}) => {
  const invalidateQuery = useInvalidateQuery();
  const [errorUpdatingTarget, setErrorUpdatingTarget] = useState(false);

  // Toast logic to make sure we don't show multiple toasts at once
  const { showSuccess, showError } = useToast({
    onClose: (id) => {
      if (id === successToast.toast?.id) {
        successToast.toast = null;
        successToast.count = 0;
      }
    },
  });
  const staffCategoryKeyToName = useStaffCategoryByKey();
  const { mutateAsync: updateTargetLevels } = useMutation({
    mutationFn: updateTargetLevelsOfScheduleApi,
  });

  //Callback to make the API call to update the target level
  const updateTargetLevel = useCallback(
    async ({ value }: { value: number }) => {
      if (!target) return;
      const staffCategoryName =
        target.staffCategoryKey && staffCategoryKeyToName[target.staffCategoryKey]?.name;
      const shiftTypeKey = target.shiftTypeKey || get(target, "shiftTypeKeys")?.[0];
      if (!staffCategoryName || !shiftTypeKey) return;

      const formattedBody = {
        scheduleId,
        body: {},
      } as IUpdateTargetLevels;

      const targetEntry = {
        min: value,
        shiftTypeKey,
        scheduleId,
        staffCategoryKey: target.staffCategoryKey || undefined,
        date: date,
        ...(target.attributeKey ? { attributeKey: target.attributeKey } : {}),
      };

      if (staffCategoryName) {
        (((formattedBody.body[date] ||= {})[staffCategoryName] ||= {})[shiftTypeKey] ||= []).push(
          targetEntry,
        );
      }

      try {
        await updateTargetLevels(formattedBody);

        // Update toaster, only show number `(... (3/3)` if greater than 1
        updateSuccessToast(showSuccess);
        setErrorUpdatingTarget(false);
        invalidateQuery([
          useListTargetLevelsQuery,
          useListOpenShiftsQuery,
          useListOrphanOpenShiftRequestsQuery,
        ]);
      } catch (error) {
        setErrorUpdatingTarget(true);
        showError("Failed to update target levels");
      }
    },
    [
      staffCategoryKeyToName,
      showSuccess,
      invalidateQuery,
      updateTargetLevels,
      showError,
      date,
      scheduleId,
      target,
      setErrorUpdatingTarget,
    ],
  );

  const debouncedUpdateTimeouts = useRef<Record<string, NodeJS.Timeout>>({});

  // Handle increment of one target level, for one day, with
  //  + and - buttons. Hence the debounced update.
  const handleTargetChange = useCallback(
    (newValue: number) => {
      const aggregatedTarget =
        target && "shiftTypeKeys" in target && target.shiftTypeKeys.length > 1;
      if (!target || aggregatedTarget) return;

      const notAggregatedTarget = target as ITargetLevel;

      const timeoutKey = `${getTargetLevelKey(notAggregatedTarget)}-${date}`;
      if (debouncedUpdateTimeouts.current[timeoutKey])
        clearTimeout(debouncedUpdateTimeouts.current[timeoutKey]);

      debouncedUpdateTimeouts.current[timeoutKey] = setTimeout(() => {
        void updateTargetLevel({
          value: newValue,
        });
      }, 1000);
    },
    [date, updateTargetLevel, target],
  );

  return useMemo(
    () => ({ handleTargetChange, errorUpdatingTarget }),
    [handleTargetChange, errorUpdatingTarget],
  );
};

/**
 * Update the success toast with the number of updates
 *  and update the toast message with the number of updates (e.g. `(3/3)`)
 *  only if greater than 1.
 */
const updateSuccessToast = (showSuccess: TToaster) => {
  let toastMessage = "Target levels updated successfully";
  if (successToast.toast) {
    successToast.count += 1;
    toastMessage += ` (${successToast.count}/${successToast.count})`;
    successToast.toast.update(toastMessage);
  } else {
    successToast.toast = showSuccess(toastMessage);
    successToast.count = 1;
  }
};
