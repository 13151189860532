import "./OpenShiftRequest.scss";

import { useState } from "react";

import { getTzDayjs, YyyyMmDd } from "@m7-health/shared-utils";

import { CalendarMonth, Check as CheckIcon, Close as CloseIcon } from "@mui/icons-material";
import { Box, Divider, Stack, Tooltip, Typography } from "@mui/material";

import { useAppConfigQuery } from "#/features/User/queries";
import { IOpenShift, IOpenShiftRequest, IShiftType, IStaffDetails } from "@/api";
import { CustomButton } from "@/common/components/TrackedComponents";
import { WorkingHoursTag } from "@/common/components/WorkingHoursTag";
import { useCurrentTimezone } from "@/common/hooks/useCurrentTimezone";
import { useKeyBy } from "@/common/hooks/utils";
import { black } from "@/common/theming";
import { pxToRem } from "@/common/utils/pxToRem";

import { useGetStaffWorkingHours } from "../../hooks";
import { ApproveOpenShiftModal } from "../ApproveOpenShiftModal/ApproveOpenShiftModal";
import { OpenShiftTime } from "../OpenShiftTime";
import { RejectOpenShiftModal } from "../RejectOpenShiftModal";

export const OpenShiftRequest = ({
  request,
  openShift,
  staff,
  dateOfShift,
  shiftType,
  canManage,
}: {
  shiftType: IShiftType;
  request: IOpenShiftRequest;
  openShift: IOpenShift;
  staff: IStaffDetails;
  dateOfShift: YyyyMmDd;
  canManage: boolean;
}) => {
  const currentTimezone = useCurrentTimezone();

  const isPartial =
    request.durationSeconds !== openShift.durationSeconds ||
    request.startDateTime !== openShift.startDateTime;

  const scheduledHours = useGetStaffWorkingHours(staff.userId, dateOfShift);
  const unitsById = useKeyBy(useAppConfigQuery().data?.units, "id");
  const requestedAt = getTzDayjs(request.createdAt, currentTimezone).format("M/D/YYYY h:mmA");

  const [openModal, setOpenModal] = useState<"approve" | "reject" | null>(null);

  return (
    <>
      <Box className="open-shift-request" key={request.id}>
        <Stack direction="column" gap={0.3} width="100%">
          <Typography fontSize={pxToRem(14)}>
            {`${staff.user.firstName} ${staff.user.lastName}, ${staff.staffType.name}`}
          </Typography>
          <Typography fontSize={pxToRem(12)}>
            Home Unit: <b>{unitsById[staff.homeUnitId]?.name}</b>
          </Typography>
          <Typography fontSize={pxToRem(12)}>
            Scheduled Hours:{"  "}
            <WorkingHoursTag
              workingHours={scheduledHours}
              sx={{ fontSize: pxToRem(12), fontWeight: 600, display: "inline" }}
              popOverOptions={{ offset: [0, 10] }}
            />
          </Typography>

          <Stack direction="row" gap={0.5} alignItems="center">
            <Tooltip title={`Request created at ${requestedAt}`}>
              <Typography variant="shy" sx={{ fontSize: pxToRem(10) }} fontWeight={400}>
                <CalendarMonth
                  sx={{ fontSize: pxToRem(14), color: black, position: "relative", top: 2 }}
                />
                {requestedAt}
              </Typography>
            </Tooltip>
          </Stack>

          {(request.staffNote || isPartial) && <Divider sx={{ margin: "12px 0px !important" }} />}
          {isPartial && (
            <Stack direction="row" gap={0} alignItems="center" height="18px">
              <Typography fontSize={pxToRem(12)}>Partial Request: </Typography>
              <OpenShiftTime
                sx={{ transform: "scale(0.8)" }}
                openShift={{
                  startDateTime: request.startDateTime || openShift.startDateTime,
                  durationSeconds: request.durationSeconds || openShift.durationSeconds,
                }}
                shiftType={shiftType}
              />
            </Stack>
          )}
          {request.staffNote && (
            <Typography fontSize={pxToRem(12)}>
              Staff Note: <b>{request.staffNote}</b>
            </Typography>
          )}
        </Stack>
        {canManage && (
          <Stack direction="row" gap={1} className="decision-buttons">
            <CustomButton
              iconOnly
              className="decision-button"
              aria-label="Accept"
              color="success"
              onClick={() => setOpenModal("approve")}
              startIcon={<CheckIcon />}
              trackingLabel="approve-ops-request"
            />
            <CustomButton
              iconOnly
              className="decision-button"
              aria-label="Deny"
              color="error"
              onClick={() => setOpenModal("reject")}
              startIcon={<CloseIcon />}
              trackingLabel="reject-ops-request"
            />
          </Stack>
        )}
      </Box>
      {canManage && (
        <>
          {openModal === "approve" && (
            <ApproveOpenShiftModal
              onClose={() => setOpenModal(null)}
              request={request}
              openShift={openShift}
              scheduleId={openShift.scheduleId}
              requester={staff.user}
            />
          )}
          {openModal === "reject" && (
            <RejectOpenShiftModal
              onClose={() => setOpenModal(null)}
              requestId={request.id}
              firstName={staff.user.firstName}
              lastName={staff.user.lastName}
            />
          )}
        </>
      )}
    </>
  );
};
