import { useCallback } from "react";

import {
  IOpenShift,
  useInvalidateQuery,
  useListOpenShiftsQuery,
  useUpdateOpenShiftMutation,
} from "@/api";
import { useToast } from "@/common/hooks";

// Persist open shifts data to the database
export const useSaveOpenShifts = () => {
  const { mutateAsync: saveOpenShifts } = useUpdateOpenShiftMutation({});
  const { showSuccess, showError } = useToast();
  const invalidateQuery = useInvalidateQuery();

  return useCallback(
    async (openShifts: IOpenShift[]) => {
      const savePromises: Promise<unknown>[] = [];

      try {
        await Promise.all(
          openShifts.map((openShift) =>
            saveOpenShifts({
              openShiftId: openShift.id,
              note: openShift.note,
              shiftIncentiveLevelId: openShift.shiftIncentiveLevelId || null,
            }),
          ),
        );

        await Promise.all(savePromises);
        showSuccess("Open shifts saved");
      } catch (error) {
        showError("Error saving open shifts");
      } finally {
        invalidateQuery(useListOpenShiftsQuery);
      }
    },
    [saveOpenShifts, showError, showSuccess, invalidateQuery],
  );
};
