import { ISODateString } from "@m7-health/shared-utils";

import { seconds } from "@/common/types";

import { APIListResponse, SDTO } from "../_shared/types";
import { IOpenShift } from "../openShift/types";
import { IUser } from "../user";

export type IOpenShiftRequest = OpenShiftRequest.DTO;
export type IOrphanOpenShiftRequest = OpenShiftRequest.DTO & { openShifts: IOpenShift[] };

export namespace OpenShiftRequest {
  export enum EStatus {
    pending = "pending",
    accepted = "accepted",
    rejected = "rejected",
  }

  export interface DTO extends SDTO.base, SDTO.withTimestamps {
    /** @deprecated use openShiftsRequestedShiftEntities instead */
    openShiftId: IOpenShift["id"];
    status: EStatus;
    decidedAt: ISODateString | null;
    deciderId: IUser["id"] | null;
    requesterId: IUser["id"];
    staffNote?: string;
    createdById: IUser["id"];
    startDateTime: ISODateString | null;
    durationSeconds: seconds | null;
    openShiftsRequestedShiftEntities?: {
      openShiftId: IOpenShift["id"];
      requestId: IOpenShiftRequest["id"];
    }[];
  }

  export namespace API {
    export const ROOT_PATH = "/open-shift-request";
    export namespace List {
      export const PATH = ROOT_PATH;
      export interface QueryParams {
        requesterIds?: IUser["id"][];
      }

      export type Response = APIListResponse<DTO>;
    }

    export namespace ListOrphans {
      export const PATH = `${ROOT_PATH}/orphans`;
      export interface QueryParams {
        scheduleIds?: IOpenShift["scheduleId"][];
      }

      export type Response = APIListResponse<IOrphanOpenShiftRequest>;
    }
  }
}
