import { useCurrentFacility } from "@/common/hooks/useCurrentFacilityId";

import { useAppFlags } from "./useAppFlags";

/**
 *  Returns House View all tab settings.
 */
export const useHouseViewEnableAllTab = (): boolean => {
  const ffOn = useAppFlags().houseViewAllTab ?? false;
  const facilityConfig = useCurrentFacility()?.configuration;
  const disabled = facilityConfig?.settings?.useHouseViewAllTab ?? false;

  return ffOn && !disabled;
};
