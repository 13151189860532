import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

import { ECommunicationMethod, EUnitPermissionAreas } from "@m7-health/shared-utils";
import { capitalize, last, map } from "lodash";

import { CheckBox, CheckBoxOutlineBlank, Email, Sms, SpeakerNotesOff } from "@mui/icons-material";
import { Grid, Stack, TextField, ToggleButtonGroup, Typography } from "@mui/material";

import { CustomInputControlled } from "~/common/components/Input/Input";
import CustomMultiSelectControlled from "~/common/components/TrackedComponents/CustomMultiSelect/CustomMultiSelectControlled";
import {
  useAppFlags,
  useAppSelector,
  useCheckUserPermission,
  useCurrentTimezone,
  useKeyBy,
  useMap,
} from "~/common/hooks";
import { Uuid } from "~/common/types";
import { rosterUserEmploymentStatus } from "~/features/Roster/constants";
import { useAllStaffAttributesByKey } from "~/routes/hooks/useStaffAttributesByKeyForUnit";

import { IEditUser } from "#/features/Roster/types";
import { useAppConfigQuery } from "#/features/User/queries";
import { IPreferenceRequirementRuleSet, IPreferencesTemplate, Unit, User } from "@/api";
import {
  Autocomplete,
  Checkbox,
  CustomSelect,
  CustomSelectControlled,
  PreferencesTemplateLabel,
  RuleSetLabel,
  ToggleButton,
} from "@/common/components";
import { CustomDatePickerControlled } from "@/common/components/DeprecatedDatePicker/DatePicker";
import { black, darkGray, darkPurple, warning } from "@/common/theming/colors";
import { homeUnitMsg } from "@/common/validation/messages";

import { useStaffDataFormQueries } from "./hooks";

const ruleSetToOption = (ruleSet: IPreferenceRequirementRuleSet) => ({
  label: ruleSet.name,
  value: ruleSet.id,
  item: ruleSet,
});
const preferencesTemplateToOptions = (referencesTemplate: IPreferencesTemplate) => ({
  label: referencesTemplate.name,
  value: referencesTemplate.id,
  item: referencesTemplate,
});

//Doesn't include Kiosk because we dont want to display that role in the field
const roleTypeKeysToNameObject = {
  [User.ERole.staff]: capitalize(User.ERole.staff),
  [User.ERole.scheduler]: capitalize(User.ERole.scheduler),
};

const defaultStaffRoleTypes = [User.ERole.staff, User.ERole.kiosk];

export const StaffDataForm = ({ userData }: { userData?: IEditUser }) => {
  const {
    control,
    formState: { errors, defaultValues },
    watch,
    setValue,
  } = useFormContext();
  const timezone = useCurrentTimezone();
  const unitsById = useKeyBy(useAppConfigQuery().data?.units, "id");
  const isAddUserModalOpen = useAppSelector((state) => state.roster.addUserModal.isOpen);

  const { newSmsOnboarding } = useAppFlags();
  const userCanCreateSchedulers = useCheckUserPermission(
    "manage",
    EUnitPermissionAreas.schedulerRoster,
  );

  const isOnOrientation = Boolean(watch("onOrientation"));
  const homeUnitId = watch("homeUnitId") as Uuid | undefined;

  const changedHomeUnitId = defaultValues && defaultValues?.homeUnitId !== homeUnitId;
  const roleTypeSelectItems = [
    { value: User.ERole.staff, label: capitalize(User.ERole.staff) },
    ...(userCanCreateSchedulers
      ? [{ value: User.ERole.scheduler, label: capitalize(User.ERole.scheduler) }]
      : []),
  ];

  const {
    units,
    schedulerData,
    allStaffAttributes,
    shiftOptions,
    ruleSets,
    preferencesTemplates,
    staffTypes,
  } = useStaffDataFormQueries(homeUnitId);

  const staffTypeItems = useMemo(
    () =>
      map(staffTypes, (staffType) => ({
        label: staffType.name + (staffType.description ? ` (${staffType.description})` : ""),
        value: staffType.name,
      })),
    [staffTypes],
  );

  const [selectedScheduler, setSelectedScheduler] = useState<Unit.ISchedulerDto | undefined>(
    undefined,
  );

  //Set existing scheduler form values when an existing scheduler is selected
  useEffect(() => {
    if (!isAddUserModalOpen) return;
    if (selectedScheduler) {
      setValue("existingSchedulerId", selectedScheduler.id);
      setValue("firstName", selectedScheduler.firstName);
      setValue("lastName", selectedScheduler.lastName);
      setValue("email", selectedScheduler.email);
      setValue("phoneNumber", selectedScheduler.phoneNumber);
      setValue("roleTypes", [User.ERole.staff, User.ERole.scheduler]);
    } else {
      setValue("existingSchedulerId", undefined);
      setValue("firstName", "");
      setValue("lastName", "");
      setValue("email", "");
      setValue("phoneNumber", "");
      setValue("roleTypes", [User.ERole.staff]);
    }
  }, [selectedScheduler, setValue, isAddUserModalOpen]);

  const allStaffAttributesByKey = useAllStaffAttributesByKey();
  const allStaffAttributeNamesByKey: Record<string, string> = Object.keys(
    allStaffAttributesByKey || {},
  ).reduce(
    (result, key) => {
      result[key] = allStaffAttributesByKey[key]?.name || "";
      return result;
    },
    {} as Record<string, string>,
  );

  const allUnitsEligible = watch("unitAssignment") as Uuid[];
  const allUnitsEligibleCustomSelect = units.filter((unit) => allUnitsEligible?.includes(unit.id));
  const allStaffAttributesFilteredByUnit = allStaffAttributes.filter((attribute) =>
    allUnitsEligible?.includes(attribute.unitId),
  );
  const attributesItems = allStaffAttributesFilteredByUnit.map((attribute) => ({
    label: `${attribute.name} (${unitsById[attribute.unitId]?.name || ""})`,
    value: attribute.key,
  }));

  // Rule set related
  const ruleSetDropdownItems = useMap(ruleSets, ruleSetToOption);
  const homeUnitRosterId = userData?.rosters.find((roster) => roster.unitId === homeUnitId)?.id;
  // Grab user rule set and set them
  useEffect(() => {
    if (ruleSets && homeUnitRosterId) {
      const userRuleSets = ruleSets.filter((ruleSet) =>
        ruleSet.rosterRuleSets.find((rosterRuleSet) => rosterRuleSet.rosterId === homeUnitRosterId),
      );
      setValue("preferenceRequirementRuleSetIds", map(userRuleSets, "id"));
    }
  }, [ruleSets, homeUnitRosterId, setValue]);

  // Preferences template related
  const preferencesTemplateItems = useMap(preferencesTemplates, preferencesTemplateToOptions);
  // Grab user preferences template and set them
  useEffect(() => {
    if (preferencesTemplates && userData?.preferencesTemplateId) {
      const userPreferencesTemplate = preferencesTemplates.find(
        (preferencesTemplate) => preferencesTemplate.id === userData.preferencesTemplateId,
      );
      setValue("preferencesTemplateId", userPreferencesTemplate?.id);
    }
  }, [preferencesTemplates, userData?.preferencesTemplateId, setValue]);
  const selectedPreferenceTemplateId = watch("preferencesTemplateId") as Uuid | undefined;

  // Watch the phone number field to handle empty values
  const phoneNumber = watch("phoneNumber") as string | null;

  useEffect(() => {
    // If phoneNumber exists and is empty after trimming, set it to null
    if (phoneNumber !== undefined && phoneNumber !== null && phoneNumber.trim() === "") {
      setValue("phoneNumber", null);
    }
  }, [phoneNumber, setValue]);

  return (
    <Grid
      item
      container
      gap={2}
      flexDirection="column"
      mt={2}
      pt="0.3rem"
      maxHeight="50vh"
      flexWrap="nowrap"
      overflow="auto"
    >
      {isAddUserModalOpen && userCanCreateSchedulers && (
        <Autocomplete
          fullWidth
          trackingLabel="scheduler-picker-from-staff-create-modal"
          options={schedulerData?.list || []}
          value={selectedScheduler}
          getOptionLabel={(scheduler) =>
            `${scheduler.firstName} ${scheduler.lastName} (${scheduler.email})`
          }
          renderOption={(props, scheduler, { selected }) => {
            return (
              <li {...props}>
                <Checkbox
                  trackingLabel="attribute-picker"
                  icon={<CheckBoxOutlineBlank fontSize="small" style={{ color: darkGray }} />}
                  checkedIcon={<CheckBox fontSize="small" style={{ color: black }} />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                  disabled
                />
                {`${scheduler.firstName} ${scheduler.lastName} (${scheduler.email})`}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField {...params} size="small" label={"Select existing scheduler"} />
          )}
          onChange={(_event, value) => {
            setSelectedScheduler(value || undefined);
          }}
        />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">General</Typography>
        </Grid>

        <Grid item xs={12}>
          <CustomMultiSelectControlled
            sx={regularSizedInputSx}
            label="Role Types*"
            name="roleTypes"
            fullWidth
            errors={errors}
            items={roleTypeSelectItems}
            value={(watch("roleTypes") || []) as string[]}
            onChange={(name, selectedValues) => {
              setValue(name, [...new Set([...defaultStaffRoleTypes, ...selectedValues])]);
            }}
            keyToNameObject={roleTypeKeysToNameObject}
          />
        </Grid>

        <Grid item xs={6}>
          <CustomInputControlled
            label="First Name*"
            name="firstName"
            control={control}
            errors={errors}
          />
        </Grid>

        <Grid item xs={6}>
          <CustomInputControlled
            label="Last Name*"
            name="lastName"
            control={control}
            errors={errors}
          />
        </Grid>

        <Grid item xs={6}>
          <CustomInputControlled label="Email*" name="email" control={control} errors={errors} />
        </Grid>

        <Grid item xs={6}>
          <CustomInputControlled
            label="Phone"
            name="phoneNumber"
            control={control}
            errors={errors}
          />
        </Grid>

        <Grid item xs={6}>
          <CustomSelectControlled
            label="Staff Type*"
            name="staffTypeName"
            control={control}
            errors={errors}
            items={staffTypeItems}
          />
        </Grid>

        <Grid item xs={6}>
          <CustomDatePickerControlled
            name="employmentStartDate"
            control={control}
            errors={errors}
            label="Employment Start"
            timezone={timezone}
            sx={{ minWidth: "100%" }}
          />
        </Grid>

        <Grid item xs={6}>
          <CustomSelectControlled
            label="Employment Status*"
            name="employmentType"
            control={control}
            errors={errors}
            items={rosterUserEmploymentStatus}
          />
        </Grid>

        <Grid item xs={6}>
          <CustomDatePickerControlled
            name="contractEndDate"
            control={control}
            errors={errors}
            label="Contract End"
            timezone={timezone}
            sx={{ minWidth: "100%" }}
          />
        </Grid>

        <Grid item xs={6}>
          <CustomSelectControlled
            label="Orientation"
            name="onOrientation"
            control={control}
            fullWidth
            errors={errors}
            items={[
              { label: "None", value: null },
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomSelectControlled
            label="Preceptor"
            name="preceptor"
            control={control}
            fullWidth
            errors={errors}
            items={[
              { label: "None", value: null },
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
          />
        </Grid>

        {isOnOrientation && (
          <Grid item xs={6}>
            <CustomDatePickerControlled
              name="orientationEndDate"
              control={control}
              errors={errors}
              label="Orientation End*"
              timezone={timezone}
              sx={{ minWidth: "100%" }}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography variant="h5">Scheduling Details</Typography>
        </Grid>

        <Grid item xs={6}>
          <CustomSelectControlled
            label="Primary Shift"
            name="shiftType"
            control={control}
            errors={errors}
            items={shiftOptions || []}
          />
        </Grid>

        <Grid item xs={6}>
          <CustomSelect<IPreferenceRequirementRuleSet>
            multiple
            label="Shift Requirement"
            MenuProps={{ className: "shift-requirement-dropdown-menu" }}
            variant="outlined"
            items={ruleSetDropdownItems}
            onChange={(event) => {
              //Filters out any null or empty values
              const newRuleSetIds = [event.target.value].flat().filter((ruleSetId) => ruleSetId);
              setValue("preferenceRequirementRuleSetIds", newRuleSetIds);
            }}
            optionRenderComponent={(ruleSet) => <RuleSetLabel ruleSet={ruleSet?.item} />}
            renderMultiSelectedValue={(ruleSet) => <RuleSetLabel ruleSet={ruleSet?.item} />}
            value={(watch("preferenceRequirementRuleSetIds") || []) as Uuid[]}
          />
        </Grid>

        <Grid item xs={6}>
          <CustomSelect<IPreferencesTemplate>
            label="Preference Template"
            multiple
            MenuProps={{ className: "preferences-template-dropdown-menu" }}
            variant="outlined"
            items={preferencesTemplateItems}
            onChange={(event) => {
              const newTemplateId = last(event.target.value);
              setValue("preferencesTemplateId", newTemplateId);
            }}
            optionRenderComponent={(preferencesTemplate) => (
              <PreferencesTemplateLabel
                preferencesTemplate={preferencesTemplate?.item}
                sx={{ width: "80px" }}
              />
            )}
            renderMultiSelectedValue={(preferencesTemplate) => (
              <PreferencesTemplateLabel
                preferencesTemplate={preferencesTemplate?.item}
                sx={{ width: "80px" }}
              />
            )}
            value={selectedPreferenceTemplateId ? [selectedPreferenceTemplateId] : []}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5">Eligibility</Typography>
        </Grid>

        <Grid item xs={12}>
          <CustomSelectControlled
            label="Home Unit*"
            name="homeUnitId"
            control={control}
            fullWidth
            errors={errors}
            items={allUnitsEligibleCustomSelect}
          />
          {changedHomeUnitId && (
            <Typography variant="small" sx={{ marginTop: "-12px", color: warning }}>
              {homeUnitMsg}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          <CustomSelectControlled
            label="Unit Eligibility*"
            name="unitAssignment"
            control={control}
            fullWidth
            errors={errors}
            items={units}
            multiple
          />
        </Grid>

        <Grid item xs={12}>
          <CustomMultiSelectControlled
            label="Position Eligibility"
            name="attributeKeys"
            fullWidth
            errors={errors}
            items={attributesItems}
            value={(watch("attributeKeys") || []) as string[]}
            onChange={setValue}
            keyToNameObject={allStaffAttributeNamesByKey}
          />
        </Grid>
      </Grid>
      {!userData?.id && newSmsOnboarding && (
        <Stack
          direction="column"
          gap={1}
          sx={{ position: "absolute", right: "32px", bottom: "32px" }}
        >
          <Typography fontSize="0.875rem" fontStyle="italic" color={darkPurple}>
            "Reset Password" method
          </Typography>
          <ToggleButtonGroup
            value={(watch("sendResetPasswordMethod") || "") as ECommunicationMethod | ""}
            exclusive
            onChange={(_, value: ECommunicationMethod) =>
              setValue("sendResetPasswordMethod", value)
            }
            aria-label="text alignment"
            color={"darkPurple"}
            sx={{ svg: { pl: 1 } }}
          >
            <ToggleButton value={""} aria-label="none" trackingLabel="none">
              None <SpeakerNotesOff />
            </ToggleButton>
            <ToggleButton
              value={ECommunicationMethod.email}
              aria-label="email"
              trackingLabel="email"
            >
              Email <Email />
            </ToggleButton>
            <ToggleButton value={ECommunicationMethod.sms} aria-label="sms" trackingLabel="sms">
              SMS <Sms />
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      )}
    </Grid>
  );
};

const regularSizedInputSx = {
  ".MuiInputBase-input": {
    padding: "8.5px 0 8.5px 14px !important",
  },
  ".MuiInputLabel-root": {
    top: "-7px",
  },
} as const;
