import React, { useCallback, useEffect } from "react";

import { isEqual } from "lodash";

import { Close } from "@mui/icons-material";
import { Box } from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import {
  setAcknowledgedSchedulerAppBannerText,
  setAcknowledgedStaffAppBannerText,
  setBannerIsOpen,
} from "#/features/User/store";
import {
  useAppDispatch,
  useAppSelector,
  useCurrentRole,
  useCurrentUnit,
  useCurrentUser,
} from "@/common/hooks";
import { nightShift } from "@/common/theming/colors";
import { isOnMobile } from "@/common/utils/isOnMobile";

const Banner = ({ isSchedulerApp }: { isSchedulerApp: boolean }) => {
  const { firstName } = useCurrentUser();
  const dispatch = useAppDispatch();
  const { bannerIsOpen, acknowledgedSchedulerAppBannerText, acknowledgedStaffAppBannerText } =
    useAppSelector(
      (state) => ({
        bannerIsOpen: state.user.bannerIsOpen,
        acknowledgedSchedulerAppBannerText: state.user.acknowledgedSchedulerAppBannerText,
        acknowledgedStaffAppBannerText: state.user.acknowledgedStaffAppBannerText,
      }),
      isEqual,
    );
  const { userIsAdmin, userIsStaff, userIsScheduler } = useCurrentRole();
  // if staff: will get the home unit. if scheduler: will get the selected unit you are on
  const userUnit = useCurrentUnit();
  // message will be trimmed and
  // replace the {firstName} with the user's first name
  // replace the [$1]($2), where $1=text and $2=url with an anchor tag- result: <a href="$2">$1</a>
  const unitConfigData = userUnit?.configuration.data;

  const messageToAllUsersTrimmed = unitConfigData?.messageToUsers
    ?.trim()
    ?.replace("{firstName}", firstName)
    ?.replace(/\[([^[\]]*)\]\(([^()]+)\)/g, '<a href="$2">$1</a>');
  const messageToSchedulerUsersTrimmed = unitConfigData?.messageToSchedulerUsers
    ?.trim()
    ?.replace("{firstName}", firstName)
    ?.replace(/\[([^[\]]*)\]\(([^()]+)\)/g, '<a href="$2">$1</a>');
  const messageToStaffUsersTrimmed = unitConfigData?.messageToStaffUsers
    ?.trim()
    ?.replace("{firstName}", firstName)
    ?.replace(/\[([^[\]]*)\]\(([^()]+)\)/g, '<a href="$2">$1</a>');

  let finalMessage: string | null = null;
  // check if user is of role and if message is not empty, and then set the final message
  // fallback to the message to all users if other conditions are not met
  if ((userIsScheduler || userIsAdmin) && messageToSchedulerUsersTrimmed?.length) {
    finalMessage = messageToSchedulerUsersTrimmed;
  } else if (userIsStaff && messageToStaffUsersTrimmed?.length) {
    finalMessage = messageToStaffUsersTrimmed;
  } else if (messageToAllUsersTrimmed?.length) {
    finalMessage = messageToAllUsersTrimmed;
  }

  useEffect(() => {
    //If the finalMessage and acknowledgedBannerText are not the same then set bannerIsOpen to true
    if (!finalMessage) return;
    if (isSchedulerApp && acknowledgedSchedulerAppBannerText !== finalMessage) {
      dispatch(setBannerIsOpen(true));
    } else if (!isSchedulerApp && acknowledgedStaffAppBannerText !== finalMessage) {
      dispatch(setBannerIsOpen(true));
    }
  }, [
    finalMessage,
    isSchedulerApp,
    acknowledgedSchedulerAppBannerText,
    acknowledgedStaffAppBannerText,
    dispatch,
  ]);

  const acknowledgeBanner = useCallback(() => {
    if (!finalMessage) return;
    if (isSchedulerApp) {
      dispatch(setAcknowledgedSchedulerAppBannerText(finalMessage));
    } else {
      dispatch(setAcknowledgedStaffAppBannerText(finalMessage));
    }
    dispatch(setBannerIsOpen(false));
  }, [dispatch, finalMessage, isSchedulerApp]);

  if (!finalMessage) {
    return null;
  }

  // if scheduler app and on mobile, set the margin to 20% to center the text away from hamburger menu
  return bannerIsOpen ? (
    <Paper
      elevation={3}
      style={{ backgroundColor: nightShift, padding: "10px" }}
      className={"hide-on-print"}
    >
      <Box
        mx={isSchedulerApp && isOnMobile() ? "20%" : ""}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <div style={{ flex: 1 }}>
          {" "}
          {/* Added a div wrapper */}
          <Typography variant="body2" align="center">
            {/* ensure that the html is safe */}
            <span dangerouslySetInnerHTML={{ __html: finalMessage }} />
          </Typography>
        </div>
        <Box onClick={acknowledgeBanner} sx={{ cursor: "pointer", mt: "5px" }}>
          <Close />
        </Box>
      </Box>
    </Paper>
  ) : (
    <></>
  );
};

export default Banner;
