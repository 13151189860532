import { IOpenShiftsSidebarState } from "../types";

export const initialState: IOpenShiftsSidebarState = {
  selectedDate: null,
  filters: {
    shiftTypes: [],
    staffCategories: [],
    unitIds: [],
    modalIsOpen: false,
  },
  modals: {
    approveOpenShiftModal: {
      isOpen: false,
    },
    rejectOpenShiftModal: {
      isOpen: false,
    },
  },
  data: {
    openShiftsByDate: {},
    schedules: [],
    staffDetails: {},
    shiftTypes: {},
    targetLevels: {},
    targetLevelCounts: {},
  },
};
