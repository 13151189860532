import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { array, date, mixed, object, string } from "yup";

import { dateMsg } from "~/common/validation/messages";
import {
  emailShapeRequired,
  phoneNumberShape,
  stringShapeRequired,
} from "~/common/validation/shapes";
import { EStaffStatus } from "~/features/Roster/types";

import { StaffDetails, User } from "@/api";
import { localDayJs } from "@/common/packages/dayjs";

export const useStaffDataForm = (selectedUnitId: string, isEditStaff: boolean) => {
  return useForm({
    mode: "onChange",
    defaultValues: isEditStaff
      ? {}
      : {
          ...createStaffDefaultValues,
          unitAssignment: [selectedUnitId],
          homeUnitId: selectedUnitId,
        },

    // if still not use floating, then can only assign a user to one unit
    resolver: yupResolver(
      object()
        .shape(isEditStaff ? editStaffRequirements : createStaffRequirements)
        .required(),
    ),
  });
};

/**
 * Yup validation schema for ensuring the home unit is included in the unit assignment.
 * If not included, does not allow to save (as the Home Unit field is required with the * displayed)
 *
 * @param {mixed} - The schema to be extended.
 * @returns {mixed} - The extended schema with the custom test.
 */
const ensureHomeUnitIsIncludedInUnitAssignment = mixed().when("unitAssignment", {
  is: Array.isArray,
  then: (schema) =>
    schema.test({
      name: "homeUnitInUnitAssignment",
      message: "Home unit must be included in unit assignment",
      test: function (homeUnitId) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
        const unitAssignment = this.parent.unitAssignment;
        return Array.isArray(unitAssignment) && unitAssignment.includes(homeUnitId);
      },
    }),
  otherwise: (schema) => schema,
});

const createStaffRequirements = {
  // Required fields
  email: emailShapeRequired,
  firstName: stringShapeRequired,
  lastName: stringShapeRequired,
  staffTypeName: stringShapeRequired,
  homeUnitId: ensureHomeUnitIsIncludedInUnitAssignment,
  employmentType: stringShapeRequired,
  unitAssignment: array().of(string().required()).min(1, "Select at least one unit").required(),
  roleTypes: array().of(string().required()).min(1, "Select at least one role type").required(),
  preceptor: mixed().oneOf([true, false]),
  onOrientation: mixed().oneOf([true, false]),

  // Optional fields - remove 'required' validation
  phoneNumber: phoneNumberShape,
  employmentStartDate: date().typeError(dateMsg).nullable(),
  orientationEndDate: mixed().when("onOrientation", {
    is: true,
    then: () => date().typeError(dateMsg).nullable(),
    otherwise: () => string().nullable(),
  }),
  shiftType: string().nullable(),
  status: string().nullable(),
};

const editStaffRequirements = {
  // Keep only the required fields for editing
  email: emailShapeRequired,
  firstName: stringShapeRequired,
  lastName: stringShapeRequired,
  unitAssignment: array().of(string().required()).min(1, "Select at least one unit").required(),
  homeUnitId: ensureHomeUnitIsIncludedInUnitAssignment,
  roleTypes: array().of(string().required()).min(1, "Select at least one role type").required(),
  staffTypeName: string(),
  phoneNumber: phoneNumberShape,
};

const createStaffDefaultValues = {
  // Required fields with empty defaults
  email: "",
  firstName: "",
  lastName: "",
  roleTypes: [User.ERole.staff, User.ERole.kiosk],
  preceptor: false,
  onOrientation: false,
  employmentType: StaffDetails.EEmploymentType.fullTime,
  staffTypeName: null,

  // Optional fields with sensible defaults
  chargeEligibility: false,
  contractEndDate: null,
  phoneNumber: null,
  employmentStartDate: localDayJs(),
  externalStaffIdentifier: "",
  orientationEndDate: null,
  shiftType: "",
  status: EStaffStatus.Active,
};
