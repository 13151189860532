import { memo, useCallback } from "react";

import { YyyyMmDd } from "@m7-health/shared-utils";
import { isEqual } from "lodash";

import { alpha, Box, Typography } from "@mui/material";

import { IUnitBasic } from "~/routes/api/types";

import { SelectedUnit } from "#/features/HouseView/components/SideBar/states/SelectedUnit";
import { IUnitConfig } from "#/features/User/types";
import { INote, IStaffDetails, IStaffShift } from "@/api";
import { useAppDispatch, useAppSelector } from "@/common/hooks";
import { mediumGray } from "@/common/theming";

import { houseViewStore } from "../../../store";

export const UnitDayCell = memo(
  ({
    unit,
    formattedDay,
    shiftsByDayByUnit,
    staffDetailsByUserId,
    notesByUserId,
    notesFromStaffByUserId,
  }: {
    unit: IUnitConfig;
    formattedDay: YyyyMmDd;
    shiftsByDayByUnit: Record<YyyyMmDd, Record<IUnitBasic["id"], IStaffShift[]>>;
    staffDetailsByUserId: Record<string, IStaffDetails>;
    notesByUserId: Record<string, INote>;
    notesFromStaffByUserId: Record<string, INote>;
  }) => {
    const dispatch = useAppDispatch();
    const { selectedUnitId, selectedDate } = useAppSelector(
      (state) => ({
        selectedUnitId: state.houseView.pageFilters.selectedUnitId,
        selectedDate: state.houseView.pageFilters.selectedDateForData,
      }),
      isEqual,
    );
    const hasShifts = !!shiftsByDayByUnit[formattedDay]?.[unit.id]?.length;
    const isSelectedUnitCard = selectedUnitId === unit.id && selectedDate === formattedDay;

    const onUnitCardClick = useCallback(
      (unitId: IUnitBasic["id"], date: YyyyMmDd) => {
        dispatch(houseViewStore.state.selectUnit(unitId));
        dispatch(houseViewStore.state.selectDateForData(date));
      },
      [dispatch],
    );
    return (
      <Box
        className={`unit-data-cell ${isSelectedUnitCard ? "selected" : ""}`}
        key={`${unit.id}-${formattedDay}`}
        onClick={() => onUnitCardClick(unit.id, formattedDay)}
      >
        {hasShifts ? (
          <SelectedUnit
            key={"unit-card-selected-unit-" + unit.id + formattedDay}
            selectedUnit={unit}
            shifts={shiftsByDayByUnit[formattedDay]}
            staffDetails={staffDetailsByUserId}
            notes={notesByUserId}
            notesFromStaff={notesFromStaffByUserId}
            compressed={true}
            unitsByScheduleId={{}}
            unitCard={true}
          />
        ) : (
          <Typography
            variant="subtitle1"
            sx={{ color: alpha(mediumGray, 2) }}
            fontWeight="100"
            children="No Shifts"
          />
        )}
      </Box>
    );
  },
);
