import { useState } from "react";

// eslint-disable-next-line deprecate/import
import { Button, ButtonProps, IconButton, Tooltip, Typography } from "@mui/material";

import { black, darkGray, white } from "~/common/theming/colors";
import { emptySx, TSx } from "~/common/types";
import { useAppTracking } from "~/modules/mixpanel/Context";
import { Mxp } from "~/modules/mixpanel/types";

import CustomModal from "../../Modal";

import { IButtonProps } from "./types";

export const CustomButton = ({
  children,
  label,
  trackingLabel,
  type = "button",
  onClick,
  onClickAsync,
  color = "primary",
  variant = "contained",
  disabled = false,
  style,
  startIcon,
  isLoading = undefined,
  loadingIndicator = undefined,
  sx = emptySx,
  iconOnly = false,
  confirmation,
  ...rest
}: IButtonProps & ButtonProps) => {
  // Wrap the passed onClick function for analytics
  const track = useAppTracking();

  const onClickWrapper = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    confirmed = false,
  ): Promise<void> => {
    const onClickAction = (onClick || onClickAsync) as typeof onClickAsync;
    if (confirmation && !confirmed) {
      const confirmationText = typeof confirmation === "function" ? confirmation() : confirmation;
      if (confirmationText) {
        setConfirmationModalIsOpen(true);
        event.stopPropagation();
        return Promise.resolve();
      }
    }

    if (onClickAction) {
      if (!trackingLabel) {
        if (label) trackingLabel ||= label;
        if (typeof children === "string") trackingLabel ||= children;
      }

      // Explicit empty label is allowed
      if (trackingLabel) {
        track(Mxp.Event.elementClicked, {
          [Mxp.Property.element.type]: Mxp.ElementsTypes.button,
          [Mxp.Property.element.label]: trackingLabel,
        });
      }

      const triggerOnClick = onClickAsync
        ? async () => {
            setIsLoading(true);
            await onClickAction(event);
            setIsLoading(false);
          }
        : () => onClickAction(event);

      return triggerOnClick();
    }

    return Promise.resolve();
  };

  // Loading state
  const [isLoadingState, setIsLoading] = useState(isLoading);
  isLoading ||= isLoadingState;
  if (isLoading) disabled ||= isLoading;

  const computedSx = {
    padding: "6px 12px",
    ...style,
    whiteSpace: "nowrap",
    ...sx,
  } as TSx;

  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const confirmationText = typeof confirmation === "function" ? confirmation() : confirmation;
  const confirmationModal = confirmationText && (
    <CustomModal
      isOpen={confirmationModalIsOpen}
      closeDisabled={true}
      primaryDisabled={isLoading}
      primaryBtnText={isLoading ? loadingIndicator || "Loading ..." : "Confirm"}
      modalHeaderText={confirmationText}
      withBlurBackground
      onSecondaryBtnClick={
        isLoading
          ? undefined
          : (event) => {
              event?.stopPropagation();
              setConfirmationModalIsOpen(false);
            }
      }
      onClose={(event) => {
        event?.stopPropagation();
        setConfirmationModalIsOpen(false);
      }}
      onSubmit={(event) => {
        event.stopPropagation();
        void (async () => {
          setIsLoading(true);
          await onClickWrapper(event, true);
          setConfirmationModalIsOpen(false);
          setIsLoading(false);
        })();
      }}
    />
  );

  if (!iconOnly) {
    return (
      <Button
        disabled={disabled}
        variant={variant}
        type={type}
        onClick={(event) => {
          void onClickWrapper(event);
        }}
        color={color}
        tabIndex={0}
        disableElevation
        sx={computedSx}
        startIcon={startIcon}
        {...rest}
      >
        {confirmationModal}
        {label && (
          <Typography
            className="button-label"
            color={disabled ? darkGray : variant === "contained" ? white : black}
            variant="button"
          >
            {isLoading ? loadingIndicator || "loading..." : label}
          </Typography>
        )}
        {children && children}
      </Button>
    );
  } else {
    const button = (
      <IconButton
        sx={sx as TSx}
        disabled={disabled}
        type={type}
        onClick={(event) => {
          void onClickWrapper(event);
        }}
        color={color}
        {...rest}
      >
        {startIcon || rest.endIcon}
      </IconButton>
    );
    return (
      <>
        {confirmationModal}
        {label ? (
          // wrap in span for tooltips on disabled buttons.
          // https://mui.com/material-ui/react-tooltip/#disabled-elements
          <Tooltip
            placement="top"
            title={<Typography sx={{ fontSize: "16px" }} children={label} color={white} />}
          >
            <span>{button}</span>
          </Tooltip>
        ) : (
          button
        )}
      </>
    );
  }
};
