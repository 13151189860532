import { ShiftType, toISO } from "@m7-health/shared-utils";
import { PayloadAction } from "@reduxjs/toolkit";

import { IAttribute, IUnit, IUnitShiftType, StaffCategory, TargetLevel } from "@/api";
import { KeyBy } from "@/common/types";

import { TTimeTargetLevels } from "../components/sections/UnitTargetLevels/types";

import { THouseViewState } from ".";

export type TUnitTargetLevelState = {
  selectedStaffCategoryKey: StaffCategory.EKey;
  toUpdate: Record<TargetLevel.Unit.DTO["id"], TargetLevel.Unit.DTO>;
  toDelete: Record<TargetLevel.Unit.DTO["id"], TargetLevel.Unit.DTO>;
  toCreate: Record<TargetLevel.Unit.DTO["id"], TargetLevel.Unit.DTO>;
  recordsInDb: Record<TargetLevel.Unit.DTO["id"], TargetLevel.Unit.DTO>;
  unitShiftTypesByKey: Record<IUnitShiftType["key"], IUnitShiftType>;
  unitAttributesByKey: Record<IAttribute["key"], IAttribute>;
  timeTargetLevels: TTimeTargetLevels;
};

export const UnitTargetLevelActionsInitState: TUnitTargetLevelState = {
  selectedStaffCategoryKey: StaffCategory.EKey.other,
  toUpdate: {},
  toDelete: {},
  toCreate: {},
  recordsInDb: {},
  unitShiftTypesByKey: {},
  unitAttributesByKey: {},
  timeTargetLevels: {},
};

export const UnitTargetLevelActions = {
  updateUnitTargetLevel: (
    { unitTargetLevels }: THouseViewState,
    action: PayloadAction<TargetLevel.Unit.DTO>,
  ) => {
    if (unitTargetLevels.toCreate[action.payload.id]) {
      unitTargetLevels.toCreate[action.payload.id] = action.payload;
    } else if (unitTargetLevels.toUpdate[action.payload.id]) {
      unitTargetLevels.toUpdate[action.payload.id] = action.payload;
    } else if (unitTargetLevels.toDelete[action.payload.id]) {
      unitTargetLevels.toDelete[action.payload.id] = action.payload;
    } else {
      unitTargetLevels.toUpdate[action.payload.id] = action.payload;
    }
  },
  deleteUnitTargetLevel: (
    { unitTargetLevels }: THouseViewState,
    action: PayloadAction<TargetLevel.Unit.DTO>,
  ) => {
    if (unitTargetLevels.toCreate[action.payload.id]) {
      delete unitTargetLevels.toCreate[action.payload.id];
      return;
    } else if (unitTargetLevels.toUpdate[action.payload.id]) {
      delete unitTargetLevels.toUpdate[action.payload.id];
    }
    unitTargetLevels.toDelete[action.payload.id] = action.payload;
  },
  restoreUnitTargetLevel: (
    { unitTargetLevels }: THouseViewState,
    action: PayloadAction<TargetLevel.Unit.DTO>,
  ) => {
    delete unitTargetLevels.toDelete[action.payload.id];
    delete unitTargetLevels.toUpdate[action.payload.id];
    delete unitTargetLevels.toCreate[action.payload.id];
  },
  createUnitTargetLevel: (
    { unitTargetLevels }: THouseViewState,
    {
      payload,
    }: PayloadAction<{
      unitId: IUnit["id"];
      attributeKey?: IAttribute["key"];
    }>,
  ) => {
    const id = window.crypto.randomUUID();
    unitTargetLevels.toCreate[id] = {
      label: "",
      unitId: payload.unitId,
      shiftTypeKeys: [],
      daysOfWeek: [],
      min: 0,
      staffCategoryKey: unitTargetLevels.selectedStaffCategoryKey,
      id,
      attributeKey: payload.attributeKey || null,
      // Unused fields
      staffTypeKey: null,
      shiftTypeKey: "" as ShiftType.Key,
      createdAt: toISO(new Date()),
      updatedAt: toISO(new Date()),
    };
  },
  duplicateUnitTargetLevel: (
    { unitTargetLevels }: THouseViewState,
    action: PayloadAction<TargetLevel.Unit.DTO>,
  ) => {
    const id = window.crypto.randomUUID();
    unitTargetLevels.toCreate[id] = { ...action.payload, id };
  },

  setSelectedStaffCategoryKey: (
    { unitTargetLevels }: THouseViewState,
    { payload }: PayloadAction<StaffCategory.EKey>,
  ) => {
    unitTargetLevels.selectedStaffCategoryKey = payload;
  },

  setTimeTargetLevels: (
    { unitTargetLevels }: THouseViewState,
    { payload }: PayloadAction<React.SetStateAction<TTimeTargetLevels>>,
  ) => {
    if (typeof payload === "function") {
      unitTargetLevels.timeTargetLevels = payload(unitTargetLevels.timeTargetLevels);
    } else {
      unitTargetLevels.timeTargetLevels = payload;
    }
  },

  setDbUnitTargetLevels: (
    { unitTargetLevels }: THouseViewState,
    action: PayloadAction<KeyBy<TargetLevel.Unit.DTO, "id">>,
  ) => {
    unitTargetLevels.recordsInDb = action.payload;
  },

  setUnitShiftTypesByKey: (
    { unitTargetLevels }: THouseViewState,
    action: PayloadAction<KeyBy<IUnitShiftType, "key">>,
  ) => {
    unitTargetLevels.unitShiftTypesByKey = action.payload;
  },

  setUnitAttributesByKey: (
    { unitTargetLevels }: THouseViewState,
    action: PayloadAction<KeyBy<IAttribute, "key">>,
  ) => {
    unitTargetLevels.unitAttributesByKey = action.payload;
  },

  cancelAllUnitTargetLevelChanges: (
    { unitTargetLevels }: THouseViewState,
    { payload }: PayloadAction<IUnit>,
  ) => {
    unitTargetLevels.toCreate = {};
    unitTargetLevels.toDelete = {};
    unitTargetLevels.toUpdate = {};
    unitTargetLevels.timeTargetLevels = payload.configuration?.data?.timeTargetLevels ?? {};
  },
};
