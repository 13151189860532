import { useCallback, useEffect, useMemo } from "react";

import { ShiftType } from "@m7-health/shared-utils";
import { entries, filter, isEqual, map, uniq, uniqBy, values } from "lodash";

import { useAppConfigQuery } from "#/features/User/queries";
import { StaffCategory } from "@/api";
import { useCurrentFacilityId } from "@/common/hooks";
import { useAppDispatch, useAppSelector } from "@/common/hooks/useRedux";

import { ESideBarState } from "../OpenShiftSidebarContent";
import { openShiftsSidebarActions } from "../store";
import { TOpenShiftsSidebarFilters } from "../types";

export const useSidebarFilters = ({ sidebarState }: { sidebarState: ESideBarState }) => {
  const dispatch = useAppDispatch();
  const appConfig = useAppConfigQuery();
  const currentFacilityId = useCurrentFacilityId();

  const {
    sidebarFilters,
    shiftTypes,
    targetsLevels,
    scheduleGridShiftTypes,
    scheduleGridStaffCategory,
    houseViewUnitIds,
    houseViewStaffCategories,
    staffDetails,
  } = useAppSelector(
    (state) => ({
      sidebarFilters: state.openShiftsSidebar.filters,
      //All shift types across all schedules
      shiftTypes: uniqBy(
        values(state.openShiftsSidebar.data.shiftTypes).flatMap((shiftTypeByKey) =>
          values(shiftTypeByKey),
        ),
        "key",
      ),
      targetsLevels: state.openShiftsSidebar.data.targetLevels,
      scheduleGridShiftTypes: state.schedulerGrid.grid.filters.shiftType,
      scheduleGridStaffCategory: state.schedulerGrid.grid.selectedStaffCategory,
      houseViewUnitIds: state.houseView.pageFilters.unitIds,
      houseViewStaffCategories: state.houseView.pageFilters.selectedStaffCategories,
      staffDetails: values(state.openShiftsSidebar.data.staffDetails),
    }),
    isEqual,
  );

  //Use effect to match the sidebar filter state to the schedule grid or house view filter state
  //on initial load or when the pages filter state changes
  useEffect(() => {
    const isScheduleGrid = sidebarState === ESideBarState.scheduleGrid;
    const staffCategory = isScheduleGrid
      ? scheduleGridStaffCategory
        ? [scheduleGridStaffCategory]
        : []
      : houseViewStaffCategories;
    dispatch(
      openShiftsSidebarActions.setSidebarFilters({
        shiftTypes: isScheduleGrid ? scheduleGridShiftTypes : [],
        staffCategories: staffCategory,
        unitIds: isScheduleGrid ? [] : houseViewUnitIds,
      }),
    );
  }, [
    scheduleGridShiftTypes,
    scheduleGridStaffCategory,
    houseViewUnitIds,
    houseViewStaffCategories,
    sidebarState,
    dispatch,
  ]);

  const staffCategorySelectOptions = useMemo(() => {
    const availableStaffCategories = uniq(
      staffDetails.map((details) => details.staffType.staffCategory.key),
    );
    return entries(StaffCategory.EName)
      .filter(([key]) => availableStaffCategories.includes(key as StaffCategory.EKey))
      .map(([key, name]) => ({
        label: name,
        value: key,
      }));
  }, [staffDetails]);

  const shiftTypesWithTargets = useMemo(() => {
    const uniqShiftTypeTargets = uniq(
      values(targetsLevels).reduce((acc, scheduleTargetLevels) => {
        return [...acc, ...map(scheduleTargetLevels.orderedTargets, "shiftTypeKey")];
      }, [] as ShiftType.Key[]),
    );

    return shiftTypes.filter((shiftType) => uniqShiftTypeTargets.includes(shiftType.key));
  }, [shiftTypes, targetsLevels]);
  const accessibleUnits = appConfig.data?.accessibleUnits;
  const availableUnits = useMemo(
    () =>
      filter(accessibleUnits, (unit) => unit.facilityId === currentFacilityId && !unit.archivedAt),
    [accessibleUnits, currentFacilityId],
  );

  const toggleFiltersCollapse = useCallback(
    (event: React.SyntheticEvent, forceOpenOrClose?: boolean) => {
      if (event.defaultPrevented) return;
      event.stopPropagation();
      dispatch(
        openShiftsSidebarActions.setSidebarFilters({
          modalIsOpen: forceOpenOrClose ?? !sidebarFilters.modalIsOpen,
        }),
      );
    },
    [dispatch, sidebarFilters.modalIsOpen],
  );

  const setSidebarFilters = useCallback(
    (filters: Partial<TOpenShiftsSidebarFilters>) => {
      dispatch(openShiftsSidebarActions.setSidebarFilters(filters));
    },
    [dispatch],
  );

  return useMemo(
    () => ({
      sidebarFilters,
      shiftTypes,
      targetsLevels,
      scheduleGridShiftTypes,
      scheduleGridStaffCategory,
      shiftTypesWithTargets,
      availableUnits,
      toggleFiltersCollapse,
      setSidebarFilters,
      staffCategorySelectOptions,
    }),
    [
      sidebarFilters,
      shiftTypes,
      targetsLevels,
      scheduleGridShiftTypes,
      scheduleGridStaffCategory,
      shiftTypesWithTargets,
      availableUnits,
      toggleFiltersCollapse,
      setSidebarFilters,
      staffCategorySelectOptions,
    ],
  );
};
