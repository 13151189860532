import { EmailOutlined } from "@mui/icons-material";
import { Badge, Tooltip } from "@mui/material";

import { darkPurple, white } from "@/common/theming";

export const OpenShiftRequestCountIcon = ({ count }: { count?: number }) => {
  if (!count) return null;

  return (
    <Tooltip title={`${count} Open Shift Request(s)`} arrow placement="top">
      <Badge className="request-count-badge" badgeContent={count} sx={style}>
        <EmailOutlined />
      </Badge>
    </Tooltip>
  );
};

const style = {
  ".MuiBadge-badge": {
    height: "16px",
    width: "16px",
    minWidth: "16px",
    backgroundColor: darkPurple,
    color: white,
  },
};
