import { useMemo, useState } from "react";

import { EUnitPermissionAreas } from "@m7-health/shared-utils";
import { keyBy, pick, sortBy, values } from "lodash";

import { Box, Typography } from "@mui/material";

import { IShiftType, StaffShift, useShiftTypesForSchedule } from "~/api";
import { PartialShiftTimePicker } from "~/common/components/PartialShiftTimePicker/PartialShiftTimePicker";
import ShiftTypeFilterDropdown from "~/common/components/ShiftTypeFilterDropdown";
import { NOT_EXISTING_UUID } from "~/common/constants";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { seconds, TimeString } from "~/common/types";
import { useSelectedSchedule } from "~/features/HouseView/hooks/useSelectedSchedule";
import { houseViewStore } from "~/features/HouseView/store";

import {
  useCheckUserPermission,
  useCurrentRole,
  useCurrentTimezone,
  useDeepMemo,
} from "@/common/hooks";
import { voidingShiftStatus } from "@/common/utils/shifts";

import { HouseViewSideBar, STAFF_ITEMS_LAYOUTS } from "..";
import { StaffItem } from "../..";
import { splitOrEditShift } from "../helpers/splitOrEditShift";
import { THouseViewSideBar } from "../types";

export const FloatToUnitV2 = ({
  selectedUnit,
  shifts,
  staffDetails,
  notes,
  notesFromStaff,
  staffDatesMetadata,
}: THouseViewSideBar) => {
  const [partialShiftAttributes, setPartialShiftAttributes] = useState<{
    customStartTime: TimeString | null;
    customDuration: seconds | null;
  }>({
    customStartTime: null,
    customDuration: null,
  });
  const [partialShiftIsValid, setPartialShiftIsValid] = useState(true);

  const dispatch = useAppDispatch();
  const { userIsKiosk } = useCurrentRole();
  const canManage = useCheckUserPermission("manage", EUnitPermissionAreas.houseView);
  const timezone = useCurrentTimezone(selectedUnit.id);
  const {
    floating: { staffShift },
    pageFilters: { selectedStaffCategories },
  } = useAppSelector((state) => state.houseView);
  const selectedSchedule = useSelectedSchedule();
  const [selectedShiftType, setSelectedShiftType] = useState<IShiftType["key"] | undefined>(
    staffShift?.shiftTypeKey,
  );
  const selectedStaffIdToFloat = staffShift?.staffId;
  const selectedShiftIdToFloat = staffShift?.id;
  const shiftTypesBySchedule = useAppSelector((state) => state.houseView.pageData.shiftTypes);
  const shiftTypes = useDeepMemo(
    () => values(shiftTypesBySchedule[selectedSchedule?.id || NOT_EXISTING_UUID] || []),
    [shiftTypesBySchedule, selectedSchedule?.id],
  );

  let eligibleShiftsToFloat = useMemo(() => {
    const attributesByKey = keyBy(selectedUnit.attributes, "key");

    return Object.values(shifts || {})
      .flat()
      .filter(({ staffId, status, scheduleId, shiftTypeKey }) => {
        if (voidingShiftStatus(status)) return false;

        const currentStaffDetails = staffDetails?.[staffId];
        if (!currentStaffDetails) return false;

        const categoryEligible =
          !selectedStaffCategories.length ||
          selectedStaffCategories.includes(currentStaffDetails.staffType.staffCategoryKey);
        const positionEligible =
          !selectedStaffCategories.length ||
          !!currentStaffDetails.attributeKeys?.find((key) =>
            selectedStaffCategories.includes(attributesByKey[key]?.name.toLowerCase() || ""),
          );
        if (!categoryEligible && !positionEligible) return false;

        if (!currentStaffDetails.user.rosters?.some(({ unitId }) => unitId === selectedUnit.id))
          return false;

        if (scheduleId === selectedSchedule?.id) return false;

        const shiftType = shiftTypesBySchedule?.[scheduleId]?.[shiftTypeKey];

        // Only allow working shifts to be floated
        if (!shiftType?.isWorkingShift) return false;

        return true;
      });
  }, [
    shifts,
    staffDetails,
    selectedUnit,
    selectedSchedule,
    selectedStaffCategories,
    shiftTypesBySchedule,
  ]);

  const shiftToFloat = eligibleShiftsToFloat.find((shift) => shift.id === selectedShiftIdToFloat);
  const originalShiftTypes = useShiftTypesForSchedule(
    shiftToFloat?.scheduleId || NOT_EXISTING_UUID,
  );

  eligibleShiftsToFloat = sortBy(
    eligibleShiftsToFloat,
    (shift) => new Date(staffDatesMetadata?.[shift.staffId]?.lastFloatedAt || 0),
  );

  if (!eligibleShiftsToFloat || !staffDetails || !notes || !notesFromStaff) return null;

  const startEditWithShift = () => {
    if (!selectedShiftType || !selectedStaffIdToFloat || !selectedSchedule || !shiftToFloat) return;
    const newShiftType = shiftTypes.find(({ key }) => key === selectedShiftType);
    if (!newShiftType) return;
    const originalShiftType = originalShiftTypes.find(
      ({ key }) => key === shiftToFloat?.shiftTypeKey,
    );
    if (!originalShiftType) return;

    const positionAsCategory = selectedUnit.attributes?.find((attribute) =>
      selectedStaffCategories.includes(attribute.name.toLowerCase()),
    );

    const workingAwayFromHomeUnit =
      staffDetails[selectedStaffIdToFloat]?.homeUnitId !== selectedUnit.id;

    dispatch(
      houseViewStore.state.startEditShifts({
        staffId: selectedStaffIdToFloat,
        autoSave:
          partialShiftAttributes.customDuration === shiftToFloat.customDuration &&
          partialShiftAttributes.customStartTime === shiftToFloat.customStartTime,
        shifts: splitOrEditShift({
          shiftToSplitAndFloat: shiftToFloat,
          selectedSchedule,
          originalShiftType,
          newShiftType,
          partialShiftAttributes,
          timezone,
          targetedShiftParams: {
            ...(positionAsCategory ? { attributes: [positionAsCategory.key] } : {}),
            isWorkingAway: workingAwayFromHomeUnit,
            status: workingAwayFromHomeUnit ? StaffShift.EStatus.floated : null,
          },
        }),
      }),
    );
  };

  return (
    <>
      <Box sx={{ overflowY: "auto" }} className="eligible-shifts-container">
        {eligibleShiftsToFloat.length > 0 ? (
          eligibleShiftsToFloat.map((shift) => (
            <StaffItem
              sx={{ padding: "5px", margin: "1px" }}
              shift={shift}
              note={notes[shift.staffId]}
              noteFromStaff={notesFromStaff[shift.staffId]}
              unitId={selectedUnit.id}
              onClick={
                userIsKiosk || !canManage
                  ? undefined
                  : () => {
                      dispatch(houseViewStore.state.startFloating());
                      dispatch(
                        houseViewStore.state.setFloatShift(
                          pick(shift, ["staffId", "customStartTime", "customDuration", "id"]),
                        ),
                      );
                    }
              }
              selectable={!userIsKiosk && canManage}
              selected={shift.id === selectedShiftIdToFloat}
              layout={STAFF_ITEMS_LAYOUTS.floating}
              lastStatusType={StaffShift.EStatus.floated}
            />
          ))
        ) : (
          <Typography className="no-float-eligible">No eligible shifts to float</Typography>
        )}
      </Box>
      <Box flexGrow={1} />
      <HouseViewSideBar.Helpers.BottomActions
        title="Shift Details"
        actionButton={
          selectedStaffIdToFloat
            ? {
                label: `Float Staff to ${selectedUnit.name}`,
                trackingLabel: "Float Staff to Unit",
                disabled: !selectedShiftType || !partialShiftIsValid,
                action: startEditWithShift,
              }
            : undefined
        }
        cancelButton={{
          label: "Cancel",
          disabled: false,
          action: () => {
            setPartialShiftAttributes({
              customDuration: null,
              customStartTime: null,
            });
            dispatch(houseViewStore.state.endAction());
          },
        }}
        children={
          selectedStaffIdToFloat ? (
            <>
              <ShiftTypeFilterDropdown
                shiftTypes={shiftTypes.filter((shiftType) => shiftType.isWorkingShift)}
                selectedOption={shiftTypes.find((shiftType) => shiftType.key === selectedShiftType)}
                selectOption={(shiftType) => {
                  setSelectedShiftType(shiftType?.key as IShiftType["key"]);
                  setPartialShiftAttributes({
                    customDuration: null,
                    customStartTime: null,
                  });
                }}
                isMultiSelect={false}
              />
              {selectedShiftType && (
                <PartialShiftTimePicker
                  alwaysOn={true}
                  key={selectedShiftType}
                  shiftType={
                    (selectedShiftType &&
                      shiftTypes.find(({ key }) => key === selectedShiftType)) ||
                    null
                  }
                  staffShift={partialShiftAttributes}
                  onChange={(shiftAttributes, isValid) => {
                    if (isValid) {
                      setPartialShiftAttributes({
                        customDuration: null,
                        customStartTime: null,
                        ...shiftAttributes,
                      });
                      setPartialShiftIsValid(true);
                    } else {
                      setPartialShiftIsValid(false);
                    }
                  }}
                />
              )}
            </>
          ) : null
        }
      />
    </>
  );
};
