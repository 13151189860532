import { useMemo } from "react";

import { getTzDayjs, ShiftType, Timezone, toISO, YyyyMmDd } from "@m7-health/shared-utils";

import { houseViewStore } from "#/features/HouseView/store";
import { ISchedule, IStaffShift, IUnit, IUser, useListStaffShiftsQuery } from "@/api";
import { useAppDispatch } from "@/common/hooks";

export type TGridActions = {
  handleDragStart: (e: React.DragEvent<HTMLTableCellElement>) => void;
  handleDragOver: (e: React.DragEvent<HTMLTableCellElement>) => void;
  handleDrop: (e: React.DragEvent<HTMLTableCellElement>) => void;
  onCellClick: (e: React.MouseEvent<HTMLTableCellElement>) => void;
};

export type TGridCellData = {
  date: YyyyMmDd;
  unitId: IUnit["id"];
  staffId: IUser["id"];
  shiftKey?: ShiftType.Key;
  staffShiftId?: IStaffShift["id"];
  scheduleId?: ISchedule["id"];
  timezone: Timezone;
};

export const useGridActions = (): TGridActions => {
  const dispatch = useAppDispatch();

  return useMemo(() => {
    const onCellClick = (event: React.MouseEvent<HTMLTableCellElement>) => {
      const { date, unitId, staffId } = event.currentTarget.dataset as TGridCellData;
      dispatch(houseViewStore.state.selectUnit(unitId));
      dispatch(houseViewStore.state.selectDateForData(date));
      dispatch(houseViewStore.state.startEditShifts({ staffId }));
    };

    const handleDragStart = (event: React.DragEvent<HTMLTableCellElement>) => {
      const element = event.target as HTMLTableCellElement;
      const dataCarrier = element.matches("td") ? element : element.closest("td");
      if (!dataCarrier) return;

      const shiftData = dataCarrier?.dataset as TGridCellData;
      event.dataTransfer.setData("text/plain", JSON.stringify(shiftData));
    };

    const handleDragOver = (event: React.DragEvent) => event.preventDefault();

    const handleDrop = (event: React.DragEvent<HTMLTableCellElement>) => {
      const element = event.target as HTMLTableCellElement;
      const dataCarrier = element.matches("td") ? element : element.closest("td");
      if (!dataCarrier) return;

      const targetShiftData = dataCarrier.dataset as TGridCellData | undefined;
      const fromShiftData = JSON.parse(event.dataTransfer.getData("text/plain")) as TGridCellData;

      // Force moving shifts within the same staff
      if (
        !targetShiftData ||
        !fromShiftData ||
        targetShiftData.staffId !== fromShiftData.staffId ||
        !fromShiftData.scheduleId ||
        !fromShiftData.staffShiftId || // No "from" shift
        targetShiftData.staffShiftId // Already existing "to" shift
      ) {
        return;
      }

      // Find shift and start update
      void useListStaffShiftsQuery
        .apiFunction({
          scheduleIds: [fromShiftData.scheduleId],
          staffIds: [fromShiftData.staffId],
        })
        .then((staffShifts) => {
          const shift = staffShifts.find(({ id }) => fromShiftData.staffShiftId === id);
          if (!shift) return;

          // Propagate change
          dispatch(
            houseViewStore.state.startEditShifts({
              staffId: targetShiftData.staffId,
              fromDate: fromShiftData.date,
              toDate: targetShiftData.date,
              shifts: [
                {
                  ...shift,
                  date: toISO(getTzDayjs(targetShiftData.date, targetShiftData.timezone)),
                },
              ],
            }),
          );
          dispatch(houseViewStore.state.selectUnit(targetShiftData.unitId));
          dispatch(houseViewStore.state.selectDateForData(targetShiftData.date));
        });
    };

    return {
      handleDragStart,
      handleDragOver,
      handleDrop,
      onCellClick,
    };
  }, [dispatch]);
};
