import { useState } from "react";

import { Close, Search } from "@mui/icons-material";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import { Fab, InputAdornment, Stack, TextField } from "@mui/material";
import Drawer from "@mui/material/Drawer";

import { CustomButton } from "@/common/components";
import { useExternalUserManagement } from "@/common/hooks";

import "./sidebar.scss";
import { WorkdayContent } from "./WorkdayContent";

export const StaffExternalUserManagementSidebar = () => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const externalUsers = useExternalUserManagement();

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
    setSearch("");
  };

  if (!externalUsers) {
    return null;
  }

  return (
    <div>
      <CustomButton
        trackingLabel={"roster-updates"}
        iconOnly={true}
        startIcon={
          <Fab color="primary" aria-label="Roster Updates icon" sx={{ width: 40, height: 40 }}>
            <PeopleOutlineIcon />
          </Fab>
        }
        variant={"contained"}
        aria-label="Roster Updates"
        onClick={toggleDrawer(!open)}
        sx={{ px: 0 }}
      />

      <Drawer
        variant={"temporary"}
        open={open}
        onClose={toggleDrawer(false)}
        sx={{ zIndex: 10000 }}
        PaperProps={{
          sx: { paddingTop: "60px", width: 600, height: "calc(100% - 70px)", position: "relative" },
        }}
      >
        <Stack className={"sidebar-toolbar"} direction="row">
          <PeopleOutlineIcon />

          <TextField
            variant="standard"
            placeholder={"Filter"}
            onChange={(event) => setSearch(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />

          <CustomButton
            trackingLabel={null}
            startIcon={<Close />}
            className="close-button"
            variant="text"
            onClick={toggleDrawer(false)}
            iconOnly
          />
        </Stack>

        {open && <WorkdayContent search={search.toLowerCase()} />}
      </Drawer>
    </div>
  );
};
