import { YyyyMmDd, THouseViewTimeRange, TimeString } from "@m7-health/shared-utils";
import { PayloadAction } from "@reduxjs/toolkit";
import { uniq } from "lodash";

import { IFacility, StaffCategory } from "~/api";
import { IUnitBasic } from "~/routes/api/types";

import { THouseViewState } from ".";

/** @deprecated but still to be used for Time Range Modal */
// TODO: Remove this completely
export enum EHVTimeRange {
  "all" = "all",
  "day7A7P" = "day7A7P",
  "night7P7A" = "night7P7A",
}

export const DEFAULT_CUSTOM_TIME_RANGE: THouseViewTimeRange = {
  startTime: "07:00:00" as TimeString,
  endTime: "19:00:00" as TimeString,
  customAbbreviation: "All",
};

export enum EViewLengthOptions {
  day = "day",
  week = "oneWeek",
  sixWeek = "sixWeek",
}

export type TGridFilters = {
  showShiftTargetLevels: boolean;
  showTimeTargetLevels: boolean;
  showAggregatedShiftTargetLevels: boolean;
  showAssignedAttributeTargetLevels: boolean;
  showEligibleAttributeTargetLevels: boolean;
};

export type PageFilters = {
  // selectedDateForData is the date for which data is being displayed
  // this is different from selectedDate which is the date selected in the date picker
  // in regular day view, will always be the same
  // in multi-week view, will be different
  selectedDateForData: YyyyMmDd | null;
  selectedUnitId: IUnitBasic["id"] | null;
  selectedFacilityId: IFacility["id"] | null;
  selectedStaffCategories: (StaffCategory.EKey | string)[];
  customTimeRange: THouseViewTimeRange | null;
  showTargetLevels: boolean;
  selectedViewLength: EViewLengthOptions;
  isSidebarExpanded: boolean;
  // Which units to filter by globally on the page
  // When all units are shown, this should be an empty array
  unitIds: IUnitBasic["id"][];
  gridFilters: TGridFilters;
};

export const PageFiltersInitialValues: PageFilters = {
  selectedDateForData: null,
  selectedFacilityId: null,
  selectedUnitId: null,
  selectedStaffCategories: [],
  showTargetLevels: true,
  isSidebarExpanded: true,
  unitIds: [],
  customTimeRange: null,
  selectedViewLength: EViewLengthOptions.day,
  gridFilters: {
    showShiftTargetLevels: true,
    showTimeTargetLevels: false,
    showAggregatedShiftTargetLevels: true,
    showAssignedAttributeTargetLevels: false,
    showEligibleAttributeTargetLevels: false,
  },
};

export const PageFiltersActions = {
  // only update selectedDateForData (DO NOT UPDATE selectedDate)
  selectDateForData(
    { pageFilters }: THouseViewState,
    action: PayloadAction<PageFilters["selectedDateForData"]>,
  ) {
    pageFilters.selectedDateForData = action.payload;
  },
  setUnitsFilter({ pageFilters }: THouseViewState, action: PayloadAction<IUnitBasic["id"][]>) {
    pageFilters.unitIds = action.payload;
  },
  selectViewLength(
    { pageFilters }: THouseViewState,
    action: PayloadAction<PageFilters["selectedViewLength"]>,
  ) {
    pageFilters.selectedViewLength = action.payload;
  },
  selectFacility(
    { pageFilters }: THouseViewState,
    action: PayloadAction<PageFilters["selectedFacilityId"]>,
  ) {
    pageFilters.selectedFacilityId = action.payload;
  },
  selectUnit(
    { pageFilters }: THouseViewState,
    action: PayloadAction<PageFilters["selectedUnitId"]>,
  ) {
    pageFilters.selectedUnitId = action.payload;
  },
  selectStaffCategories: (
    { pageFilters }: THouseViewState,
    action: PayloadAction<StaffCategory.EKey[]>,
  ) => {
    pageFilters.selectedStaffCategories = action.payload;
  },
  appendStaffCategories: (
    { pageFilters }: THouseViewState,
    action: PayloadAction<StaffCategory.EKey[]>,
  ) => {
    pageFilters.selectedStaffCategories = uniq([
      ...pageFilters.selectedStaffCategories,
      ...action.payload,
    ]);
  },
  selectCustomTimeRange: (
    { pageFilters }: THouseViewState,
    action: PayloadAction<THouseViewTimeRange>,
  ) => {
    pageFilters.customTimeRange = action.payload;
  },
  selectShowTargetLevels: ({ pageFilters }: THouseViewState, action: PayloadAction<boolean>) => {
    pageFilters.showTargetLevels = action.payload;
  },
  setGridFilters: (
    { pageFilters }: THouseViewState,
    action: PayloadAction<Partial<TGridFilters>>,
  ) => {
    pageFilters.gridFilters = {
      ...pageFilters.gridFilters,
      ...action.payload,
    };
  },
  setIsSidebarExpanded: (
    { pageFilters }: THouseViewState,
    action: PayloadAction<PageFilters["isSidebarExpanded"]>,
  ) => {
    pageFilters.isSidebarExpanded = action.payload;
  },
};
