import { useEffect, useMemo } from "react";

import { YyyyMmDd } from "@m7-health/shared-utils";
import { entries, groupBy, map, max, min } from "lodash";

import { ISchedulerGridState } from "#/features/SchedulerGrid/store/initialState";
import {
  IRoster,
  ISchedule,
  IUser,
  ShiftType,
  StaffDetails,
  useListOpenShiftsQuery,
  useListRosterQuery,
  User,
} from "@/api";
import { openShiftsSidebarActions } from "@/common/components/OpenShiftSidebarContent/store";
import {
  useAppDispatch,
  useAppFlags,
  useDeepMemo,
  useM7SimpleContext,
  useMapBy,
} from "@/common/hooks";
import { TDateFilter } from "@/common/types";

export const useSetOpenShiftDataForHouseView = ({
  schedules,
  shiftTypesByScheduleIdByKey,
  staffDetailsByStaffId,
}: {
  schedules: ISchedule[];
  shiftTypesByScheduleIdByKey: Record<string, Record<string, ShiftType.Schedule.DTO>>;
  staffDetailsByStaffId: Record<string, StaffDetails.DTO>;
}) => {
  const dispatch = useAppDispatch();
  const { openShiftV2 } = useAppFlags();

  const { currentRole } = useM7SimpleContext();
  const kioskUser = currentRole === User.ERole.kiosk;

  const unitIds = useMapBy(schedules, "unitId", { uniq: true });

  const dateFilter = useDeepMemo(() => {
    const fromDate = min(map(schedules, "startDay"));
    const toDate = max(map(schedules, "endDay"));
    if (!fromDate || !toDate) return undefined;

    return [
      { value: fromDate, operator: "gte" },
      { value: toDate, operator: "lte" },
    ] as TDateFilter[];
  }, [schedules]);

  // Fetch remote data
  const { data: openShifts } = useListOpenShiftsQuery(
    {
      unitIds: unitIds,
      date: dateFilter,
      shiftCount: { min: 0 },
      includePast: true,
      includeOpenShiftsRequests: true,
    },
    { skip: !unitIds.length || !dateFilter || kioskUser || !openShiftV2 },
  );

  const { data: rosters } = useListRosterQuery(
    { unitIds: unitIds },
    { skip: !unitIds.length || kioskUser || !openShiftV2 },
  );

  const staffIdByRosterId = useMemo(() => {
    if (!rosters?.length) return null;

    return rosters.reduce(
      (acc, roster) => {
        acc[roster.id] = roster.userId;
        return acc;
      },
      {} as Record<IRoster["id"], IUser["id"]>,
    );
  }, [rosters]);

  // Set data in the store
  const organizedOpenShiftData = useMemo(() => {
    if (!openShifts || !staffIdByRosterId) return null;

    const openShiftsByDates = groupBy(openShifts, "date");
    const sortedOpenShifts: ISchedulerGridState["grid"]["data"]["openShiftData"]["byDate"] = {};

    entries(openShiftsByDates).forEach(([date, dateOpenShifts]) => {
      const dateData: ISchedulerGridState["grid"]["data"]["openShiftData"]["byDate"][YyyyMmDd] =
        (sortedOpenShifts[date] = {
          allOpenShifts: dateOpenShifts,
          byScheduleIdCategoryAndShiftType: {},
          byStaff: {},
        });

      dateOpenShifts.forEach((openShift) => {
        const requests = openShift.openShiftRequests || [];

        // Push open shifts for the per day data
        const scheduleData = (dateData.byScheduleIdCategoryAndShiftType[openShift.scheduleId] ||=
          {});
        const categoryData = (scheduleData[openShift.staffCategoryKey] ||= {});
        const shiftTypeData = (categoryData[openShift.shiftType] ||= {
          openShifts: [],
          openShiftRequests: [],
        });
        shiftTypeData.openShifts.push(openShift);
        shiftTypeData.openShiftRequests.push(...requests);

        // Push for the staff data
        requests.forEach((request) => {
          const staffId = staffIdByRosterId[request.requesterId as unknown as IRoster["id"]];
          if (!staffId) return;

          const staffData = (dateData.byStaff[staffId] ||= {
            openShiftRequests: [],
          });
          staffData.openShiftRequests.push(request);
        });
      });
    });

    return sortedOpenShifts;
  }, [openShifts, staffIdByRosterId]);

  useEffect(() => {
    if (
      !organizedOpenShiftData ||
      !shiftTypesByScheduleIdByKey ||
      !schedules.length ||
      !staffDetailsByStaffId
    )
      return;

    // Set data in the store
    dispatch(
      openShiftsSidebarActions.setOpenShiftsSidebarData({
        openShiftsByDate: organizedOpenShiftData,
        shiftTypes: shiftTypesByScheduleIdByKey,
        schedules,
        staffDetails: staffDetailsByStaffId,
      }),
    );
  }, [
    organizedOpenShiftData,
    shiftTypesByScheduleIdByKey,
    schedules,
    staffDetailsByStaffId,
    dispatch,
  ]);
};
