import { groupBy, keyBy, mapValues } from "lodash";

import { IScheduleShiftType, IUnitShiftType } from "./types";

/**
 * ScheduleShiftType helpers
 */
const byScheduleId = (schedulesShiftTypes: IScheduleShiftType[]) =>
  groupBy(schedulesShiftTypes, "scheduleId");

const byScheduleIdByShiftTypeId = (schedulesShiftTypes: IScheduleShiftType[]) =>
  mapValues(byScheduleId(schedulesShiftTypes), (shiftTypes) => keyBy(shiftTypes, "shiftTypeId"));

const byScheduleIdByShiftTypeKey = (schedulesShiftTypes: IScheduleShiftType[]) =>
  mapValues(byScheduleId(schedulesShiftTypes), (shiftTypes) => keyBy(shiftTypes, "key"));
export type TShiftTypeByScheduleIdByShiftTypeKey = ReturnType<typeof byScheduleIdByShiftTypeKey>;

/**
 * UnitShiftType helpers
 */
const byUnitId = (schedulesShiftTypes: IUnitShiftType[]) => groupBy(schedulesShiftTypes, "unitId");

const byUnitIdByShiftTypeId = (schedulesShiftTypes: IUnitShiftType[]) =>
  mapValues(byUnitId(schedulesShiftTypes), (shiftTypes) => keyBy(shiftTypes, "shiftTypeId"));

const byUnitIdByShiftTypeKey = (schedulesShiftTypes: IUnitShiftType[]) =>
  mapValues(byUnitId(schedulesShiftTypes), (shiftTypes) => keyBy(shiftTypes, "key"));

export const ShiftTypeHelpers = {
  byScheduleId,
  byScheduleIdByShiftTypeId,
  byScheduleIdByShiftTypeKey,
  byUnitId,
  byUnitIdByShiftTypeId,
  byUnitIdByShiftTypeKey,
};
