import { boolean, date, number, string } from "yup";

import {
  dateMsg,
  emailMsg,
  getMinNumber,
  integerMsg,
  numberMsg,
  requiredMsg,
  phoneNumberMsg,
} from "./messages";

// Regular expression for validating North American phone numbers
// Accepts formats like:
// +1 (123) 456-7890
// (123) 456-7890
// 123-456-7890
// 1234567890
// Optional +1 country code
// Area code can be in parentheses or not
// Allows spaces, dots or hyphens as separators
const phoneNumberReg = /^(?:\+?1[-.\s]?)?(?:\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/;

export const emailShape = string().email(emailMsg);
export const phoneNumberShape = string().matches(phoneNumberReg, phoneNumberMsg).nullable();
export const integerShape = number()
  .typeError(numberMsg)
  .min(0, getMinNumber(0))
  .integer(integerMsg);
export const dateShape = date().typeError(dateMsg);

export const emailShapeRequired = emailShape.required(requiredMsg);
export const stringShapeRequired = string().required(requiredMsg);
export const booleanShape = boolean();
export const booleanShapeRequired = boolean().required(requiredMsg);
export const dateShapeRequired = dateShape.required();
export const integerShapeRequired = integerShape.required(requiredMsg);
export const phoneNumberShapeRequired = phoneNumberShape;
