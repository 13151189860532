import { Box } from "@mui/material";

import { EventUserCreated } from "~/api/eventLog/events/users/created";

import { IUnitConfig } from "#/features/User/types";
import { EventLog } from "@/api";

export const UserCreated = ({
  log,
  unit,
}: {
  log: EventLog.DTO<EventUserCreated.Payloads>;
  unit?: IUnitConfig;
}) => {
  const { user } = log.eventPayload;
  const userName = (
    <span data-testid={"userName"}>
      {user.firstName} {user.lastName}
    </span>
  );

  return (
    <Box data-testid={"user-created"}>
      <b>{userName}</b> was created on <span data-testid={"unit"}>{unit?.name ?? log.unitId}</span>.
    </Box>
  );
};
