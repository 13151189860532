import { Box, Typography } from "@mui/material";

const videoUrl =
  "https://www.loom.com/embed/cc5bde27210848c58126be37aa959428?sid=39257355-589e-4458-a900-d68af8e28b78";

export const WatchTrainingVideo = ({ className }: { className: string }) => {
  return (
    <Box className={className}>
      <Typography className="title">Watch this video for a brief training</Typography>
      <Box
        sx={{
          mt: 4,
          mb: 2,
          width: 320,
          height: 180,
        }}
      >
        <div
          style={{ position: "relative", paddingBottom: "56.25%", height: 0, overflow: "hidden" }}
        >
          <iframe
            title="M7 Training Video"
            frameBorder="0"
            width="100%"
            height="100%"
            src={videoUrl}
            allowFullScreen
            style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          />
        </div>
      </Box>
    </Box>
  );
};
