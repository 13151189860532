import { formatPhoneNumber } from "@/common/utils/phoneNumber";

import { StringEditor, StringRenderer } from "./String";
import { TCellComponent } from "./types";

const validator = (value: string) => !value || !!formatPhoneNumber(value, true);
const formatValue = (value: string) => formatPhoneNumber(value, true) || value;

const PhoneNumberEditor: TCellComponent<string>["editor"] = (params) => {
  return (
    <StringEditor
      {...params}
      errorMessage="Invalid phone number"
      validateValue={validator}
      formatValue={formatValue}
    />
  );
};

export const PhoneNumberCell: TCellComponent<string> = {
  readonly: StringRenderer,
  editor: PhoneNumberEditor,
};
