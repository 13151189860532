import { CreateStaffUserDTO } from "@/api";

// All versions of event payloads should be defined here
type TPayloadV1 = {
  user: Pick<
    CreateStaffUserDTO,
    | "email"
    | "firstName"
    | "lastName"
    | "phoneNumber"
    | "homeUnitId"
    | "employmentType"
    | "staffTypeKey"
    | "contractEndDate"
    | "isSoftDeleted"
    | "softDeletedDate"
  >;
};

export namespace EventUserCreated {
  export enum EVersions {
    "v1" = "1",
  }

  export type Payloads = TPayloadV1;
  export type PayloadByVersion = {
    [EVersions.v1]: TPayloadV1;
  };
}
