import { YyyyMmDd } from "@m7-health/shared-utils";
import { PayloadAction } from "@reduxjs/toolkit";

import { IRealTimeStaffingTarget } from "~/api/realTimeStaffingTargets";

import {
  IAttribute,
  INote,
  ISchedule,
  IScheduleShiftType,
  IShiftType,
  IStaffCategory,
  IStaffDetails,
  IStaffShift,
  IStaffType,
  ITargetLevel,
  IUnitTargetLevel,
  IWorkingHours,
  StaffDetails,
} from "@/api";
import { KeyBy, TTargetLevelKey } from "@/common/types";

import { THouseViewState } from ".";

type TData = {
  staffShifts: IStaffShift[];
  shiftTypes: Record<IScheduleShiftType["scheduleId"], KeyBy<IScheduleShiftType, "key">>;
  schedules: ISchedule[];
  notes: INote[];
  staffDetails: KeyBy<IStaffDetails, "userId">;
  staffWorkingHoursByStaffId: KeyBy<IWorkingHours, "staffId">;
  metadataByStaffId: KeyBy<StaffDetails.ShiftMetadataDTO, "staffId">;
  staffingTargets: IRealTimeStaffingTarget[];
  targetLevels: {
    [scheduleId: string]: {
      unitTargetLevel: IUnitTargetLevel[];
      scheduleTargetLevels: ITargetLevel[];
      orderedKeys: TTargetLevelKey[];
      orderedTargets: {
        [key in TTargetLevelKey]: {
          shiftTypeKey: IShiftType["key"];
          staffCategoryKey: IStaffCategory["key"] | null;
          staffTypeKey: IStaffType["key"] | null;
          attributeKey: IAttribute["key"] | null;
          label?: string;
          min?: number;
          shiftTypeKeys?: IShiftType["key"][];
        };
      };
      byDate: {
        [key in YyyyMmDd]: {
          [targetLevelIdentifier in TTargetLevelKey]: ITargetLevel | IUnitTargetLevel;
        };
      };
    };
  };
  aggregatedTargetLevels: {
    [scheduleId: string]: {
      keys: TTargetLevelKey[];
      targets: { [key in TTargetLevelKey]: IUnitTargetLevel };
    };
  };

  targetLevelCounts: Record<ISchedule["id"], Record<YyyyMmDd, Record<TTargetLevelKey, number>>>;
};
export type PageData = TData & {
  loaders: { [key in keyof TData]: boolean };
  isDataLoading: boolean;
};

export const pageDataInitState: PageData = {
  staffShifts: [],
  schedules: [],
  notes: [],
  shiftTypes: {},
  staffDetails: {},
  staffWorkingHoursByStaffId: {},
  metadataByStaffId: {},
  staffingTargets: [],
  targetLevelCounts: {},
  targetLevels: {},
  aggregatedTargetLevels: {},
  loaders: {
    staffShifts: false,
    shiftTypes: false,
    schedules: false,
    notes: false,
    staffDetails: false,
    staffWorkingHoursByStaffId: false,
    metadataByStaffId: false,
    staffingTargets: false,
    targetLevels: false,
    targetLevelCounts: false,
    aggregatedTargetLevels: false,
  },
  isDataLoading: false,
};

export const PageDataActions = {
  setData: (state: THouseViewState, action: PayloadAction<Partial<PageData>>) => {
    state.pageData = { ...state.pageData, ...action.payload };
  },

  setShiftTypes: ({ pageData }: THouseViewState, action: PayloadAction<PageData["shiftTypes"]>) => {
    pageData.shiftTypes = action.payload;
  },

  setStaffShifts: (
    { pageData }: THouseViewState,
    action: PayloadAction<PageData["staffShifts"]>,
  ) => {
    pageData.staffShifts = action.payload;
  },

  setDataLoaders: (
    { pageData }: THouseViewState,
    { payload }: PayloadAction<Partial<PageData["loaders"]>>,
  ) => {
    pageData.loaders = { ...pageData.loaders, ...payload };
    pageData.isDataLoading = Object.values(pageData.loaders).some(Boolean);
  },

  setStaffDetails: (
    { pageData }: THouseViewState,
    action: PayloadAction<PageData["staffDetails"]>,
  ) => {
    pageData.staffDetails = action.payload;
  },

  setTargetLevelCounts: (
    { pageData }: THouseViewState,
    action: PayloadAction<PageData["targetLevelCounts"]>,
  ) => {
    pageData.targetLevelCounts = action.payload;
  },

  setAggregatedTargetLevels: (
    { pageData }: THouseViewState,
    action: PayloadAction<PageData["aggregatedTargetLevels"]>,
  ) => {
    pageData.aggregatedTargetLevels = action.payload;
  },

  setTargetLevels: (
    { pageData }: THouseViewState,
    action: PayloadAction<PageData["targetLevels"]>,
  ) => {
    pageData.targetLevels = action.payload;
  },
};
