import { useEffect, useMemo } from "react";

import {
  getScheduleCoverage,
  Schedule,
  ShiftType,
  StaffShift,
  YyyyMmDd,
} from "@m7-health/shared-utils";
import { compact, entries, isEmpty, isEqual, set, uniq, values } from "lodash";

import { houseViewStore } from "#/features/HouseView/store";
import {
  ISchedule,
  IScheduleShiftType,
  IStaffCategory,
  IStaffShift,
  IUser,
  StaffDetails,
} from "@/api";
import { openShiftsSidebarActions } from "@/common/components/OpenShiftSidebarContent/store";
import { useAppDispatch, useAppSelector } from "@/common/hooks";
import { TTargetLevelKey } from "@/common/types";
import { getTargetLevelKey } from "@/common/utils/getTargetLevelKey";

export const useSetTargetLevelCounts = ({
  schedules,
  shiftsByScheduleId,
  staffDetailsByStaffId,
  shiftTypesByScheduleIdByKey,
}: {
  schedules: ISchedule[];
  shiftsByScheduleId: Record<ISchedule["id"], IStaffShift[]>;
  staffDetailsByStaffId: Record<string, StaffDetails.DTO>;
  shiftTypesByScheduleIdByKey: Record<string, Record<string, IScheduleShiftType>>;
}) => {
  const dispatch = useAppDispatch();

  const { targetLevelsByScheduleId, aggregatedTargetLevelsByScheduleId } = useAppSelector(
    (state) => ({
      targetLevelsByScheduleId: state.houseView.pageData.targetLevels,
      aggregatedTargetLevelsByScheduleId: state.houseView.pageData.aggregatedTargetLevels,
    }),
    isEqual,
  );
  const staffCategoryById = useMemo(() => {
    return entries(staffDetailsByStaffId).reduce((acc, [userId, staffDetail]) => {
      acc.set(userId, staffDetail.staffType.staffCategoryKey);

      return acc;
    }, new Map<IUser["id"], IStaffCategory["key"]>());
  }, [staffDetailsByStaffId]);

  useEffect(() => {
    const countsByScheduleIdByDate: Record<
      ISchedule["id"],
      Record<YyyyMmDd, Record<TTargetLevelKey, number>>
    > = {};

    // If some data is missing, don't run the effect
    if (
      schedules.length === 0 ||
      isEmpty(schedules) ||
      isEmpty(shiftsByScheduleId) ||
      isEmpty(staffDetailsByStaffId) ||
      isEmpty(shiftTypesByScheduleIdByKey) ||
      isEmpty(targetLevelsByScheduleId) ||
      isEmpty(aggregatedTargetLevelsByScheduleId)
    )
      return;

    schedules.forEach((schedule) => {
      const staffShifts = shiftsByScheduleId[schedule.id];
      if (!staffShifts) return;
      const shiftTypesArray = values(shiftTypesByScheduleIdByKey[schedule.id]);
      const targetLevels = targetLevelsByScheduleId[schedule.id];
      const aggregatedTargetLevels = aggregatedTargetLevelsByScheduleId[schedule.id]?.targets;

      const allShiftGaps = getScheduleCoverage(
        schedule as Schedule.DTO,
        staffShifts as StaffShift.DTO[],
        shiftTypesArray as ShiftType.DTO[],
        staffCategoryById,
      );

      //Set counts for all shifts for both aggregated and single shift targets
      const allCoverage = [...allShiftGaps.coverageByDate.entries()];
      allCoverage.forEach(([date, dayCoverage]) => {
        dayCoverage.forEach((coverage) => {
          const { staffCategoryKey, shiftTypeKey } = coverage;

          const targetLevelKey = getTargetLevelKey({
            shiftTypeKey: shiftTypeKey as ShiftType.Key,
            staffCategoryKey: staffCategoryKey as IStaffCategory["key"],
          });
          set(
            countsByScheduleIdByDate,
            [schedule.id, date, targetLevelKey],
            coverage.totalShiftCount,
          );

          if (aggregatedTargetLevels) {
            Object.entries(aggregatedTargetLevels).forEach(([key, targetLevel]) => {
              const { shiftTypeKeys, attributeKey } = targetLevel;
              if (
                shiftTypeKeys.includes(shiftTypeKey as ShiftType.Key) &&
                staffCategoryKey === targetLevel.staffCategoryKey &&
                !attributeKey
              ) {
                const newTotal = addWithAveragedDecimals(
                  coverage.totalShiftCount,
                  countsByScheduleIdByDate[schedule.id]?.[date as YyyyMmDd]?.[
                    key as TTargetLevelKey
                  ] || 0,
                );
                set(countsByScheduleIdByDate, [schedule.id, date, key], newTotal);
              }
            });
          }
        });
      });

      //Get all attribute keys belonging to our targets and set counts for each elligible and assigned attribute
      const allTargetLevels = [
        ...Object.values(targetLevels?.orderedTargets || {}),
        ...Object.values(aggregatedTargetLevels || {}),
      ];
      const attributeKeys = uniq(
        compact(allTargetLevels.map((targetLevel) => targetLevel.attributeKey)),
      );
      attributeKeys.forEach((attributeKey) => {
        const attributeTargetLevels = allTargetLevels.filter(
          (targetLevel) => targetLevel.attributeKey === attributeKey,
        );
        const assignedAttributeStaffShifts = staffShifts.filter((staffShift) =>
          staffShift.attributes.includes(attributeKey),
        ) as StaffShift.DTO[];
        const eligibleAttributeStaffShifts = staffShifts.filter((staffShift) =>
          staffDetailsByStaffId[staffShift.staffId]?.attributeKeys.includes(attributeKey),
        ) as StaffShift.DTO[];

        const setCountsForAttributeTargets = (
          staffShiftsForAttribute: StaffShift.DTO[],
          eligibleOnly: boolean,
        ) => {
          const attributeGaps = getScheduleCoverage(
            schedule as Schedule.DTO,
            staffShiftsForAttribute,
            shiftTypesArray as ShiftType.DTO[],
            staffCategoryById,
          );
          const attributeCoverage = [...attributeGaps.coverageByDate.entries()];
          attributeCoverage.forEach(([date, dayCoverage]) => {
            dayCoverage.forEach((coverage) => {
              const { staffCategoryKey, shiftTypeKey } = coverage;

              attributeTargetLevels.forEach((targetLevel) => {
                const shiftTypeKeys = targetLevel.shiftTypeKeys;
                if (
                  shiftTypeKeys?.includes(shiftTypeKey as ShiftType.Key) &&
                  staffCategoryKey === targetLevel.staffCategoryKey
                ) {
                  const targetLevelKey = getTargetLevelKey({
                    ...targetLevel,
                    eligibleOnly,
                  });
                  let newTotal = coverage.totalShiftCount;
                  if (shiftTypeKeys.length > 1) {
                    newTotal = addWithAveragedDecimals(
                      newTotal,
                      countsByScheduleIdByDate[schedule.id]?.[date as YyyyMmDd]?.[targetLevelKey] ||
                        0,
                    );
                  }
                  set(countsByScheduleIdByDate, [schedule.id, date, targetLevelKey], newTotal);
                }
              });
            });
          });
        };
        setCountsForAttributeTargets(assignedAttributeStaffShifts, false);
        setCountsForAttributeTargets(eligibleAttributeStaffShifts, true);
      });
    });

    dispatch(houseViewStore.state.setTargetLevelCounts(countsByScheduleIdByDate));
    dispatch(
      openShiftsSidebarActions.setOpenShiftsSidebarData({
        targetLevelCounts: countsByScheduleIdByDate,
      }),
    );
  }, [
    dispatch,
    schedules,
    shiftsByScheduleId,
    staffDetailsByStaffId,
    shiftTypesByScheduleIdByKey,
    targetLevelsByScheduleId,
    staffCategoryById,
    aggregatedTargetLevelsByScheduleId,
  ]);
};

//This is a helper function to add two numbers and average the decimals
const addWithAveragedDecimals = (a: number, b: number) => {
  const aWhole = Math.floor(a);
  const bWhole = Math.floor(b);
  const aDecimal = a - aWhole;
  const bDecimal = b - bWhole;
  return aWhole + bWhole + (aDecimal + bDecimal) / 2;
};
