import { useEffect, useMemo } from "react";

import * as Sentry from "@sentry/react";
import { LDContext, useLDClient } from "launchdarkly-react-client-sdk";

import { TM7Context, useDeepMemo, useM7Context } from "../hooks";

type TUsedM7Context =
  | {
      currentUser: TM7Context["currentUser"];
      currentRole: TM7Context["currentRole"];
      currentUnit: TM7Context["currentUnit"];
      currentFacility: TM7Context["currentFacility"];
    }
  | undefined;

/*
 * Should only be used in the hooks below and anywhere to debug the context
 *  but should never be used directly to render or retrieve value to
 *  be used in any logic.
 */
export const usePrivateBuildLaunchDarklyContext = () => {
  const m7Context = useM7Context();

  const partialM7Context: TUsedM7Context = useDeepMemo(() => {
    if (!m7Context) return undefined;
    return {
      currentUser: m7Context.currentUser,
      currentRole: m7Context.currentRole,
      currentUnit: m7Context.currentUnit,
      currentFacility: m7Context.currentFacility,
    };
  }, [m7Context]);

  return useMemo(() => {
    // we must have at least user data to build the context
    if (!partialM7Context) return;

    const userContext = buildUserContext(partialM7Context);
    const unitContext = buildUnitContext(partialM7Context);
    const facilityContext = buildFacilityContext(partialM7Context);
    const roleContext = buildRoleContext(partialM7Context);

    return {
      kind: "multi",
      user: userContext,
      unit: unitContext,
      facility: facilityContext,
      currentRole: roleContext,
    };
  }, [partialM7Context]);
};

export const useLaunchDarkly = () => {
  const m7LaunchDarklyContext = usePrivateBuildLaunchDarklyContext();

  const ldClient = useLDClient();

  // Configure LaunchDarkly client. On error, send to Sentry
  useEffect(() => {
    if (!ldClient) return;

    ldClient.on("error", (error: unknown) => {
      Sentry.addBreadcrumb({
        category: "launchdarkly",
        message: "Error",
        data: { error: error },
      });
    });
  }, [ldClient]);

  // Set LaunchDarkly user context
  useEffect(() => {
    if (!ldClient || !m7LaunchDarklyContext) return;

    void ldClient.identify(m7LaunchDarklyContext);
  }, [m7LaunchDarklyContext, ldClient]);
};

const buildUserContext = (context: TUsedM7Context): LDContext | undefined => {
  if (!context?.currentUser) return undefined;

  const {
    currentUser: { email, firstName, lastName, roles, id },
  } = context;
  return {
    key: id,
    name: `${firstName} ${lastName}`,
    firstName,
    lastName,
    email,
    roles,
  };
};

const buildUnitContext = (context: TUsedM7Context): LDContext | undefined => {
  const currentUnit = context?.currentUnit;
  if (!currentUnit) return undefined;

  const { id, name } = currentUnit;
  return {
    key: id,
    name,
  };
};

const buildFacilityContext = (context: TUsedM7Context): LDContext | undefined => {
  const currentFacility = context?.currentFacility;
  if (!currentFacility) return undefined;

  const { id, name } = currentFacility;
  return {
    key: id,
    name,
  };
};

const buildRoleContext = (context: TUsedM7Context): LDContext | undefined => {
  const currentRole = context?.currentRole;
  if (!currentRole) return undefined;

  return {
    key: currentRole,
    name: currentRole,
  };
};
