import { __HouseViewFacilityPicker } from "./FacilityPicker";
import { __HouseViewHeaders } from "./Headers";
import { __HouseViewModals } from "./modals";
import { HouseViewMultiWeekTable } from "./MultiWeekHouseViewTable/Table";
import { __HouseViewSideBar } from "./SideBar/SideBar";
import { SingleDayView } from "./SingleDayView/SingleDayView";
import { __HouseViewUnitCard } from "./UnitCard";
import { __HouseViewUnitCardStaff } from "./UnitCardStaff";
import { WeekViewTable } from "./WeekViewTable/WeekViewTable";

const HouseView = () => <></>;

HouseView.UnitCard = __HouseViewUnitCard;
HouseView.UnitCardStaff = __HouseViewUnitCardStaff;
HouseView.SideBar = __HouseViewSideBar;
HouseView.Modals = __HouseViewModals;
HouseView.Headers = __HouseViewHeaders;
HouseView.FacilityPicker = __HouseViewFacilityPicker;
HouseView.MultiWeekTable = HouseViewMultiWeekTable;
HouseView.SingleDayView = SingleDayView;
HouseView.WeekViewTable = WeekViewTable;

HouseView.SIDEBAR_WIDTH = "400px";

export { StaffItem } from "./StaffItem";
export { HouseView };
