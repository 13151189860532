import { Fragment } from "react";

import { isEqual, sortBy } from "lodash";

import { Box } from "@mui/material";

import { INote, IStaffCategory, IStaffDetails, IStaffShift } from "~/api";
import CustomCollapse from "~/common/components/Collapse";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { MixpanelProvider } from "~/modules/mixpanel/Provider";
import { Mxp } from "~/modules/mixpanel/types";
import { IUnitBasic } from "~/routes/api/types";

import { IUnitConfig } from "#/features/User/types";
import { useAppFlags } from "@/common/hooks";
import { KeyBy } from "@/common/types";
import { isOnMobile } from "@/common/utils/isOnMobile";
import { voidingShiftStatus } from "@/common/utils/shifts";

import { HouseView } from "..";
import { houseViewStore } from "../../store";

export const SingleDayView = ({
  notesByUserId,
  notesFromStaffByUserId,
  shiftsByUnit,
  staffDetailsByUserId,
  units,
}: {
  notesByUserId: KeyBy<INote, "userId">;
  notesFromStaffByUserId: KeyBy<INote, "userId">;
  shiftsByUnit: Record<IUnitBasic["id"], IStaffShift[]>;
  staffDetailsByUserId: KeyBy<IStaffDetails, "userId">;
  units: IUnitConfig[];
}) => {
  const { useNewSingleDayMobileView } = useAppFlags();
  const isPatientCountsView = isOnMobile() && !useNewSingleDayMobileView;
  const isNewSingleDayMobileView = isOnMobile() && useNewSingleDayMobileView;
  const dispatch = useAppDispatch();

  const { shiftTypeByScheduleByKey, selectedUnitId } = useAppSelector(
    (state) => ({
      shiftTypeByScheduleByKey: state.houseView.pageData.shiftTypes,
      selectedUnitId: state.houseView.pageFilters.selectedUnitId,
    }),
    isEqual,
  );
  // select unit and return to home - no action in progress
  const selectUnit = (unit: IUnitBasic) => {
    if (selectedUnitId !== unit.id) {
      dispatch(houseViewStore.state.selectUnit(unit.id));
      dispatch(houseViewStore.state.endFloating());
      dispatch(houseViewStore.state.endAssignAttributes());
      dispatch(houseViewStore.state.endFindStaffToWork());
      dispatch(houseViewStore.state.endEditShifts());
    }
  };

  return (
    <MixpanelProvider properties={{ [Mxp.Property.layout.section]: "main-content" }}>
      <Box className={"unit-grid-wrapper"}>
        <Box
          className={`house-view-units-grid ${isNewSingleDayMobileView ? "is-new-single-day-mobile-view" : ""} ${isPatientCountsView ? "is-patient-counts-view" : ""}`}
        >
          {sortBy(units, "sortPosition", "name").map((unit) => {
            const shifts = shiftsByUnit[unit.id] || []!;

            const liveShifts: IStaffShift[] = [];
            const shiftsWithUpdates: Partial<Record<IStaffCategory["key"], IStaffShift[]>> = {};
            const otherShifts: Partial<Record<IStaffCategory["key"], IStaffShift[]>> = {};

            shifts?.forEach((shift) => {
              const shiftStaffDetails = staffDetailsByUserId?.[shift.staffId];
              const shiftType = shiftTypeByScheduleByKey[shift.scheduleId]?.[shift.shiftTypeKey];
              const category = shiftStaffDetails?.staffType?.staffCategory?.key;
              if (!category) return;
              const shiftStatus = voidingShiftStatus(shift);

              if (!shiftStatus && shiftType?.isCountedForRealTimeStaffingTarget) {
                liveShifts.push(shift);
              } else {
                if (shiftStatus) {
                  (shiftsWithUpdates[category] ||= []).push(shift);
                } else if (shiftType?.isWorkingShift) {
                  (otherShifts[category] ||= []).push(shift);
                }
              }
            });

            return (
              <Fragment key={"house-view-home-unit-card" + unit.id}>
                <HouseView.UnitCard
                  withPatientCount
                  shifts={liveShifts}
                  allShifts={shifts}
                  unit={unit}
                  notes={notesByUserId}
                  notesFromStaff={notesFromStaffByUserId}
                  staffDetails={staffDetailsByUserId}
                  onSelect={selectUnit}
                  otherShifts={(category: IStaffCategory["key"]) => (
                    <>
                      {shiftsWithUpdates[category]?.[0] && (
                        <CustomCollapse
                          isExpandedByDefault
                          label={`Staff with updates`}
                          content={
                            <Box>
                              {shiftsWithUpdates[category]!.map((shift) => (
                                <HouseView.UnitCardStaff
                                  key={"unit-card-staff-member-" + shift.id}
                                  unitId={unit.id}
                                  shift={shift}
                                  shiftType={
                                    shiftTypeByScheduleByKey[shift.scheduleId]?.[shift.shiftTypeKey]
                                  }
                                  withOtherShifts
                                  staffDetails={staffDetailsByUserId}
                                  notes={notesByUserId}
                                  notesFromStaff={notesFromStaffByUserId}
                                />
                              ))}
                            </Box>
                          }
                        />
                      )}
                      {otherShifts[category]?.[0] && (
                        <CustomCollapse
                          isExpandedByDefault
                          label={`Staff with other shift types`}
                          content={
                            <Box>
                              {otherShifts[category]!.map((shift) => (
                                <HouseView.UnitCardStaff
                                  key={"unit-card-staff-member-" + shift.id}
                                  unitId={unit.id}
                                  withOtherShifts
                                  shiftType={
                                    shiftTypeByScheduleByKey[shift.scheduleId]?.[shift.shiftTypeKey]
                                  }
                                  shift={shift}
                                  staffDetails={staffDetailsByUserId}
                                  notes={notesByUserId}
                                  notesFromStaff={notesFromStaffByUserId}
                                />
                              ))}
                            </Box>
                          }
                        />
                      )}
                    </>
                  )}
                />
              </Fragment>
            );
          })}
        </Box>
      </Box>
    </MixpanelProvider>
  );
};
