import { buildApiMutationHook } from "../_shared/helpers";

import { User } from "./types";

export const useTriggerLoginCommunication = buildApiMutationHook<
  User.API.TriggerLoginCommunication.QueryParams,
  User.API.TriggerLoginCommunication.Response
>(User.API.TriggerLoginCommunication.PATH, "post");

export const useUpdateUser = buildApiMutationHook<
  User.API.UpdateUser.QueryParams,
  User.API.UpdateUser.Response
>(User.API.UpdateUser.PATH, "patch");

export const useBulkUploadUsers = buildApiMutationHook<
  User.API.BulkUploadUsers.QueryParams,
  User.API.BulkUploadUsers.Response
>(User.API.BulkUploadUsers.PATH, "post");

export const useBulkUpdateStaff = buildApiMutationHook<
  User.API.BulkUpdateStaff.QueryParams,
  User.API.BulkUpdateStaff.Response
>(User.API.BulkUpdateStaff.PATH, "post");
