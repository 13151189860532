import { useSearchParams } from "react-router-dom";

import { getTzDate, YyyyMmDd } from "@m7-health/shared-utils";
import { entries } from "lodash";

import { Box, Divider, Stack, Typography } from "@mui/material";

import { MixpanelProvider } from "~/modules/mixpanel/Provider";
import { Mxp } from "~/modules/mixpanel/types";

import { useCurrentTimezone } from "@/common/hooks";
import { getTargetLevelKey } from "@/common/utils/getTargetLevelKey";
import { pxToRem } from "@/common/utils/pxToRem";

import { OpenShiftSingleDayRow } from "./components/OpenShiftSingleDayRow";
import { useDataForSingleDay } from "./hooks/useDataForSingleDay";
import { useOpenShiftTargets } from "./hooks/useOpenShiftTargets";
import { ESideBarState } from "./OpenShiftSidebarContent";
import "./OpenShiftSidebarContent.scss";

export const SingleDay = ({ sidebarState }: { sidebarState: ESideBarState }) => {
  const { openShiftDayData, shiftTypes, targetCounts, selectedDate, longestLabelLengths } =
    useDataForSingleDay();
  const targets = useOpenShiftTargets();
  const timezone = useCurrentTimezone();
  const [searchParams] = useSearchParams();
  const selectedDayFromQuery = (searchParams.get("selectedDay") as YyyyMmDd | undefined) || null;
  const isHouseView = sidebarState === ESideBarState.houseView;
  //In house view the current day that we care about is the day selected in the day selector filter
  //Otherwise its the current day
  const startOfRelativeDay = getTzDate(isHouseView ? selectedDayFromQuery : null, timezone).startOf(
    "day",
  );
  const dateIsPast = getTzDate(selectedDate, timezone) < startOfRelativeDay;

  if (!openShiftDayData || !entries(openShiftDayData).length || !selectedDate || dateIsPast)
    return (
      <Box sx={{ p: 2, alignItems: "center", display: "flex", justifyContent: "center" }}>
        <Typography fontSize={pxToRem(14)} textAlign="center">
          There are no open shifts for this date
        </Typography>
      </Box>
    );

  return (
    <MixpanelProvider properties={{ [Mxp.Property.layout.section]: "sidebar-ops-day-edit" }}>
      <Box className="open-shifts-single-day">
        {/* Open shifts rows */}
        {entries(openShiftDayData).map(([unitName, openShiftsForUnit]) => {
          return (
            <Box key={unitName}>
              {isHouseView && (
                <Box className="unit-name-cell">
                  <Typography>{unitName}</Typography>
                </Box>
              )}
              <Stack direction="column" sx={{ px: "1rem" }}>
                {openShiftsForUnit.map(
                  (
                    { shiftTypeKey, scheduleId, openShifts, openShiftRequests, staffCategoryKey },
                    index,
                  ) => {
                    const shiftType = shiftTypes[scheduleId]?.[shiftTypeKey];

                    if (!shiftType) return null;

                    const target = targets[scheduleId]?.[staffCategoryKey]?.[shiftTypeKey];
                    const targetKey = target && getTargetLevelKey(target);
                    const targetCount = targetKey
                      ? targetCounts[scheduleId]?.[selectedDate]?.[targetKey] || 0
                      : 0;

                    return (
                      <>
                        <Box
                          key={`${scheduleId}-${shiftTypeKey}-${staffCategoryKey}-${index}`}
                          className="open-shift-wrapper"
                        >
                          <OpenShiftSingleDayRow
                            sidebarState={sidebarState}
                            currentCount={targetCount}
                            shiftType={shiftType}
                            target={target}
                            openShiftData={{ openShifts, openShiftRequests }}
                            scheduleId={scheduleId}
                            longestLabelLengths={longestLabelLengths}
                          />
                        </Box>
                        {index !== openShiftsForUnit.length - 1 && <Divider className="divider" />}
                      </>
                    );
                  },
                )}
              </Stack>
            </Box>
          );
        })}
      </Box>
    </MixpanelProvider>
  );
};
