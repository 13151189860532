import { useEffect, useState } from "react";

import { EUnitPermissionAreas } from "@m7-health/shared-utils";

import { Box, FormControl, InputLabel, Select, Typography } from "@mui/material";

import CustomCollapse from "~/common/components/Collapse";
import { NoteUpdateTag } from "~/common/components/NoteUpdateTag/NoteUpdateTag";
import { NOT_EXISTING_UUID } from "~/common/constants";
import { useAppDispatch } from "~/common/hooks/useRedux";
import { houseViewStore } from "~/features/HouseView/store";
import { useAppConfigQuery } from "~/features/User/queries";

import { useSelectedSchedule } from "#/features/HouseView/hooks/useSelectedSchedule";
import { IStaffCategory, IStaffShift, StaffShift, useListStaffCategoriesQuery } from "@/api";
import { MenuItem } from "@/common/components";
import { PartialShiftTimePicker } from "@/common/components/PartialShiftTimePicker/PartialShiftTimePicker";
import {
  useCheckUserPermission,
  useCurrentRole,
  useCurrentTimezone,
  useKeyBy,
} from "@/common/hooks";
import { darkGray, white } from "@/common/theming/colors";
import { seconds, TimeString } from "@/common/types";
import { showStatusForUnit } from "@/common/utils/shifts";

import { HouseViewSideBar, STAFF_ITEMS_LAYOUTS } from "..";
import { StaffItem } from "../../StaffItem";
import { splitOrEditShift } from "../helpers/splitOrEditShift";
import { HouseViewSideBarHooks } from "../hooks";
import { THouseViewSideBar } from "../types";

const hooks = HouseViewSideBarHooks;

export const UpdateStatus = ({ selectedUnit, staffDetails }: THouseViewSideBar) => {
  const dispatch = useAppDispatch();
  const { userIsKiosk } = useCurrentRole();
  const canManage = useCheckUserPermission("manage", EUnitPermissionAreas.houseView);
  const schedule = useSelectedSchedule();
  const timezone = useCurrentTimezone(selectedUnit.id);

  /* Queries */
  const { data: allCategories } = useListStaffCategoriesQuery({
    unitIds: [selectedUnit.id],
  });
  const categoriesByKey = useKeyBy(allCategories, "key");

  /** State */
  // Which status to sort all shifts by
  const [sortByStatusUpdate, setSortByStatusUpdate] = useState<StaffShift.EStatus>(
    StaffShift.EStatus.onCall,
  );
  // Which shift is selected, if any
  const [selectedStaffShift, setSelectedStaffShift] = useState<IStaffShift | null>(null);
  // buffer of partial attributes of selected shift, before going to edit shifts
  const [partialShiftAttributes, setPartialShiftAttributes] = useState<{
    customStartTime: TimeString | null;
    customDuration: seconds | null;
    status: StaffShift.EStatus | null;
  }>({
    customStartTime: null,
    customDuration: null,
    status: null,
  });
  const [partialShiftIsValid, setPartialShiftIsValid] = useState(true);
  const [dirtyState, __setDirtyState] = useState(false);
  const setDirtyState = (value: boolean) => {
    __setDirtyState(value);
    dispatch(houseViewStore.state.setCurrentActionDirtiness(value));
  };
  // When staff shift change, reset partial attributes
  useEffect(() => {
    if (selectedStaffShift) {
      setPartialShiftAttributes({
        customStartTime: selectedStaffShift.customStartTime,
        customDuration: selectedStaffShift.customDuration,
        status: selectedStaffShift.status,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStaffShift?.id]);

  /** Other computed data */
  const scheduleId = schedule?.id;
  const unit = useAppConfigQuery().data?.accessibleUnits?.find(
    (accessibleUnit) => accessibleUnit.id === selectedUnit.id,
  );
  const shiftTypesByKey = hooks.useShiftTypesByKey(scheduleId || NOT_EXISTING_UUID);
  const displayableCategories = hooks.updateStatus.useBuildCategories({
    shiftTypesByKey,
    sortByStatusUpdate,
  });

  return (
    <>
      <Box px={1}>
        <FormControl fullWidth sx={{ mb: 1 }}>
          <InputLabel id="update-notes-sort-by-label">Sort by least to most recent…</InputLabel>
          <Select<StaffShift.EStatus>
            sx={{ ".MuiInputBase-input": { p: 1 } }}
            label="Sort by least to most recent…"
            labelId="update-notes-sort-by-label"
            fullWidth
            value={sortByStatusUpdate}
            onChange={(event) => setSortByStatusUpdate(event.target.value as StaffShift.EStatus)}
          >
            {/** show update if: not floated and update is used in current unit  */}
            {Object.values(StaffShift.EStatus).map(
              (status) =>
                showStatusForUnit(status, unit) && (
                  <MenuItem
                    value={status}
                    key={"update-notes-update-option-" + status}
                    trackingLabel={null}
                  >
                    <NoteUpdateTag
                      update={status}
                      sx={{ p: 0.2, px: 0.5, width: "-webkit-fill-available" }}
                      unitId={selectedUnit.id}
                    />
                  </MenuItem>
                ),
            )}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ overflowY: "auto" }}>
        {displayableCategories.map(([category, categoryShifts]) => {
          const categoryName = categoriesByKey[category as IStaffCategory["key"]]?.name || category;

          return (
            <CustomCollapse
              styles={{ mt: 0 }}
              key={"update-notes-" + category}
              label={categoryName}
              isExpandedByDefault={true}
            >
              {categoryShifts.map((shift) => (
                <StaffItem
                  layout={STAFF_ITEMS_LAYOUTS.updateStatus}
                  unitId={selectedUnit.id}
                  lastStatusType={sortByStatusUpdate}
                  shift={shift}
                  selectable={!userIsKiosk && canManage}
                  selected={selectedStaffShift?.id === shift.id}
                  onClick={
                    userIsKiosk || !canManage
                      ? undefined
                      : () => {
                          setSelectedStaffShift(shift);
                          setDirtyState(true);
                        }
                  }
                />
              ))}
            </CustomCollapse>
          );
        })}
      </Box>
      <Box flexGrow={1} />
      {selectedStaffShift && (
        <HouseViewSideBar.Helpers.BottomActions
          title={"Assign Shift Status"}
          actionButton={{
            disabled: !partialShiftIsValid || !dirtyState,
            label: "Add status",
            action: () => {
              const shiftType = shiftTypesByKey[selectedStaffShift.shiftTypeKey];
              if (!shiftType || !schedule) return;
              const workingAwayFromHomeUnit =
                staffDetails?.[selectedStaffShift.staffId]?.homeUnitId !== selectedUnit.id;

              dispatch(
                houseViewStore.state.startEditShifts({
                  staffId: selectedStaffShift.staffId,
                  autoSave:
                    partialShiftAttributes.customDuration === selectedStaffShift.customDuration &&
                    partialShiftAttributes.customStartTime === selectedStaffShift.customStartTime,
                  shifts: splitOrEditShift({
                    shiftToSplitAndFloat: selectedStaffShift,
                    originalShiftType: shiftType,
                    newShiftType: shiftType,
                    partialShiftAttributes,
                    selectedSchedule: schedule,
                    timezone,
                    targetedShiftParams: {
                      isWorkingAway: workingAwayFromHomeUnit,
                      status: partialShiftAttributes.status,
                    },
                  }),
                }),
              );
            },
          }}
          cancelButton={{
            disabled: !partialShiftIsValid,
            label: "Cancel",
            action: () => dispatch(houseViewStore.state.endAction()),
          }}
        >
          <FormControl sx={{ mb: 1 }} fullWidth>
            <InputLabel sx={{ px: 1, background: white }} shrink id="update-shift-status">
              Shift Status
            </InputLabel>
            <Select<StaffShift.EStatus>
              sx={{ ".MuiInputBase-input": { p: 1 } }}
              label="Sort by least to most recent…"
              labelId="update-shift-status"
              fullWidth
              displayEmpty
              value={partialShiftAttributes.status || ""}
              onChange={(event) => {
                setPartialShiftAttributes((oldAttributes) => ({
                  ...oldAttributes,
                  status: event.target.value as StaffShift.EStatus,
                }));
                setDirtyState(true);
              }}
            >
              <MenuItem value={""} key={"update-shift-status-no-status"} trackingLabel={null}>
                <Typography fontSize="13px" color={darkGray}>
                  <i>No Status</i>
                </Typography>
              </MenuItem>
              {Object.values(StaffShift.EStatus).map(
                (status) =>
                  showStatusForUnit(status, unit) && (
                    <MenuItem
                      value={status}
                      key={"update-shift-status-" + status}
                      trackingLabel={null}
                    >
                      <NoteUpdateTag
                        update={status}
                        sx={{ p: 0.2, px: 0.5, width: "-webkit-fill-available" }}
                      />
                    </MenuItem>
                  ),
              )}
            </Select>
          </FormControl>
          <PartialShiftTimePicker
            alwaysOn={true}
            shiftType={shiftTypesByKey[selectedStaffShift.shiftTypeKey] || null}
            staffShift={partialShiftAttributes}
            onChange={(shiftAttributes, isValid) => {
              if (isValid) {
                setPartialShiftAttributes((oldAttributes) => ({
                  ...oldAttributes,
                  customDuration: null,
                  customStartTime: null,
                  ...shiftAttributes,
                }));
                setPartialShiftIsValid(true);
              } else {
                setPartialShiftIsValid(false);
              }
            }}
          />
        </HouseViewSideBar.Helpers.BottomActions>
      )}
    </>
  );
};
