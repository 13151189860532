import React, { useState } from "react";

import ReactDOMServer from "react-dom/server";

import { compact } from "lodash";

import { MoreHoriz } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Menu from "@mui/material/Menu";

import { black } from "~/common/theming/colors";
import { MixpanelProvider } from "~/modules/mixpanel/Provider";
import { Mxp } from "~/modules/mixpanel/types";

import { MenuItem } from "../TrackedComponents";

import { IMeatballsMenuProps } from "./types";

const MeatballsMenu = ({
  "data-testid": dataTestId,
  items,
  meatballIconStyles,
  menuItemStyles,
}: IMeatballsMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (menuItemAction: (() => void) | undefined) => {
    setAnchorEl(null);
    menuItemAction && menuItemAction();
  };

  if (!items.length) return null;

  return (
    <MixpanelProvider properties={{ [Mxp.Property.layout.component]: "meatballs-menu" }}>
      <div>
        <IconButton
          data-testid={dataTestId}
          aria-controls={isOpen ? "basic-menu" : undefined}
          aria-expanded={isOpen ? "true" : undefined}
          aria-haspopup="true"
          id="basic-button"
          onClick={handleButtonClick}
          sx={meatballIconStyles ?? iconButtonStyles}
        >
          <MoreHoriz />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={isOpen}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={menuStyles}
        >
          {compact(items).map((item) => {
            const labelString =
              typeof item.label === "string"
                ? item.label
                : ReactDOMServer.renderToString(item.label);
            return (
              <MenuItem
                trackingLabel={labelString}
                key={labelString}
                onClick={() => handleMenuItemClick(item?.onClick)}
                sx={menuItemStyles}
              >
                {item?.label}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    </MixpanelProvider>
  );
};

const iconButtonStyles = {
  border: `1px solid ${black}`,
  borderRadius: "4px",
  width: "36px",
  height: "36px",
};

const menuStyles = { marginTop: "8px" };

export default MeatballsMenu;
