import { memo } from "react";

import { isEqual } from "lodash";

import { useAppSelector } from "@/common/hooks";

import { Filters } from "./components";
import { MultiDay } from "./MultiDay";
import { SingleDay } from "./SingleDay";

export enum ESideBarState {
  houseView = "houseView",
  scheduleGrid = "scheduleGrid",
}

enum ESidebarPage {
  multiDay = "multiDay",
  singleDay = "singleDay",
}

export const OpenShiftSidebarContent = memo(({ sidebarState }: { sidebarState: ESideBarState }) => {
  const { selectedDate } = useAppSelector(
    (state) => ({
      selectedDate: state.openShiftsSidebar.selectedDate,
    }),
    isEqual,
  );
  const currentSideBarState = selectedDate ? ESidebarPage.singleDay : ESidebarPage.multiDay;

  return (
    <>
      <Filters sidebarState={sidebarState} />
      {currentSideBarState === ESidebarPage.multiDay && <MultiDay sidebarState={sidebarState} />}
      {currentSideBarState === ESidebarPage.singleDay && <SingleDay sidebarState={sidebarState} />}
    </>
  );
});
