import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { getTzDayjs } from "@m7-health/shared-utils";
import { useQueryClient } from "@tanstack/react-query";
import { filter, groupBy, isEqual, keyBy, orderBy, sortBy } from "lodash";

import {
  ISchedule,
  IStaffCategory,
  IStaffShift,
  StaffCategory,
  StaffShift,
  useBulkSaveStaffShifts,
  useInvalidateQuery,
  useListOpenShiftsQuery,
  useListOrphanOpenShiftRequestsQuery,
  useListStaffShiftsQuery,
  useShiftTypesForSchedule,
} from "~/api";
import { sortingFunction } from "~/common/components/ShiftTypeFilterDropdown/sorting";
import { useAppSelector } from "~/common/hooks/useRedux";
import { useToast } from "~/common/hooks/useToast";
import { KeyBy } from "~/common/types";
import { IShift } from "~/routes/api/types";

import { useCurrentTimezone, useDeepMemo, useUnitStaffCategories } from "@/common/hooks";
import { voidingShiftStatus } from "@/common/utils/shifts";

import { useSelectedSchedule } from "../../hooks/useSelectedSchedule";

import { shiftLastStatusUpdatedAt } from "./helpers/shiftLastNoteUpdatedAt";
import { THouseViewSideBar } from "./types";

type TSection = [string, IStaffShift[], IStaffCategory["key"] | undefined];

export const HouseViewSideBarHooks = {
  useSetExpandedCategoryOnMount: () => {
    const selectedStaffCategories = useAppSelector(
      (state) => state.houseView.pageFilters.selectedStaffCategories,
      isEqual,
    );
    const [expandedCategory, setExpandedCategory] = useState<string | null>(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
      setExpandedCategory(selectedStaffCategories[0] || StaffCategory.EKey.nurse);
    });

    return expandedCategory;
  },

  useShiftTypesByKey: (scheduleId: ISchedule["id"]) => {
    const shiftTypes = useShiftTypesForSchedule(scheduleId);

    return useMemo(() => keyBy(shiftTypes, "key"), [shiftTypes]);
  },

  updateNotes: {
    useAnonymousNotes: () => {
      const allNotes = useAppSelector((state) => state.houseView.pageData.notes);

      return useDeepMemo(
        () =>
          keyBy(
            allNotes.filter((note) => note.userId === null),
            "scheduleId",
          ),
        [allNotes],
      );
    },
  },

  updateStatus: {
    useBuildCategories: ({
      shiftTypesByKey: shiftTypes,
      sortByStatusUpdate: sortByStatus,
    }: {
      shiftTypesByKey: KeyBy<IShift, "key">;
      sortByStatusUpdate: StaffShift.EStatus;
    }) => {
      const selectedScheduleId = useSelectedSchedule()?.id;
      const { staffDetails, staffShifts, selectedUnitId, staffDatesMetadata, selectedDay } =
        useAppSelector(
          ({ houseView }) => ({
            staffDetails: houseView.pageData.staffDetails,
            staffShifts: houseView.pageData.staffShifts,
            selectedUnitId: houseView.pageFilters.selectedUnitId,
            staffDatesMetadata: houseView.pageData.metadataByStaffId,
            selectedDay: houseView.pageFilters.selectedDateForData,
          }),
          isEqual,
        );
      const timezone = useCurrentTimezone(selectedUnitId);
      const sortByStatusUpdate = useCallback(
        (shiftsToSort: IStaffShift[]) =>
          sortBy(shiftsToSort, (shift) => {
            const metadata = staffDatesMetadata?.[shift.staffId];

            return new Date(shiftLastStatusUpdatedAt(metadata, sortByStatus) || 0);
          }),
        [sortByStatus, staffDatesMetadata],
      );

      return useMemo(() => {
        // if (!selectedUnit || !selectedUnitShifts.length || !staffDetails || !notes || !shiftTypes)
        //   return [];

        const [from, to] = [
          getTzDayjs(selectedDay, timezone).startOf("day").toISOString(),
          getTzDayjs(selectedDay, timezone).endOf("day").toISOString(),
        ];

        const selectedUnitShifts = staffShifts.filter(({ scheduleId, date }) => {
          return scheduleId === selectedScheduleId && date >= from && date <= to && selectedUnitId;
        });

        const shiftsByCategory = groupBy(
          Object.values(selectedUnitShifts).flat(),
          ({ staffId }) => staffDetails[staffId]?.staffType.staffCategoryKey,
        );

        const otherWorkingShifts = sortByStatusUpdate(
          Object.values(shiftsByCategory)
            .flat()
            .filter(({ shiftTypeKey }) => {
              const shiftType = shiftTypes[shiftTypeKey];

              return shiftType?.isWorkingShift && !shiftType?.isCountedForRealTimeStaffingTarget;
            }),
        );

        let patientAndRealTimeTargetShiftsByCategory: [string, typeof selectedUnitShifts][] =
          Object.entries(shiftsByCategory).map(([category, categoryShifts]) => [
            category,
            sortByStatusUpdate(
              categoryShifts.filter(
                ({ shiftTypeKey }) => shiftTypes[shiftTypeKey]?.isCountedForRealTimeStaffingTarget,
              ),
            ),
          ]);
        patientAndRealTimeTargetShiftsByCategory = orderBy(
          patientAndRealTimeTargetShiftsByCategory,
          ([category]) => category,
        );

        const otherWorkingShiftCategory = [
          "Staff with other shift types",
          otherWorkingShifts,
        ] as const;
        return filter([
          ...patientAndRealTimeTargetShiftsByCategory,
          otherWorkingShifts[0] ? otherWorkingShiftCategory : undefined,
        ]);
      }, [
        selectedDay,
        timezone,
        staffShifts,
        sortByStatusUpdate,
        selectedScheduleId,
        selectedUnitId,
        staffDetails,
        shiftTypes,
      ]);
    },
  },

  assignAttributes: {
    useBuildCategories: (
      selectedUnit: THouseViewSideBar["selectedUnit"],
      shifts: THouseViewSideBar["shifts"],
      shiftTypes: KeyBy<IShift, "key">,
      staffDetails: THouseViewSideBar["staffDetails"],
    ): TSection[] => {
      const { values: allCategories } = useUnitStaffCategories(selectedUnit.id);
      const selectedUnitShifts = useMemo(
        () => (selectedUnit && shifts?.[selectedUnit.id]) || null || [],
        [selectedUnit, shifts],
      );

      return useMemo(() => {
        if (!selectedUnit || !selectedUnitShifts.length || !staffDetails || !shiftTypes) return [];

        const sections: TSection[] = [
          ...allCategories.map((category) => [category.name, [], category.key] as TSection),
          ["Staff with updates", [], undefined] as TSection,
          ["Staff with other shift types", [], undefined] as TSection,
        ];
        const indexedSections = sections.reduce(
          (acc, section) => {
            const [name, _shifts, category] = section;
            acc[category || name] = section;
            return acc;
          },
          {} as Record<string, TSection>,
        );

        selectedUnitShifts.forEach((shift) => {
          const staff = staffDetails[shift.staffId];
          const categoryKey = staff?.staffType.staffCategoryKey;
          const shiftType = shiftTypes[shift.shiftTypeKey];
          const shiftStatus = shift?.status;

          if (!categoryKey || !shiftType) return;
          if (!shiftType.isWorkingShift) return;

          // if shift has update and is not floated, add to "Staff with updates" section
          // or if shift has update and is floated AND FF is on, add to "Staff with updates" section
          // TODO: cleanup logic once FF is live
          if (voidingShiftStatus(shiftStatus)) {
            indexedSections["Staff with updates"]![1].push(shift);
          } else if (shiftStatus === StaffShift.EStatus.floated) {
            indexedSections["Staff with updates"]![1].push(shift);
          } else if (!shiftType.isCountedForRealTimeStaffingTarget) {
            indexedSections["Staff with other shift types"]![1].push(shift);
          } else {
            indexedSections[categoryKey]?.[1].push(shift);
          }
        });

        sections.forEach((section) => {
          section[1] = section[1].sort((shiftA, shiftB) => {
            const [shiftTypeA, shiftTypeB] = [
              shiftTypes[shiftA.shiftTypeKey],
              shiftTypes[shiftB.shiftTypeKey],
            ];

            const sortedByShiftType = sortingFunction(shiftTypeA, shiftTypeB);
            if (sortedByShiftType !== 0) return sortedByShiftType;

            const [staffIdA, staffIdB] = [shiftA.staffId, shiftB.staffId];
            const [staffA, staffB] = [staffDetails[staffIdA], staffDetails[staffIdB]];
            const [nameA, nameB] = [
              `${staffA?.user?.lastName || ""} ${staffA?.user.firstName || ""}`,
              `${staffB?.user?.lastName || ""} ${staffB?.user?.firstName || ""}`,
            ];

            return nameA.localeCompare(nameB);
          });
        });

        return sections.filter(([, sectionShifts]) => sectionShifts.length);
      }, [selectedUnit, selectedUnitShifts, staffDetails, shiftTypes, allCategories]);
    },

    useCommitChanges: (
      unitShifts: IStaffShift[] | undefined,
      cachedAttributesForUpdates: KeyBy<IStaffShift, "id">,
      resetShifts: () => void,
      setDirtyState: (isDirty: boolean) => void,
    ) => {
      const invalidateQuery = useInvalidateQuery();
      const { showSuccess, showError } = useToast();
      const queryClient = useQueryClient();

      const updateHasError = useRef(false);

      const { mutate: saveBulkShifts, isPending: bulkShiftsAreSaving } = useBulkSaveStaffShifts({
        onError: (_error) => {
          updateHasError.current = true;
          showError(
            "New schedule data was created while you were editing.  Please refresh your page to get the latest.  Note: you may lose some of your changes.",
          );
        },
        onSuccess: () => {
          // On settled, show success, and reset dirty state
          showSuccess("Saved successfully!");
          invalidateQuery([useListOpenShiftsQuery, useListOrphanOpenShiftRequestsQuery]);
          setDirtyState(false);
        },
        onSettled: () => {
          void queryClient.invalidateQueries({ queryKey: [useListStaffShiftsQuery.queryKey] });
        },
      });

      return {
        isLoading: bulkShiftsAreSaving,
        commitChanges: () => {
          // Reset/prepare mutation captured error state
          updateHasError.current = false;

          // Gather shifts that have changed
          const shiftsById = keyBy(unitShifts, "id");
          const attributesToUpdate = Object.values(cachedAttributesForUpdates).filter(
            ({ id, attributes }) => shiftsById[id]?.attributes?.join(",") !== attributes?.join(","),
          );

          if (attributesToUpdate.length === 0) {
            resetShifts();
            showSuccess("No changes to save.");
            return;
          }

          return saveBulkShifts({
            staffShifts: attributesToUpdate,
          });
        },
      };
    },
  },
};
