import { useMemo } from "react";

import { getTzDayjs } from "@m7-health/shared-utils";
import { isEqual, values } from "lodash";

import { Box, Stack, Typography } from "@mui/material";

import { CustomButton } from "@/common/components";
import { useGetMultiDayOpenShiftData } from "@/common/components/OpenShiftSidebarContent/hooks";
import { ESideBarState } from "@/common/components/OpenShiftSidebarContent/OpenShiftSidebarContent";
import { openShiftsSidebarActions } from "@/common/components/OpenShiftSidebarContent/store";
import { useAppDispatch, useAppSelector, useCurrentTimezone } from "@/common/hooks";
import { darkPurple, white } from "@/common/theming";
import { pxToRem } from "@/common/utils/pxToRem";

export const OpenShiftsSidebarHeader = () => {
  const { selectedDate } = useAppSelector(
    (state) => ({
      selectedDate: state.openShiftsSidebar.selectedDate,
    }),
    isEqual,
  );
  const currentTimezone = useCurrentTimezone();
  const dispatch = useAppDispatch();

  // TODO: Should we cap by end date too?
  const { openShiftsByDate } = useGetMultiDayOpenShiftData(ESideBarState.houseView);
  const requestCount = useMemo(() => {
    return values(openShiftsByDate).reduce(
      (countByDay, openShiftByDate) =>
        values(openShiftByDate).reduce(
          (countByStaff, openShift) => countByStaff + (openShift.openShiftRequests?.length || 0),
          countByDay,
        ),
      0,
    );
  }, [openShiftsByDate]);

  return (
    <>
      {selectedDate && (
        <>
          <Stack direction="column" alignItems="flex-start" gap={0}>
            <Typography sx={{ fontSize: pxToRem(16), marginLeft: "-1px" }}>
              <b>Open Shifts</b>
            </Typography>
            <Typography sx={{ fontSize: pxToRem(10), marginTop: "-3px" }}>
              {getTzDayjs(selectedDate, currentTimezone).format("MMM DD, YYYY")}
            </Typography>
          </Stack>
          <Box flexGrow={1} />
          <CustomButton
            trackingLabel="see-all-ops-requests"
            size="small"
            label="See All Requests"
            variant="outlined"
            sx={{ p: 0, px: 1, mr: 1, top: "-2px" }}
            onClick={() => {
              dispatch(openShiftsSidebarActions.setSidebarSelectedDate(null));
            }}
          />
        </>
      )}
      {!selectedDate && (
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography>Requests</Typography>
          <Box
            sx={{
              background: darkPurple,
              borderRadius: "6px",
              width: "24px",
              height: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ color: white, fontSize: pxToRem(13) }}>{requestCount}</Typography>
          </Box>
        </Stack>
      )}
    </>
  );
};
