import { useMemo } from "react";

import { entries, isEmpty, isEqual, uniq } from "lodash";

import { IStaffCategory, IStaffType, Schedule, Unit } from "~/api";
import { useStaffTypeNamesQuery } from "~/routes/queries";

import { THouseViewState } from "#/features/HouseView/store";
import { useAppSelector } from "@/common/hooks";
import { TTargetLevelKey } from "@/common/types";

export type TTargetLevelData = {
  filteredTargetLevelsForUnit: THouseViewState["pageData"]["targetLevels"][string]["orderedTargets"];
  orderedTargetLevelKeys: TTargetLevelKey[];
  filteredAggregatedTargetLevelsForUnit: THouseViewState["pageData"]["aggregatedTargetLevels"][string]["targets"];
  orderedAggregatedTargetLevelKeys: TTargetLevelKey[];
};

export const useOrganizeTargetLevelDataForSchedules = ({
  schedules,
  unitId,
}: {
  schedules: Schedule.DTO[];
  unitId: Unit.DTO["id"];
}) => {
  const { aggregatedTargetLevels, targetLevels, selectedStaffCategories } = useAppSelector(
    (state) => ({
      aggregatedTargetLevels: state.houseView.pageData.aggregatedTargetLevels,
      targetLevels: state.houseView.pageData.targetLevels,
      selectedStaffCategories: state.houseView.pageFilters.selectedStaffCategories,
    }),
    isEqual,
  );
  const { data: staffTypesWithCategories } = useStaffTypeNamesQuery();
  const staffCategoriesKeyByTypeKey = useMemo(() => {
    if (!staffTypesWithCategories) return null;

    return staffTypesWithCategories.reduce(
      (acc, staffType) => {
        acc[staffType.key] = staffType.staffCategoryKey;
        return acc;
      },
      {} as { [staffTypeKey: IStaffType["key"]]: IStaffCategory["key"] },
    );
  }, [staffTypesWithCategories]);

  return useMemo(() => {
    // If no schedules, there is nothing to show
    const stateIsReady = !isEmpty(schedules);
    if (!stateIsReady) return null;

    const orderedTargetLevelKeys: TTargetLevelKey[] = [];
    const orderedAggregatedTargetLevelKeys: TTargetLevelKey[] = [];
    let targetLevelsForUnit: THouseViewState["pageData"]["targetLevels"][string]["orderedTargets"] =
      {};
    let aggregatedTargetLevelsForUnit: THouseViewState["pageData"]["aggregatedTargetLevels"][string]["targets"] =
      {};

    const schedulesForUnit = schedules.filter((schedule) => schedule.unitId === unitId);

    //Get target level data and combine across all schedules for the unit
    schedulesForUnit.forEach((schedule) => {
      const scheduleTargetLevelData = targetLevels[schedule.id];
      if (scheduleTargetLevelData) {
        orderedTargetLevelKeys.push(...scheduleTargetLevelData.orderedKeys);
        targetLevelsForUnit = {
          ...targetLevelsForUnit,
          ...scheduleTargetLevelData.orderedTargets,
        };
      }

      const aggregatedScheduleTargetLevelData = aggregatedTargetLevels[schedule.id];
      if (aggregatedScheduleTargetLevelData) {
        orderedAggregatedTargetLevelKeys.push(...aggregatedScheduleTargetLevelData.keys);
        aggregatedTargetLevelsForUnit = {
          ...aggregatedTargetLevelsForUnit,
          ...aggregatedScheduleTargetLevelData.targets,
        };
      }
    });
    const filteredTargetLevels = Object.fromEntries(
      entries(targetLevelsForUnit).filter(([, { staffCategoryKey, staffTypeKey }]) => {
        const staffCategory =
          staffCategoryKey || (staffTypeKey && staffCategoriesKeyByTypeKey?.[staffTypeKey]);
        return (
          staffCategory &&
          (!selectedStaffCategories.length || selectedStaffCategories.includes(staffCategory))
        );
      }),
    );

    const filteredAggregatedTargetLevels = Object.fromEntries(
      entries(aggregatedTargetLevelsForUnit).filter(([, { staffCategoryKey, staffTypeKey }]) => {
        const staffCategory =
          staffCategoryKey || (staffTypeKey && staffCategoriesKeyByTypeKey?.[staffTypeKey]);
        return (
          staffCategory &&
          (!selectedStaffCategories.length || selectedStaffCategories.includes(staffCategory))
        );
      }),
    );

    return {
      filteredTargetLevelsForUnit: filteredTargetLevels,
      orderedTargetLevelKeys: uniq(orderedTargetLevelKeys),
      orderedAggregatedTargetLevelKeys: uniq(orderedAggregatedTargetLevelKeys),
      filteredAggregatedTargetLevelsForUnit: filteredAggregatedTargetLevels,
    };
  }, [
    targetLevels,
    staffCategoriesKeyByTypeKey,
    selectedStaffCategories,
    schedules,
    unitId,
    aggregatedTargetLevels,
  ]);
};
