import { Fragment } from "react";

import { Timezone } from "@m7-health/shared-utils";

import { Box } from "@mui/material";

import { EventUserUpdated } from "~/api/eventLog/events";

import { expectedColumnLabels } from "#/features/Roster/BulkCreateUsers/components/DataTable/constants";
import { TExpectedColumn } from "#/features/Roster/BulkCreateUsers/components/DataTable/types";
import { IUnitConfig } from "#/features/User/types";
import { EventLog } from "@/api";
import { formattedDateOrInvalid } from "@/common/components/StaffWorkDaySidebar/formattedDateOrInvalid";

/**
 * Display user_updated event log entry's updatedFields. Defaults to from/to layout with
 * date handling for "contractEndDate" and "softDeletedDate".
 * Update for "isSoftDeleted" is ignored as redundant to "softDeletedDate".
 */
export const UserUpdated = ({
  log,
  unit,
}: {
  log: EventLog.DTO<EventUserUpdated.Payloads>;
  unit?: IUnitConfig;
}) => {
  const { user, updatedFields } = log.eventPayload;
  const updated = Object.keys(updatedFields ?? {});
  const values: { from: string; to: string }[] = Object.values(updatedFields ?? {});
  const userName = (
    <span data-testid={"userName"}>
      {user.firstName} {user.lastName}
    </span>
  );

  return (
    <Box className={"updated-fields"} data-testid={"user-updated"}>
      {updated.length === 0 && <b data-testid={"no-changes"}>{userName}</b>}
      {updated.map((key: string, index) => (
        <Fragment key={user.firstName + user.lastName + key}>
          {(() => {
            switch (key) {
              case "contractEndDate":
                return (
                  <div data-testid={"contractEndDate"}>
                    <b>{userName}</b> {expectedColumnLabels[key as TExpectedColumn].toLowerCase()}{" "}
                    was updated from{" "}
                    <span data-testid={"contractEndDate-from"}>
                      {formattedDateOrInvalid(values[index]?.from, unit?.timezone as Timezone)}
                    </span>{" "}
                    to{" "}
                    <span data-testid={"contractEndDate-to"}>
                      {formattedDateOrInvalid(values[index]?.to, unit?.timezone as Timezone)}
                    </span>{" "}
                    .
                  </div>
                );
              case "isSoftDeleted":
                return <></>;
              case "softDeletedDate":
                const softDeletedDate = user.softDeletedDate ?? values[index]?.to ?? "";
                return (
                  <div data-testid={"softDeletedDate"}>
                    <b>{userName}</b> was suspended. Termination date{" "}
                    <span data-testid={"softDeletedDate-to"}>
                      {formattedDateOrInvalid(softDeletedDate, unit?.timezone as Timezone)}
                    </span>
                    .
                  </div>
                );
              default:
                return (
                  <div data-testid={key}>
                    <b>{userName}</b>{" "}
                    {(expectedColumnLabels[key as TExpectedColumn] ?? "").toLowerCase()} was updated{" "}
                    <span data-testid={`${key}-from`}>{values?.[index]?.from}</span> to{" "}
                    <span data-testid={`${key}-to`}>{values?.[index]?.to}</span>.
                  </div>
                );
            }
          })()}
        </Fragment>
      ))}
    </Box>
  );
};
