import { useCallback, useEffect, useMemo, useState } from "react";

import { getTzDayjs, YyyyMmDd } from "@m7-health/shared-utils";
import { compact, entries, isEqual, keyBy, keys, map, pick } from "lodash";

import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { IOpenShift, IOpenShiftRequest, IShiftType } from "@/api";
import { useM7SimpleContext, useToast } from "@/common/hooks";

import { CustomButton, CustomSwitch, MoreInfo } from "../..";
import { CustomModal } from "../../Modal";
import { ShiftV2 } from "../../Shift/ShiftV2";
import { IncentiveLevelDropdown } from "../../ShiftIncentiveLevel";
import { useSaveOpenShifts } from "../hooks/useSaveOpenShifts";
import { TOpenShiftGroup } from "../hooks/useSingleDayOpenShiftGroups";

import { OpenShiftTime } from "./OpenShiftTime";

export type TUpdateSubsetModalProps = {
  openShiftsToUpdate: TOpenShiftGroup[];
  onClose: () => void;
  staffCategoryName: string;
  shiftType: IShiftType;
  date: YyyyMmDd;
  allRequests: IOpenShiftRequest[];
};

export const UpdateSubsetModal = ({
  onClose,
  openShiftsToUpdate,
  staffCategoryName,
  shiftType,
  date,
  allRequests,
}: TUpdateSubsetModalProps) => {
  const { currentTimezone } = useM7SimpleContext();
  const saveOpenShifts = useSaveOpenShifts();
  const { showInfo } = useToast();

  const [uneditableOpenShifts, editableOpenShifts] = useMemo(() => {
    const requestsByOpenShiftId = allRequests.reduce(
      (acc, request) => {
        request.openShiftsRequestedShiftEntities?.forEach((requestedEntity) => {
          (acc[requestedEntity.openShiftId] ||= []).push(request);
        });

        return acc;
      },
      {} as Record<string, IOpenShiftRequest[]>,
    );

    const openShiftsWithRequests: IOpenShift[] = [];
    const openShiftsWithoutRequests: IOpenShift[] = [];

    openShiftsToUpdate.forEach((openShiftGroup) => {
      const openShiftIds = map(openShiftGroup.openShifts, "id");
      const hasRequests = keys(pick(requestsByOpenShiftId, openShiftIds)).flat().length;
      if (hasRequests) openShiftsWithRequests.push(...openShiftGroup.openShifts);
      else openShiftsWithoutRequests.push(...openShiftGroup.openShifts);
    });

    // Open shifts with requests are uneditable
    return [openShiftsWithRequests, openShiftsWithoutRequests];
  }, [openShiftsToUpdate, allRequests]);

  const [stateOpenShifts, setStateOpenShifts] = useState<IOpenShift[]>(editableOpenShifts);
  const [selectedOpenShifts, setSelectedOpenShifts] = useState<Record<string, boolean>>({});
  const [selectedShiftIncentiveLevelId, setSelectedShiftIncentiveLevelId] = useState<string | null>(
    null,
  );
  const [selectedNote, setSelectedNote] = useState<string | null>(null);
  const atLeastOneSelected = Object.values(selectedOpenShifts).some(Boolean);

  useEffect(() => {
    setStateOpenShifts(editableOpenShifts);
  }, [editableOpenShifts]);

  const onSubmit = useCallback(async () => {
    const openShiftsById = keyBy(stateOpenShifts, "id");
    const originalOpenShiftsById = keyBy(openShiftsToUpdate, "id");

    const changedOpenShifts = compact(
      entries(openShiftsById).map(([id, openShift]) => {
        const originalOpenShift = originalOpenShiftsById[id];
        if (isEqual(openShift, originalOpenShift)) return null;

        return openShift;
      }),
    );

    await saveOpenShifts(changedOpenShifts);
    onClose();
  }, [stateOpenShifts, openShiftsToUpdate, saveOpenShifts, onClose]);

  const applyBulkChanges = useCallback(() => {
    const idsToUpdate = entries(selectedOpenShifts)
      .filter(([_, value]) => value)
      .map(([id]) => id);

    setStateOpenShifts((prev) =>
      prev.map((openShift) => {
        if (idsToUpdate.includes(openShift.id))
          return {
            ...openShift,
            note: selectedNote || "",
            shiftIncentiveLevelId: selectedShiftIncentiveLevelId || null,
          };
        return openShift;
      }),
    );

    setSelectedOpenShifts({});
    setSelectedShiftIncentiveLevelId(null);
    setSelectedNote(null);

    showInfo("Changes applied");
  }, [selectedOpenShifts, showInfo, selectedNote, selectedShiftIncentiveLevelId]);

  return (
    <CustomModal
      isOpen={true}
      onClose={onClose}
      modalHeaderText={`Update Open Shifts`}
      onSecondaryBtnClick={onClose}
      onSubmitAsync={onSubmit}
      trackingLabel={"edit-ops-attributes"}
    >
      <Box className="update-open-shifts-modal-content">
        <Typography>
          Update {staffCategoryName}
          <ShiftV2 shiftType={shiftType} variant="medium" sx={{ mx: 1 }} />
          Open Shifts on {getTzDayjs(date, currentTimezone).format("MMM DD, YYYY")}
        </Typography>
        {stateOpenShifts.length > 0 && (
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1, fontStyle: "italic" }}>
            Use select boxes to update multiple open shifts at once.
          </Typography>
        )}
        {atLeastOneSelected && (
          <Box sx={{ p: "20px", border: "1px solid #e0e0e0", borderRadius: "5px", my: 2, mb: 4 }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <IncentiveLevelDropdown
                width="200px"
                label="Incentive"
                value={selectedShiftIncentiveLevelId || ""}
                onChange={(event) => setSelectedShiftIncentiveLevelId(event.target.value || null)}
              />
              <TextField
                label="Note"
                size="small"
                maxRows={2}
                value={selectedNote}
                multiline
                sx={{ width: 300, marginLeft: "15px !important", marginRight: "15px !important" }}
                variant="standard"
                onChange={(event) => setSelectedNote(event.target.value)}
              />
              <Box flexGrow={1} />
              <CustomButton label="Apply" onClick={applyBulkChanges} />
            </Stack>
            {/* <Divider /> */}
          </Box>
        )}
        <TableContainer component={Paper} className="update-open-shifts-list" sx={{ mt: 2 }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className="multi-select-cell" />
                <TableCell>Shift Time</TableCell>
                <TableCell>Incentive</TableCell>
                <TableCell>Note</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stateOpenShifts.map((openShift, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell className="multi-select-cell">
                      <CustomSwitch
                        label=""
                        name={openShift.id}
                        switchVariant="checkbox"
                        trackingLabel={undefined}
                        checked={!!selectedOpenShifts[openShift.id]}
                        onChange={() => {
                          setSelectedOpenShifts((prev) => ({
                            ...prev,
                            [openShift.id]: !prev[openShift.id],
                          }));
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <OpenShiftTime
                        openShift={openShift}
                        shiftType={shiftType}
                        sx={{ width: 100 }}
                      />
                    </TableCell>
                    <TableCell>
                      <IncentiveLevelDropdown
                        label=""
                        value={openShift.shiftIncentiveLevelId || ""}
                        onChange={(event) => {
                          setStateOpenShifts((prev) =>
                            prev.map((prevOpenShift) => {
                              if (prevOpenShift.id === openShift.id)
                                return {
                                  ...prevOpenShift,
                                  shiftIncentiveLevelId: event.target.value || null,
                                };
                              return prevOpenShift;
                            }),
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        size="small"
                        maxRows={2}
                        value={openShift.note}
                        multiline
                        sx={{
                          width: 300,
                          marginLeft: "15px !important",
                          marginRight: "15px !important",
                        }}
                        variant="standard"
                        onChange={(event) => {
                          setStateOpenShifts((prev) =>
                            prev.map((prevOpenShift) => {
                              if (prevOpenShift.id === openShift.id)
                                return { ...prevOpenShift, note: event.target.value };
                              return prevOpenShift;
                            }),
                          );
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
              {uneditableOpenShifts.length > 0 && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Typography variant="body2" color="error" sx={{ mt: 1, fontStyle: "italic" }}>
                      Open shifts with pending requests can't be updated. Approve or decline the
                      requests to continue.
                    </Typography>
                  </TableCell>
                </TableRow>
              )}

              {uneditableOpenShifts.map((openShift, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      <MoreInfo title="Cannot update open shift that has requests" />
                    </TableCell>
                    <TableCell>
                      <OpenShiftTime
                        openShift={openShift}
                        shiftType={shiftType}
                        sx={{ width: 100 }}
                      />
                    </TableCell>
                    <TableCell>
                      <IncentiveLevelDropdown
                        disabled={true}
                        label=""
                        value={openShift.shiftIncentiveLevelId || ""}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        disabled={true}
                        label=""
                        size="small"
                        maxRows={2}
                        value={openShift.note}
                        multiline
                        sx={{
                          width: 300,
                          marginLeft: "15px !important",
                          marginRight: "15px !important",
                        }}
                        variant="standard"
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </CustomModal>
  );
};
