import { useMemo } from "react";

import { EUnitPermissionAreas, getTzFormattedDate, Timezone } from "@m7-health/shared-utils";
import { intersection, isEqual, map } from "lodash";

import { PersonAdd } from "@mui/icons-material";
import { Box, lighten, Stack, Typography } from "@mui/material";

import {
  INote,
  IScheduleShiftType,
  IStaffDetails,
  IStaffShift,
  IStaffType,
  StaffShift,
} from "~/api";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { brandPurple, darkPurple, white } from "~/common/theming/colors";
import { KeyBy } from "~/common/types";
import { useAppConfigQuery } from "~/features/User/queries";
import { IUnitBasic } from "~/routes/api/types";

import { useAppFlags, useCheckUserPermission, useCurrentRole, useKeyBy } from "@/common/hooks";
import { voidingShiftStatus } from "@/common/utils/shifts";

import { houseViewStore } from "../store";

import { STAFF_ITEMS_LAYOUTS } from "./SideBar";
import { StaffItem } from "./StaffItem";

export const UnitCardStaff = ({
  unitId,
  notes,
  notesFromStaff,
  staffDetails,
  shift,
  shiftType,
  withOtherShifts = false,
}: {
  unitId: IUnitBasic["id"];
  notes: KeyBy<INote, "userId">;
  notesFromStaff: KeyBy<INote, "userId">;
  staffDetails: KeyBy<IStaffDetails, "userId">;
  shift: IStaffShift;
  shiftType?: IScheduleShiftType;
  withOtherShifts?: boolean;
}) => {
  const { userIsKiosk } = useCurrentRole();
  const canManage = useCheckUserPermission("manage", EUnitPermissionAreas.houseView);
  const { hvPositionAsTab } = useAppFlags();

  const { id, staffId } = shift;

  const {
    selectedStaffCategories,
    selectedDate,
    floatingInProgress,
    selectedUnitId,
    selectedStaffId,
  } = useAppSelector(
    (state) => ({
      selectedStaffCategories: state.houseView.pageFilters.selectedStaffCategories,
      selectedDate: state.houseView.pageFilters.selectedDateForData,
      floatingInProgress: state.houseView.sidebarCurrentAction.inProgress === "floating",
      selectedUnitId: state.houseView.pageFilters.selectedUnitId,
      selectedStaffId: state.houseView.floating?.staffShift?.staffId,
    }),
    isEqual,
  );
  const dispatch = useAppDispatch();

  const details = staffDetails[staffId];
  const selectedUnit = useAppConfigQuery().data?.accessibleUnits?.find(
    ({ id: aUnitId }) => aUnitId === selectedUnitId,
  );
  const unitPositions = useKeyBy(selectedUnit?.attributes, "key");
  const staffPositions = useMemo(
    () => map(details?.attributeKeys, (key) => unitPositions[key]?.name.toLocaleLowerCase()),
    [details, unitPositions],
  );
  const shiftDate =
    selectedUnit && getTzFormattedDate(shift.date, selectedUnit.timezone as Timezone);

  if (!shift || !details) return null;

  const {
    user: { rosters },
    staffType: { key: staffTypeKey, staffCategoryKey },
  } = details;
  const unitIds = map(rosters, "unitId");

  const voidedShiftStatus = voidingShiftStatus(shift) && shift.status;
  const positionEligible = hvPositionAsTab
    ? intersection(selectedStaffCategories, staffPositions).length > 0
    : false;

  const highlightIfEligible =
    !!selectedUnitId &&
    floatingInProgress &&
    selectedUnitId !== unitId &&
    !voidedShiftStatus &&
    (selectedStaffCategories.includes(staffCategoryKey) ||
      positionEligible ||
      !selectedStaffCategories.length) &&
    unitIds.includes(selectedUnitId) &&
    shiftDate === selectedDate;

  if (
    !shiftType || // we hide no shift
    (!shiftType.isCountedForRealTimeStaffingTarget && !withOtherShifts) || // we hide not counted for real time staffing target, except if with other shifts
    staffTypeKey === ("houseSupervisor" as IStaffType["key"]) // we hide house supervisor
  )
    return null;

  const selectFloatShift = () => {
    dispatch(houseViewStore.state.setFloatShift({ id, staffId }));
  };

  const selectEditShift = () => {
    dispatch(houseViewStore.state.startEditShifts({ staffId }));
  };

  return (
    <StaffItem
      shift={shift}
      note={notes[shift.staffId]}
      noteFromStaff={notesFromStaff[shift.staffId]}
      staffDetails={details}
      unitId={unitId}
      onClick={(_event, event) => {
        event.stopPropagation();

        if (floatingInProgress) {
          if (!userIsKiosk && canManage && highlightIfEligible) selectFloatShift();
        } else {
          selectEditShift();
        }
      }}
      selectable={highlightIfEligible}
      selected={staffId === selectedStaffId}
      layout={[
        ...STAFF_ITEMS_LAYOUTS.unitCard,
        ...(highlightIfEligible ? ["lastStatusChange" as const] : []),
      ]}
      lastStatusType={StaffShift.EStatus.floated}
      disabled={floatingInProgress && !highlightIfEligible}
      sx={{
        ...(floatingInProgress
          ? {
              padding: "2px",
              backgroundColor: white + " !important",
              ...(highlightIfEligible
                ? {
                    backgroundColor: lighten(brandPurple, 0.9) + " !important",
                    margin: "5px 0",
                  }
                : {}),
              ...(staffId === selectedStaffId
                ? {
                    border: `2px solid ${darkPurple}`,
                    backgroundColor: lighten(darkPurple, 0.9) + " !important",
                  }
                : {}),
            }
          : {}),
        ".main-items": {
          alignItems: "center",
        },
      }}
    >
      {highlightIfEligible && (
        <Stack direction={"row"} alignItems={"center"} className="floating-eligible-tag">
          <Box flexGrow={1} />
          <Typography
            borderRadius={2}
            variant="small"
            fontSize={13}
            sx={{
              backgroundColor: brandPurple,
              display: "flex",
              alignItems: "center",
              height: "10px",
              padding: "5px",
              borderRadius: "5px",
              ml: 1,
              opacity: 0.8,
            }}
          >
            <PersonAdd fontSize="small" />
            Eligible
          </Typography>
        </Stack>
      )}
    </StaffItem>
  );
};

export const __HouseViewUnitCardStaff = UnitCardStaff;
