import { useMemo } from "react";

import { getTzDayjs, YyyyMmDd } from "@m7-health/shared-utils";

import { useListWorkingHoursQuery } from "@/api";
import { useCurrentTimezone } from "@/common/hooks/useCurrentTimezone";

/**
 * Custom hook to retrieve the total scheduled working hours for a specific staff member
 * within the week of a given date.
 *
 * @param {string} staffId - The unique identifier of the staff member.
 * @param {YyyyMmDd} dateOfShift - The date of the shift in 'YYYY-MM-DD' format.
 * @returns {number} - The total scheduled working hours for the staff member in the specified week.
 *
 * This hook uses the current timezone to calculate the start and end of the week
 * for the given date. It then queries the working hours for the staff member within
 * this date range and returns the total hours in decimal format.
 */
export const useGetStaffWorkingHours = (staffId: string, dateOfShift: YyyyMmDd) => {
  const currentTimezone = useCurrentTimezone();

  const beginningOfWeek = getTzDayjs(dateOfShift, currentTimezone)
    .startOf("week")
    .toDate()
    .toISOString();
  const endOfWeek = getTzDayjs(dateOfShift, currentTimezone).endOf("week").toDate().toISOString();

  const { data: staffWorkingHours } = useListWorkingHoursQuery({
    staffIds: [staffId],
    dateRanges: [
      {
        from: beginningOfWeek,
        to: endOfWeek,
      },
    ],
    shiftType: {
      isWorkingShift: true,
      isPaidShift: true,
    },
  });

  return useMemo(() => {
    return (staffWorkingHours?.[0]?.cumulatedSeconds || 0) / 3600;
  }, [staffWorkingHours]);
};
