import { IUnit } from "@/api";
import { useMap } from "@/common/hooks";

import { useUserTableUnitData } from "../../ContextProvider";

import { MultiDropdownEditor, MultiDropdownRenderer } from "./Dropdown";
import { TCellComponent } from "./types";

const useUnitsOptions = () => {
  const units = useUserTableUnitData().units.values;

  return useMap(units, (unit) => ({
    label: unit.name + (unit.archivedAt ? " (archived)" : ""),
    value: unit.id,
  }));
};

const FloatingUnitsEditor: TCellComponent<IUnit["id"][]>["editor"] = (params) => (
  <MultiDropdownEditor<IUnit["id"]> options={useUnitsOptions()} {...params} />
);

const FloatingUnitsRenderer: TCellComponent<IUnit["id"][]>["readonly"] = (params) => (
  <MultiDropdownRenderer options={useUnitsOptions()} {...params} />
);

export const FloatingUnitsCell: TCellComponent<IUnit["id"][]> = {
  readonly: FloatingUnitsRenderer,
  editor: FloatingUnitsEditor,
};
