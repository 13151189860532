import { getTzDate, Timezone } from "@m7-health/shared-utils";
import { DateTime } from "luxon";

import { EDateFormats } from "~/modules/luxon/formats";

export const formattedDateOrInvalid = (date?: string, timezone?: Timezone) => {
  return date
    ? getTzDate(DateTime.fromISO(date), timezone).toFormat(EDateFormats.MmDdYyyy)
    : "Invalid Date";
};
