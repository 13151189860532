import { useEffect } from "react";

import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Box } from "@mui/material";

import { useAppSelector } from "@/common/hooks";

export const ScheduleScroller = () => {
  const isSidebarExpanded = useAppSelector(
    (state) => state.houseView.pageFilters.isSidebarExpanded,
  );

  useEffect(() => {
    const cleanup = setupScrollHandlers();
    return cleanup;
  }, []);

  return (
    <Box className={`scroll-schedule-wrapper ${isSidebarExpanded ? "sidebar-open" : ""}`}>
      <Box className="scroll-schedule left-scroll" onClick={() => scrollWeekViewTable("left")}>
        <ArrowBackIos />
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box className="scroll-schedule right-scroll" onClick={() => scrollWeekViewTable("right")}>
        <ArrowForwardIos />
      </Box>
    </Box>
  );
};

const scrollWeekViewTable = (direction: "left" | "right") => {
  const tableToScroll = document.querySelector(".week-view-table-wrapper");

  // Calculate the scroll amount based on the viewport width and number of days visible
  const tableWidth = document.documentElement.getBoundingClientRect().width - 35;
  const scrollAmount = (direction === "left" ? -tableWidth : tableWidth) / 5;

  if (tableToScroll) {
    const currentScrollLeft = tableToScroll.scrollLeft;
    tableToScroll.scrollTo({
      left: currentScrollLeft + scrollAmount,
      behavior: "smooth",
    });
  }
};

// On scroll, show/hide the scroll buttons based on the position of the table
const setupScrollHandlers = () => {
  const tableToScroll = document.querySelector(".week-view-table-wrapper");

  const onScroll = () => {
    if (!tableToScroll) return;

    // If far left, hide left scroll button
    if (tableToScroll.scrollLeft === 0)
      document.querySelector(".scroll-schedule.left-scroll")?.classList.add("hidden");
    else document.querySelector(".scroll-schedule.left-scroll")?.classList.remove("hidden");

    // If far right, hide right scroll button. With a 25px buffer
    if (tableToScroll.scrollLeft + tableToScroll.clientWidth >= tableToScroll.scrollWidth - 25)
      document.querySelector(".scroll-schedule.right-scroll")?.classList.add("hidden");
    else document.querySelector(".scroll-schedule.right-scroll")?.classList.remove("hidden");
  };

  if (tableToScroll) tableToScroll.addEventListener("scroll", onScroll);

  return () => {
    if (tableToScroll) tableToScroll.removeEventListener("scroll", onScroll);
  };
};
