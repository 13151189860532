import { useCallback } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { isEqual, upperFirst } from "lodash";

import { Box, Grid, Typography } from "@mui/material";

import CustomModal from "~/common/components/Modal";
import { useErrors } from "~/common/hooks/useErrors";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { useToast } from "~/common/hooks/useToast";
import { pxToRem } from "~/common/utils/pxToRem";

import {
  useInvalidateQuery,
  useListOpenShiftsQuery,
  useListOrphanOpenShiftRequestsQuery,
  useRejectOpenShiftRequestMutation,
} from "@/api";

import { openShiftsSidebarActions } from "../../store";

import { IRejectOpenShiftModalProps } from "./types";

export const RejectOpenShiftModal = ({
  firstName,
  lastName,
  requestId,
  onClose,
}: IRejectOpenShiftModalProps) => {
  const dispatch = useAppDispatch();
  const { showSuccess } = useToast();
  const { handleErrors } = useErrors();
  const queryClient = useQueryClient();
  const invalidateQuery = useInvalidateQuery();

  const { selectedUnitId, selectedScheduleId } = useAppSelector(
    (state) => ({
      selectedUnitId: state.common.currentUnit?.id || undefined,
      selectedScheduleId: state.openShifts.selectedScheduleId || undefined,
    }),
    isEqual,
  );

  const { mutate: rejectOpenShifts, isPending: isApprovingPending } =
    useRejectOpenShiftRequestMutation({
      onSuccess: () => {
        dispatch(openShiftsSidebarActions.setRejectOpenShiftModalOpen(false));
        showSuccess("The open shift was rejected successfully");
        //Keep old query invalidation for usage on open shifts page
        void queryClient.invalidateQueries({ queryKey: ["openShifts", selectedScheduleId] });
        invalidateQuery([useListOpenShiftsQuery, useListOrphanOpenShiftRequestsQuery]);
        onClose();
      },
      onError: handleErrors,
      onSettled: () => {
        void queryClient.invalidateQueries({
          queryKey: ["openShiftsRequestsMetadata", selectedUnitId],
        });
      },
    });

  const rejectOpenShiftHandler = useCallback(() => {
    rejectOpenShifts({
      requestId,
    });
  }, [rejectOpenShifts, requestId]);

  const modalContent = (
    <Grid container flexDirection="column" mt="-20px">
      <Grid item my={2}>
        <Grid container>
          <Box
            sx={{
              fontSize: pxToRem(24),
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            <Typography fontSize={pxToRem(24)} sx={{ display: "inline" }} fontWeight={500}>
              You are about to decline {upperFirst(firstName)} {upperFirst(lastName)}'s open shift
              request.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <CustomModal
      isOpen={true}
      primaryBtnText="Decline Request"
      modalContent={modalContent}
      modalHeaderText=""
      onSecondaryBtnClick={onClose}
      onSubmit={rejectOpenShiftHandler}
      primaryDisabled={isApprovingPending}
      trackingLabel={"ops-decline"}
    />
  );
};
