import { useMemo } from "react";

import { getTzDate, getTzDayjs, ISODateString, toISO, YyyyMmDd } from "@m7-health/shared-utils";
import { keyBy, map, uniq } from "lodash";

import {
  ISchedule,
  IUser,
  ShiftTypeHelpers,
  useListNotesQuery,
  useListPreferenceRequestsQuery,
  useListScheduleShiftTypeQuery,
  useListStaffDetailsQuery,
  useListStaffShiftsQuery,
  useListUnitShiftTypeQuery,
} from "~/api";
import { NOT_EXISTING_UUID } from "~/common/constants";
import { useCurrentFacilityId } from "~/common/hooks/useCurrentFacilityId";
import { useAppSelector } from "~/common/hooks/useRedux";
import { AnyDate } from "~/common/types";
import { getDateInUtcIsoFormat, getDatesInRange, timeAdd, timeOverlap } from "~/common/utils/dates";

import { useCurrentTimezone, useDeepMemo, useMapBy } from "@/common/hooks";

import { HouseViewHooks } from "../../../features/HouseView/hooks";
import { DEFAULT_CUSTOM_TIME_RANGE } from "../../../features/HouseView/store/pageFiltersActions";

const { useSelectedDay } = HouseViewHooks;

export const StaffCalendarHooks = {
  useCalendarDays: ({
    selectedDay,
    weeksAround,
  }: {
    selectedDay: AnyDate;
    weeksAround: number;
  }) => {
    const unitId = useAppSelector((state) => state.houseView.pageFilters.selectedUnitId);
    const timezone = useCurrentTimezone(unitId);
    const selectedDayJs = useMemo(() => getTzDayjs(selectedDay, timezone), [selectedDay, timezone]);

    return useMemo(() => {
      const startDateToDisplay = selectedDayJs.addInTz(-7 * weeksAround, "day").startOf("week");
      const endDateToDisplay = selectedDayJs.addInTz(7 * weeksAround, "day").endOf("week");
      const datesInRange = getDatesInRange(startDateToDisplay, endDateToDisplay);

      return datesInRange;
    }, [selectedDayJs, weeksAround]);
  },

  useSchedulesShiftTypes: (scheduleIds: ISchedule["id"][]) => {
    const memoizedScheduleIds = useDeepMemo(() => uniq(scheduleIds).sort(), [scheduleIds]);

    const { data: scheduleShiftTypes = [] } = useListScheduleShiftTypeQuery(
      { scheduleIds: memoizedScheduleIds },
      { skip: !memoizedScheduleIds.length },
    );
    const scheduleShiftTypeIds = useDeepMemo(
      () => uniq(map(scheduleShiftTypes, "id")).sort(),
      [scheduleShiftTypes],
    );

    return useMemo(() => {
      return ShiftTypeHelpers.byScheduleIdByShiftTypeKey(scheduleShiftTypes);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scheduleShiftTypeIds]);
  },

  useUnitShiftTypes: (unitIds: ISchedule["id"][]) => {
    const { data: unitShiftTypes = [] } = useListScheduleShiftTypeQuery(
      {
        scheduleIds: unitIds,
      },
      { skip: !unitIds.length },
    );
    return useMemo(() => {
      return ShiftTypeHelpers.byScheduleIdByShiftTypeKey(unitShiftTypes);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map(unitShiftTypes, "id").sort()]);
  },

  useStaffUnitShiftTypes: (staffId: IUser["id"]) => {
    // Retrieve unit id from staff. We only care about the home unit, because
    //  schedule shifts has their own schedule shifts types. Unit shifts types
    //  are used for preferences
    const { data: currentUserDetailsArray } = useListStaffDetailsQuery({
      staffIds: [staffId],
    });
    const currentUserDetails = currentUserDetailsArray?.[0];

    const { data: unitShiftTypes = [] } = useListUnitShiftTypeQuery(
      {
        unitIds: [currentUserDetails?.homeUnitId ?? NOT_EXISTING_UUID],
      },
      { skip: !currentUserDetails?.homeUnitId },
    );

    return useMemo(() => keyBy(unitShiftTypes, "key"), [unitShiftTypes]);
  },

  useStaffShifts: (
    staffId: IUser["id"],
    {
      from,
      to,
    }: {
      from: ISODateString;
      to: ISODateString;
    },
    skip = false,
  ) => {
    const { data: shifts } = useListStaffShiftsQuery(
      {
        date: [
          { operator: "gte", value: from },
          { operator: "lte", value: to },
        ],
        staffIds: [staffId],
      },
      { skip: skip || !staffId },
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => shifts, [map(shifts, "id")]);
  },

  useStaffNotes: (
    staffId: IUser["id"],
    { from, to }: { from: YyyyMmDd; to: YyyyMmDd },
    skip = false,
  ) => {
    const { data: notes } = useListNotesQuery(
      {
        staffIds: [staffId],
        date: [
          { operator: "gte", value: from },
          { operator: "lte", value: to },
        ],
      },
      { skip: skip || !staffId },
    );

    return useMemo(() => keyBy(notes, ({ date }) => getDateInUtcIsoFormat(date)), [notes]);
  },

  usePreferences: (
    staffId: IUser["id"],
    { from, to }: { from: YyyyMmDd; to: YyyyMmDd },
    skip = false,
  ) => {
    const { data: preferences } = useListPreferenceRequestsQuery(
      {
        date: [
          { operator: "gte", value: from },
          { operator: "lte", value: to },
        ],
        staffIds: [staffId],
      },
      { skip: skip || !staffId },
    );

    return useMemo(
      () => keyBy(preferences, ({ date }) => getDateInUtcIsoFormat(date)),
      [preferences],
    );
  },

  /**
   * Returns staff shifts filtered by time range based on the current application state (overlapping with the custom time range).
   *
   * @returns {Object} An object containing the filtered staff shifts and loading state.
   * @property {IStaffShift[]} data - The filtered array of staff shifts.
   * @property {boolean} staffShiftsAreLoading - Indicates whether the staff shifts are still loading.
   *
   * @remarks
   * This hook uses various application states and flags to determine how to filter the shifts:
   * - ** It returns all shifts that overlap with the selected custom time range.
   * - Otherwise, it uses the selectedTimeRange (day7A7P, night7P7A, or all).
   *
   * The function handles various edge cases, including:
   * - Partial night shifts that start after midnight
   * - Custom start times and durations for shifts
   * - Overlapping time ranges
   *
   * It also accounts for different time formats and removes milliseconds from time strings for consistency.
   */
  useFilteredStaffShifts: () => {
    const { dataValue: selectedDay } = useSelectedDay();
    const selectedFacilityId = useCurrentFacilityId();
    const selectedUnitId = useAppSelector((state) => state.houseView.pageFilters.selectedUnitId);
    const timezone = useCurrentTimezone(selectedUnitId);
    const selectedCustomTimeRange =
      useAppSelector((state) => state.houseView.pageFilters.customTimeRange) ||
      DEFAULT_CUSTOM_TIME_RANGE;

    const luxonDate = useMemo(() => getTzDate(selectedDay, timezone), [selectedDay, timezone]);
    const { data: unfilteredStaffShifts = [], isLoading: staffShiftsAreLoading } =
      useListStaffShiftsQuery(
        {
          date: [
            { value: toISO(luxonDate.startOf("day").toJSDate()), operator: "gte" },
            { value: toISO(luxonDate.endOf("day").toJSDate()), operator: "lte" },
          ],
        },
        { skip: !selectedFacilityId },
      );
    const scheduleIds = useMapBy(unfilteredStaffShifts, "scheduleId");
    const scheduleShiftTypes = self.useSchedulesShiftTypes(scheduleIds);

    const staffShifts = useMemo(() => {
      if (selectedCustomTimeRange.customAbbreviation === "All") return unfilteredStaffShifts;

      return unfilteredStaffShifts.filter((shift) => {
        const shiftType = scheduleShiftTypes?.[shift.scheduleId]?.[shift.shiftTypeKey];
        if (!shiftType) return false;

        const startTime = shift.customStartTime || shiftType.startTime;
        const endTime = timeAdd(startTime, shift.customDuration || shiftType.durationSeconds);

        // return the shifts that overlap with the selected custom time range
        return timeOverlap(
          { from: startTime, to: endTime, originalFrom: shiftType.startTime },
          { from: selectedCustomTimeRange.startTime, to: selectedCustomTimeRange.endTime },
        );
      });
    }, [
      selectedCustomTimeRange.customAbbreviation,
      selectedCustomTimeRange.startTime,
      selectedCustomTimeRange.endTime,
      unfilteredStaffShifts,
      scheduleShiftTypes,
    ]);
    return { data: staffShifts, staffShiftsAreLoading };
  },
};

const self = StaffCalendarHooks;
