import { useMemo } from "react";

import { entries, get, isEqual, uniq, values } from "lodash";

import { useAppConfigQuery } from "#/features/User/queries";
import {
  IOpenShift,
  IOpenShiftRequest,
  ISchedule,
  IShiftType,
  IStaffCategory,
  StaffCategory,
} from "@/api";
import { NOT_EXISTING_UUID } from "@/common/constants";
import { useAppSelector, useKeyBy } from "@/common/hooks";

import { TOpenShiftsSidebarFilters } from "../types";

type TOrganizedOpenShift = {
  scheduleId: ISchedule["id"];
  shiftTypeKey: IShiftType["key"];
  staffCategoryKey: IStaffCategory["key"];
  openShifts: IOpenShift[];
  openShiftRequests: IOpenShiftRequest[];
  unitName?: string;
};

export const useDataForSingleDay = () => {
  const units = useAppConfigQuery().data?.accessibleUnits;
  const unitsById = useKeyBy(units, "id");

  const {
    selectedDate,
    sidebarFilters,
    schedules,
    shiftTypesByScheduleId,
    staffDetails,
    targetCounts,
    openShiftsByDate,
    targets,
  } = useAppSelector((state) => {
    const date = state.openShiftsSidebar.selectedDate;
    return {
      selectedDate: date,
      sidebarFilters: state.openShiftsSidebar.filters,
      schedules: state.openShiftsSidebar.data.schedules,
      shiftTypesByScheduleId: state.openShiftsSidebar.data.shiftTypes,
      staffDetails: state.openShiftsSidebar.data.staffDetails,
      openShiftsByDate: date
        ? state.openShiftsSidebar.data.openShiftsByDate[date]?.byScheduleIdCategoryAndShiftType
        : undefined,
      targetCounts: state.openShiftsSidebar.data.targetLevelCounts,
      targets: state.openShiftsSidebar.data.targetLevels,
    };
  }, isEqual);

  const filteredSchedules = useMemo(() => {
    if (!selectedDate) return [];

    return schedules.filter(
      (schedule) => schedule.startDay <= selectedDate && schedule.endDay >= selectedDate,
    );
  }, [schedules, selectedDate]);

  const schedulesById = useKeyBy(filteredSchedules, "id");
  const staffCategoryOptions = useMemo(
    () => uniq(values(staffDetails).map((details) => details.staffType.staffCategory.key)),
    [staffDetails],
  );

  const openShiftDayData = useMemo(() => {
    if (!openShiftsByDate || !selectedDate) return null;

    const organizedOpenShifts: TOrganizedOpenShift[] = [];

    //Get all open shifts for the selected day and construct an object with key details
    entries(targets).forEach(([scheduleId, { byDate }]) => {
      // If the schedule is not in the provided params,
      if (!(scheduleId in schedulesById)) return;

      const dateTargets = byDate[selectedDate];
      const openShiftsByStaffCategories = openShiftsByDate[scheduleId];
      const indexedOpenShifts: Record<
        ISchedule["id"],
        Partial<Record<IStaffCategory["key"], Record<IShiftType["key"], boolean>>>
      > = {};
      entries(openShiftsByStaffCategories).forEach(([staffCategoryKey, shiftTypes]) => {
        entries(shiftTypes).forEach(([shiftTypeKey, shiftTypeData]) => {
          ((indexedOpenShifts[scheduleId] ||= {})[staffCategoryKey] ||= {})[shiftTypeKey] = true;

          const { openShifts, openShiftRequests } = shiftTypeData;
          const openShiftData = {
            scheduleId: scheduleId as ISchedule["id"],
            shiftTypeKey,
            staffCategoryKey,
            openShifts,
            openShiftRequests,
            unitName: unitsById[schedulesById[scheduleId]?.unitId || ""]?.name,
          };
          if (
            showOpenShift(
              openShiftData,
              sidebarFilters,
              schedulesById,
              staffCategoryOptions,
              shiftTypesByScheduleId,
            )
          )
            organizedOpenShifts.push(openShiftData);
        });
      });

      // For each target level with no open shifts, create an empty open shift group.
      entries(dateTargets).forEach(([_targetLevelKey, target]) => {
        const { shiftTypeKey, staffCategoryKey } = target;
        // if aggregated, or attribute target, skip
        if (target.attributeKey || (get(target, "shiftTypeKeys") || []).length > 1) return;
        if (!staffCategoryKey) return;

        // Check if open shifts exist for this shift type and staff category.
        // If so, no need to create an empty open shift.
        const openShift = indexedOpenShifts[scheduleId]?.[staffCategoryKey]?.[shiftTypeKey];
        if (openShift) return;

        const emptyOpenShift = {
          scheduleId: scheduleId as ISchedule["id"],
          shiftTypeKey,
          staffCategoryKey,
          openShifts: [] as IOpenShift[],
          openShiftRequests: [] as IOpenShiftRequest[],
          unitName: unitsById[schedulesById[scheduleId]?.unitId || ""]?.name,
        };
        if (
          showOpenShift(
            emptyOpenShift,
            sidebarFilters,
            schedulesById,
            staffCategoryOptions,
            shiftTypesByScheduleId,
          )
        ) {
          organizedOpenShifts.push(emptyOpenShift);
          ((indexedOpenShifts[scheduleId] ||= {})[staffCategoryKey] ||= {})[shiftTypeKey] = true;
        }
      });
    });

    // Sort by staff category, then shift type, then schedule
    organizedOpenShifts.sort((a, b) => {
      if (a.staffCategoryKey === b.staffCategoryKey) {
        return a.shiftTypeKey.localeCompare(b.shiftTypeKey);
      }
      return a.staffCategoryKey.localeCompare(b.staffCategoryKey);
    });

    const organizedOpenShiftsByUnitName: Record<string, TOrganizedOpenShift[]> =
      organizedOpenShifts.reduce(
        (acc, openShift) => {
          const unitName = openShift.unitName;
          if (!unitName) return acc;
          const unitGroup = (acc[unitName] ||= []);
          unitGroup.push(openShift);
          return acc;
        },
        {} as Record<string, TOrganizedOpenShift[]>,
      );

    return organizedOpenShiftsByUnitName;
  }, [
    openShiftsByDate,
    targets,
    selectedDate,
    sidebarFilters,
    schedulesById,
    staffCategoryOptions,
    shiftTypesByScheduleId,
    unitsById,
  ]);

  const longestLabelLengths: { shiftType: number; staffCategory: number } = useMemo(() => {
    if (!openShiftDayData) return { shiftType: 0, staffCategory: 0 };
    const allOpenShifts = values(openShiftDayData).flat();
    return allOpenShifts.reduce(
      (acc, { shiftTypeKey, scheduleId, staffCategoryKey }) => {
        const shiftType = shiftTypesByScheduleId[scheduleId]?.[shiftTypeKey];
        const staffCategory = StaffCategory.EName[staffCategoryKey];
        if (!shiftType || !staffCategory) return acc;
        return {
          shiftType: Math.max(acc.shiftType, shiftType.scheduleViewDisplayName.length),
          staffCategory: Math.max(acc.staffCategory, staffCategory.length),
        };
      },
      { shiftType: 0, staffCategory: 0 },
    );
  }, [openShiftDayData, shiftTypesByScheduleId]);

  return useMemo(
    () => ({
      openShiftDayData,
      targetCounts,
      shiftTypes: shiftTypesByScheduleId,
      selectedDate: selectedDate,
      longestLabelLengths,
    }),
    [openShiftDayData, targetCounts, shiftTypesByScheduleId, selectedDate, longestLabelLengths],
  );
};

//Filtering function that determines if an open shift fits the criteria to be shown on the sidebar or not
const showOpenShift = (
  openShiftData: TOrganizedOpenShift,
  sidebarFilters: TOpenShiftsSidebarFilters,
  schedulesById: Record<ISchedule["id"], ISchedule>,
  staffCategoryOptions: IStaffCategory["key"][],
  shiftTypes: Record<ISchedule["id"], Record<IShiftType["key"], IShiftType>>,
) => {
  const { shiftTypeKey, staffCategoryKey, scheduleId } = openShiftData;
  let shouldShow = true;

  if (
    (sidebarFilters?.shiftTypes?.length ?? 0) > 0 &&
    !sidebarFilters?.shiftTypes?.includes(shiftTypeKey)
  ) {
    shouldShow = false;
  }
  if (
    (sidebarFilters?.staffCategories?.length ?? 0) > 0 &&
    !sidebarFilters?.staffCategories?.includes(staffCategoryKey)
  ) {
    shouldShow = false;
  }
  if (
    (sidebarFilters?.unitIds?.length ?? 0) > 0 &&
    !sidebarFilters?.unitIds?.includes(schedulesById[scheduleId]?.unitId || NOT_EXISTING_UUID)
  ) {
    shouldShow = false;
  }
  if (!staffCategoryOptions.includes(staffCategoryKey)) {
    shouldShow = false;
  }
  if (!shiftTypes[scheduleId]?.[shiftTypeKey]) {
    shouldShow = false;
  }
  return shouldShow;
};
