import { useCallback, useState } from "react";

import { getTzFormattedDate, YyyyMmDd } from "@m7-health/shared-utils";
import { round } from "lodash";

import { Box, Grid, Tooltip, Typography } from "@mui/material";

import { IScheduleShiftType, Schedule, StaffCategory, Unit } from "~/api";
import { useAppDispatch } from "~/common/hooks/useRedux";
import { Dayjs } from "~/common/packages/dayjs";
import { black, lines } from "~/common/theming/colors";
import { returnStaffedColorForTarget } from "~/features/HouseView/hooks/useStaffingTabs";

import { houseViewStore, THouseViewState } from "#/features/HouseView/store";
import { openShiftsSidebarActions } from "@/common/components/OpenShiftSidebarContent/store";
import { useDeepMemo } from "@/common/hooks";
import { add24Hours, stripMilliseconds } from "@/common/utils/dates";

import { CELL_DIMENSIONS } from "../constants";
import { TTargetLevelData } from "../hooks/useOrganizeTargetLevelDataForSchedules";
import { ITablesData } from "../types";

import { HeaderCellContainer, ShiftTypeWrapper } from "./HeaderCell.styled";
import { ShiftTypeSummaryRows } from "./ShiftTypeSummaryRows";

interface IHeaderCellProps {
  cellDate: Dayjs;
  columnKey: string;
  schedules: Schedule.DTO[];
  tablesData: ITablesData;
  unit: Unit.DTO;
  shiftTypes: { [shiftTypeKey: IScheduleShiftType["key"]]: IScheduleShiftType };
  showSixWeekViewShiftTypeSummaryFF: boolean;
  targetData: TTargetLevelData;
  stateData: {
    selectedStaffCategories: THouseViewState["pageFilters"]["selectedStaffCategories"];
    showTargetLevels: THouseViewState["pageFilters"]["showTargetLevels"];
    customTimeRange: THouseViewState["pageFilters"]["customTimeRange"];
    gridFilters: THouseViewState["pageFilters"]["gridFilters"];
    targetLevelCounts: THouseViewState["pageData"]["targetLevelCounts"];
    targetLevels: THouseViewState["pageData"]["targetLevels"];
  };
}

export const HeaderCell = ({
  cellDate,
  columnKey: _columnKey,
  schedules,
  tablesData,
  unit,
  shiftTypes,
  showSixWeekViewShiftTypeSummaryFF,
  targetData,
  stateData: {
    selectedStaffCategories,
    showTargetLevels: selectedShowTargetLevels,
    customTimeRange: selectedCustomTimeRange,
    gridFilters,
    targetLevelCounts,
    targetLevels,
  },
}: IHeaderCellProps) => {
  /** HIGH LEVEL STATE */
  const dispatch = useAppDispatch();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const attributesToShow = useDeepMemo(() => {
    const assigned = gridFilters.showAssignedAttributeTargetLevels ? ["assigned"] : [];
    const eligible = gridFilters.showEligibleAttributeTargetLevels ? ["eligible"] : [];
    return [...assigned, ...eligible] as ("assigned" | "eligible")[];
  }, [
    gridFilters.showAssignedAttributeTargetLevels,
    gridFilters.showEligibleAttributeTargetLevels,
  ]);

  const resetSidebarAndSelectDateAndUnit = useCallback(
    (formattedDate: YyyyMmDd) => {
      if (showSixWeekViewShiftTypeSummaryFF) {
        dispatch(houseViewStore.state.selectUnit(unit.id));
        dispatch(houseViewStore.state.endAction(true));
        dispatch(houseViewStore.state.selectDateForData(formattedDate));
        dispatch(openShiftsSidebarActions.setSidebarSelectedDate(formattedDate));
      }
    },
    [dispatch, showSixWeekViewShiftTypeSummaryFF, unit.id],
  );

  // facility time ranges
  const allFacilityTimeRanges = unit?.facility?.configuration.settings?.houseViewTimeRanges;
  if (!selectedCustomTimeRange) {
    return null;
  }

  const timeRangesToShow =
    selectedCustomTimeRange.customAbbreviation === "All"
      ? allFacilityTimeRanges
      : [selectedCustomTimeRange];

  // unit time target levels
  const unitTimeTargetLevelsByCategory = unit?.configuration?.data?.timeTargetLevels;

  const unitStaffCategories = unit.staffCategoryKeys.filter(
    (category) => !selectedStaffCategories.length || selectedStaffCategories.includes(category),
  );

  const dayKey: YyyyMmDd = cellDate.format("YYYY-MM-DD") as YyyyMmDd;

  const schedule = schedules.find(
    ({ startDay, endDay, unitId: possibleUnitId }) =>
      startDay <= dayKey && endDay >= dayKey && possibleUnitId === unit.id,
  );

  return (
    <HeaderCellContainer
      flexDirection="column"
      alignItems="flex-start"
      sx={{
        opacity: 1,
        width: CELL_DIMENSIONS.width,
      }}
    >
      <Box
        sx={{
          backgroundColor: "transparent",
          paddingTop: "0px",
          borderTop: "1px solid transparent",
          marginTop: "-7px",
          marginBottom: "-7px",
          height: "54px",
        }}
      ></Box>
      <Grid>
        {(!showSixWeekViewShiftTypeSummaryFF || gridFilters.showTimeTargetLevels) &&
          timeRangesToShow?.map((aRange) => (
            <>
              {unitStaffCategories.map((staffCategoryKey) => {
                const shiftsByDayByRange = tablesData?.[schedule?.id || ""];
                const timeRangeKey = `${aRange.startTime}-${aRange.endTime}`;
                const count = round(
                  shiftsByDayByRange?.[dayKey]?.shiftCountsByStaffCategory?.[timeRangeKey]?.[
                    staffCategoryKey
                  ] || 0,
                  1,
                );

                const matchingTimeTargetLevel = unitTimeTargetLevelsByCategory?.[
                  StaffCategory.EName[staffCategoryKey]
                ]?.find(
                  (aLevel) =>
                    stripMilliseconds(aLevel.startTime) === aRange.startTime &&
                    (stripMilliseconds(aLevel.endTime) === aRange.endTime ||
                      stripMilliseconds(aLevel.endTime) === add24Hours(aRange.endTime)),
                );
                const minCount = matchingTimeTargetLevel?.min;

                const backgroundColor = minCount
                  ? returnStaffedColorForTarget(count, minCount)
                  : "white";

                if (showSixWeekViewShiftTypeSummaryFF) {
                  const gridCell = (
                    <Grid
                      onMouseEnter={() => setIsTooltipOpen(true)}
                      onMouseLeave={() => setIsTooltipOpen(false)}
                      key={`${aRange.startTime}-${aRange.endTime}-${staffCategoryKey}`}
                      container
                      alignItems="center"
                      justifyContent="space-around"
                      sx={{
                        borderTop: `1px solid ${lines}`,
                        height: CELL_DIMENSIONS.height,
                        background: backgroundColor,
                        borderBottom: `0px solid ${lines}`,
                        borderRight: `1px solid ${lines}`,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        resetSidebarAndSelectDateAndUnit(dayKey);
                      }}
                    >
                      <ShiftTypeWrapper>
                        <Typography sx={{ fontSize: "0.7rem", color: black }}>
                          {count}
                          {selectedShowTargetLevels && minCount && `/${minCount}`}
                        </Typography>
                      </ShiftTypeWrapper>
                    </Grid>
                  );

                  if (!isTooltipOpen) return gridCell;

                  return (
                    <Tooltip
                      title={`View ${cellDate?.format("MMM D, YYYY")}`}
                      arrow
                      key={`tooltip-hv-header-${getTzFormattedDate(cellDate)}-${staffCategoryKey}`}
                    >
                      {gridCell}
                    </Tooltip>
                  );
                }
                return (
                  <Grid
                    key={`${aRange.startTime}-${aRange.endTime}-${staffCategoryKey}`}
                    container
                    alignItems="center"
                    justifyContent="space-around"
                    sx={{
                      borderTop: `1px solid ${lines}`,
                      height: "27px",
                      background: backgroundColor,
                      borderBottom: `0px solid ${lines}`,
                      borderRight: `1px solid ${lines}`,
                    }}
                  >
                    <ShiftTypeWrapper>
                      <Typography sx={{ fontSize: "0.7rem", color: black }}>
                        {count}
                        {selectedShowTargetLevels && minCount && `/${minCount}`}
                      </Typography>
                    </ShiftTypeWrapper>
                  </Grid>
                );
              })}
            </>
          ))}
        {gridFilters.showShiftTargetLevels && showSixWeekViewShiftTypeSummaryFF && targetData && (
          <ShiftTypeSummaryRows.Count
            orderedTargets={targetData.orderedTargetLevelKeys}
            targetLevelsByDate={targetLevels[schedule?.id || ""]?.byDate[dayKey]}
            filteredTargets={targetData.filteredTargetLevelsForUnit}
            targetLevelCounts={targetLevelCounts[schedule?.id || ""]?.[dayKey]}
            selectedShowTargetLevels={selectedShowTargetLevels}
            timeRangesToShow={timeRangesToShow}
            shiftTypes={shiftTypes}
            onClick={() => {
              resetSidebarAndSelectDateAndUnit(dayKey);
            }}
            cellDate={cellDate}
          />
        )}
        {gridFilters.showAggregatedShiftTargetLevels && showSixWeekViewShiftTypeSummaryFF && (
          <ShiftTypeSummaryRows.Count
            orderedTargets={targetData.orderedAggregatedTargetLevelKeys}
            filteredTargets={targetData.filteredAggregatedTargetLevelsForUnit}
            aggregated={true}
            targetLevelCounts={targetLevelCounts[schedule?.id || ""]?.[dayKey]}
            selectedShowTargetLevels={selectedShowTargetLevels}
            timeRangesToShow={timeRangesToShow}
            shiftTypes={shiftTypes}
            onClick={() => {
              resetSidebarAndSelectDateAndUnit(dayKey);
            }}
            cellDate={cellDate}
          />
        )}
        {!!attributesToShow.length && showSixWeekViewShiftTypeSummaryFF && (
          <>
            <ShiftTypeSummaryRows.AttributeCount
              attributesToShow={attributesToShow}
              orderedTargets={targetData.orderedTargetLevelKeys}
              filteredTargets={targetData.filteredTargetLevelsForUnit}
              targetLevelCounts={targetLevelCounts[schedule?.id || ""]?.[dayKey]}
              selectedShowTargetLevels={selectedShowTargetLevels}
              timeRangesToShow={timeRangesToShow}
              shiftTypes={shiftTypes}
              onClick={() => {
                resetSidebarAndSelectDateAndUnit(dayKey);
              }}
              cellDate={cellDate}
              targetLevelsByDate={targetLevels[schedule?.id || ""]?.byDate[dayKey]}
            />

            <ShiftTypeSummaryRows.AttributeCount
              attributesToShow={attributesToShow}
              orderedTargets={targetData.orderedAggregatedTargetLevelKeys}
              filteredTargets={targetData.filteredAggregatedTargetLevelsForUnit}
              aggregated={true}
              targetLevelCounts={targetLevelCounts[schedule?.id || ""]?.[dayKey]}
              selectedShowTargetLevels={selectedShowTargetLevels}
              timeRangesToShow={timeRangesToShow}
              shiftTypes={shiftTypes}
              onClick={() => {
                resetSidebarAndSelectDateAndUnit(dayKey);
              }}
              cellDate={cellDate}
            />
          </>
        )}
      </Grid>
    </HeaderCellContainer>
  );
};
