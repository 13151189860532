import { Box, Typography } from "@mui/material";

import { pxToRem } from "@/common/utils/pxToRem";

export const Questions = ({ className }: { className: string }) => {
  return (
    <Box className={className}>
      <Typography className="title">You are ready to use M7!</Typography>
      <Typography textAlign="center" sx={{ mt: 5, fontSize: pxToRem(16) }}>
        Questions? Click the Support tab below.
      </Typography>
    </Box>
  );
};
