import { useCallback, useEffect, useState } from "react";

// expected to be used here
// eslint-disable-next-line deprecate/import
import { EPermissionVerbs, EUnitPermissionAreas } from "@m7-health/shared-utils";
import { values } from "lodash";

import { Close, LockPerson } from "@mui/icons-material";
import {
  Avatar,
  Box,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
} from "@mui/material";

import { User } from "@/api";
import { CustomButton } from "@/common/components";
import {
  useAppDispatch,
  useAppFlags,
  useCheckUserPermissions,
  useM7SimpleContext,
} from "@/common/hooks";
import { resetPermissionsOverrides, setPermissions } from "@/common/store";
import { isOnMobile } from "@/common/utils/isOnMobile";

import "./PermissionsFAB.scss";

export const PermissionsFAB = () => {
  const dispatch = useAppDispatch();
  const showFab = useAppFlags().showFlagFab;
  const isMobile = isOnMobile();

  const [isOpen, setIsOpen] = useState(false);

  const m7SimpleContext = useM7SimpleContext();
  const isAdmin = m7SimpleContext.currentRole === User.ERole.admin;

  const dispatchPermissions = useCallback(
    (key: EUnitPermissionAreas, value: EPermissionVerbs | null) => {
      dispatch(setPermissions({ key, value }));
    },
    [dispatch],
  );

  const checkPermission = useCheckUserPermissions();
  const originalCheckPermission = useCheckUserPermissions({
    skipOverrides: true,
  });

  // Reset permissions overrides when re-rendering
  useEffect(() => {
    dispatch(resetPermissionsOverrides());
  }, [dispatch]);

  if (isMobile || !isAdmin || !showFab) return null;

  return (
    <Card className={"PermissionsFAB hide-on-print " + (isOpen ? "expanded" : "contracted")}>
      <CardHeader
        avatar={
          <Avatar className="logo-open-button" onClick={() => setIsOpen(!isOpen)}>
            <LockPerson fontSize="large" />
          </Avatar>
        }
        action={
          <IconButton aria-label="close" onClick={setIsOpen.bind(null, false)}>
            <Close />
          </IconButton>
        }
        title={`Permissions (with overrides)`}
      />
      <CardContent className="content">
        <Box className="feature-flags-list">
          {values(EUnitPermissionAreas).map((permission) =>
            PermissionActionFor({
              area: permission,
              appValue: {
                read: checkPermission("read", permission),
                manage: checkPermission("manage", permission),
              },
              originalValue: {
                read: originalCheckPermission("read", permission),
                manage: originalCheckPermission("manage", permission),
              },
              action: dispatchPermissions,
            }),
          )}
        </Box>
      </CardContent>
      <CardActions disableSpacing sx={{ mt: 4 }}>
        <CustomButton children="Reset all" onClick={() => dispatch(resetPermissionsOverrides())} />
        <Box flexGrow={1} />
        <CustomButton
          variant="text"
          children="Close"
          onClick={setIsOpen.bind(null, false)}
          trackingLabel={null}
        />
      </CardActions>
    </Card>
  );
};

const PermissionActionFor = ({
  area,
  originalValue,
  appValue,
  action,
}: {
  area: EUnitPermissionAreas;
  originalValue: { read: boolean; manage: boolean };
  appValue: { read: boolean; manage: boolean };
  action: (key: EUnitPermissionAreas, value: EPermissionVerbs | null) => void;
}) => {
  const appNoAccess = !appValue.read;
  const appReadonly = appValue.read && !appValue.manage;
  const appManage = appValue.manage;
  const originalNoAccess = !originalValue.read;
  const originalReadonly = originalValue.read && !originalValue.manage;
  const originalManage = originalValue.manage;

  return (
    <ButtonGroup key={area} className="key-value-line">
      <CustomButton
        variant="outlined"
        trackingLabel={null}
        className="keyLabel"
        children={labelFor(area)}
      />
      <CustomButton
        variant={appNoAccess && originalNoAccess ? "contained" : "outlined"}
        color={appNoAccess ? "darkPurple" : "primary"}
        trackingLabel={null}
        className="value"
        children={"No Access" + (originalNoAccess ? " (original)" : "")}
        onClick={() => action(area, null)}
      />
      <CustomButton
        variant={appReadonly && originalReadonly ? "contained" : "outlined"}
        color={appReadonly ? "darkPurple" : "primary"}
        trackingLabel={null}
        className="value"
        children={"Read Only" + (originalReadonly ? " (original)" : "")}
        onClick={() => action(area, EPermissionVerbs.readOnly)}
      />
      <CustomButton
        variant={appManage && originalManage ? "contained" : "outlined"}
        color={appManage ? "darkPurple" : "primary"}
        trackingLabel={null}
        className="value"
        children={"Manage" + (originalManage ? " (original)" : "")}
        onClick={() => action(area, EPermissionVerbs.manage)}
      />
    </ButtonGroup>
  );
};

// camelCase to human readable
const labelFor = (key: EUnitPermissionAreas) => {
  return key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase());
};
