import { memo, useCallback, useEffect, useState } from "react";

import { YyyyMmDd } from "@m7-health/shared-utils";

import { Input, Stack, Typography } from "@mui/material";

import { ITargetLevel, IUnitTargetLevel } from "@/api";
import { black, darkGray, redAlert } from "@/common/theming";

import { CustomButton } from "../../TrackedComponents";
import { useHandleTargetChange } from "../hooks/useHandleTargetchange";

export const TargetInput = ({
  target,
  current,
  date,
  scheduleId,
  canManage,
}: {
  target: ITargetLevel | IUnitTargetLevel;
  current: number;
  date: YyyyMmDd;
  scheduleId: string;
  canManage: boolean;
}) => {
  const { handleTargetChange, errorUpdatingTarget } = useHandleTargetChange({
    date,
    target,
    scheduleId,
  });
  const isBelowTarget = current < target.min;
  const showCountAsterisk = current % 1 !== 0 ? true : false;
  const [stateTargetMin, setStateTargetMin] = useState(0);

  const changeTargetMin = useCallback(
    (value: number) => {
      setStateTargetMin(value);
      handleTargetChange(value);
    },
    [handleTargetChange],
  );

  //Use effect that watches for errors updating a target and sets the state target value back to its original value if there is an error
  useEffect(() => {
    if (errorUpdatingTarget) {
      setStateTargetMin(target.min);
    }
  }, [errorUpdatingTarget, target]);

  //Use effect that sets the state target value to the target min on initial render and when target min changes
  useEffect(() => {
    setStateTargetMin(target.min);
  }, [target.min]);

  return (
    <Stack className="target-input-wrapper" direction="row" sx={targetInputSx}>
      <Typography sx={{ display: "flex", flexWrap: "nowrap", gap: 0.5 }}>
        <span className={isBelowTarget ? "below-target" : ""}>
          {Math.floor(current)}
          {showCountAsterisk && <sup>*</sup>}
        </span>
        /
      </Typography>
      {canManage ? (
        <>
          <Input
            value={stateTargetMin}
            onChange={(event) => {
              changeTargetMin(Number(event.target.value));
            }}
            disableUnderline
          />
          <Stack direction="column" className="target-update-actions">
            <ActionButton
              trackingLabel="increase-target"
              label="+"
              onClick={() => changeTargetMin(stateTargetMin + 1)}
            />
            <ActionButton
              trackingLabel="decrease-target"
              label="-"
              onClick={() => changeTargetMin(stateTargetMin - 1)}
            />
          </Stack>
        </>
      ) : (
        <span>{stateTargetMin}</span>
      )}
    </Stack>
  );
};

const ActionButton = memo(
  ({
    label,
    onClick,
    disabled,
    trackingLabel,
  }: {
    label: "+" | "-";
    onClick: () => void;
    disabled?: boolean;
    trackingLabel: string;
  }) => {
    return (
      <CustomButton
        label={label}
        sx={{
          width: "20px",
          display: "flex",
        }}
        trackingLabel={trackingLabel}
        onClick={(event) => {
          event.stopPropagation();
          if (disabled) return;

          onClick();
        }}
        disabled={disabled}
        variant="outlined"
        className={`target-update-action ${disabled ? "disabled" : ""}`}
        size="small"
      />
    );
  },
);

const targetInputSx = {
  display: "flex",
  alignItems: "center",
  marginTop: "0 !important",

  input: {
    height: "22px",
    position: "relative",
    top: 1,
  },

  ".below-target": {
    color: redAlert,
  },

  ".MuiInputBase-root": {
    borderBottom: "none",
    width: "25px",
    "& .MuiInputBase-input": {
      textAlign: "center",
    },
  },

  ".target-update-action": {
    minWidth: "unset",
    padding: 0,
    height: "20px",

    transition: "0.2s ease-in-out",

    borderColor: "transparent",

    "& .MuiTypography-root": {
      fontSize: "18px",
      color: darkGray,
    },

    "&:hover": {
      "& .MuiTypography-root": {
        color: black,
      },

      borderColor: black,
    },

    "&:first-child": {
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
    },

    "&:last-child": {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      position: "relative",
      top: -1,
    },

    ".MuiButton-startIcon": {
      padding: 0,
      margin: 0,
    },
  },

  ".target-update-actions": {
    position: "relative",
    top: "3px",
  },
};
