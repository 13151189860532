import { EPermissionVerbs, EUnitPermissionAreas, Timezone } from "@m7-health/shared-utils";
import { Action, createSlice, Draft } from "@reduxjs/toolkit";
import { persistReducer, REHYDRATE } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { TAvailableFeatureFlags } from "../hooks";

import { ICommonState } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: ICommonState = {
  m7Context: {
    currentUserId: undefined,
    currentUser: null,
    currentRole: undefined,
    currentUnitId: undefined,
    currentUnit: null,
    currentFacilityId: undefined,
    currentFacility: null,
    currentTimezone: "America/New_York" as Timezone,
  },
  featureFlagsOverrides: {},
  permissionsOverrides: {},
  selectedUnit: null,
  currentUnit: null,
  unitModalType: "",
  createFacilityModal: {
    isOpen: false,
  },
  history: [],
};

interface RehydrateAction extends Action<typeof REHYDRATE> {
  payload: ICommonState;
}

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setSelectedUnit: (state, action: PayloadAction<ICommonState["selectedUnit"]>) => {
      state.selectedUnit = action.payload;
    },
    setCurrentUnit: (state, action: PayloadAction<ICommonState["currentUnit"]>) => {
      state.currentUnit = action.payload;
    },
    setCurrentContext: (state, action: PayloadAction<ICommonState["m7Context"]>) => {
      state.m7Context = action.payload;
    },
    setUnitModal: (state, action: PayloadAction<ICommonState["unitModalType"]>) => {
      state.unitModalType = action.payload;
    },
    setCreateFacilityModalIsOpen: (
      state,
      action: PayloadAction<ICommonState["createFacilityModal"]["isOpen"]>,
    ) => {
      state.createFacilityModal.isOpen = action.payload;
    },
    setHistory: (state, action: PayloadAction<ICommonState["history"][number]>) => {
      state.history = [action.payload, ...state.history].slice(0, 10);
    },
    setFeatureFlags: <T extends keyof TAvailableFeatureFlags>(
      state: Draft<ICommonState>,
      action: PayloadAction<{
        key: T;
        value: TAvailableFeatureFlags[T] | null;
      }>,
    ) => {
      const value = action.payload.value;
      if (value === null) {
        delete state.featureFlagsOverrides[action.payload.key];
        return;
      }

      state.featureFlagsOverrides[action.payload.key] = value;
    },
    resetFeatureFlagsOverrides: (state) => {
      state.featureFlagsOverrides = {};
    },

    setPermissions: (
      state: Draft<ICommonState>,
      action: PayloadAction<{
        key: EUnitPermissionAreas;
        value: EPermissionVerbs | null | undefined;
      }>,
    ) => {
      const value = action.payload.value;
      if (value === undefined) {
        delete state.permissionsOverrides[action.payload.key];
        return;
      }

      state.permissionsOverrides[action.payload.key] = value;
    },
    resetPermissionsOverrides: (state) => {
      state.permissionsOverrides = {};
    },
  },
  extraReducers: (builder) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    builder.addCase(REHYDRATE, (state, action: RehydrateAction) => {
      state = { ...action.payload };
    });
  },
});

export const {
  setCurrentContext,
  setSelectedUnit,
  setUnitModal,
  setCurrentUnit,
  setCreateFacilityModalIsOpen,
  setHistory,
  setFeatureFlags,
  resetFeatureFlagsOverrides,
  setPermissions,
  resetPermissionsOverrides,
} = commonSlice.actions;

const persistConfig = {
  key: "common",
  storage,
};

export default persistReducer(persistConfig, commonSlice.reducer);
