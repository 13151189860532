import { useMemo } from "react";

import { keyBy } from "lodash";

import { IUnitBasic } from "~/routes/api/types";

import { ISchedule, useListSchedulesQuery, useListUnitsQuery } from "@/api";

export const useUnitsByScheduleId = () => {
  const { data: schedules } = useListSchedulesQuery({});
  const { data: units } = useListUnitsQuery({});

  return useMemo(() => {
    const unitsById = keyBy(units, "id");

    return (schedules || []).reduce(
      (acc, schedule) => {
        const unit = unitsById[schedule.unitId];
        if (unit) acc[schedule.id] = unit;
        return acc;
      },
      {} as { [key in ISchedule["id"]]: IUnitBasic },
    );
  }, [units, schedules]);
};
