import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";

import { CustomButton } from "@/common/components";

import { AddToHomeScreen, Questions, WatchTrainingVideo } from "./Pages";

const pageIndexes = [0, 1, 2];

export const StaffOnboardingContent = ({
  currentPageIndex,
  setCurrentPageIndex,
}: {
  currentPageIndex: number;
  setCurrentPageIndex: (index: number) => void;
}) => {
  return (
    <Stack className="staff-onboarding-content">
      {/* Content of the Pages */}

      <AddToHomeScreen className={`content-panel ${currentPageIndex !== 0 ? "inactive" : ""}`} />
      <WatchTrainingVideo className={`content-panel ${currentPageIndex !== 1 ? "inactive" : ""}`} />
      <Questions className={`content-panel ${currentPageIndex !== 2 ? "inactive" : ""}`} />

      {/* Arrows Controls and index indicator  */}
      <Stack direction="row" className="page-controls">
        <CustomButton
          iconOnly
          onClick={() => setCurrentPageIndex(currentPageIndex - 1)}
          startIcon={<KeyboardArrowLeft fontSize="large" />}
          trackingLabel="tab-back"
          label="Previous"
          disabled={currentPageIndex === 0}
        />

        <Box flexGrow={1} />
        <Stack className="page-indicators" direction="row" spacing={1}>
          {pageIndexes.map((index) => (
            <Box
              className={`page-indicator ${currentPageIndex === index ? "active" : ""}`}
              key={`page-indicator-onboarding-${index}`}
            />
          ))}
        </Stack>
        <Box flexGrow={1} />

        <CustomButton
          iconOnly
          onClick={() => setCurrentPageIndex(currentPageIndex + 1)}
          startIcon={<KeyboardArrowRight fontSize="large" />}
          trackingLabel="tab-forward"
          label="Next"
          disabled={currentPageIndex === pageIndexes.length - 1}
        />
      </Stack>
    </Stack>
  );
};
