import { map } from "lodash";

import { Close, FilterAlt } from "@mui/icons-material";
import { Box, Collapse, Grid, lighten, Stack, Typography } from "@mui/material";

import { useCurrentFacilityId } from "@/common/hooks";
import { black, darkPurple, mediumGray, white } from "@/common/theming/colors";

import { CustomButton, CustomSelect, ShiftTypeFilterDropdown, UnitPicker } from "../..";
import { useSidebarFilters } from "../hooks/useSidebarFilters";
import { ESideBarState } from "../OpenShiftSidebarContent";

export const Filters = ({ sidebarState }: { sidebarState: ESideBarState }) => {
  const currentFacilityId = useCurrentFacilityId();

  const {
    sidebarFilters,
    shiftTypes,
    shiftTypesWithTargets,
    availableUnits,
    toggleFiltersCollapse,
    setSidebarFilters,
    staffCategorySelectOptions,
  } = useSidebarFilters({ sidebarState });

  return (
    <Box
      className="filters-container"
      sx={{
        border: sidebarFilters.modalIsOpen ? `2px solid ${black}` : `1px solid ${mediumGray}`,
        borderRadius: "4px",
      }}
      onClick={toggleFiltersCollapse}
    >
      <Stack display={"flex"} flexDirection={"row"} alignItems={"center"} p={1}>
        <CustomButton
          label="Filters"
          sx={{ width: "80px", height: "25px", ".MuiButton-startIcon": { mr: 0 } }}
          startIcon={<FilterAlt />}
          variant="text"
          onClick={toggleFiltersCollapse}
        />

        <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
          {!!sidebarFilters.unitIds?.length && (
            <FilterTag content={`Units (${sidebarFilters.unitIds.length})`} />
          )}
          {!!sidebarFilters.staffCategories?.length && (
            <FilterTag content={`Staff Categories (${sidebarFilters.staffCategories.length})`} />
          )}
          {!!sidebarFilters.shiftTypes?.length && (
            <FilterTag content={`Shift Types (${sidebarFilters.shiftTypes.length})`} />
          )}
        </Stack>
        <Box flexGrow={1} />
        {sidebarFilters.modalIsOpen && (
          <CustomButton
            trackingLabel="Close find staff to work Filters"
            size="small"
            variant="outlined"
            sx={{
              border: `1px solid ${mediumGray}`,
            }}
            iconOnly
            startIcon={<Close sx={{ fontSize: "20px" }} />}
            onClick={(event) => toggleFiltersCollapse(event, false)}
          />
        )}
      </Stack>
      <Collapse
        in={sidebarFilters.modalIsOpen}
        sx={{
          zIndex: 10,
          background: white,
          position: "relative",
          ".MuiCheckbox-root": {
            padding: "4px !important",
          },
        }}
        // Prevents closing the date picker when interacting with content
        onClick={(event) => event.stopPropagation()}
      >
        <Grid className="filters-section" container gap={2} sx={{ padding: 2 }}>
          {sidebarState === ESideBarState.scheduleGrid && (
            <Grid item width="100%">
              <ShiftTypeFilterDropdown
                shiftTypes={shiftTypesWithTargets}
                selectedOption={shiftTypes.filter((shiftType) =>
                  sidebarFilters.shiftTypes?.includes(shiftType.key),
                )}
                selectOption={(newSelection) => {
                  const newShiftTypes = newSelection || [];
                  setSidebarFilters({
                    shiftTypes: newShiftTypes.map(({ key }) => key),
                  });
                }}
                isMultiSelect
                label="Shift Types"
                emptyOptionLabel="Clear filters"
                emptyOptionLabelDisplayWhenSelected="No filter selected"
              />
            </Grid>
          )}
          {sidebarState === ESideBarState.houseView && (
            <Grid item width="100%">
              <UnitPicker
                unitOptions={availableUnits}
                valueIds={sidebarFilters.unitIds}
                onChange={(units) => setSidebarFilters({ unitIds: map(units, "id") })}
                facilityId={currentFacilityId || undefined}
              />
            </Grid>
          )}
          <Grid item width="100%">
            <CustomSelect
              items={staffCategorySelectOptions}
              label="Staff Categories"
              placeholder="Staff Categories"
              multiple
              value={sidebarFilters.staffCategories}
              onChange={(event) => {
                const categoryValues = Array.isArray(event.target.value)
                  ? event.target.value
                  : [event.target.value];
                setSidebarFilters({ staffCategories: categoryValues });
              }}
            />
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  );
};

const FilterTag = ({ content }: { content: string }) => (
  <Typography
    sx={{
      color: darkPurple,
      background: lighten(darkPurple, 0.9),
      textWrap: "nowrap",
      fontWeight: "500",
      padding: "2px 4px",
      fontSize: "11px",
      borderRadius: "4px",
      border: `1px solid ${darkPurple}`,
    }}
    children={content}
  />
);
