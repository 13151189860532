import "./WeekView.scss";
import { memo, useState } from "react";

import { sortBy } from "lodash";

import { Box, CircularProgress } from "@mui/material";

import { IUnitConfig } from "#/features/User/types";
import { INote } from "@/api";
import { KeyBy } from "@/common/types";

import { DateHeaderRow } from "./components/dateHeaderRow";
import { ScheduleScroller } from "./components/scheduleScroller";
import { UnitRow } from "./components/unitRow";
import { useGetWeekViewData } from "./hooks/useGetWeekViewData";

export const WeekViewTable = memo(
  ({
    notesByUserId,
    notesFromStaffByUserId,
    units,
  }: {
    notesByUserId: KeyBy<INote, "userId">;
    notesFromStaffByUserId: KeyBy<INote, "userId">;
    units: IUnitConfig[];
  }) => {
    const [collapsedUnits, setCollapsedUnits] = useState<Record<string, boolean>>({});
    const { shiftsByDayByUnit, isLoadingData, firstRange, staffDetailsByUserId } =
      useGetWeekViewData();

    if (isLoadingData || !firstRange) {
      return <CircularProgress />;
    }

    return (
      <Box className="week-view-table-wrapper">
        <ScheduleScroller />
        <Box className="week-view-table">
          <DateHeaderRow firstRange={firstRange} />
          {sortBy(units, "name").map((unit) => (
            <UnitRow
              key={unit.id}
              isCollapsed={!!collapsedUnits[unit.id]}
              setCollapsedUnits={setCollapsedUnits}
              unit={unit}
              shiftsByDayByUnit={shiftsByDayByUnit}
              staffDetailsByUserId={staffDetailsByUserId}
              notesByUserId={notesByUserId}
              notesFromStaffByUserId={notesFromStaffByUserId}
              firstRange={firstRange}
            />
          ))}
        </Box>
      </Box>
    );
  },
);
