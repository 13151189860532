import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Dayjs, getTzDayjs, ISODateString, toISO } from "@m7-health/shared-utils";

import { Typography } from "@mui/material";

import { CustomDatePicker } from "@/common/components/DeprecatedDatePicker";

import { useUserTableUnitData } from "../../ContextProvider";

import { useBlurOnEscape } from "./helpers";
import { NaCell } from "./NaCell";
import { TCellComponent } from "./types";

const DateEditor: TCellComponent<ISODateString>["editor"] = ({
  value,
  onChange,
  onBlur,
  autoFocus,
}) => {
  const timezone = useUserTableUnitData().timezone;

  // Wrap setter to check for date validity
  const [error, setError] = useState(false);
  const dayjsValueRef = useRef(getTzDayjs(value, timezone));

  const updateDayjsValue = useCallback(
    (newValue: Dayjs | undefined | null) => {
      if (!newValue) return;

      dayjsValueRef.current = newValue;

      if (newValue.isValid()) setError(false);
      else setError(true);
    },
    [setError],
  );

  // Set initial value
  useEffect(() => {
    updateDayjsValue(getTzDayjs(value, timezone));
  }, [value, updateDayjsValue, timezone]);

  const changeAndBlur = useCallback(
    (event?: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      // If click on the date picker, or clear button, don't blur
      if (event?.relatedTarget?.ariaLabel?.startsWith("Choose date")) return;
      if (event?.relatedTarget?.ariaLabel === "Clear value") return;

      if (error) return;
      onChange(toISO(dayjsValueRef.current));
      onBlur(event);
    },
    [onChange, onBlur, error],
  );

  const blurOnEscape = useBlurOnEscape(onBlur);
  const textFieldProps = useMemo(
    () => ({
      textField: {
        variant: "standard" as const,
        size: "small" as const,
        onBlur: changeAndBlur,
        autoFocus,
        onKeyDown: blurOnEscape,
      },
    }),
    [changeAndBlur, autoFocus, blurOnEscape],
  );

  return (
    <CustomDatePicker
      autoFocus={autoFocus}
      name="dateField"
      label={null}
      value={dayjsValueRef.current}
      timezone={timezone}
      onChange={updateDayjsValue}
      slotProps={textFieldProps}
      onClose={changeAndBlur}
    />
  );
};

const DateRenderer: TCellComponent<ISODateString>["readonly"] = ({ value, triggerEditMode }) => {
  const timezone = useUserTableUnitData().timezone;

  if (!value) return <NaCell onClick={triggerEditMode} />;
  return (
    <Typography
      onClick={triggerEditMode}
      children={getTzDayjs(value, timezone).format("MM/DD/YYYY")}
    />
  );
};

export const DateCell: TCellComponent<ISODateString> = {
  readonly: DateRenderer,
  editor: DateEditor,
};
