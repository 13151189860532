import { useState } from "react";

import { EUnitPermissionAreas } from "@m7-health/shared-utils";
import { compact, flatMap, isEqual } from "lodash";

import { Article } from "@mui/icons-material";
import { Box, Stack, Tooltip, Typography } from "@mui/material";

import { IOpenShift, IOpenShiftRequest, IShiftType, ITargetLevel, IUnitTargetLevel } from "@/api";
import { useAppSelector, useCheckUserPermission, useStaffCategoryByKey } from "@/common/hooks";
import { pxToRem } from "@/common/utils/pxToRem";

import { CustomButton, CustomCollapse, ShiftIncentiveLevel, ShiftV2 } from "../..";
import { useSingleDayOpenShiftGroups } from "../hooks/useSingleDayOpenShiftGroups";
import { ESideBarState } from "../OpenShiftSidebarContent";

import { OpenShiftRequest } from "./OpenShiftRequest/OpenShiftRequest";
import { OpenShiftRequestCountIcon } from "./OpenShiftRequestCountIcon";
import { OpenShiftTime } from "./OpenShiftTime";
import { TargetInput } from "./TargetInput";
import { UpdateSubsetModal } from "./UpdateSubsetModal";

export const OpenShiftSingleDayRow = ({
  shiftType,
  target,
  openShiftData,
  currentCount,
  scheduleId,
  longestLabelLengths,
  sidebarState,
}: {
  shiftType: IShiftType;
  target: ITargetLevel | IUnitTargetLevel | undefined;
  openShiftData: {
    openShifts: IOpenShift[];
    openShiftRequests: IOpenShiftRequest[];
  };
  currentCount: number;
  scheduleId: string;
  longestLabelLengths: { shiftType: number; staffCategory: number };
  sidebarState: ESideBarState;
}) => {
  const staffCategoryNames = useStaffCategoryByKey();
  const staffCategoryName = target?.staffCategoryKey
    ? staffCategoryNames[target?.staffCategoryKey]?.name
    : "";
  const canManage = useCheckUserPermission(
    "manage",
    sidebarState === ESideBarState.houseView
      ? EUnitPermissionAreas.houseView
      : EUnitPermissionAreas.scheduleGrid,
  );

  const { staffData, date } = useAppSelector((state) => {
    return {
      staffData: state.openShiftsSidebar.data.staffDetails,
      date: state.openShiftsSidebar.selectedDate,
    };
  }, isEqual);

  // Build tabs, and handle tab selection
  const { openShiftGroups } = useSingleDayOpenShiftGroups({
    openShifts: openShiftData.openShifts,
    openShiftRequests: openShiftData.openShiftRequests,
  });

  const [openShiftsToUpdate, setOpenShiftsToUpdate] = useState<typeof openShiftGroups | null>(null);
  return (
    <>
      {openShiftsToUpdate && date && (
        <UpdateSubsetModal
          date={date}
          onClose={() => setOpenShiftsToUpdate(null)}
          openShiftsToUpdate={openShiftsToUpdate}
          staffCategoryName={staffCategoryName}
          shiftType={shiftType}
          allRequests={compact(flatMap(openShiftGroups, "openShiftRequests"))}
        />
      )}
      <Stack direction="column" spacing={1} sx={{ position: "relative" }}>
        {/* Headers */}
        <Stack className="headers" direction="row" spacing={1}>
          <Box
            className="staff-category-box"
            sx={{ width: `${longestLabelLengths.staffCategory * 7.6}px` }}
          >
            <Typography fontSize={pxToRem(14)}>{staffCategoryName}</Typography>
          </Box>
          <ShiftV2
            shiftType={shiftType}
            variant="medium"
            sx={{
              ".tag-text": { width: `${longestLabelLengths.shiftType * 7.6}px` },
            }}
          />

          <Box flexGrow={1} />

          {target && (
            <TargetInput
              canManage={canManage}
              target={target}
              current={currentCount}
              date={date!}
              scheduleId={scheduleId}
            />
          )}
        </Stack>

        {/* Open shifts tabs */}
        {openShiftGroups.map(
          ({ value: key, openShifts: groupOpenShifts, openShiftRequests, openShiftLiveCount }) => {
            const firstOpenShift = groupOpenShifts?.[0];
            if (!firstOpenShift) return null;

            return (
              <CustomCollapse
                key={key}
                className={"open-shift-group " + (openShiftRequests.length ? "some" : "none")}
                disabled={!openShiftRequests.length}
                label={
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ flexGrow: 1, alignItems: "center", justifyContent: "center" }}
                  >
                    <Typography className={"open-shift-count none"}>
                      {openShiftLiveCount}
                    </Typography>
                    <OpenShiftTime openShift={firstOpenShift} shiftType={shiftType} />
                    <ShiftIncentiveLevel
                      shiftIncentiveLevelId={firstOpenShift.shiftIncentiveLevelId}
                      size="small"
                      withDollarSign
                    />
                    {firstOpenShift.note && (
                      <Tooltip title={firstOpenShift.note} arrow placement="top">
                        <Article />
                      </Tooltip>
                    )}
                    <OpenShiftRequestCountIcon count={openShiftRequests.length} />
                    <Box flexGrow={1} />
                  </Stack>
                }
              >
                {openShiftRequests.map((openShiftRequest) => {
                  const staff = staffData[openShiftRequest.createdById];
                  if (!openShiftRequest || !staff || !date) return <></>;

                  return (
                    <OpenShiftRequest
                      key={openShiftRequest.id}
                      request={openShiftRequest}
                      openShift={firstOpenShift}
                      staff={staff}
                      dateOfShift={date}
                      shiftType={shiftType}
                      canManage={canManage}
                    />
                  );
                })}
              </CustomCollapse>
            );
          },
        )}

        {openShiftGroups.length > 0 && canManage && (
          <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
            <Box flexGrow={1} />
            <CustomButton
              trackingLabel="edit-open-shifts"
              label="Edit"
              onClick={() => setOpenShiftsToUpdate(openShiftGroups)}
              size="small"
              sx={{ p: 0 }}
            />
          </Stack>
        )}
      </Stack>
    </>
  );
};
