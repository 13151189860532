import "./MultiDay.scss";

import { useCallback, useState } from "react";

import { EUnitPermissionAreas, m7DayJs, YyyyMmDd } from "@m7-health/shared-utils";

import { ArrowForwardIosSharp, Article } from "@mui/icons-material";
import { Box, Stack, Tooltip, Typography } from "@mui/material";

import { useAppDispatch } from "~/common/hooks/useRedux";
import { MixpanelProvider } from "~/modules/mixpanel/Provider";
import { Mxp } from "~/modules/mixpanel/types";

import { houseViewStore } from "#/features/HouseView/store";
import { SchedulerGridStore } from "#/features/SchedulerGrid/store";
import { scrollSchedulerGridToDate } from "#/features/SchedulerGrid/utils/scrollSchedulerGridToDate";
import { useAppConfigQuery } from "#/features/User/queries";
import { IOpenShift, OpenShiftRequest as TOpenShiftRequest, StaffCategory } from "@/api";
import { useCheckUserPermission, useKeyBy } from "@/common/hooks";
import { useCurrentTimezone } from "@/common/hooks/useCurrentTimezone";
import { dateToTimeString, timeAdd, TimeStringToStandardTime } from "@/common/utils/dates";
import { pxToRem } from "@/common/utils/pxToRem";

import { ShiftV2 } from "../Shift/ShiftV2";
import { ShiftIncentiveLevel } from "../ShiftIncentiveLevel";

import { OpenShiftRequest } from "./components";
import { OpenShiftRequestCountIcon } from "./components/OpenShiftRequestCountIcon";
import { useGetMultiDayOpenShiftData } from "./hooks";
import { ESideBarState } from "./OpenShiftSidebarContent";
import { openShiftsSidebarActions } from "./store";

const initialExpandedOpenShifts: Record<string, boolean> = {};

export const MultiDay = ({ sidebarState }: { sidebarState: ESideBarState }) => {
  const [expandedOpenShifts, setExpandedOpenShifts] =
    useState<Record<string, boolean>>(initialExpandedOpenShifts);

  const dispatch = useAppDispatch();
  const currentTimezone = useCurrentTimezone();
  const units = useAppConfigQuery().data?.accessibleUnits;
  const unitsById = useKeyBy(units, "id");
  const {
    sortedDateKeys,
    openShiftsByDate,
    shiftOptions,
    unitShiftIncentiveLevels,
    staffDetails,
    schedulesById,
    longestLabelLengths,
  } = useGetMultiDayOpenShiftData(sidebarState);
  const isHouseView = sidebarState === ESideBarState.houseView;
  const canManage = useCheckUserPermission(
    "manage",
    sidebarState === ESideBarState.houseView
      ? EUnitPermissionAreas.houseView
      : EUnitPermissionAreas.scheduleGrid,
  );

  const setPageData = useCallback(
    ({ date, staffCategoryKey }: { date: YyyyMmDd; staffCategoryKey?: StaffCategory.EKey }) => {
      if (!isHouseView) {
        staffCategoryKey && dispatch(SchedulerGridStore.setGridStaffCategory(staffCategoryKey));
        dispatch(
          SchedulerGridStore.setSidePanelDetailsData({
            selectedDayKey: date,
          }),
        );
        scrollSchedulerGridToDate(date);
      } else {
        staffCategoryKey &&
          dispatch(houseViewStore.state.appendStaffCategories([staffCategoryKey]));
        dispatch(houseViewStore.state.selectDateForData(date));
      }
    },
    [isHouseView, dispatch],
  );

  const onClickOpenShiftGroup = useCallback(
    (openShift: IOpenShift) => {
      setExpandedOpenShifts((prev) => {
        const groupIsExpanded = prev[openShift.id];
        if (!groupIsExpanded) {
          //If expanding an open shift group then set the pages staff category to the open shift group staff category
          setPageData({
            date: openShift.date,
            staffCategoryKey: openShift.staffCategoryKey,
          });
        }
        return {
          ...prev,
          [openShift.id]: !groupIsExpanded,
        };
      });
    },
    [setPageData],
  );

  const onClickDateCell = useCallback(
    (date: YyyyMmDd) => {
      setPageData({ date });
      dispatch(openShiftsSidebarActions.setSidebarSelectedDate(date));
    },
    [setPageData, dispatch],
  );

  return (
    <MixpanelProvider properties={{ [Mxp.Property.layout.section]: "sidebar-ops-all-requests" }}>
      <div className="multi-day-container">
        {!sortedDateKeys.length && (
          <Box sx={{ p: 2, alignItems: "center", display: "flex", justifyContent: "center" }}>
            <Typography fontSize={pxToRem(14)} textAlign="center">
              There are no open shift requests for{" "}
              {isHouseView ? "these schedules" : "this schedule"}
            </Typography>
          </Box>
        )}
        {sortedDateKeys.map((date) => (
          <div key={date}>
            <Box className="date-cell" onClick={() => onClickDateCell(date)}>
              <Typography>{m7DayJs(date).format("MMM D")}</Typography>
            </Box>
            {openShiftsByDate[date]?.map((openShift) => {
              const pendingRequests =
                openShift.openShiftRequests?.filter(
                  ({ status }) => status === TOpenShiftRequest.EStatus.pending,
                ) || [];
              const shiftType = shiftOptions[openShift.scheduleId]?.[openShift.shiftType];
              if (!shiftType) return null;
              const startTime = openShift.startDateTime
                ? dateToTimeString(openShift.startDateTime, currentTimezone)
                : shiftType.startTime;
              const duration = openShift.durationSeconds || shiftType.durationSeconds;
              const endTime = timeAdd(startTime, duration);
              const timeLabel =
                TimeStringToStandardTime(startTime, "short") +
                "-" +
                TimeStringToStandardTime(endTime, "short");

              return (
                <Box key={openShift.id} className="open-shift-container">
                  <Box
                    className="open-shift-details"
                    onClick={() => {
                      onClickOpenShiftGroup(openShift);
                    }}
                  >
                    <Stack direction="column" gap={0.5}>
                      {isHouseView && (
                        <Box sx={{ marginBottom: "6px" }}>
                          <Typography fontWeight={550} fontSize={pxToRem(15)}>
                            {unitsById[schedulesById[openShift.scheduleId]?.unitId || ""]?.name}
                          </Typography>
                        </Box>
                      )}
                      <Stack direction="row" alignItems="center" gap={1}>
                        <ArrowForwardIosSharp
                          sx={{ fontSize: pxToRem(15) }}
                          className={`expand-icon ${expandedOpenShifts[openShift.id] ? "expanded" : ""}`}
                        />
                        <Typography className={"open-shift-count"}>
                          {openShift.shiftCount}
                        </Typography>
                        <Box
                          className="staff-category-box"
                          sx={{ width: `${longestLabelLengths.staffCategory * 7.6}px` }}
                        >
                          <Typography fontSize={pxToRem(14)}>
                            {StaffCategory.EName[openShift.staffCategoryKey]}
                          </Typography>
                        </Box>
                        <ShiftV2
                          shiftType={shiftType}
                          variant={"medium"}
                          sx={{
                            ".tag-text": { width: `${longestLabelLengths.shiftType * 7.6}px` },
                          }}
                        />
                        <Typography
                          noWrap
                          sx={{ minWidth: "55px" }}
                          fontSize={pxToRem(14)}
                          children={timeLabel}
                        />
                        <OpenShiftRequestCountIcon count={openShift.openShiftRequests?.length} />
                      </Stack>
                      <Stack direction="row" alignItems="center" sx={{ ml: "20px" }}>
                        {openShift.shiftIncentiveLevelId && (
                          <ShiftIncentiveLevel
                            shiftIncentiveLevel={
                              unitShiftIncentiveLevels[openShift.shiftIncentiveLevelId]
                            }
                            withDollarSign
                            inline
                          />
                        )}
                        {openShift.note && (
                          <Tooltip placement="top" title={openShift.note}>
                            <Article sx={{ fontSize: pxToRem(19) }} />
                          </Tooltip>
                        )}
                      </Stack>
                    </Stack>
                  </Box>

                  {expandedOpenShifts[openShift.id] && (
                    <Box sx={{ pl: 2, mt: 1 }}>
                      {pendingRequests?.map((request) => {
                        const staff = staffDetails[request.createdById];

                        if (!staff) return null;
                        return (
                          <OpenShiftRequest
                            canManage={canManage}
                            request={request}
                            staff={staff}
                            dateOfShift={date}
                            openShift={openShift}
                            shiftType={shiftType}
                          />
                        );
                      })}
                    </Box>
                  )}
                </Box>
              );
            })}
          </div>
        ))}
      </div>
    </MixpanelProvider>
  );
};
