import { useMemo } from "react";

import { keyBy, orderBy } from "lodash";

import { useAppConfigQuery } from "#/features/User/queries";
import { IUnitConfig } from "#/features/User/types";
import {
  IAttribute,
  IPreferenceRequirementRuleSet,
  IPreferencesTemplate,
  IStaffType,
  IUnit,
  useListPreferenceRequirementRuleSetsQuery,
  useListPreferencesTemplatesQuery,
  useListUnitShiftTypeQuery,
} from "@/api";
import { useCurrentFacilityId } from "@/common/hooks";
import { TEmptyArray, Timezone } from "@/common/types";

import { UserTableUnitData } from "../types";

const emptyStaffTypes = [] as IStaffType[];

/**
 * Builds the unit data for the user table.
 * @param unitId - The ID of the unit to build data for.
 * @returns The unit data for the user table.
 */
export const useBuildData = (unitId: IUnit["id"]): UserTableUnitData | null => {
  const allUnits = useAppConfigQuery().data?.accessibleUnits;
  const facilityId = useCurrentFacilityId();
  const filteredUnits = useMemo(
    () =>
      allUnits
        ?.filter((unit) => unit.facilityId === facilityId)
        .sort((unitA) => (unitA.archivedAt ? 1 : -1)),
    [allUnits, facilityId],
  );

  const { data: shiftTypes } = useListUnitShiftTypeQuery({ unitIds: [unitId] });
  const staffTypes = useAppConfigQuery().data?.staffTypes || emptyStaffTypes;
  const { data: preferenceRequirementRuleSets } = useListPreferenceRequirementRuleSetsQuery({
    unitIds: [unitId],
  });
  const { data: preferencesTemplates } = useListPreferencesTemplatesQuery({
    unitIds: [unitId],
  });

  return useMemo(() => {
    const unitsById = keyBy(filteredUnits, "id");
    const orderedShiftTypes = orderBy(shiftTypes, "sortPosition");
    const shiftTypesByKey = keyBy(orderedShiftTypes, "key");
    const preferenceRequirementRuleSetsById = keyBy(preferenceRequirementRuleSets, "id");
    const preferencesTemplatesById = keyBy(preferencesTemplates, "id");
    const currentUnit = unitsById[unitId];

    if (!currentUnit) return null;

    return {
      timezone: currentUnit.timezone as Timezone,
      currentUnit: currentUnit,
      units: {
        values: filteredUnits ?? TEmptyArray<IUnitConfig>(),
        byId: unitsById,
      },
      shiftTypes: {
        values: orderedShiftTypes,
        byKey: shiftTypesByKey,
      },
      attributes: {
        values: currentUnit.attributes ?? TEmptyArray<IAttribute>(),
        byKey: keyBy(currentUnit.attributes, "key"),
      },
      staffTypes: {
        values: staffTypes,
        byKey: keyBy(staffTypes, "key"),
      },
      preferenceRequirementRuleSets: {
        values: preferenceRequirementRuleSets ?? TEmptyArray<IPreferenceRequirementRuleSet>(),
        byId: preferenceRequirementRuleSetsById,
      },
      preferencesTemplates: {
        values: preferencesTemplates ?? TEmptyArray<IPreferencesTemplate>(),
        byId: preferencesTemplatesById,
      },
    };
  }, [
    filteredUnits,
    shiftTypes,
    preferenceRequirementRuleSets,
    preferencesTemplates,
    unitId,
    staffTypes,
  ]);
};
