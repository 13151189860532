import { Box } from "@mui/material";

import { OpenShiftSidebarContent } from "@/common/components/OpenShiftSidebarContent";
import { ESideBarState } from "@/common/components/OpenShiftSidebarContent/OpenShiftSidebarContent";

export const OpenShifts = () => {
  return (
    <Box sx={{ marginTop: "-16px" }}>
      <OpenShiftSidebarContent sidebarState={ESideBarState.houseView} />
    </Box>
  );
};
