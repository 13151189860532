import { EventUserCreated } from "~/api/eventLog/events";

const comparableAttributes = [
  "firstName",
  "lastName",
  "email",
  "softDeletedDate",
  "isSoftDeleted",
  "staffTypeKey",
  "employmentType",
  "contractEndDate",
] as const;

type TComparableAttributes = (typeof comparableAttributes)[number];
type TDifferences = Partial<{
  [key in TComparableAttributes]: {
    from: string;
    to: string;
  };
}>;

// All versions of event payloads should be defined here
type TPayloadV1 = EventUserCreated.Payloads & {
  updatedFields: TDifferences;
  origin: TUserOrigin;
};

export type TUserOrigin = {
  type: "external-user-integration-csv";
};

export namespace EventUserUpdated {
  export enum EVersions {
    "v1" = "1",
  }

  export type Payloads = TPayloadV1;
  export type PayloadByVersion = {
    [EVersions.v1]: TPayloadV1;
  };
}
