import { Dayjs, YyyyMmDd } from "@m7-health/shared-utils";

import { Box, Typography } from "@mui/material";

export const DateHeaderRow = ({
  firstRange,
}: {
  firstRange: {
    from: Dayjs;
    to: Dayjs;
    allDays: Dayjs[];
    formattedDays: YyyyMmDd[];
  };
}) => {
  return (
    <Box className="date-header-row" gap={0}>
      {firstRange?.allDays.map((day) => (
        <Box className="date-header-cell">
          <Typography>{`${day.format("ddd")} ${day.format("M/D")}`}</Typography>
        </Box>
      ))}
    </Box>
  );
};
