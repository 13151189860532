import { memo } from "react";

import { Box, Typography } from "@mui/material";

import CustomButton from "~/common/components/TrackedComponents/Button";
import { darkPurple } from "~/common/theming/colors";

import { TSx } from "@/common/types";

const BottomActions: React.FC<{
  title: string;
  children?: React.ReactNode;
  actionButton?: {
    label: string;
    trackingLabel?: string;
    action: () => void;
    disabled: boolean;
    sx?: TSx;
  };
  cancelButton?: {
    label: string;
    trackingLabel?: string;
    action: () => void;
    disabled: boolean;
  };
}> = ({ children, actionButton, cancelButton, title }) => {
  return (
    <Box
      mt={2}
      position={"relative"}
      border={children ? "2px solid " + darkPurple : "none"}
      p={1}
      pt={children ? 3 : 2}
      mb="8px"
      borderRadius={"5px"}
    >
      {children && (
        <Typography
          variant="subtitle1"
          fontWeight="500"
          children={title}
          width="100%"
          sx={{
            position: "absolute",
            top: "-19px",
            background: "white",
            width: "fit-content",
            padding: 0.5,
            borderTopRightRadius: "10%",
          }}
        />
      )}
      {children}
      <Box display={"flex"} flexDirection={"row"} columnGap={"10px"}>
        {actionButton && (
          <CustomButton
            sx={{ mt: children ? 2 : 0, width: "initial", ...actionButton.sx }}
            disabled={actionButton.disabled}
            label={actionButton.label}
            trackingLabel={actionButton.trackingLabel}
            onClick={actionButton.action}
          />
        )}
        {cancelButton && (
          <CustomButton
            sx={{ mt: children ? 2 : 0, width: "initial" }}
            disabled={cancelButton.disabled}
            variant={actionButton ? "text" : "contained"}
            label={cancelButton.label}
            trackingLabel={cancelButton.trackingLabel}
            onClick={cancelButton.action}
          />
        )}
        <Box flexGrow={1} />
      </Box>
    </Box>
  );
};

export const __HouseViewSideBarBottomActions = memo(BottomActions);
