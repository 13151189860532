import { useCallback, useEffect, useMemo, useState } from "react";

import { EUnitPermissionAreas, Timezone } from "@m7-health/shared-utils";
import { get, groupBy, isEqual, keys, orderBy } from "lodash";

import { QueryBuilder } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Box, ButtonProps, Chip, lighten, Stack, Tooltip, Typography } from "@mui/material";

import { INote, IStaffCategory, IStaffDetails, IStaffShift, StaffCategory } from "~/api";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { useToast } from "~/common/hooks/useToast";
import { black, darkPurple, mediumGray, white } from "~/common/theming/colors";
import { KeyBy } from "~/common/types";
import { isOnMobile } from "~/common/utils/isOnMobile";
import { IUnitBasic } from "~/routes/api/types";

import { SelectedUnit } from "#/features/HouseView/components/SideBar/states/SelectedUnit";
import { useAppConfigQuery } from "#/features/User/queries";
import { CustomButton } from "@/common/components";
import CustomTabs from "@/common/components/TrackedComponents/Tabs";
import {
  useAppFlags,
  useCheckUserPermission,
  useCurrentRole,
  useFacilityTimezones,
  useHouseViewEnableAllTab,
} from "@/common/hooks";
import { localDayJs } from "@/common/packages/dayjs";
import { dateToDateKey, timezoneLabel } from "@/common/utils/dates";

import { HouseViewHooks } from "../hooks";
import { houseViewStore } from "../store";

import { HouseView } from ".";

const { useStaffingTabs, useSortedStaffShifts } = HouseViewHooks;

const emptyCategories: IStaffCategory["key"][] = [];
const emptyShifts = [] as IStaffShift[];

const UnitCard = ({
  withPatientCount,
  unit,
  notes,
  notesFromStaff,
  staffDetails,
  shifts,
  allShifts,
  onSelect,
  otherShifts,
}: {
  unit: IUnitBasic;
  shifts: IStaffShift[];
  allShifts: IStaffShift[];
  staffDetails: KeyBy<IStaffDetails, "userId">;
  notes: KeyBy<INote, "userId">;
  notesFromStaff: KeyBy<INote, "userId">;
  onSelect?: (unit: IUnitBasic) => void;
  otherShifts?: (category: StaffCategory.EKey) => React.ReactNode;
  withPatientCount?: boolean;
}) => {
  const { useNewSingleDayMobileView } = useAppFlags();
  const dispatch = useAppDispatch();
  const toast = useToast();
  const facilityHasManyTimezones = useFacilityTimezones().length > 1;
  const enableAllTab = useHouseViewEnableAllTab();
  const isPatientCountsView = isOnMobile() && !useNewSingleDayMobileView;
  const isNewSingleDayMobileView = isOnMobile() && useNewSingleDayMobileView;

  const { selected } = useAppSelector((state) => ({
    selected: state.houseView.pageFilters.selectedUnitId === unit.id,
  }));
  const selectedDate = useAppSelector((state) => state.houseView.pageFilters.selectedDateForData)!;

  const dateBeforeToday = selectedDate < dateToDateKey(localDayJs());
  const dateAfterToday = selectedDate > dateToDateKey(localDayJs());
  const { userIsKiosk } = useCurrentRole();
  const canManage = useCheckUserPermission("manage", EUnitPermissionAreas.houseView);
  const hideUpdatePatientCountButton =
    userIsKiosk || !canManage || isPatientCountsView || !withPatientCount || dateBeforeToday;

  const floatToCategories = useAppSelector(
    (state) => state.houseView.pageFilters.selectedStaffCategories,
    isEqual,
  );
  const actionIsInProgress = useAppSelector(
    (state) => !!state.houseView.sidebarCurrentAction.inProgress,
  );
  const actionIsDirty = useAppSelector((state) => state.houseView.sidebarCurrentAction.isDirty);
  const toastActionIsDirty = useCallback(() => {
    if (!selected) toast.showInfo("Cannot select a new unit, cancel action or save changes first");
  }, [toast, selected]);
  // Category for this unit
  const appConfig = useAppConfigQuery().data;
  const unitCategories =
    appConfig?.accessibleUnits.find(({ id }) => id === unit.id)?.staffCategoryKeys ||
    emptyCategories;
  const allAvailableCategories = appConfig?.staffCategories;

  // Filter out the house supervisor category
  const categories = useMemo(() => {
    const filteredCategories = orderBy(
      allAvailableCategories?.filter(
        ({ key }) => unitCategories.includes(key) && key !== "houseSupervisor",
      ),
      "name",
    );

    const allTab = enableAllTab ? [{ key: "all", name: "All", usedForTargetLevel: false }] : [];

    // for mobile just show All tab
    if (isOnMobile()) {
      return allTab as StaffCategory.DTO[];
    }

    return [
      ...allTab,
      ...(filteredCategories.length === 0
        ? allAvailableCategories?.filter(({ key }) => key !== "houseSupervisor") || []
        : filteredCategories),
    ] as StaffCategory.DTO[];
  }, [unitCategories, allAvailableCategories, enableAllTab]);

  // Staff Category filter
  const [selectedStaffCategoryTab, __setSelectedStaffCategoryTab] = useState<
    StaffCategory.EKey | string | undefined
  >(categories[0]?.key);

  const setSelectedStaffCategoryTab = useCallback(
    (category: StaffCategory.EKey | string, setUnit = true) => {
      // Do not change category if something is in progress
      if (!selected || !actionIsInProgress) {
        __setSelectedStaffCategoryTab(category);
      }
      // Only change selection if no action is in progress, and a unit is
      //  already selected.
      if (!actionIsInProgress && setUnit) onSelect?.(unit);
    },
    [onSelect, selected, actionIsInProgress, unit],
  );

  useEffect(() => {
    if (selected && selectedStaffCategoryTab && !actionIsInProgress) {
      dispatch(
        houseViewStore.state.selectStaffCategories(
          selectedStaffCategoryTab === "all"
            ? []
            : ([selectedStaffCategoryTab] as StaffCategory.EKey[]),
        ),
      );
    }
  }, [selectedStaffCategoryTab, dispatch, selected, actionIsInProgress]);

  const sortedShiftTypes = useAppSelector((state) => state.houseView.pageData.shiftTypes);

  // Filtered shifts, based on selected category
  const shiftByCategory = useMemo(() => {
    if (!selectedStaffCategoryTab) return undefined;

    const groupedShifts = groupBy(
      shifts,
      ({ staffId }) => staffDetails[staffId]?.staffType.staffCategoryKey,
    );
    keys(groupedShifts).forEach((categoryKey) => {
      groupedShifts[categoryKey] = groupedShifts[categoryKey]!.filter(
        ({ scheduleId, shiftTypeKey }) =>
          sortedShiftTypes[scheduleId]?.[shiftTypeKey]?.isCountedForRealTimeStaffingTarget,
      );
    });

    return groupedShifts;
  }, [selectedStaffCategoryTab, sortedShiftTypes, shifts, staffDetails]);

  useEffect(() => {
    if (actionIsInProgress && selectedStaffCategoryTab) {
      const newCategory = floatToCategories[0] || "all";
      if (selectedStaffCategoryTab !== newCategory) {
        setSelectedStaffCategoryTab(newCategory as StaffCategory.EKey, false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionIsInProgress]);

  const borderWidth = selected ? "3px" : "1px";

  const allStaffingTargets = useAppSelector(
    (state) => state.houseView.staffingLevels.allStaffingTargets,
  );
  const selectedStaffingTargetsArray = allStaffingTargets[unit.id] || [];
  // Grab target that have an id, meaning it's not a new target
  const latestStaffingTargetLevel = selectedStaffingTargetsArray?.find((target) =>
    get(target, "id"),
  );
  const latestPatientCount = latestStaffingTargetLevel?.patientCount;

  const tabs = useStaffingTabs(
    unit.id,
    categories,
    shiftByCategory,
    selectedStaffCategoryTab,
    selectedDate,
    latestStaffingTargetLevel,
  );

  // reset title of All tab to just "ALL"
  if (tabs?.[0]?.value === "all") {
    tabs[0].label = "All";
  }

  const unitName = useMemo(() => {
    if (!facilityHasManyTimezones) return unit.name;

    return (
      <Stack direction="row" spacing={3} alignItems="center">
        <Typography children={unit.name} />
        <Tooltip
          title={
            <Typography
              children={`${unit.timezone}  (${timezoneLabel(unit.timezone as Timezone, { format: "long" })})`}
              sx={{ color: white, fontSize: "13px" }}
            />
          }
        >
          <Chip
            className="timezone-chip"
            size="small"
            icon={<QueryBuilder />}
            label={timezoneLabel(unit.timezone as Timezone)}
          />
        </Tooltip>
      </Stack>
    );
  }, [facilityHasManyTimezones, unit.name, unit.timezone]);

  const selectedTab = tabs.find(({ value }) => value === selectedStaffCategoryTab);
  const shiftsToDisplay = useSortedStaffShifts(selectedTab?.shifts || emptyShifts, staffDetails);

  // Set default category
  useEffect(() => {
    if (!categories[0]) return;
    if (selectedTab?.customPosition) return;

    if (
      !categories.find(({ key }) => key === selectedStaffCategoryTab) ||
      selectedStaffCategoryTab === undefined
    ) {
      setSelectedStaffCategoryTab(categories[0].key, false);
    }
  }, [
    categories,
    selectedStaffCategoryTab,
    setSelectedStaffCategoryTab,
    shifts,
    staffDetails,
    selectedTab?.customPosition,
  ]);

  if (!selectedStaffCategoryTab) return <></>;

  const getPlusPatientCountButtonColor = () => {
    if (
      !latestStaffingTargetLevel &&
      Object.keys(unit.staffingLevelMatrix).length !== 0 &&
      !dateAfterToday
    ) {
      return "error";
    }
    return "darkPurple";
  };

  const convertToShiftsById = (id: string) => {
    const ret: Record<IUnitBasic["id"], IStaffShift[]> = {};
    ret[id] = allShifts ?? [];

    return ret;
  };

  const RenderStaffingModalOpen = ({ sx }: Pick<ButtonProps, "sx">) => (
    <CustomButton
      onClick={() => {
        dispatch(houseViewStore.state.setStaffingLevelModalIsOpen(true));
        dispatch(houseViewStore.state.setSelectedUnitId(unit.id));
      }}
      sx={{
        // icon styling
        minWidth: "30px",
        padding: "0px 0px !important",
        ".MuiButton-startIcon": { marginRight: 0, marginLeft: 0 },
        borderRadius: 100,
        ...sx,
      }}
      startIcon={<AddIcon />}
      // if there is no latestStaffingTargetLevel and there is a staffing level, show error color
      color={getPlusPatientCountButtonColor()}
      trackingLabel="Update Patient Count"
    />
  );

  return (
    <Box
      className="unit-card"
      key={unit.id + categories.map(({ key }) => key).join("")}
      display="flex"
      minWidth={"300px"}
      flex={"auto"}
      flexDirection="column"
      height={"fit-content"}
      mt={!isOnMobile() ? 3 : 0}
      mb={isNewSingleDayMobileView ? 0 : 3}
      py={isNewSingleDayMobileView ? 0 : 1}
      px={isPatientCountsView ? 2 : 0}
      position={"relative"}
      sx={{
        border: `${borderWidth} solid ${selected ? darkPurple : mediumGray}`,
        borderRadius: !isOnMobile() ? "0 10px 10px" : 2,
      }}
      mr={!isOnMobile() ? 2 : 0}
      onClick={() => (actionIsDirty ? toastActionIsDirty() : onSelect?.(unit))}
    >
      {/* If on Mobile, show the patient count, else show tabs etc. */}
      {isPatientCountsView ? (
        <Box className="unit-data-wrapper">
          <Box display="flex" flexDirection="row" alignItems="start" justifyContent="space-between">
            <Typography className="unit-name" children={unitName} />
            <CustomButton
              onClick={() => {
                dispatch(houseViewStore.state.setStaffingLevelModalIsOpen(true));
                dispatch(houseViewStore.state.setSelectedUnitId(unit.id));
              }}
              sx={{
                top: "-4px",
                // icon styling
                minWidth: "30px",
                padding: "0px 0px !important",
                ".MuiButton-startIcon": { marginRight: 0, marginLeft: 0 },
                borderRadius: 100,
              }}
              startIcon={<AddIcon />}
              // if there is no latestStaffingTargetLevel and there is a staffing level, show error color
              color={getPlusPatientCountButtonColor()}
              trackingLabel="Update Patient Count"
            />
          </Box>
          <Box className="staff-counts">
            <Typography>
              <b>Patients</b>:{" "}
              <span className="patient-count-number">{latestPatientCount || "N/A"}</span>
            </Typography>
            {tabs.map((tab) => (
              <Typography sx={tab.sx}>{tab.label}</Typography>
            ))}
          </Box>
        </Box>
      ) : (
        <>
          <CustomTabs<StaffCategory.EKey | string>
            className={"unit-card-tabs"}
            tracking={true}
            onChange={(category) =>
              setSelectedStaffCategoryTab(category, !isNewSingleDayMobileView)
            }
            tabs={tabs}
            value={selectedStaffCategoryTab}
            height="30px"
            sx={{
              "& > .MuiBox-root": { borderBottom: "none" },
              position: "absolute",
              top: "-30px",
              ...(selected && actionIsInProgress
                ? {
                    pointerEvents: "none",
                  }
                : {}),
              left: selected ? "-7px" : "-5px",
              ".custom-tab": {
                border: "none",
                borderBottom: `${borderWidth} solid ${selected ? darkPurple : mediumGray}`,
                color: lighten(black, 0.2),
                "&:after": {
                  content: '""',
                  position: "absolute",
                  top: "0",
                  left: "0",
                  right: "0",
                  bottom: "0",
                  background: "rgba(255,255,255,0.2)",
                },
                "&.selected": {
                  zIndex: 10,
                  borderLeft: `${borderWidth} solid ${
                    selectedStaffCategoryTab && selected ? darkPurple : mediumGray
                  }`,
                  borderRight: `${borderWidth} solid ${
                    selectedStaffCategoryTab && selected ? darkPurple : mediumGray
                  }`,
                  borderTop: `${borderWidth} solid ${
                    selectedStaffCategoryTab && selected ? darkPurple : mediumGray
                  }`,
                  borderBottom: "none",
                  color: black,
                  "&:after": {
                    background: "rgba(255,255,255,0)",
                  },
                },
              },
              ".MuiTabs-indicator": {
                display: "none",
              },
            }}
          />
          {!hideUpdatePatientCountButton && withPatientCount && !isNewSingleDayMobileView && (
            <RenderStaffingModalOpen
              sx={{
                position: "absolute",
                // brings it all the way to the right horizontally
                right: 0,
                // brings it above the card next to the tabs vertically
                top: "-30px",
              }}
            />
          )}
          {shiftsToDisplay ? (
            <>
              <Box className={"wrapper-staff-list-title"}>
                <Typography
                  className={"staff-list-title"}
                  variant="subtitle1"
                  fontWeight="500"
                  children={unitName}
                />

                {!hideUpdatePatientCountButton && withPatientCount && isNewSingleDayMobileView && (
                  <Box className={"patient-counts"}>
                    <Typography variant="subtitle1" fontWeight={500}>
                      <span className="patient-count-number">{latestPatientCount || "N/A"}</span>
                    </Typography>
                    <RenderStaffingModalOpen sx={{ maxHeight: "28px" }} />
                  </Box>
                )}
              </Box>

              <Box className={"unit-card-container"}>
                {selectedTab?.value === "all" ? (
                  <>
                    <SelectedUnit
                      key={"unit-card-selected-unit-" + unit.id}
                      selectedUnit={unit}
                      shifts={convertToShiftsById(unit.id)}
                      staffDetails={staffDetails}
                      notes={notes}
                      notesFromStaff={notesFromStaff}
                      compressed={true}
                      unitsByScheduleId={{}}
                      unitCard={true}
                    />
                  </>
                ) : (
                  <>
                    {shiftsToDisplay.map((shift) => (
                      <HouseView.UnitCardStaff
                        key={"unit-card-staff-member-" + shift.id}
                        unitId={unit.id}
                        shiftType={sortedShiftTypes[shift.scheduleId]?.[shift.shiftTypeKey]}
                        shift={shift}
                        staffDetails={staffDetails}
                        notes={notes}
                        notesFromStaff={notesFromStaff}
                      />
                    ))}
                  </>
                )}
                {selectedTab?.customPosition
                  ? null
                  : otherShifts?.(selectedStaffCategoryTab as StaffCategory.EKey)}
              </Box>
            </>
          ) : (
            "No staff planned for this day"
          )}
        </>
      )}
    </Box>
  );
};

export const __HouseViewUnitCard = UnitCard;
