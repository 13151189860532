import { IAttribute, IShiftType, IStaffCategory, IStaffType } from "@/api";
import { TimeString, TTargetLevelKey, TTimeTargetLevelKey } from "@/common/types";

export const getTargetLevelKey = ({
  staffCategoryKey,
  staffTypeKey,
  shiftTypeKey,
  shiftTypeKeys,
  attributeKey,
  eligibleOnly = false,
}: {
  staffCategoryKey?: IStaffCategory["key"] | null;
  staffTypeKey?: IStaffType["key"] | null;
  shiftTypeKey?: IShiftType["key"];
  shiftTypeKeys?: IShiftType["key"][];
  attributeKey?: IAttribute["key"] | null;
  eligibleOnly?: boolean;
}) => {
  const staffCategory = staffCategoryKey || "_NO_STAFF_CATEGORY_";
  const staffType = staffTypeKey || "_NO_STAFF_TYPE_";
  const attribute = attributeKey || "_NO_ATTRIBUTE_";
  const assignedOrEligible = eligibleOnly ? "eligible" : "assigned";
  const shiftType = shiftTypeKeys?.length
    ? shiftTypeKeys.join(",")
    : shiftTypeKey || "_NO_SHIFT_TYPE_";

  return `category_${staffCategory}-type_${staffType}-shiftType_${shiftType}-attribute_${attribute}-assignedOrEligible_${assignedOrEligible}` as TTargetLevelKey;
};

export const getTimeTargetLevelKey = ({
  startTime,
  endTime,
  staffCategoryKey,
}: {
  startTime: TimeString;
  endTime: TimeString;
  staffCategoryKey: IStaffCategory["key"];
}) => {
  return `startTime_${startTime}-endTime_${endTime}-category_${staffCategoryKey}` as TTimeTargetLevelKey;
};
