import { memo } from "react";

import { YyyyMmDd } from "@m7-health/shared-utils";
import { isEmpty, isEqual } from "lodash";

import { IScheduleShiftType, Schedule, Unit } from "~/api";
import { Dayjs } from "~/common/packages/dayjs";

import { THouseViewState } from "#/features/HouseView/store";
import { useAppSelector, useDeepMemo } from "@/common/hooks";

import { useOrganizeTargetLevelDataForSchedules } from "../hooks/useOrganizeTargetLevelDataForSchedules";
import { ITablesData } from "../types";

import { HeaderCell } from "./HeaderCell";
import { HeaderNameCell } from "./HeaderNameCell";

export const ScheduleTableHeaderRow = memo(function MemoizedScheduleTableHeaderRow({
  datesRows,
  formattedDateRows,
  unit,
  schedules,
  tablesData,
  expanded,
  setExpandedUnits,
  shiftTypes,
  showSixWeekViewShiftTypeSummaryFF,
}: {
  datesRows: Dayjs[];
  formattedDateRows: YyyyMmDd[];
  unit: Unit.DTO;
  schedules: Schedule.DTO[];
  tablesData: ITablesData;
  expanded: boolean;
  setExpandedUnits: (value: React.SetStateAction<Record<string, boolean>>) => void;
  shiftTypes: THouseViewState["pageData"]["shiftTypes"];
  showSixWeekViewShiftTypeSummaryFF: boolean;
}) {
  const targetData = useOrganizeTargetLevelDataForSchedules({
    schedules,
    unitId: unit.id,
  });

  const stateData = useAppSelector(
    (state) => ({
      selectedStaffCategories: state.houseView.pageFilters.selectedStaffCategories,
      showTargetLevels: state.houseView.pageFilters.showTargetLevels,
      customTimeRange: state.houseView.pageFilters.customTimeRange,
      gridFilters: state.houseView.pageFilters.gridFilters,
      targetLevelCounts: state.houseView.pageData.targetLevelCounts,
      targetLevels: state.houseView.pageData.targetLevels,
    }),
    isEqual,
  );

  const allShiftTypes = useDeepMemo(() => {
    if (isEmpty(shiftTypes)) return null;

    return schedules.reduce(
      (acc, schedule) => {
        acc = { ...acc, ...shiftTypes[schedule.id] };
        return acc;
      },
      {} as { [shiftTypeKey: IScheduleShiftType["key"]]: IScheduleShiftType },
    );
  }, [schedules, shiftTypes]);

  // Check if targetData and shift types are ready (null vs something)
  // And if we have at least one schedule to show
  if (!targetData || !allShiftTypes || isEmpty(schedules)) return null;

  return (
    <tr>
      {/* Corner cell */}
      <th className="sticky-col sticky-corner">
        <HeaderNameCell
          unit={unit}
          expanded={expanded}
          setExpandedUnits={setExpandedUnits}
          shiftTypes={allShiftTypes}
          targetData={targetData}
          stateData={stateData}
        />
      </th>

      {/* Add a column for each date */}
      {datesRows.map((date, index) => {
        const formattedDate = formattedDateRows[index] || date.format("YYYY-MM-DD");

        const classNames = ["sticky-row"];
        if (date.day() === 6) classNames.push("sunday");
        classNames.push(formattedDate);

        return (
          <th key={formattedDate} className={classNames.join(" ")}>
            <div>
              <HeaderCell
                key={formattedDate + "-header" + unit.id}
                cellDate={date}
                // pass date as columnKey
                columnKey={formattedDate}
                schedules={schedules}
                tablesData={tablesData}
                unit={unit}
                shiftTypes={allShiftTypes}
                showSixWeekViewShiftTypeSummaryFF={showSixWeekViewShiftTypeSummaryFF}
                targetData={targetData}
                stateData={stateData}
              />
            </div>
          </th>
        );
      })}
    </tr>
  );
});
