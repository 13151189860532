import "./SideBar.scss";

import { memo, useCallback, useEffect, useMemo } from "react";

import { EUnitPermissionAreas, getTzDate } from "@m7-health/shared-utils";
import { isEqual, sortBy } from "lodash";

import { Close, KeyboardArrowLeft } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";

import { IStaffDetails } from "~/api";
import CustomButton from "~/common/components/TrackedComponents/Button";
import { NOT_EXISTING_UUID } from "~/common/constants";
import { useCurrentFacilityId } from "~/common/hooks/useCurrentFacilityId";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { useToast } from "~/common/hooks/useToast";
import { mediumGray } from "~/common/theming/colors";
import { isOnMobile } from "~/common/utils/isOnMobile";
import { MixpanelProvider } from "~/modules/mixpanel/Provider";
import { Mxp } from "~/modules/mixpanel/types";

import { useAppConfigQuery } from "#/features/User/queries";
import { openShiftsSidebarActions } from "@/common/components/OpenShiftSidebarContent/store";
import {
  useCheckUserPermission,
  useCurrentRole,
  useCurrentTimezone,
  useFilterBy,
} from "@/common/hooks";

import { houseViewStore } from "../../store";
import { EViewLengthOptions } from "../../store/pageFiltersActions";
import { OpenShiftsSidebarHeader } from "../OpenShiftsSidebarHeader";

import { EditShiftsV2 } from "./states/EditShiftsV2";
import { FloatToUnitV2 } from "./states/FloatToUnitV2";
import { OpenShifts } from "./states/OpenShifts";
import { UpdateStatus } from "./states/UpdateStatus";
import { THouseViewSideBar } from "./types";

import { HouseViewSideBar } from ".";

const SideBarStates = HouseViewSideBar.States;

const componentByAction = () =>
  ({
    floating: FloatToUnitV2,
    assignAttributes: SideBarStates.AssignAttributes,
    findStaffToWork: SideBarStates.FindStaffToWork,
    editShifts: EditShiftsV2,
    updateStatus: UpdateStatus,
    openShifts: OpenShifts,
  }) as const;
const headerContentByAction = () =>
  ({
    floating: (unitName: string) => <Typography>Float Staff to {unitName}</Typography>,
    assignAttributes: () => <Typography>Assign Positions</Typography>,
    findStaffToWork: () => <Typography>Find Staff to Work</Typography>,
    editShifts: (_unitName: string, staffDetails?: IStaffDetails) => (
      <Typography>
        {staffDetails
          ? `Edit ${staffDetails.user.lastName || ""} ${staffDetails.user.firstName || ""}'s`
          : "Saving"}
        &nbsp;Schedule
      </Typography>
    ),
    updateStatus: () => <Typography>Add Status Update</Typography>,
    openShifts: () => <OpenShiftsSidebarHeader />,
  }) as const;

const SideBar = (props: Omit<THouseViewSideBar, "selectedUnit">) => {
  /** HIGH-LEVEL STATE */
  const dispatch = useAppDispatch();
  const { showInfo } = useToast();

  /** USER STATE */
  const mobileView = isOnMobile();
  const { userIsKiosk } = useCurrentRole();
  const canManage = useCheckUserPermission("manage", EUnitPermissionAreas.houseView);

  const {
    selectedUnitId,
    selectedDateForData,
    currentAction,
    isSidebarActionDirty,
    selectedStaffDetails,
    bannerIsOpen,
    staffDatesMetadata,
    isMultiWeekViewSelected,
    openShiftSelectedDate,
    isSidebarExpanded,
  } = useAppSelector((state) => {
    const selectedStaffId = state.houseView.editShifts.staffId;
    return {
      bannerIsOpen: state.user.bannerIsOpen,
      selectedUnitId: state.houseView.pageFilters.selectedUnitId,
      selectedDateForData: state.houseView.pageFilters.selectedDateForData,
      currentAction: state.houseView.sidebarCurrentAction.inProgress,
      isSidebarActionDirty: state.houseView.sidebarCurrentAction.isDirty,
      selectedStaffDetails: selectedStaffId
        ? state.houseView.pageData.staffDetails[selectedStaffId]
        : undefined,
      staffDatesMetadata: state.houseView.pageData.metadataByStaffId,
      isMultiWeekViewSelected:
        state.houseView.pageFilters.selectedViewLength === EViewLengthOptions.sixWeek,
      openShiftSelectedDate: state.openShiftsSidebar.selectedDate,
      isSidebarExpanded: state.houseView.pageFilters.isSidebarExpanded,
    };
  }, isEqual);
  const timezone = useCurrentTimezone(selectedUnitId);

  const selectedUnit = useMemo(
    () => (selectedUnitId && props.units?.[selectedUnitId]) || null,
    [selectedUnitId, props.units],
  );
  const aUnitIsSelected = !!selectedUnitId;

  const facilityId = useCurrentFacilityId() || NOT_EXISTING_UUID;
  const unFilteredUnits = useAppConfigQuery().data?.accessibleUnits;
  const unOrderedUnits = useFilterBy(unFilteredUnits, (unit) => unit.facilityId === facilityId, [
    facilityId,
  ]);

  const ComponentToDisplay = useMemo(() => {
    if (currentAction) return componentByAction()[currentAction];

    if (aUnitIsSelected) return SideBarStates.SelectedUnit;

    return null;
  }, [currentAction, aUnitIsSelected]);

  const cancelAction = useCallback(() => {
    if (isSidebarActionDirty) {
      showInfo("You have unsaved changes. Please save or cancel first.");
      return;
    } else if (currentAction === "openShifts" && !openShiftSelectedDate) {
      dispatch(openShiftsSidebarActions.setSidebarSelectedDate(selectedDateForData));
      return;
    }
    dispatch(houseViewStore.state.endAction());
  }, [
    dispatch,
    isSidebarActionDirty,
    showInfo,
    currentAction,
    openShiftSelectedDate,
    selectedDateForData,
  ]);

  const actionIsInProgress = !!currentAction;
  const selectedUnitName = selectedUnit?.name || "";
  const editOrView = userIsKiosk || !canManage ? "View" : "Edit";
  const headerContent = currentAction ? (
    headerContentByAction()[currentAction](selectedUnitName, selectedStaffDetails)
  ) : (
    <Stack direction="column" alignItems="flex-start" justifyContent="space-between">
      <Typography>{`${editOrView} unit: ${selectedUnitName}`}</Typography>
      <Typography sx={{ fontSize: "14px" }}>
        {`${getTzDate(selectedDateForData, timezone).toFormat("LL/dd/yyyy")}`}
      </Typography>
    </Stack>
  );

  // Select first unit
  useEffect(() => {
    if (isOnMobile()) return;
    const orderedUnits = sortBy(unOrderedUnits || [], "sortPosition", "name");
    if (!orderedUnits?.length) return;
    if (!selectedUnitId || !orderedUnits.find(({ id }) => id === selectedUnitId)) {
      const firstUnitId = orderedUnits[0]?.id;
      if (firstUnitId) {
        dispatch(houseViewStore.state.selectUnit(firstUnitId));
      }
    }
  }, [selectedUnitId, unOrderedUnits, dispatch, canManage]);

  return (
    <MixpanelProvider
      properties={{
        [Mxp.Property.layout.section]: `sidebar${isMultiWeekViewSelected ? "-multi-week" : ""}`,
        [Mxp.Property.layout.subSection]: currentAction || "__none__",
      }}
    >
      <Box
        className={`house-view-sidebar hide-on-print ${currentAction || "selected-unit"} ${
          !isSidebarExpanded ? "collapsed" : ""
        }`}
        position={"relative"}
        height={`calc(100vh - ${bannerIsOpen ? "160px" : "110px"})`}
        sx={{
          overflow: "hidden",
          border: `1px solid ${mediumGray}`,
          borderRadius: "5px",
          marginTop: "8px",
          marginRight: "8px",
          marginLeft: "8px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box className="header-container">
          {actionIsInProgress && (
            <CustomButton
              trackingLabel="cancel action"
              iconOnly
              onClick={cancelAction}
              startIcon={<KeyboardArrowLeft />}
              sx={{ p: 0.5 }}
            />
          )}
          {!actionIsInProgress && selectedUnit && mobileView && (
            <CustomButton
              trackingLabel="back-to-unit-list"
              iconOnly
              startIcon={<KeyboardArrowLeft />}
              onClick={() => dispatch(houseViewStore.state.selectUnit(null))}
            />
          )}
          <div className={`header-content ${actionIsInProgress ? "with-action" : ""}`}>
            {headerContent}
          </div>
          {!mobileView && (
            <CustomButton
              className="house-view-collapse-sidebar-button"
              iconOnly
              size="small"
              startIcon={<Close sx={{ fontSize: "24px" }} />}
              trackingLabel="collapse-sidebar"
              onClick={() =>
                dispatch(houseViewStore.state.setIsSidebarExpanded(!isSidebarExpanded))
              }
            />
          )}
        </Box>
        <Box className="sidebar-content">
          {ComponentToDisplay && selectedUnit && (
            <>
              <Box sx={{ paddingX: "10px", paddingTop: "16px" }}>
                <HouseViewSideBar.Helpers.TopActions />
              </Box>
              <ComponentToDisplay
                {...props}
                staffDatesMetadata={staffDatesMetadata}
                selectedUnit={selectedUnit}
                key={"house-view-sidebar-" + (selectedUnitId || "") + (currentAction || "")}
              />
            </>
          )}
        </Box>
      </Box>
    </MixpanelProvider>
  );
};
export const __HouseViewSideBar = memo(SideBar);
