import { memo } from "react";

import { Dayjs, Uuid, YyyyMmDd } from "@m7-health/shared-utils";

import { IScheduleShiftType } from "~/api/shiftType";
import { IUnit } from "~/api/unit";

import { ISchedule, IStaffDetails, IStaffShift } from "@/api";

import { useSetOpenShiftDataForHouseView } from "./MultiWeekHouseViewTable/hooks/useSetOpenShiftDataForHouseView";
import { useSetTargetLevelCounts } from "./MultiWeekHouseViewTable/hooks/useSetTargetLevelCounts";
import { useSetTargetLevels } from "./MultiWeekHouseViewTable/hooks/useSetTargetLevels";

//Sets the necessary open shifts and target levels data for the multi week view table
export const SetMultiWeekViewData = memo(
  ({
    schedules,
    units,
    shiftTypesByScheduleIdByKey,
    datesRange,
    staffDetailsByStaffId,
    shiftsByScheduleId,
  }: {
    schedules: ISchedule[];
    units: IUnit[];
    shiftTypesByScheduleIdByKey: Record<
      Uuid,
      Record<IScheduleShiftType["key"], IScheduleShiftType>
    >;
    datesRange: [Dayjs, YyyyMmDd][];
    staffDetailsByStaffId: Record<string, IStaffDetails>;
    shiftsByScheduleId: Record<string, IStaffShift[]>;
  }) => {
    useSetTargetLevels({
      schedules,
      unitIds: units.map((unit) => unit.id),
      shiftTypes: shiftTypesByScheduleIdByKey,
      datesRange: datesRange,
    });
    useSetOpenShiftDataForHouseView({
      schedules,
      shiftTypesByScheduleIdByKey,
      staffDetailsByStaffId,
    });
    useSetTargetLevelCounts({
      schedules,
      shiftTypesByScheduleIdByKey,
      shiftsByScheduleId,
      staffDetailsByStaffId,
    });
    return null;
  },
);
