import { buildApiMutationHook } from "../_shared/helpers";

import { BeAsyncJob } from "./types";

export const useQueueDummyJob = buildApiMutationHook<
  BeAsyncJob.API.Dummy.QueryParams,
  BeAsyncJob.API.Dummy.Response
>(BeAsyncJob.API.Dummy.PATH);

export const useQueueResetStaffPreferredHoursJob = buildApiMutationHook<
  BeAsyncJob.API.ResetStaffPreferredHours.QueryParams,
  BeAsyncJob.API.ResetStaffPreferredHours.Response
>(BeAsyncJob.API.ResetStaffPreferredHours.PATH);

export const useSendEmail = buildApiMutationHook<
  BeAsyncJob.API.SendEmail.QueryParams,
  BeAsyncJob.API.SendEmail.Response
>(BeAsyncJob.API.SendEmail.PATH);

export const useUploadPTORequestsCSV = buildApiMutationHook<
  BeAsyncJob.API.UploadPTORequestsCSV.QueryParams,
  BeAsyncJob.API.UploadPTORequestsCSV.Response
>(BeAsyncJob.API.UploadPTORequestsCSV.PATH, "postForm");

export const useQueueCreateBlankSubmissions = buildApiMutationHook<
  BeAsyncJob.API.CreateBlankSubmissions.QueryParams,
  BeAsyncJob.API.CreateBlankSubmissions.Response
>(BeAsyncJob.API.CreateBlankSubmissions.PATH);

export const useUploadStaffingLevelMatrixCSV = buildApiMutationHook<
  BeAsyncJob.API.UploadStaffingLevelMatrixCSV.QueryParams,
  BeAsyncJob.API.UploadStaffingLevelMatrixCSV.Response
>(BeAsyncJob.API.UploadStaffingLevelMatrixCSV.PATH, "postForm");

export const useTriggerZendeskUserSync = buildApiMutationHook<
  BeAsyncJob.API.TriggerZendeskUserSync.QueryParams,
  BeAsyncJob.API.TriggerZendeskUserSync.Response
>(BeAsyncJob.API.TriggerZendeskUserSync.PATH);

export const useTriggerOpenShiftV2DataMigration = buildApiMutationHook<
  BeAsyncJob.API.TriggerOpenShiftV2DataMigration.QueryParams,
  BeAsyncJob.API.TriggerOpenShiftV2DataMigration.Response
>(BeAsyncJob.API.TriggerOpenShiftV2DataMigration.PATH);
