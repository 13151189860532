import { memo, useMemo, useState } from "react";

import { getTzDayjs, getTzFormattedDate, Timezone, YyyyMmDd } from "@m7-health/shared-utils";
import { isEqual, keyBy, map, sortBy, uniq } from "lodash";

import { Box, CircularProgress } from "@mui/material";

import {
  ISchedule,
  IUnit,
  StaffDetails,
  useListSchedulesQuery,
  useListStaffDetailsQuery,
  useListStaffShiftsQuery,
  useListUnitsQuery,
} from "~/api";
import { NOT_EXISTING_UUID } from "~/common/constants";
import { useCurrentFacilityId } from "~/common/hooks/useCurrentFacilityId";
import { useAppSelector } from "~/common/hooks/useRedux";
import { Dayjs } from "~/common/packages/dayjs";
import { Uuid } from "~/common/types";

import {
  useAppFlags,
  useDeepMemo,
  useFacilityTimezones,
  useGroupBy,
  useKeyBy,
  useTimezoneByUnitId,
} from "@/common/hooks";
import { compareStaffOrder } from "@/common/utils/compareStaffOrder";
import { getDatesInRange } from "@/common/utils/dates";

import { StaffCalendarHooks } from "../../../../common/components/StaffCalendar/hooks";
import { HouseViewHooks } from "../../hooks";
import { SetMultiWeekViewData } from "../SetMultiWeekViewData";

import { useComputeTablesData } from "./hooks/useComputeTablesData";
import { useGridActions } from "./hooks/useGridActions";
import { useSynchronizedHorizontalScroll } from "./hooks/useSynchronizedHorizontalScroll";
import { TableStyleContainer } from "./style";

import { TableParts } from ".";

const { useSchedulesShiftTypes, useUnitShiftTypes } = StaffCalendarHooks;
const { useSelectedDay } = HouseViewHooks;

const emptySchedules: ISchedule[] = [];
const emptyUnits: IUnit[] = [];
const emptyStaffDetails: StaffDetails.DTO[] = [];

const MultiWeekHouseViewTable = memo(function MemoizedMultiWeekHouseViewTable() {
  const selectedFacilityId = useCurrentFacilityId();
  const showSixWeekViewShiftTypeSummaryFF = useAppFlags().showSixWeekViewShiftTypeSummary;
  const { selectedStaffCategories, unitIdsFilter } = useAppSelector(
    (state) => ({
      selectedStaffCategories: state.houseView.pageFilters.selectedStaffCategories,
      unitIdsFilter: state.houseView.pageFilters.unitIds,
    }),
    isEqual,
  );

  const facilityTimezones = useFacilityTimezones();
  const timezoneByUnitId = useTimezoneByUnitId();

  const { datePickerValue: selectedDay } = useSelectedDay();
  const rangesByTimezone = useMemo(
    () =>
      facilityTimezones.reduce(
        (allRanges, timezone) => {
          const from = getTzDayjs(selectedDay, timezone);
          const to = getTzDayjs(selectedDay, timezone).addInTz(6, "week");
          const allDays = getDatesInRange(from, to);
          const formattedDays = map(allDays, (day) => getTzFormattedDate(day));
          allRanges[timezone] = {
            from,
            to,
            allDays,
            formattedDays,
          };
          return allRanges;
        },
        {} as Record<
          Timezone,
          { from: Dayjs; to: Dayjs; allDays: Dayjs[]; formattedDays: YyyyMmDd[] }
        >,
      ),
    [facilityTimezones, selectedDay],
  );

  const firstRange = useDeepMemo(
    () => facilityTimezones[0] && rangesByTimezone[facilityTimezones[0]],
    [facilityTimezones, rangesByTimezone],
  );

  /** QUERIES */
  // Get all shifts for the selected facility and date range
  const { data: staffShifts = [] } = useListStaffShiftsQuery(
    {
      date: firstRange
        ? [
            { value: getTzFormattedDate(firstRange.from), operator: "gte" },
            { value: getTzFormattedDate(firstRange.to), operator: "lte" },
          ]
        : [],
    },
    { skip: !selectedFacilityId || !firstRange },
  );
  // Get the rest of the data needed to display the shifts
  const { data: allSchedules = emptySchedules } = useListSchedulesQuery({});
  const { data: unfilteredUnits = emptyUnits } = useListUnitsQuery(
    { facilityId: selectedFacilityId || NOT_EXISTING_UUID },
    { skip: !selectedFacilityId },
  );
  const { data: allStaffDetails = emptyStaffDetails, isLoading: isLoadingStaffDetails } =
    useListStaffDetailsQuery({}, { skip: staffShifts.length === 0 });

  const units = useMemo(() => {
    let results = unfilteredUnits.filter((unit) => !unit.archivedAt);
    results = unitIdsFilter.length
      ? results.filter((unit) => unitIdsFilter.includes(unit.id))
      : results;
    return results;
  }, [unitIdsFilter, unfilteredUnits]);

  /** INDEXING/CACHING data */
  // staff shifts
  const shiftsByScheduleId = useGroupBy(staffShifts, "scheduleId");
  // schedules
  const scheduleIds = useDeepMemo(() => Object.keys(shiftsByScheduleId), [shiftsByScheduleId]);
  // include any schedule that could potentially have a shift in between the from / to range
  // needs to be looking at startDay and endDay, and if either is in the range, include the schedule
  const schedules = useMemo(() => {
    if (!allSchedules.length) return emptySchedules;

    return allSchedules.filter(
      (schedule) =>
        scheduleIds.includes(schedule.id) &&
        firstRange &&
        (schedule.startDay >= getTzFormattedDate(firstRange.from) ||
          schedule.startDay <= getTzFormattedDate(firstRange.to) ||
          schedule.endDay >= getTzFormattedDate(firstRange.from) ||
          schedule.endDay <= getTzFormattedDate(firstRange.to)),
    );
  }, [allSchedules, scheduleIds, firstRange]);
  // shift types
  const unitsById = useMemo(() => keyBy(units, "id"), [units]);
  const shiftTypesByScheduleIdByKey = useSchedulesShiftTypes(scheduleIds);
  const shiftTypesByUnitIdByKey = useUnitShiftTypes(Object.keys(unitsById));

  // units
  const unitsByScheduleIds = useMemo(
    () =>
      schedules.reduce(
        (acc, schedule) => {
          acc[schedule.id] = unitsById[schedule.unitId]!;
          return acc;
        },
        {} as Record<string, (typeof units)[number]>,
      ),
    [schedules, unitsById],
  );
  // Staff details
  const staffDetailsByStaffId = useKeyBy(allStaffDetails, "userId");

  // there can be a lot of shifts incoming. So the method of basing the memoized data
  //  bellow (staffByUnitId) on a more "scoped" data set with uniq elements is more efficient
  const staffAndScheduleIds = useDeepMemo(() => {
    return uniq(staffShifts.map(({ scheduleId, staffId }) => [scheduleId, staffId])).sort() as [
      Uuid,
      Uuid,
    ][];
  }, [staffShifts]);
  const staffByUnitId = useMemo(() => {
    return staffAndScheduleIds.reduce(
      (acc, [scheduleId, staffId]) => {
        (acc[unitsByScheduleIds?.[scheduleId]?.id || ""] ||= new Set<string>()).add(staffId);
        return acc;
      },
      {} as Record<string, Set<string>>,
    );
  }, [staffAndScheduleIds, unitsByScheduleIds]);

  const tablesData = useComputeTablesData({
    schedules,
    shiftsByScheduleId,
    staffDetailsByStaffId,
    selectedStaffCategories: selectedStaffCategories,
    shiftTypesByScheduleIdByKey,
    timezoneByUnitId,
  });

  const [expandedUnits, setExpandedUnits] = useState<Record<string, boolean>>({});

  useSynchronizedHorizontalScroll();
  const gridActions = useGridActions();

  const datesRange = useMemo(() => {
    if (!firstRange) return null;

    return firstRange.allDays.map(
      (day, index) => [day, firstRange.formattedDays[index]!] as [Dayjs, YyyyMmDd],
    );
  }, [firstRange]);

  const getCachedIndexedStaffs = useMemo(() => {
    const indexedStaffs: Record<IUnit["id"], Uuid[]> = {};

    return (unit: IUnit) => {
      const cachedStaffs = indexedStaffs[unit.id];
      if (cachedStaffs) return cachedStaffs;

      const sortStaffByPrimaryShiftTypeFlag =
        unit?.configuration?.settings?.schedulerApp?.sortStaffByPrimaryShiftTypeInSchedulerGrid;
      const availableShiftTypes = shiftTypesByUnitIdByKey[unit.id] || {};

      // sort staff by primary shift type, employment type, and last name
      const staffSorting = (staffIdA: Uuid, staffIdB: Uuid) => {
        const staffA = staffDetailsByStaffId[staffIdA];
        const staffB = staffDetailsByStaffId[staffIdB];
        if (!staffA || !staffB) return 0;
        return compareStaffOrder(
          sortStaffByPrimaryShiftTypeFlag,
          staffA,
          staffB,
          unit.id,
          availableShiftTypes,
        );
      };

      const staffsOfFilteredCategory = [...(staffByUnitId[unit.id] || [])]
        .filter((staffId) => {
          const staffCategoryKey = staffDetailsByStaffId[staffId]?.staffType.staffCategoryKey;
          return (
            staffCategoryKey &&
            (!selectedStaffCategories.length || selectedStaffCategories.includes(staffCategoryKey))
          );
        })
        .sort(staffSorting);

      indexedStaffs[unit.id] = staffsOfFilteredCategory;

      return staffsOfFilteredCategory;
    };
  }, [staffByUnitId, staffDetailsByStaffId, selectedStaffCategories, shiftTypesByUnitIdByKey]);

  const schedulesByUnitId = useGroupBy(schedules, "unitId");

  if (isLoadingStaffDetails || !datesRange || !firstRange || !schedules.length) {
    return <CircularProgress></CircularProgress>;
  }

  // Return one table per UNIT now
  // Each table contains multiple header rows per available range
  // first header column is the range, and the staff names
  // then it's one column per day
  // each header row range cell contains the sum of the shifts for this range and this day
  // each staff row contains the shifts for this staff for this day
  return (
    <Box className="six-week-schedules-wrapper" overflow="auto">
      <SetMultiWeekViewData
        schedules={schedules}
        units={units}
        shiftTypesByScheduleIdByKey={shiftTypesByScheduleIdByKey}
        datesRange={datesRange}
        staffDetailsByStaffId={staffDetailsByStaffId}
        shiftsByScheduleId={shiftsByScheduleId}
      />
      <TableParts.TotalCountHeader
        datesRows={firstRange.allDays}
        formattedDateRows={firstRange.formattedDays}
        schedules={schedules}
        tablesData={tablesData}
      />

      {sortBy(units, "name").map((unit) => {
        const range = rangesByTimezone[unit.timezone];
        if (!range) return null;

        return (
          <Box
            key={`unit-table-${unit.id}`}
            mt={3}
            maxHeight="80vh"
            boxSizing="border-box"
            display="flex"
            overflow={"auto"}
            flexDirection="column"
            className="six-week-schedules-table-container"
            position="relative"
          >
            <TableStyleContainer className="multi-weeks-sync-scroll">
              <table
                className={`scheduler-table mini-view v2`}
                style={{ tableLayout: "fixed", borderCollapse: "separate", borderSpacing: 0 }}
              >
                <tbody>
                  <TableParts.HeaderRow
                    datesRows={range.allDays}
                    formattedDateRows={range.formattedDays}
                    unit={unit}
                    schedules={schedulesByUnitId[unit.id] || emptySchedules}
                    tablesData={tablesData}
                    expanded={!!expandedUnits[unit.id]}
                    setExpandedUnits={setExpandedUnits}
                    shiftTypes={shiftTypesByScheduleIdByKey}
                    showSixWeekViewShiftTypeSummaryFF={showSixWeekViewShiftTypeSummaryFF}
                  />

                  {expandedUnits[unit.id] && (
                    <TableParts.StaffRows
                      gridActions={gridActions}
                      staffIdsOfFilteredCategory={getCachedIndexedStaffs(unit)}
                      staffDetailsByStaffId={staffDetailsByStaffId}
                      datesRowsFormatted={range.formattedDays}
                      schedules={schedulesByUnitId[unit.id] || emptySchedules}
                      tablesData={tablesData}
                      unit={unit}
                      shiftTypes={shiftTypesByScheduleIdByKey}
                    />
                  )}
                </tbody>
              </table>
            </TableStyleContainer>
          </Box>
        );
      })}
    </Box>
  );
});

export const HouseViewMultiWeekTable = MultiWeekHouseViewTable;
