import { useMemo } from "react";

import { getTzDate } from "@m7-health/shared-utils";
import {
  compact,
  entries,
  filter,
  flatMap,
  get,
  groupBy,
  includes,
  intersection,
  map,
  pick,
  sortBy,
  uniqBy,
  values,
} from "lodash";

import {
  IOpenShift,
  IOpenShiftRequest,
  IOrphanOpenShiftRequest,
  IRoster,
  OpenShiftRequest,
} from "@/api";
import { useM7SimpleContext, useUnitsByScheduleId } from "@/common/hooks";

export type TOpenShiftGroup = {
  value: string;
  className: string;
  openShifts: IOpenShift[];
  openShiftRequests: IOpenShiftRequest[];
  openShiftLiveCount: number;
};

// Expose open shift data for a specific day and organize them
//  into tabs. Also expose tab selection and tab change handlers
export const useSingleDayOpenShiftGroups = ({
  openShifts,
  openShiftRequests,
  requesterRosterIds,
}: {
  openShifts: IOpenShift[] | undefined;
  openShiftRequests?: (IOpenShiftRequest | IOrphanOpenShiftRequest)[];
  requesterRosterIds?: IRoster["id"][];
}) => {
  const { currentUnitId, currentTimezone } = useM7SimpleContext();
  const unitsBySchedule = useUnitsByScheduleId();

  const unsortedOpenShiftGroups = useMemo(() => {
    const combinedOpenShifts = uniqBy(
      [
        ...(openShifts || []),
        ...flatMap(openShiftRequests, (request) => get(request, "openShifts") || []),
      ],
      "id",
    );
    // Index open shifts by tab key
    const indexedOpenShifts: Record<string, IOpenShift[]> = groupBy(
      sortBy(combinedOpenShifts, "shiftType").filter(
        (openShift) => openShift.shiftCount || openShift.openShiftRequests?.length,
      ),
      (openShift) => buildOpenShiftGroupKey(openShift),
    );

    // Build open shift variation tabs
    const openShiftVariationTabs = entries(indexedOpenShifts).map(([key, groupOpenShifts]) => {
      const openShiftIds = map(groupOpenShifts, "id");
      const requests = compact(
        flatMap(groupOpenShifts, "openShiftRequests"),
      ) as IOpenShiftRequest[];
      const orphanRequests = filter(openShiftRequests, (request) => {
        if (!("openShifts" in request)) return false;

        const requestOpenShiftIds = map(request.openShifts, "id");
        return intersection(openShiftIds, requestOpenShiftIds).length > 0;
      });
      const combinedRequests = uniqBy([...requests, ...orphanRequests], "id");
      const filteredRequests = requesterRosterIds
        ? filter(combinedRequests, (request) =>
            includes(requesterRosterIds, request.requesterId as unknown as number),
          )
        : combinedRequests;

      return {
        value: key,
        className: "open-shift-variation-tab",
        openShifts: groupOpenShifts,
        openShiftRequests: filteredRequests,
        openShiftLiveCount: groupOpenShifts.filter(({ deletedAt }) => !deletedAt).length,
      };
    });

    return openShiftVariationTabs;
  }, [openShiftRequests, openShifts, requesterRosterIds]);

  const pendingRequestsByOpenShiftId = useMemo(
    () =>
      openShifts?.reduce(
        (acc, openShift) => {
          openShift.openShiftRequests
            ?.filter((req) => req.status === OpenShiftRequest.EStatus.pending)
            .forEach((request) => {
              if (
                requesterRosterIds &&
                !includes(requesterRosterIds, request.requesterId as unknown as number)
              )
                return;

              (acc[openShift.id] ||= []).push(request);
            });

          return acc;
        },
        {} as { [key in IOpenShift["id"]]: IOpenShiftRequest[] },
      ),
    [openShifts, requesterRosterIds],
  );

  // Sort open shift groups by priority
  const openShiftGroups: TOpenShiftGroup[] = useMemo(() => {
    return sortBy(unsortedOpenShiftGroups, (group) => {
      const groupOpenShiftIds = map(group.openShifts, "id");
      const openShift = group.openShifts[0];
      if (!openShift) return 0;

      const unit = unitsBySchedule?.[openShift.scheduleId];

      let priority = 0;

      // Group with request first
      const requests = values(pick(pendingRequestsByOpenShiftId, groupOpenShiftIds));
      priority += requests.flat().length * 10_000_000;

      // Current unit first, then alphabetical order
      if (unit?.id === currentUnitId) priority += 1_000_000;
      else if (unit) priority += 10_000 * unit.name.charCodeAt(0);

      // sort by start-time, the earlier the first, the more points
      if (openShift.startDateTime)
        priority += 24 - getTzDate(openShift.startDateTime, currentTimezone).hour;

      return priority;
    }).reverse();
  }, [
    unsortedOpenShiftGroups,
    pendingRequestsByOpenShiftId,
    unitsBySchedule,
    currentUnitId,
    currentTimezone,
  ]);

  return useMemo(
    () => ({ openShiftGroups, pendingRequestsByOpenShiftId }),
    [openShiftGroups, pendingRequestsByOpenShiftId],
  );
};

// Make open shifts groupable by tab key
export const buildOpenShiftGroupKey = (openShift: IOpenShift) =>
  [
    openShift.shiftIncentiveLevelId || "__NO_INCENTIVE_LEVEL__",
    openShift.note || "__NO_NOTE__",
    openShift.durationSeconds || "__NO_DURATION__",
    openShift.startDateTime || "__NO_START_DATE_TIME__",
    openShift.staffCategoryKey || "__NO_STAFF_CATEGORY_KEY__",
    openShift.shiftType,
    openShift.date,
    openShift.scheduleId,
  ].join("|");
