import { useMemo } from "react";

import { KeyBy } from "@m7-health/shared-utils";

import { EventUserCreated, EventUserUpdated } from "~/api/eventLog/events";

import { expectedColumnLabels } from "#/features/Roster/BulkCreateUsers/components/DataTable/constants";
import { TExpectedColumn } from "#/features/Roster/BulkCreateUsers/components/DataTable/types";
import { useAppConfigQuery } from "#/features/User/queries";
import { IUnitConfig } from "#/features/User/types";
import { EventLog, useListEventLogsQuery } from "@/api";
import { useCurrentFacilityId } from "@/common/hooks/useCurrentFacilityId";
import { useKeyBy } from "@/common/hooks/utils";

export type LogType = EventLog.DTO<EventUserCreated.Payloads | EventUserUpdated.Payloads>;

export type SearchableLog = {
  searchable: string;
  log: LogType;
  unit?: IUnitConfig;
};

const expectedColumnLabelsEx = {
  ...expectedColumnLabels,
  isSoftDeleted: "suspended",
  softDeletedDate: "termination date",
};

/**
 * Returns user events log with a searchable string and unit per entry and a
 * units by id hash for given facility id or the current facility id.
 * The units by id hash is used for enabling searching by unit name.
 *
 * Search string consists of log entry properties for user values,
 * updated field keys and translated expected column labels, updated values from/to
 * and unit name.
 */
export const useSearchableUserEventLogs = (
  facilityId?: string | null,
): {
  isLoading: boolean;
  searchableLogs: SearchableLog[];
  unitsById: KeyBy<IUnitConfig, "id">;
} => {
  const filters = useMemo(() => {
    return {};
  }, []);

  const currentFacilityId = useCurrentFacilityId();
  const units = useAppConfigQuery().data?.accessibleUnits;
  const unitsById = useKeyBy(
    units?.filter((unit) => unit.facilityId === (facilityId || currentFacilityId)),
    "id",
  );
  const { data: logs, isLoading } = useListEventLogsQuery(filters || {}, { skip: !filters });
  const searchableLogs: {
    searchable: string;
    log: LogType;
  }[] = useMemo(() => {
    return (logs?.filter((log: LogType) => /user_/.test(log.eventType)) ?? [])
      .map((log: LogType) => {
        const unit = unitsById[log.unitId];
        return {
          log,
          searchable: [
            // user values
            ...Object.values(log.eventPayload.user),
            // updated values
            ...Object.values(
              Object.values(
                (log.eventPayload as unknown as EventUserUpdated.Payloads).updatedFields ?? {},
              ).map(({ from, to }: { from: string; to: string }) => [from, to]),
            ),
            // updated props
            ...Object.keys(
              (log.eventPayload as unknown as EventUserUpdated.Payloads).updatedFields ?? {},
            ).map((key) => [
              key,
              (expectedColumnLabelsEx[key as TExpectedColumn] ?? "").toLowerCase(),
            ]),
            // unit name
            unit?.name,
          ]
            .join(",")
            .toLowerCase(),
          unit,
        };
      })
      .reverse(); // last to first

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logs]);

  return { isLoading, unitsById, searchableLogs };
};
