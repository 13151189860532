import { useEffect, useMemo } from "react";

import { Dayjs, getTzDayjs, Timezone, YyyyMmDd } from "@m7-health/shared-utils";
import { entries, filter, groupBy, isEmpty, map, uniq } from "lodash";

import { houseViewStore, THouseViewState } from "#/features/HouseView/store";
import { ISchedulerGridState } from "#/features/SchedulerGrid/store/initialState";
import {
  ISchedule,
  IShiftType,
  ITargetLevel,
  IUnitTargetLevel,
  useListTargetLevelsQuery,
  useListUnitTargetLevelsQuery,
} from "@/api";
import { openShiftsSidebarActions } from "@/common/components/OpenShiftSidebarContent/store";
import { sortingFunction } from "@/common/components/ShiftTypeFilterDropdown/sorting";
import { useAppDispatch, useAppFlags } from "@/common/hooks";
import { TTargetLevelKey } from "@/common/types";
import { getTargetLevelKey } from "@/common/utils/getTargetLevelKey";

export const useSetTargetLevels = ({
  schedules,
  unitIds,
  shiftTypes,
  datesRange,
}: {
  schedules: ISchedule[];
  unitIds: string[];
  shiftTypes: Record<ISchedule["id"], Record<IShiftType["key"], IShiftType>>;
  datesRange: [Dayjs, YyyyMmDd][];
}) => {
  const dispatch = useAppDispatch();
  const { openShiftV2, showSixWeekViewShiftTypeSummary } = useAppFlags();

  const { data: targetLevels, isLoading: targetLevelsAreLoading } = useListTargetLevelsQuery(
    { scheduleIds: map(schedules, "id") },
    { skip: !schedules.length || (!showSixWeekViewShiftTypeSummary && !openShiftV2) },
  );
  const { data: unitTargetLevels, isLoading: unitTargetLevelsAreLoading } =
    useListUnitTargetLevelsQuery(
      { unitIds: unitIds },
      { skip: !unitIds.length || (!showSixWeekViewShiftTypeSummary && !openShiftV2) },
    );

  // Compute target by schedule. If data is missing, return null.
  // Empty result is valid, a schedule may have no targets.
  const targetsBySchedule = useMemo(() => {
    const dataIsLoaded = !!targetLevels && !!unitTargetLevels;
    const stateIsReady = !isEmpty(schedules);
    if (!dataIsLoaded || !stateIsReady) return null;

    const targetsByUnitId = groupBy(unitTargetLevels, "unitId");
    const targetsByScheduleId = groupBy(targetLevels, "scheduleId");

    return schedules.reduce(
      (acc, schedule) => {
        acc[schedule.id] = {
          targetLevelsForSchedule: targetsByScheduleId[schedule.id] || [],
          unitTargetLevelsForSchedule: targetsByUnitId[schedule.unitId] || [],
        };
        return acc;
      },
      {} as {
        [scheduleId in string]: {
          targetLevelsForSchedule: ITargetLevel[];
          unitTargetLevelsForSchedule: IUnitTargetLevel[];
        };
      },
    );
  }, [schedules, targetLevels, unitTargetLevels]);

  useEffect(() => {
    const dataIsReady = !!targetsBySchedule && !isEmpty(shiftTypes) && !isEmpty(datesRange);
    if (!dataIsReady) return;

    // Set target levels
    const allTargetsByScheduleId: THouseViewState["pageData"]["targetLevels"] = {};
    entries(targetsBySchedule).forEach(
      ([scheduleId, { targetLevelsForSchedule, unitTargetLevelsForSchedule }]) => {
        const allTargets = filter([targetLevelsForSchedule, unitTargetLevelsForSchedule])
          .flat()
          .filter((target) => !("shiftTypeKeys" in target) || target.shiftTypeKeys.length === 1)
          .sort((targetA, targetB) => {
            const shiftTypeA =
              "shiftTypeKeys" in targetA
                ? shiftTypes[scheduleId]?.[targetA.shiftTypeKeys[0] as IShiftType["key"]]
                : shiftTypes[scheduleId]?.[targetA.shiftTypeKey];
            const shiftTypeB =
              "shiftTypeKeys" in targetB
                ? shiftTypes[scheduleId]?.[targetB.shiftTypeKeys[0] as IShiftType["key"]]
                : shiftTypes[scheduleId]?.[targetB.shiftTypeKey];
            return (
              sortingFunction(shiftTypeA, shiftTypeB) ||
              targetA.attributeKey?.localeCompare(targetB.attributeKey || "") ||
              0
            );
          });
        const orderedKeys: TTargetLevelKey[] = [];
        const orderedTargets = allTargets.reduce(
          (acc, target) => {
            const shiftTypeKey =
              "shiftTypeKeys" in target
                ? (target.shiftTypeKeys[0] as IShiftType["key"])
                : target.shiftTypeKey;
            const targetKey = getTargetLevelKey({ ...target, shiftTypeKey });
            orderedKeys.push(targetKey);

            acc[targetKey] = {
              shiftTypeKey,
              staffCategoryKey: target.staffCategoryKey,
              staffTypeKey: target.staffTypeKey,
              attributeKey: target.attributeKey,
              label: "label" in target ? target.label : undefined,
              min: target.min,
              shiftTypeKeys: [shiftTypeKey],
            };

            return acc;
          },
          {} as THouseViewState["pageData"]["targetLevels"][string]["orderedTargets"],
        );

        const scheduleTargetsByDate = (targetLevelsForSchedule || [])?.reduce(
          (acc, target) => {
            if (!target) return acc;

            // FIXME: dates are Not UTC.
            // BE stores the same datetime regardless of your timezone for a given day
            const { date } = target;
            const formattedDate = getTzDayjs(date, "UTC" as Timezone).format(
              "YYYY-MM-DD",
            ) as YyyyMmDd;
            const targets = (acc[formattedDate] ||= []);
            targets.push(target);

            return acc;
          },
          {} as { [date in YyyyMmDd]: ITargetLevel[] },
        );

        const unitTargetByDayOfWeek = unitTargetLevelsForSchedule?.reduce(
          (acc, target) => {
            const { daysOfWeek } = target;
            daysOfWeek.forEach((dayOfWeek) => {
              const targets = (acc[dayOfWeek] ||= []);
              targets.push(target);
            });

            return acc;
          },
          {} as { [dayOfWeek in number]: IUnitTargetLevel[] },
        );

        const indexedTargets: ISchedulerGridState["grid"]["data"]["targetLevels"]["byDate"] = {};

        datesRange.forEach(([date, dateKey]) => {
          const dayOfWeek = date.day();
          const scheduleTargets = scheduleTargetsByDate[dateKey];
          const unitTargets = unitTargetByDayOfWeek?.[dayOfWeek];
          const indexedTargetForDay = (indexedTargets[dateKey] ||= {});

          const processTargets = ({
            targets,
            isScheduleTarget,
          }: {
            targets: (ITargetLevel | IUnitTargetLevel)[];
            isScheduleTarget: boolean;
          }) => {
            targets.forEach((target) => {
              const targetKey = getTargetLevelKey(target);

              if (!indexedTargetForDay[targetKey] || isScheduleTarget) {
                indexedTargetForDay[targetKey] = target;
              }
            });
          };

          processTargets({ targets: scheduleTargets || [], isScheduleTarget: true });
          processTargets({ targets: unitTargets || [], isScheduleTarget: false });
        });

        allTargetsByScheduleId[scheduleId] = {
          scheduleTargetLevels: targetLevelsForSchedule || [],
          unitTargetLevel: unitTargetLevelsForSchedule || [],
          orderedTargets,
          orderedKeys: uniq(orderedKeys),
          byDate: indexedTargets,
        };
      },
    );

    // Set aggregated target levels
    const allAggregatedTargetsByScheduleId: THouseViewState["pageData"]["aggregatedTargetLevels"] =
      {};

    entries(targetsBySchedule).forEach(([scheduleId, { unitTargetLevelsForSchedule }]) => {
      const allAggregatedTargets =
        unitTargetLevelsForSchedule?.filter((target) => target.shiftTypeKeys.length > 1) || [];
      const targetLevelKeys: TTargetLevelKey[] = [];
      const keyedTargets = allAggregatedTargets.reduce(
        (acc, target) => {
          const targetKey = getTargetLevelKey(target);
          targetLevelKeys.push(targetKey);

          acc[targetKey] = target;

          return acc;
        },
        {} as THouseViewState["pageData"]["aggregatedTargetLevels"][string]["targets"],
      );

      allAggregatedTargetsByScheduleId[scheduleId] = {
        keys: uniq(targetLevelKeys),
        targets: keyedTargets,
      };
    });

    // Dispatch changes
    dispatch(houseViewStore.state.setTargetLevels(allTargetsByScheduleId));
    dispatch(
      openShiftsSidebarActions.setOpenShiftsSidebarData({ targetLevels: allTargetsByScheduleId }),
    );
    dispatch(houseViewStore.state.setAggregatedTargetLevels(allAggregatedTargetsByScheduleId));
  }, [dispatch, datesRange, shiftTypes, targetsBySchedule]);

  useEffect(() => {
    dispatch(
      houseViewStore.state.setDataLoaders({
        targetLevels: targetLevelsAreLoading || unitTargetLevelsAreLoading,
      }),
    );
  }, [dispatch, targetLevelsAreLoading, unitTargetLevelsAreLoading]);
};
