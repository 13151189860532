import { EventUserUpdated } from "~/api/eventLog/events";

import { IUnitConfig } from "#/features/User/types";
import { EventLog } from "@/api";
import { UserLogType } from "@/common/components/StaffWorkDaySidebar/types";
import { UserCreated } from "@/common/components/StaffWorkDaySidebar/UserCreated";
import { UserUpdated } from "@/common/components/StaffWorkDaySidebar/UserUpdated";

/**
 * Map log user event type to component.
 */
export const componentForSidebarEvents = ({
  log,
  unit,
}: {
  log: UserLogType;
  unit?: IUnitConfig;
}) => {
  if (log.eventType === EventLog.EEventType.userCreated) return UserCreated({ log, unit });
  if (log.eventType === EventLog.EEventType.userUpdated)
    return UserUpdated({ log: log as EventLog.DTO<EventUserUpdated.Payloads>, unit });

  return `Unknown event ${log.eventType}`;
};
