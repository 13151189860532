import { useState } from "react";

import { Dayjs, getTzFormattedDate, THouseViewTimeRange, YyyyMmDd } from "@m7-health/shared-utils";
import { filter } from "lodash";

import { Grid, Tooltip, Typography } from "@mui/material";

import { IScheduleShiftType, StaffCategory } from "~/api";
import { black, mediumGray } from "~/common/theming/colors";
import { useStaffAttributesByKeyForUnit } from "~/routes/hooks/useStaffAttributesByKeyForUnit";

import { returnStaffedColorForTarget } from "#/features/HouseView/hooks/useStaffingTabs";
import { THouseViewState } from "#/features/HouseView/store";
import { AttributeTag } from "@/common/components/AttributeTag/AttributeTag";
import { iconComponentForShift } from "@/common/constants";
import { TTargetLevelKey } from "@/common/types";
import { timeAdd, timeOverlap } from "@/common/utils/dates";
import { getTargetLevelKey } from "@/common/utils/getTargetLevelKey";

import { ShiftTypeWrapper } from "./HeaderCell.styled";

type TShiftTypeSummaryRows = {
  orderedTargets: TTargetLevelKey[];
  filteredTargets?:
    | THouseViewState["pageData"]["targetLevels"][string]["orderedTargets"]
    | THouseViewState["pageData"]["aggregatedTargetLevels"][string]["targets"];
  aggregated?: boolean;
  targetLevelCounts?: Record<TTargetLevelKey, number> | undefined;
  selectedShowTargetLevels?: boolean;
  timeRangesToShow?: THouseViewTimeRange[];
  shiftTypes?: { [shiftTypeKey: IScheduleShiftType["key"]]: IScheduleShiftType };
  onClick?: () => void;
  cellDate?: Dayjs;
  attributesToShow?: ("assigned" | "eligible")[];
  staffAttributesByKey?: ReturnType<typeof useStaffAttributesByKeyForUnit>;
  targetLevelsByDate?: THouseViewState["pageData"]["targetLevels"][string]["byDate"][YyyyMmDd];
};

const getShiftTypesInRange = (
  shiftTypes: (IScheduleShiftType | undefined)[],
  timeRangesToShow?: THouseViewTimeRange[],
) => {
  return filter(shiftTypes, (shiftType) => {
    if (!shiftType) return false;
    const shiftStartTime = shiftType.startTime;
    const shiftDuration = shiftType.durationSeconds;
    const shiftEndTime = timeAdd(shiftStartTime, shiftDuration);
    const shiftTypeInRange = (timeRangesToShow || []).reduce((inRange, timeRange) => {
      const { startTime, endTime } = timeRange;
      const overlapSeconds = timeOverlap(
        { from: shiftStartTime, to: shiftEndTime },
        { from: startTime, to: endTime },
      );
      return inRange || overlapSeconds > 0;
    }, false);
    return shiftTypeInRange;
  });
};

const Count = ({
  orderedTargets,
  aggregated,
  filteredTargets,
  targetLevelCounts,
  selectedShowTargetLevels,
  timeRangesToShow,
  shiftTypes,
  onClick,
  cellDate,
  targetLevelsByDate,
}: TShiftTypeSummaryRows) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  return (
    <>
      {orderedTargets.map((key) => {
        const availableTarget = filteredTargets?.[key];
        if (!availableTarget || !cellDate) return null;
        const { attributeKey, shiftTypeKeys } = availableTarget || {};
        if (attributeKey || !shiftTypeKeys) return null;
        const shiftTypesFromKeys = shiftTypeKeys.map((shiftTypeKey) => shiftTypes?.[shiftTypeKey]);
        if (shiftTypesFromKeys.some((shiftType) => !shiftType)) return null;
        if (aggregated && shiftTypesFromKeys.length < 2) return null;
        if (!aggregated && shiftTypesFromKeys.length > 1) return null;

        const shiftTypesInRange = getShiftTypesInRange(shiftTypesFromKeys, timeRangesToShow);

        if (!shiftTypesInRange.length) return null;

        const actualTarget = targetLevelsByDate?.[key] || availableTarget;
        const count = targetLevelCounts?.[key] || 0;
        const showCountAsterisk = count % 1 !== 0 ? true : false;
        const min = actualTarget.min;
        const backgroundColor = min ? returnStaffedColorForTarget(count, min) : "white";

        const gridCell = (
          <Grid
            onMouseEnter={() => setIsTooltipOpen(true)}
            onMouseLeave={() => setIsTooltipOpen(false)}
            className="summary-row count"
            container
            sx={{
              background: backgroundColor,
            }}
            key={"regular target " + key}
            onClick={onClick}
          >
            <ShiftTypeWrapper>
              <Typography sx={{ fontSize: "0.7rem", color: black }}>
                {Math.floor(count)}
                {showCountAsterisk && <sup>*</sup>}
                {selectedShowTargetLevels && !!min && `/${min}`}
              </Typography>
            </ShiftTypeWrapper>
          </Grid>
        );

        if (!isTooltipOpen) return gridCell;

        return (
          <Tooltip
            title={`View ${cellDate?.format("MMM D, YYYY")}`}
            arrow
            key={`shift-type-summary-row-${key}-${getTzFormattedDate(cellDate)}`}
          >
            {gridCell}
          </Tooltip>
        );
      })}
    </>
  );
};

const Name = ({
  orderedTargets,
  filteredTargets,
  aggregated,
  shiftTypes,
  timeRangesToShow,
}: TShiftTypeSummaryRows) => {
  return (
    <>
      {orderedTargets.map((key) => {
        const availableTarget = filteredTargets?.[key];
        if (!availableTarget) return null;
        const { shiftTypeKeys, staffCategoryKey, attributeKey, label } = availableTarget || {};
        if (attributeKey || !shiftTypeKeys) return null;
        const shiftTypesFromKeys = shiftTypeKeys.map((shiftTypeKey) => shiftTypes?.[shiftTypeKey]);
        if (shiftTypesFromKeys.some((shiftType) => !shiftType)) return null;
        if (aggregated && shiftTypesFromKeys.length < 2) return null;
        if (!aggregated && shiftTypesFromKeys.length > 1) return null;
        const shiftTypesInRange = getShiftTypesInRange(shiftTypesFromKeys, timeRangesToShow);
        if (!shiftTypesInRange.length) return null;

        const IconForShift = iconComponentForShift(
          shiftTypesFromKeys.length > 1 ? undefined : shiftTypesFromKeys[0]?.muiIconClassName,
        );
        const customLabel =
          shiftTypesFromKeys.map((shiftType) => shiftType?.name).join(", ") +
          " " +
          (staffCategoryKey ? StaffCategory.EName[staffCategoryKey] || staffCategoryKey : "");

        return (
          <Grid className="summary-row name" container key={"regular target " + key}>
            <Typography className="summary-row-name-label">
              <IconForShift sx={{ fontSize: "0.75rem" }} /> {label || customLabel}
            </Typography>
          </Grid>
        );
      })}
    </>
  );
};

const AttributeName = ({
  attributesToShow,
  orderedTargets,
  filteredTargets,
  aggregated,
  shiftTypes,
  timeRangesToShow,
  staffAttributesByKey,
}: TShiftTypeSummaryRows) => {
  return (
    <>
      {orderedTargets.map((key) => {
        const availableTarget = filteredTargets?.[key];
        if (!availableTarget) return null;
        const { shiftTypeKeys, staffCategoryKey, staffTypeKey, attributeKey } =
          availableTarget || {};
        if (!attributeKey) return null;
        const attribute = staffAttributesByKey?.[attributeKey];
        if (!attribute) return null;
        if (!shiftTypeKeys) return null;
        const shiftTypesFromKeys = shiftTypeKeys.map((shiftTypeKey) => shiftTypes?.[shiftTypeKey]);
        if (shiftTypesFromKeys.some((shiftType) => !shiftType)) return null;
        if (aggregated && shiftTypesFromKeys.length < 2) return null;
        if (!aggregated && shiftTypesFromKeys.length > 1) return null;
        const shiftTypesInRange = getShiftTypesInRange(shiftTypesFromKeys, timeRangesToShow);
        if (!shiftTypesInRange.length) return null;

        const attributeTag = (
          <AttributeTag
            attribute={attribute}
            sx={{
              "&:hover": { backgroundColor: mediumGray },
              cursor: "pointer",
              boxSizing: "border-box",
              fontSize: "0.5rem",
              marginLeft: 0,
            }}
          />
        );
        return (
          <>
            {attributesToShow?.map((assignOrEligible) => (
              <Grid
                className="summary-row name"
                container
                key={"regular target " + key + assignOrEligible}
              >
                <Typography className="summary-row-name-label">
                  {attributeTag} {assignOrEligible} {attribute.name}{" "}
                  {shiftTypesFromKeys.map((shiftType) => shiftType?.name).join(", ")}{" "}
                  {staffTypeKey ||
                    (staffCategoryKey
                      ? StaffCategory.EName[staffCategoryKey] || staffCategoryKey
                      : "")}
                </Typography>
              </Grid>
            ))}
          </>
        );
      })}
    </>
  );
};

const AttributeCount = ({
  orderedTargets,
  aggregated,
  filteredTargets,
  targetLevelCounts,
  selectedShowTargetLevels,
  timeRangesToShow,
  shiftTypes,
  onClick,
  cellDate,
  attributesToShow,
  targetLevelsByDate,
}: TShiftTypeSummaryRows) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  return (
    <>
      {orderedTargets.map((key) => {
        const availableTarget = filteredTargets?.[key];
        if (!availableTarget || !cellDate) return null;
        const { attributeKey } = availableTarget || {};
        if (!attributeKey) return null;
        const shiftTypeKeys =
          "shiftTypeKeys" in availableTarget
            ? availableTarget.shiftTypeKeys
            : [availableTarget.shiftTypeKey];
        if (!shiftTypeKeys) return null;
        const shiftTypesFromKeys = shiftTypeKeys.map((shiftTypeKey) => shiftTypes?.[shiftTypeKey]);
        if (shiftTypesFromKeys.some((shiftType) => !shiftType)) return null;
        if (aggregated && shiftTypesFromKeys.length < 2) return null;
        if (!aggregated && shiftTypesFromKeys.length > 1) return null;
        const shiftTypesInRange = getShiftTypesInRange(shiftTypesFromKeys, timeRangesToShow);
        if (!shiftTypesInRange.length) return null;

        const eligibleTargetKey = getTargetLevelKey({ ...availableTarget, eligibleOnly: true });

        const actualTarget = targetLevelsByDate?.[key] || availableTarget;
        const assignedCount = targetLevelCounts?.[key] || 0;
        const eligibleCount = targetLevelCounts?.[eligibleTargetKey] || 0;
        const assignedCountLabel =
          assignedCount % 1 !== 0 ? `${Math.floor(assignedCount)}*` : assignedCount;
        const eligibleCountLabel =
          eligibleCount % 1 !== 0 ? `${Math.floor(eligibleCount)}*` : eligibleCount;
        const min = actualTarget.min;
        const assignedBackgroundColor = min
          ? returnStaffedColorForTarget(assignedCount, min)
          : "white";
        const eligibleBackgroundColor = min
          ? returnStaffedColorForTarget(eligibleCount, min)
          : "white";

        const gridCells = attributesToShow?.map((assignOrEligible) => (
          <Grid
            onMouseEnter={() => setIsTooltipOpen(true)}
            onMouseLeave={() => setIsTooltipOpen(false)}
            className="summary-row count"
            container
            sx={{
              background:
                assignOrEligible === "assigned" ? assignedBackgroundColor : eligibleBackgroundColor,
            }}
            key={"regular target " + key + assignOrEligible}
            onClick={onClick}
          >
            <ShiftTypeWrapper>
              <Typography sx={{ fontSize: "0.7rem", color: black }}>
                {assignOrEligible === "assigned" ? assignedCountLabel : eligibleCountLabel}
                {selectedShowTargetLevels && !!min && `/${min}`}
              </Typography>
            </ShiftTypeWrapper>
          </Grid>
        ));

        if (!isTooltipOpen) return gridCells;

        return (
          <>
            {attributesToShow?.map((_assignOrEligible, index) => (
              <Tooltip title={`View ${cellDate?.format("MMM D, YYYY")}`} arrow>
                {gridCells?.[index] || <></>}
              </Tooltip>
            ))}
          </>
        );
      })}
    </>
  );
};

export const ShiftTypeSummaryRows = {
  Count,
  Name,
  AttributeName,
  AttributeCount,
};
