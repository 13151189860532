import {
  getTzDayjs,
  getTzFormattedTime,
  TimeString,
  timeStringToDayjs,
} from "@m7-health/shared-utils";

import { Timelapse } from "@mui/icons-material";
import { Typography } from "@mui/material";

import { IShift } from "~/routes/api/types";

import { IShiftType } from "@/api";
import { useM7SimpleContext } from "@/common/hooks";
import { disabledGray } from "@/common/theming/colors";
import { TSx } from "@/common/types";
import { TimeStringToStandardTime } from "@/common/utils/dates";

export const OpenShiftTime = ({
  openShift,
  shiftType,
  hideTime = false,
  sx,
}: {
  openShift: {
    startDateTime?: string;
    durationSeconds?: number;
  };
  shiftType: IShiftType | IShift;
  hideTime?: boolean;
  sx?: TSx;
}) => {
  const { currentTimezone } = useM7SimpleContext();

  const startISODate = openShift.startDateTime;
  const startTime = startISODate
    ? getTzDayjs(startISODate, currentTimezone)
    : timeStringToDayjs(shiftType.startTime as TimeString, currentTimezone);
  const endTime = startTime.add(openShift.durationSeconds || shiftType.durationSeconds, "seconds");
  const isPartial =
    openShift.durationSeconds && shiftType.durationSeconds !== openShift.durationSeconds;

  return (
    <Typography sx={{ ...sx, position: "relative" }} color={disabledGray}>
      {!hideTime ? (
        <>
          {TimeStringToStandardTime(getTzFormattedTime(startTime))} -{" "}
          {TimeStringToStandardTime(getTzFormattedTime(endTime))}
          {isPartial && <Timelapse sx={{ fontSize: 16, position: "relative", top: 2, ml: 0.5 }} />}
        </>
      ) : (
        <>{isPartial && <Timelapse sx={{ fontSize: 20, position: "relative", top: 2 }} />}</>
      )}
    </Typography>
  );
};
