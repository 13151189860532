import { useEffect, useState } from "react";

import { ShiftType } from "@m7-health/shared-utils";
import { entries, isEqual, values } from "lodash";

import { ITargetLevel, IUnitTargetLevel, StaffCategory } from "@/api";
import { useAppSelector } from "@/common/hooks";

// Read targets from the store, filter them by non-aggregated targets
//  non-attribute targets, and index them by shift type key
export const useOpenShiftTargets = (): {
  [scheduleId: string]: Record<
    StaffCategory.EKey,
    Record<ShiftType.Key, ITargetLevel | IUnitTargetLevel>
  >;
} => {
  const { storeTargets, selectedCategory } = useAppSelector((state) => {
    const date = state.openShiftsSidebar.selectedDate;
    const category = state.schedulerGrid.grid.selectedStaffCategory;

    const targets: { [scheduleId: string]: (ITargetLevel | IUnitTargetLevel)[] } = {};

    entries(state.openShiftsSidebar.data.targetLevels).forEach(
      ([scheduleId, scheduleTargetLevels]) => {
        const scheduleTargetsOnDay = date ? values(scheduleTargetLevels.byDate[date]) : null;
        if (scheduleTargetsOnDay) {
          targets[scheduleId] = scheduleTargetsOnDay;
        }
      },
    );

    return {
      storeTargets: targets,
      selectedCategory: category,
    };
  }, isEqual);

  const [targets, setTargets] = useState<{
    [scheduleId: string]: Record<
      StaffCategory.EKey,
      Record<ShiftType.Key, ITargetLevel | IUnitTargetLevel>
    >;
  }>({});

  useEffect(() => {
    const finalOrderedTargets: {
      [scheduleId: string]: Record<
        StaffCategory.EKey,
        Record<ShiftType.Key, ITargetLevel | IUnitTargetLevel>
      >;
    } = {};
    entries(storeTargets).forEach(([scheduleId, targetsForSchedule]) => {
      const noAttributeTargets = targetsForSchedule.filter((target) => !target.attributeKey);
      const scheduleTargets = noAttributeTargets.filter((target) => "scheduleId" in target);
      const unitTargets = noAttributeTargets.filter((target) => "unitId" in target);

      let orderedTargets = scheduleTargets.reduce(
        (acc, target) => {
          if (!target.staffCategoryKey) return acc;

          if (!acc[target.staffCategoryKey]) {
            acc[target.staffCategoryKey] = {};
          }

          acc[target.staffCategoryKey][target.shiftTypeKey] = target;

          return acc;
        },
        {} as Record<StaffCategory.EKey, Record<ShiftType.Key, ITargetLevel | IUnitTargetLevel>>,
      );

      orderedTargets = unitTargets.reduce((acc, target) => {
        const aggregatedTarget =
          "shiftTypeKeys" in target ? target.shiftTypeKeys.length > 1 : false;
        if (aggregatedTarget) return acc;

        const shiftTypeKey = target.shiftTypeKey;
        const staffCategoryKey = target.staffCategoryKey;
        if (!staffCategoryKey) return acc;

        // Already covered by schedule targets
        if (acc[staffCategoryKey]?.[shiftTypeKey]) return acc;

        if (!acc[staffCategoryKey]) {
          acc[staffCategoryKey] = {};
        }

        acc[staffCategoryKey][shiftTypeKey] = target;

        return acc;
      }, orderedTargets);

      finalOrderedTargets[scheduleId] = orderedTargets;
    });

    setTargets(finalOrderedTargets);
  }, [storeTargets, selectedCategory]);

  return targets;
};
