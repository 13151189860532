import { useCallback, useMemo, useState } from "react";
import "./ApproveOpenShiftModal.scss";

import { dateToTimeString, getTzDayjs, Second, toISO } from "@m7-health/shared-utils";

import { Box, Stack, TextField, Typography } from "@mui/material";

import {
  IOpenShift,
  IOpenShiftRequest,
  IStaffShift,
  IUser,
  useApproveOpenShiftRequestMutation,
  useInvalidateQuery,
  useListNotesQuery,
  useListOpenShiftsQuery,
  useListOrphanOpenShiftRequestsQuery,
  useListScheduleShiftTypeQuery,
  useListStaffShiftsQuery,
} from "@/api";
import { CustomDatePicker } from "@/common/components/DeprecatedDatePicker/DatePicker";
import { CustomModal } from "@/common/components/Modal/Modal";
import { ShiftEditorTimePickers } from "@/common/components/ShiftEditor/TimePickers";
import { IncentiveLevelDropdown } from "@/common/components/ShiftIncentiveLevel/Dropdown";
import { ShiftTypeFilterDropdown } from "@/common/components/ShiftTypeFilterDropdown/ShiftTypeFilterDropdown";
import { NOT_EXISTING_UUID } from "@/common/constants";
import { useCurrentTimezone } from "@/common/hooks";
import { useErrors } from "@/common/hooks/useErrors";
import { useAppDispatch } from "@/common/hooks/useRedux";
import { useToast } from "@/common/hooks/useToast";
import { black } from "@/common/theming";
import { dateString, seconds, TimeString, Uuid } from "@/common/types";

import { openShiftsSidebarActions } from "../../store";

export const ApproveOpenShiftModal = ({
  request,
  scheduleId,
  openShift,
  requester,
  onClose,
}: {
  request: IOpenShiftRequest;
  scheduleId?: Uuid;
  openShift?: IOpenShift;
  requester: IUser;
  onClose: () => void;
}) => {
  const timezone = useCurrentTimezone();

  const dispatch = useAppDispatch();
  const invalidateQuery = useInvalidateQuery();
  const { showSuccess } = useToast();
  const { handleErrors } = useErrors();

  const [partialShiftAttributes, setPartialShiftAttributes] = useState<{
    customStartTime: TimeString | null;
    customDuration: seconds | null;
  }>({
    customStartTime: null,
    customDuration: null,
  });

  const [shiftIncentiveLevelId, setShiftIncentiveLevelId] = useState<Uuid | null | undefined>(
    openShift?.shiftIncentiveLevelId,
  );
  const [shiftNote, setShiftNote] = useState<string>(openShift?.note ?? "");
  const [shiftType, setShiftType] = useState<IStaffShift["shiftTypeKey"] | null | undefined>(
    openShift?.shiftType,
  );
  const [shiftDate, setShiftDate] = useState<dateString | null | undefined>(
    toISO(getTzDayjs(openShift?.date, timezone)),
  );
  const [shiftIsValid, setShiftIsValid] = useState<boolean>(true);

  const { mutate: approveOpenShifts, isPending: isApprovingPending } =
    useApproveOpenShiftRequestMutation({
      onSuccess: () => {
        dispatch(openShiftsSidebarActions.setApproveOpenShiftModalOpen(false));
        showSuccess("The open shift was approved successfully");
        invalidateQuery([
          useListOpenShiftsQuery,
          useListStaffShiftsQuery,
          useListNotesQuery,
          useListOrphanOpenShiftRequestsQuery,
        ]);
        onClose();
      },
      onError: handleErrors,
    });

  const { data: availableShiftTypes = [] } = useListScheduleShiftTypeQuery(
    { scheduleIds: [scheduleId || NOT_EXISTING_UUID] },
    { skip: !scheduleId },
  );

  const selectedAndAvailableShiftType = useMemo(
    () => availableShiftTypes.find(({ key }) => key === shiftType),
    [availableShiftTypes, shiftType],
  );
  const startEndTime = useMemo(() => {
    let startTime = selectedAndAvailableShiftType?.startTime;
    let duration = selectedAndAvailableShiftType?.durationSeconds;

    if (request.startDateTime) startTime = dateToTimeString(request.startDateTime, timezone);
    if (request.durationSeconds) duration = request.durationSeconds;

    if (openShift?.startDateTime) startTime ||= dateToTimeString(openShift.startDateTime, timezone);
    if (openShift?.durationSeconds) duration ||= openShift.durationSeconds;

    if (!startTime || !duration) return undefined;

    return {
      startTime: startTime,
      durationSeconds: duration as Second,
    };
  }, [
    selectedAndAvailableShiftType?.startTime,
    selectedAndAvailableShiftType?.durationSeconds,
    request.startDateTime,
    request.durationSeconds,
    timezone,
    openShift?.startDateTime,
    openShift?.durationSeconds,
  ]);

  const approveOpenShiftHandler = useCallback(() => {
    approveOpenShifts({
      requestId: request.id,
      customStartTime: partialShiftAttributes.customStartTime,
      customDuration: partialShiftAttributes.customDuration,
      shiftIncentiveLevelId: shiftIncentiveLevelId,
      shiftTypeKey: shiftType,
      shiftDate: shiftDate,
      approvalNote: shiftNote,
    });
  }, [
    partialShiftAttributes,
    shiftIncentiveLevelId,
    request.id,
    approveOpenShifts,
    shiftType,
    shiftDate,
    shiftNote,
  ]);

  const modalContent = (
    <Box display="flex" flexDirection="column">
      <Typography variant="h6" className="modal-header">
        Approve Request
      </Typography>
      <Typography variant="body1" className="modal-description" pb={2}>
        Please confirm you would like to approve this request.
      </Typography>
      <Stack direction="column" gap={2}>
        <Box display="flex" flexDirection="column" gap={0.5}>
          <Typography className="update-field">
            Requesting Staff:{" "}
            <b>
              {requester.firstName} {requester.lastName}
            </b>
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={0.5}>
          <Typography className="update-field">Shift Type</Typography>
          <ShiftTypeFilterDropdown
            className="shift-type-dropdown"
            hoursInLabel={"skipInput"}
            shiftTypes={availableShiftTypes || []}
            selectedOption={selectedAndAvailableShiftType}
            selectOption={(staffShiftType) =>
              staffShiftType?.key && setShiftType(staffShiftType.key)
            }
            isMultiSelect={false}
            readonly={true}
            label=""
            emptyOptionLabel={" \u00A0 \u00A0 None"}
            sx={{
              minHeight: "47px",
              ...readonlyShiftTypeDropdownStyles,
            }}
          />
        </Box>

        <Box display="flex" flexDirection="column" gap={0.5}>
          <Typography className="update-field">Date</Typography>
          <CustomDatePicker
            name="shiftDate"
            label=""
            value={getTzDayjs(shiftDate, timezone)}
            onChange={(newDate) => {
              if (newDate) {
                setShiftDate(toISO(newDate));
              }
            }}
            sx={readonlyDatePickerStyles}
            timezone={timezone}
            readOnly={true}
            disabled={true}
          />
        </Box>

        <Box display="flex" flexDirection="column" gap={0.5}>
          <Typography className="update-field">Time</Typography>
          <ShiftEditorTimePickers
            timezone={timezone}
            staffShift={partialShiftAttributes}
            updateStaffShift={setPartialShiftAttributes}
            shiftType={startEndTime || selectedAndAvailableShiftType}
            setShiftValidity={(isValid) => setShiftIsValid(isValid)}
          />
        </Box>

        <Box display="flex" flexDirection="column" gap={0.5} sx={{ marginTop: -2 }}>
          <Typography className="update-field">Incentive</Typography>
          <IncentiveLevelDropdown
            onChange={(event) => {
              const incentiveLevelId = (event.target.value || null) as Uuid | null;
              setShiftIncentiveLevelId(incentiveLevelId);
            }}
            value={shiftIncentiveLevelId || ""}
            name="incentiveLevelId"
            label=""
          />
        </Box>

        <Box display="flex" flexDirection="column" gap={0.5}>
          <Typography className="update-field">Note</Typography>
          <TextField
            multiline
            minRows={3}
            onChange={(event) => {
              const note = event.target.value || "";
              setShiftNote(note);
            }}
            value={shiftNote || ""}
            name="note"
            label=""
          />
        </Box>
      </Stack>
    </Box>
  );

  return (
    <CustomModal
      isOpen={true}
      primaryBtnText="Assign Shift"
      modalContent={modalContent}
      closeDisabled
      modalHeaderText=""
      onSecondaryBtnClick={onClose}
      onSubmit={approveOpenShiftHandler}
      primaryDisabled={isApprovingPending || !shiftIsValid}
      trackingLabel={"ops-approval"}
    />
  );
};

const readonlyShiftTypeDropdownStyles = {
  "& .MuiSelect-icon": {
    display: "none",
  },
  "& .MuiSelect-select": {
    paddingRight: "14px !important",
  },
};

const readonlyDatePickerStyles = {
  "& .MuiInputAdornment-root": {
    display: "none",
  },
  "& .Mui-readOnly": {
    "-webkit-text-fill-color": black,
  },
};
