import { useCallback } from "react";

import { EPermissionVerbs, EUnitPermissionAreas } from "@m7-health/shared-utils";
import { compact, includes } from "lodash";

import {
  ADMIN_PERMISSIONS,
  KIOSK_PERMISSIONS,
  PERMISSION_ALLOW_ALL,
  STAFF_PERMISSIONS,
  TSingularPermission,
} from "~/routes/components/ProtectedRoute/types";

import { NOT_EXISTING_UUID } from "../constants";
import { TMaybeArray } from "../types";

import { useCurrentRole } from "./useCurrentRole";
import { useCurrentUnitId } from "./useCurrentUnitId";
import { useAppSelector } from "./useRedux";
import { useCurrentUserPermissions } from "./useUser";

const groupedVerbs = {
  read: [EPermissionVerbs.readOnly, EPermissionVerbs.manage],
  manage: [EPermissionVerbs.manage],
};

export type TCheckUserPermission = [
  action: keyof typeof groupedVerbs,
  areas: TMaybeArray<EUnitPermissionAreas | TSingularPermission> | undefined,
  options?: {
    unitId?: string;
    skipOverrides?: boolean;
  },
];

/**
 * Custom hook to check one user permissions based on roles and specific areas.
 * It's the preferred way to check permissions if you only need to check one permission.
 * @returns boolean indicating whether the user has the required permission.
 */
export const useCheckUserPermission = (...params: TCheckUserPermission) => {
  const checkUserPermissions = useCheckUserPermissions();
  return checkUserPermissions(...(params as TCheckUserPermission));
};

/**
 * Custom hook to check user permissions based on roles and specific areas.
 * @returns A callback function to check user permissions.
 */
export const useCheckUserPermissions = (parentOptions?: TCheckUserPermission[2]) => {
  const currentUnitId = useCurrentUnitId();
  const { userIsAdmin, userIsKiosk, userIsStaff } = useCurrentRole();
  const {
    permissionsDataByUnit,
    schedulerRosterPermission,
    houseViewPermission,
    shiftReportsPermission,
  } = useCurrentUserPermissions();

  const permissionsOverrides = useAppSelector((state) => state.common.permissionsOverrides);

  return useCallback(
    /**
     * Check if the user has permission for a specific action and area(s).
     * @param action - The type of action to check (e.g., 'read' or 'manage').
     * @param areas - The area(s) to check permissions for.
     * @param options - Optional parameters, including a specific unit ID.
     * @returns A boolean indicating whether the user has the required permission.
     */
    (...params: TCheckUserPermission) => {
      const [action, areaOrAreas, options] = params;
      const areas = compact(Array.isArray(areaOrAreas) ? areaOrAreas : [areaOrAreas]);
      const verbs = groupedVerbs[action];

      // Admins have all permissions, except if they are overridden
      if (userIsAdmin)
        return (
          checkAdminOverride({
            areas,
            skipOverrides: parentOptions?.skipOverrides,
            permissionsOverrides,
            verbs,
          }) ?? true
        );

      // Overrides are not applied for non-admin users
      // Check permissions for each area
      // If any area has the required permission, return true
      return areas.some((area) => {
        switch (area) {
          case PERMISSION_ALLOW_ALL:
            return true;
          case STAFF_PERMISSIONS:
            return userIsStaff;
          case ADMIN_PERMISSIONS:
            return userIsAdmin;
          case KIOSK_PERMISSIONS:
            return userIsKiosk;
          case EUnitPermissionAreas.houseView:
            if (!houseViewPermission) return false;
            // return true if the user has the required permission
            return verbs.includes(houseViewPermission);
          case EUnitPermissionAreas.schedulerRoster:
            if (!schedulerRosterPermission) return false;
            return verbs.includes(schedulerRosterPermission);
          case EUnitPermissionAreas.shiftReports:
            if (!shiftReportsPermission) return false;
            return verbs.includes(shiftReportsPermission);
        }

        // Default: regular permission, as non-admin/non-staff user
        const unitId =
          options?.unitId || parentOptions?.unitId || currentUnitId || NOT_EXISTING_UUID;
        const selectedUnitPermissions = permissionsDataByUnit[unitId] || {};
        const selectedAreaPermission = selectedUnitPermissions[area];
        if (!selectedAreaPermission) return false;

        return verbs.includes(selectedAreaPermission);
      });
    },
    [
      currentUnitId,
      houseViewPermission,
      parentOptions?.unitId,
      parentOptions?.skipOverrides,
      permissionsDataByUnit,
      permissionsOverrides,
      schedulerRosterPermission,
      shiftReportsPermission,
      userIsAdmin,
      userIsKiosk,
      userIsStaff,
    ],
  );
};

// Check if there is an override for specific areas.
// If there is an override, return true or false depending on if the verb is included in the override.
// Otherwise, return null (no override is set).
const checkAdminOverride = ({
  areas,
  skipOverrides,
  permissionsOverrides,
  verbs,
}: {
  areas: (EUnitPermissionAreas | TSingularPermission)[];
  skipOverrides?: boolean;
  permissionsOverrides: Partial<Record<EUnitPermissionAreas, EPermissionVerbs | null>>;
  verbs: EPermissionVerbs[];
}) => {
  if (skipOverrides) return null;

  // Check for each area if there is an override.
  // undefined is: no override is set so we don't check
  for (const area of areas) {
    const override = permissionsOverrides[area as EUnitPermissionAreas];
    if (override === undefined) continue;

    return includes(verbs, override);
  }

  return null;
};
