import { YyyyMmDd } from "@m7-health/shared-utils";

export const scrollSchedulerGridToDate = (date: YyyyMmDd) => {
  const tableToScroll = document.querySelector(".scheduler-table.v3")?.parentElement;

  const dateCellRect = document?.querySelector(`.date-cell-${date}`)?.getBoundingClientRect();
  const tableRect = tableToScroll?.getBoundingClientRect();
  let targetX = 0;

  if (dateCellRect && tableToScroll && tableRect) {
    targetX =
      tableToScroll.scrollLeft +
      (dateCellRect.left - tableRect.left) -
      tableRect.width / 2 +
      dateCellRect.width / 2;
  }

  if (tableToScroll && targetX) {
    tableToScroll.scrollTo({
      left: targetX,
      behavior: "smooth",
    });
  }
};
