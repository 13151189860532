// eslint-disable-next-line deprecate/import
import { useFlags } from "launchdarkly-react-client-sdk";

import { PlatformUpdateInProgressFeatureFlag } from "../components/AppWideModal/types";

import { useDeepMemo } from "./useDeepMemo";
import { useAppSelector } from "./useRedux";

export type TAvailableFeatureFlags = {
  /*
   ** System Permanent flags
   */
  // Flag used to show the platform updates in progress modal
  platformUpdatesInProgress: PlatformUpdateInProgressFeatureFlag;
  // Flag used to disable type to confirm, only OFF in demo envs
  disableTypeToConfirmModals: boolean;

  /*
   ** Feature related permanent flags
   */
  // Mostly ON in non-prod envs. Flag to show FAB related to flags/permission manipulation
  showFlagFab: boolean;
  // Flag to show a specific position as tab (eg: sitter). Combined with hardcoded
  //  position key both on the BE and the FE.
  hvPositionAsTab: boolean;

  /*
   ** New Feature rollout
   */
  openShiftV2: boolean;
  houseViewAllTab: boolean;
  workdayIntegration: boolean;
  useOneWeekView: boolean;
  useNewSingleDayMobileView: boolean;

  /**
   * Feature flags to keep but not to expand, unique states that need to be
   *  handled carefully. Marking as deprecated because we should not use them more,
   *  but we need to decide how we want to keep or remove them.
   *
   * Example: Holiday FF should not be used in any new code, but we don't want to remove
   *  it because we still want to support the existing code that uses it.
   */

  /* @deprecated */
  questionnairesV1: boolean;
  /* @deprecated */
  useHouseviewUnitConfigDefaultFilters: boolean;
  /* @deprecated */
  staffingTargetLevelModalQueuesToInputPatientCount: boolean;
  /* @deprecated */
  holidaySchedule: boolean;
  /* @deprecated */
  schedulerStaffProfileExtraFeatures: boolean;

  /*
   ** Feature flags that are ON in prod for everyone - should be removed
   ** They all must be marked as deprecated individually.
   */
  /* @deprecated TO REMOVE */
  staffNote: boolean;
  /* @deprecated TO REMOVE */
  allowSwapRequestsWithDifferentShiftTypes: boolean;
  /* @deprecated TO REMOVE */
  stayOnPageOnUnitChange: boolean;
  /* @deprecated TO REMOVE */
  newSmsOnboarding: boolean;
  /* @deprecated TO REMOVE */
  m7StaffWelcomeModal: boolean;
  /* @deprecated TO REMOVE */
  massUpdateShiftSchedulerGrid: boolean;
  /* @deprecated TO REMOVE */
  csvUserUploadV2: boolean;
  /* @deprecated TO REMOVE */
  useBulkUpdateStaff: boolean;
  /* @deprecated TO REMOVE */
  showSixWeekViewShiftTypeSummary: boolean;
};

export const useAppFlags = () => {
  const flags = useFlags<TAvailableFeatureFlags>();
  const featureFlagsOverrides = useAppSelector((state) => state.common.featureFlagsOverrides);

  return useDeepMemo(
    () => ({ ...flags, ...featureFlagsOverrides }),
    [flags, featureFlagsOverrides],
  );
};
