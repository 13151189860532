/* eslint-disable @typescript-eslint/naming-convention */

// https://github.com/moment/luxon/blob/master/docs/formatting.md

export enum EDateFormats {
  /** 2nd March 2025 Formatted as 03/02/2025 */
  MmDdYyyy = "MM/dd/y",

  /** 2nd March 2025 Formatted as March 2025 */
  MmmmYyyy = "MMMM y",

  /** 2nd March 2025 Formatted as 2 */
  D = "d",

  /** 2nd March 2025 Formatted as Mon Mar 02, 2025 */
  WkMthDdYyyy = "EEE MMM dd, yyyy",

  /** 2nd March 2025 Formatted as Mar 02, 2025 */
  MthDdYyyy = "MMM dd, yyyy",
}
