import { buildApiListQueryHook } from "../_shared/helpers";

import { OpenShiftRequest } from "./types";

export const useListOpenShiftRequestsQuery = buildApiListQueryHook<
  OpenShiftRequest.API.List.QueryParams,
  OpenShiftRequest.API.List.Response
>(OpenShiftRequest.API.List.PATH);

export const useListOrphanOpenShiftRequestsQuery = buildApiListQueryHook<
  OpenShiftRequest.API.ListOrphans.QueryParams,
  OpenShiftRequest.API.ListOrphans.Response
>(OpenShiftRequest.API.ListOrphans.PATH);
