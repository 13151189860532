import { Fragment, useMemo } from "react";

import { getTzDate, m7DayJs, Timezone } from "@m7-health/shared-utils";
import { DateTime } from "luxon";

import { Box, Typography } from "@mui/material";

import { useCurrentUnitId } from "@/common/hooks";
import {
  SearchableLog,
  useSearchableUserEventLogs,
} from "@/common/hooks/useSearchableUserEventLogs";

import { componentForSidebarEvents } from "./componentForSidebarEvents";

/**
 * Display given user event logs by updatedAt date with optional filtering by search.
 */
export const WorkdayContent = ({ search }: { search: string }) => {
  const { searchableLogs, isLoading, unitsById } = useSearchableUserEventLogs();
  const currentUnitId = useCurrentUnitId();

  // hash given searched logs by updatedAt date.
  const getByDate = (slogs: SearchableLog[]) =>
    slogs.reduce(
      (hash, searchable) => {
        const updatedAt = searchable.log.updatedAt.substring(0, 10);

        if (!hash[updatedAt]) {
          hash[updatedAt] = [];
        }
        hash[updatedAt]!.push(searchable);

        return hash;
      },
      {} as Record<string, SearchableLog[]>,
    );

  // filter searchableLogs searchable by search
  const searchLogs = useMemo(() => {
    if (search.length || currentUnitId) {
      return searchableLogs.filter(({ log, searchable }) => {
        return searchable.indexOf(search) != -1 && (!currentUnitId || currentUnitId === log.unitId);
      });
    }

    return searchableLogs;
  }, [search, searchableLogs, currentUnitId]);

  const byDate = getByDate(searchLogs);

  return (
    <>
      <Box p={2} className={"sidebar-timeline"}>
        {!isLoading && (
          <>
            {searchLogs.length === 0 &&
              (!search ? (
                <span
                  data-testid={"no-events"}
                >{`No event logs found${currentUnitId ? ` for unit "${unitsById?.[currentUnitId]?.name ?? "current unit"}"` : ""}.`}</span>
              ) : (
                <span data-testid={"no-events-searched"}>
                  No event logs found when searching for "
                  <span data-testid={"search"}>{search}</span>".
                </span>
              ))}

            {Object.keys(byDate).map((key) => {
              return (
                <Fragment key={key}>
                  <Box className={"log-by-date-container"}>
                    <Box className={"log-date"}>
                      <Typography>{m7DayJs(key).format("MMM D")}</Typography>
                    </Box>

                    {byDate[key]?.map(({ log, unit }) => {
                      return (
                        <Fragment key={log.id}>
                          <Box className={"log-entry"} data-testid={"log-entry"}>
                            <Box className={"log-description"} data-testid={"description"}>
                              {componentForSidebarEvents({ log, unit })}
                            </Box>
                            <Box className={"log-timestamp"} data-testid={"timestamp"}>
                              {getTzDate(
                                DateTime.fromISO(log.updatedAt),
                                unit?.timezone as Timezone,
                              ).toFormat("t")}
                            </Box>
                          </Box>
                        </Fragment>
                      );
                    })}
                  </Box>
                </Fragment>
              );
            })}

            {searchLogs.length > 0 && (
              <Box className={"displaying"} data-testid={"displaying"}>
                Displaying {searchLogs.length} of {searchableLogs.length} total entries.
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
};
