import { useCallback, useMemo } from "react";

import { compact, isEqual, uniq } from "lodash";

import {
  TBulkUpdateStaff,
  useBulkUpdateStaff,
  useInvalidateQuery,
  useListPreferenceRequirementRuleSetsQuery,
  useListStaffDetailsQuery,
  User,
} from "@/api";
import { useAppDispatch, useAppSelector, useErrors, useToast } from "@/common/hooks";

import { clearUpdatedUsers } from "../../store";

export const useSaveUpdates = () => {
  const invalidateQueries = useInvalidateQuery();
  const { showSuccess } = useToast();
  const { handleErrors } = useErrors();
  const dispatch = useAppDispatch();

  const { mutate: bulkUpdateStaff, isPending: isBulkUpdateStaffPending } = useBulkUpdateStaff({
    onSuccess: () => {
      showSuccess("Staff roster updated successfully");
      invalidateQueries(useListStaffDetailsQuery);
      invalidateQueries(useListPreferenceRequirementRuleSetsQuery);
      dispatch(clearUpdatedUsers());
    },
    onError: (error) => handleErrors(error),
  });
  const updatedUsers = useAppSelector(
    (state) => state.roster.bulkUpdateStaff.updatedUsers,
    isEqual,
  );

  const saveUpdates = useCallback(() => {
    const staffToBulkSave: TBulkUpdateStaff = Object.entries(updatedUsers).map(
      ([userId, user]) => ({
        userId,
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber,
        email: user.email,
        //Never remove home unit when updating staff
        unitIds: uniq([...(user.unitIds || []), user.staffDetails?.homeUnitId || ""]),
        //Never remove staff or kiosk roles when updating staff
        roles: uniq([User.ERole.kiosk, User.ERole.staff, ...(user.roles || [])]),
        staffDetails: {
          employmentType: user.staffDetails?.employmentType || undefined,
          shiftType: user.staffDetails?.shiftType,
          preferenceRequirementRuleSetIds: compact(
            user.staffDetails?.preferenceRequirementRuleSetIds || [],
          ),
          employmentStartDate: user.staffDetails?.employmentStartDate || undefined,
          staffTypeKey: user.staffDetails?.staffTypeKey || undefined,
          attributeKeys: compact(user.staffDetails?.attributeKeys || []),
          preceptor: user.staffDetails?.preceptor ?? null,
          onOrientation: user.staffDetails?.onOrientation ?? null,
          orientationEndDate: user.staffDetails?.orientationEndDate || undefined,
          preferencesTemplateId: user.staffDetails?.preferencesTemplateId,
        },
      }),
    );

    bulkUpdateStaff({ staffToBulkSave });
  }, [bulkUpdateStaff, updatedUsers]);

  return useMemo(
    () => ({ saveUpdates, isBulkUpdateStaffPending }),
    [saveUpdates, isBulkUpdateStaffPending],
  );
};
