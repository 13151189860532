import {
  ECommunicationMethod,
  ISODateString,
  User as SharedM7User,
  ELoginCommunicationType,
} from "@m7-health/shared-utils";

import { IUnitBasic } from "~/routes/api/types";

import { IUserWithDetails } from "#/features/Roster/components/UserTable/types";

import {
  APIElementResponse,
  ApiEmptyResponse,
  APIListResponse,
  ApiResponse,
  SDTO,
} from "../_shared/types";
import { IStaffCategory } from "../staffCategory";
import { IStaffDetails } from "../staffDetails";

export type IUser = User.DTO;

export namespace User {
  export enum ERole {
    admin = "admin",
    scheduler = "scheduler",
    staff = "staff",
    kiosk = "kiosk",
  }

  export interface IRole {
    id: number;
    name: ERole;
  }

  export interface DTO extends SDTO.base, SDTO.withTimestamps, SDTO.softDeletable {
    unitIds?: IUnitBasic["id"][];
    roles: ERole[];
    isSoftDeleted?: boolean;
    softDeletedDate?: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: string | null;
    lastLoggedInAt: ISODateString | null;
    onboardingStatus: SharedM7User.EOnboardingStatus;
  }

  export namespace API {
    export const ROOT_PATH = "/user";

    export namespace List {
      export const PATH = ROOT_PATH;
      export interface QueryParams {
        unitIds?: IUnitBasic["id"][];
        ids?: IUser["id"][];
        roles?: DTO["roles"];
        staffCategories?: IStaffCategory["key"][];
        suspended?: boolean[];
      }

      export type Response = APIListResponse<DTO>;
    }

    export namespace GetNotificationsCount {
      export const PATH = `${ROOT_PATH}/notifications-count`;
      export interface QueryParams {
        version: "2";
      }

      export type Response = APIElementResponse<{
        // eslint-disable-next-line @typescript-eslint/naming-convention
        OPSRequestCount: number;
        approvalRequestCount: number;
      }>;
    }

    export namespace TriggerLoginCommunication {
      export const PATH = `${ROOT_PATH}/trigger-login-communication`;
      export interface QueryParams {
        userIds: IUser["id"][];
        preferredCommunicationMethod: ECommunicationMethod;
        loginCommunicationType: ELoginCommunicationType;
      }

      export type Response = ApiEmptyResponse;
    }

    export namespace UpdateUser {
      export const PATH = (args: QueryParams) => `${ROOT_PATH}/${args.id}`;
      export interface QueryParams {
        id: IUser["id"];
        onboardingStatus?: SharedM7User.EOnboardingStatus;
      }
      export type Response = ApiResponse<DTO>;
    }
    export namespace BulkUploadUsers {
      export const PATH = `${ROOT_PATH}/bulk-upload`;
      export interface QueryParams {
        users: CreateStaffUserDTO[];
        sendResetPasswordMethod: ECommunicationMethod | null;
      }

      export type Response = ApiEmptyResponse;
    }

    export namespace BulkUpdateStaff {
      export const PATH = `${ROOT_PATH}/staff/bulk-save`;
      export interface QueryParams {
        staffToBulkSave: TBulkUpdateStaff;
      }

      export type Response = ApiEmptyResponse;
    }
  }
}

type TStaffToBulkUpdate = { userId: string } & Partial<
  Omit<IUserWithDetails, "staffDetails"> & {
    staffDetails: Partial<
      Pick<
        IStaffDetails,
        | "attributeKeys"
        | "contractEndDate"
        | "employmentStartDate"
        | "employmentType"
        | "onOrientation"
        | "orientationEndDate"
        | "preceptor"
        | "preferenceRequirementRuleSetIds"
        | "preferencesTemplateId"
        | "staffTypeKey"
        | "shiftType"
      >
    >;
  }
>;
export type TBulkUpdateStaff = TStaffToBulkUpdate[];

export type CreateStaffUserDTO = Pick<
  IUser & IStaffDetails,
  | "email"
  | "firstName"
  | "lastName"
  | "phoneNumber"
  | "staffTypeKey"
  | "preceptor"
  | "onOrientation"
  | "orientationEndDate"
  | "employmentType"
  | "contractEndDate"
  | "employmentStartDate"
  | "shiftType"
  | "homeUnitId"
  | "roles"
  | "isSoftDeleted"
  | "softDeletedDate"
>;
