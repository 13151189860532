import { IUnit } from "@/api";
import { useMap } from "@/common/hooks";

import { useUserTableUnitData } from "../../ContextProvider";

import { SingleDropdownEditor, SingleDropdownRenderer } from "./Dropdown";
import { TCellComponent } from "./types";

const useUnitsOptions = () => {
  const units = useUserTableUnitData().units.values;

  return useMap(units, (unit) => ({
    label: unit.name + (unit.archivedAt ? " (archived)" : ""),
    value: unit.id,
  }));
};

const HomeUnitEditor: TCellComponent<IUnit["id"]>["editor"] = (params) => (
  <SingleDropdownEditor<IUnit["id"]> options={useUnitsOptions()} {...params} />
);

const HomeUnitRenderer: TCellComponent<IUnit["id"]>["readonly"] = (params) => (
  <SingleDropdownRenderer options={useUnitsOptions()} {...params} />
);

export const HomeUnitCell: TCellComponent<IUnit["id"]> = {
  readonly: HomeUnitRenderer,
  editor: HomeUnitEditor,
};
