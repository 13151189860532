import { useCurrentFacility } from "@/common/hooks/useCurrentFacilityId";

import { useAppFlags } from "./useAppFlags";

/**
 *  Returns external user management settings.
 */
export const useExternalUserManagement = (): boolean => {
  const ffOn = useAppFlags().workdayIntegration ?? false;
  const facilityConfig = useCurrentFacility()?.configuration;
  const enabled: boolean = facilityConfig?.settings?.useExternalUserIntegration ?? false;

  return ffOn && enabled;
};
