import { useCallback, useEffect, useState } from "react";

// expected to be used here
// eslint-disable-next-line deprecate/import
import { useFlags } from "launchdarkly-react-client-sdk";
import { entries, keys } from "lodash";

import { Close, Flag } from "@mui/icons-material";
import {
  Avatar,
  Box,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
} from "@mui/material";

import { User } from "@/api";
import { CustomButton, CustomTabs } from "@/common/components";
import { CustomTabPanel } from "@/common/components/TrackedComponents/Tabs/TabPanel/TabPanel";
import {
  TAvailableFeatureFlags,
  useAppDispatch,
  useAppFlags,
  useAppStore,
  useM7SimpleContext,
  useToast,
} from "@/common/hooks";
import { resetFeatureFlagsOverrides, setFeatureFlags } from "@/common/store";
import { isOnMobile } from "@/common/utils/isOnMobile";
import { usePrivateBuildLaunchDarklyContext } from "@/common/utils/useLaunchDarkly";
import "./FeatureFlagFAB.scss";

type TTabs = "flags" | "context";
const tabs: { label: string; value: TTabs }[] = [
  { label: "Flags", value: "flags" },
  { label: "Context", value: "context" },
];

export const FeatureFlagFAB = () => {
  const dispatch = useAppDispatch();
  const originalFlags = useFlags();
  const appFlags = useAppFlags();
  const isMobile = isOnMobile();
  const store = useAppStore();
  const { showInfo } = useToast();
  const m7LaunchDarklyContext = usePrivateBuildLaunchDarklyContext();

  const [isOpen, setIsOpen] = useState(false);
  const [tab, setTab] = useState<TTabs>("flags");

  const m7SimpleContext = useM7SimpleContext();
  const isAdmin = m7SimpleContext.currentRole === User.ERole.admin;

  const dispatchFeatureFlag = useCallback(
    <T extends keyof TAvailableFeatureFlags>(key: T, value: TAvailableFeatureFlags[T] | null) => {
      dispatch(setFeatureFlags({ key, value }));
    },
    [dispatch],
  );

  // If remote flags are updated, reset all overrides
  useEffect(() => {
    if (keys(store.getState().common.featureFlagsOverrides).length > 0) {
      showInfo("Remote flags updated, resetting all overrides");
      dispatch(resetFeatureFlagsOverrides());
    }
  }, [dispatch, originalFlags, showInfo, store]);

  const copy = useCallback(
    (value: string, message: string) => {
      void window.navigator.clipboard.writeText(value).then(() => showInfo(message));
    },
    [showInfo],
  );

  if (isMobile || !isAdmin || !appFlags.showFlagFab) return null;

  const title =
    tab === "flags"
      ? `Active Feature Flags (${Object.keys(originalFlags).length})`
      : "Current LaunchDarkly Context";

  return (
    <Card className={"FeatureFlagFAB hide-on-print " + (isOpen ? "expanded" : "contracted")}>
      <CardHeader
        avatar={
          <Avatar className="logo-open-button" onClick={() => setIsOpen(!isOpen)}>
            <Flag fontSize="large" />
          </Avatar>
        }
        action={
          <IconButton aria-label="close" onClick={setIsOpen.bind(null, false)}>
            <Close />
          </IconButton>
        }
        title={title}
      />
      <CardContent className="content">
        <Box className="feature-flags-list">
          <CustomTabs
            value={tab}
            onChange={(newValue) => setTab(newValue)}
            tabs={tabs}
            tracking={false}
          />
          <CustomTabPanel value={tab} tabKey="flags">
            <>
              {entries(originalFlags).map(([key, value]) =>
                flagActionFor(
                  key as keyof TAvailableFeatureFlags,
                  value as TAvailableFeatureFlags[keyof TAvailableFeatureFlags],
                  appFlags[key as keyof TAvailableFeatureFlags],
                  dispatchFeatureFlag,
                  copy,
                ),
              )}
            </>
          </CustomTabPanel>
          <CustomTabPanel value={tab} tabKey="context">
            <pre>{JSON.stringify(m7LaunchDarklyContext, null, 2)}</pre>
          </CustomTabPanel>
        </Box>
      </CardContent>
      <CardActions disableSpacing sx={{ mt: 4 }}>
        <CustomButton children="Reset all" onClick={() => dispatch(resetFeatureFlagsOverrides())} />
        <Box flexGrow={1} />
        <CustomButton
          variant="text"
          children="Close"
          onClick={setIsOpen.bind(null, false)}
          trackingLabel={null}
        />
      </CardActions>
    </Card>
  );
};

const flagActionFor = (
  key: keyof TAvailableFeatureFlags,
  originalValue: TAvailableFeatureFlags[keyof TAvailableFeatureFlags],
  appValue: TAvailableFeatureFlags[keyof TAvailableFeatureFlags],
  action: (
    key: keyof TAvailableFeatureFlags,
    value: TAvailableFeatureFlags[keyof TAvailableFeatureFlags],
  ) => void,
  copy: (value: string, message: string) => void,
) => {
  if (Boolean(originalValue) !== originalValue) return;

  return (
    <ButtonGroup key={key} className="key-value-line">
      <CustomButton
        variant="outlined"
        trackingLabel={null}
        className="keyLabel"
        onClick={() => copy(key, "Key copied: " + key)}
        children={labelFor(key)}
      />
      <CustomButton
        variant={appValue === true && originalValue !== appValue ? "contained" : "outlined"}
        color={appValue === true ? "darkPurple" : "primary"}
        trackingLabel={null}
        className="value"
        children={"true" + (originalValue ? " (original)" : "")}
        onClick={() => action(key, true)}
      />
      <CustomButton
        variant={appValue === false && originalValue !== appValue ? "contained" : "outlined"}
        color={appValue === false ? "darkPurple" : "primary"}
        trackingLabel={null}
        className="value"
        children={"false" + (!originalValue ? " (original)" : "")}
        onClick={() => action(key, false)}
      />
    </ButtonGroup>
  );
};

// camelCase to human readable
const labelFor = (key: keyof TAvailableFeatureFlags) => {
  return key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase());
};
